import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { CancelorRefund, getEmployerDetails } from "../store/slices/employer";
import { Link, useParams, useNavigate } from "react-router-dom";
import Moment from "moment";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import empimg from "../../src/Assets/Images/restaurant.png";
import Table from "react-bootstrap/Table";
import { FaStar, FaRegStar } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { MdOutlineEmail } from "react-icons/md";
import {
  resetPassword,
  suspendAccount,
  deleteAccount,
  activateAccount,
} from "../store/slices/config";
import ShowprofileSkeleton from "./ProfileSkeleton";
import DataSkeleton from "./dataskeleton";
import { AllowMacs, getAllowedMacs } from "../store/slices/activity";
import ReviewModal from "../utils/helpers/ReviewModal";

export function StarRating1({ value }) {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <span key={index}>
      {index < value ? (
        <FaStar color="#ffc107" style={{ marginRight: "10px" }} />
      ) : (
        <FaRegStar color="grey" style={{ marginRight: "10px" }} />
      )}
    </span>
  ));

  return <div>{stars}</div>;
}

function EmployerDetails() {
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  const [isLoading, setIsloading] = useState(true);

  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  };
  const navigate = useNavigate();
  const { id } = useParams();
  localStorage.setItem("employerId", id);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [transactionsData, settransactionsData] = useState([]);
  const [jobsData, setjobsData] = useState([]);
  const [reviews, setReviews] = useState(false);
  const [reviewModal, setShowReviewModal] = useState(false);

  const [message, setmessage] = useState("Do you want to reset password?");
  const [smessage, setsmessage] = useState(
    "Do you want to suspend this account?"
  );
  const [dmessage, setdmessage] = useState(
    "Do you want to delete this account?"
  );
  const [amessage, setamessage] = useState(
    "Do you want to activate this account?"
  );

  const calculateDateDifference = (startDate, endDate) => {
    const startDateMoment = Moment(startDate, "YYYY-MM-DD");
    const endDateMoment = Moment(endDate, "YYYY-MM-DD");

    if (startDateMoment.isValid() && endDateMoment.isValid()) {
      return endDateMoment.diff(startDateMoment, "days");
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (data && data?.ratingsList) {
      // Check if any rating has a comment
      const hasComment = data?.ratingsList.some((rating) => rating.comment);
      setReviews(hasComment);
    }
  }, [data]);

  const resetPassword1 = (id, type) => {
    confirmAlert({
      title: "Reset Password",
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: () => resetpassword2(id, type),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Employer Details";
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  }, []);

  const resetpassword2 = (id, type) => {
    const item = { id, type };
    dispatch(resetPassword(item))
      .unwrap()
      .then((data) => {
        alert(data.user.message);
      })
      .catch(({ message }) => {});
  };

  React.useEffect(() => {
    getDetails();
  }, [dispatch]);

  const getDetails = () => {
    dispatch(getEmployerDetails(id))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
        settransactionsData(data.users.data.transactionsData);
        setjobsData(data?.users.data?.jobsData);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const suspendAcc1 = (id, role) => {
    confirmAlert({
      title: "Suspend account",
      message: smessage,
      buttons: [
        {
          label: "Yes",
          onClick: () => suspendAcc2(id, role),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const suspendAcc2 = (id, role) => {
    const item = { id, role };
    dispatch(suspendAccount(item))
      .unwrap()
      .then((data) => {
        alert(data.user.message);
        window.location.reload(true);
      })
      .catch(({ message }) => {});
  };

  const activateAcc1 = (id, role) => {
    confirmAlert({
      title: "Activate account",
      message: amessage,
      buttons: [
        {
          label: "Yes",
          onClick: () => activateAcc2(id, role),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const activateAcc2 = (id, role) => {
    const item = { id, role };
    dispatch(activateAccount(item))
      .unwrap()
      .then((data) => {
        alert(data?.user.message);
        window.location.reload(true);
      })
      .catch(({ message }) => {});
  };

  const deleteAcc1 = (id, role) => {
    confirmAlert({
      title: "Delete account",
      message: dmessage,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteAcc2(id, role),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteAcc2 = (id, role) => {
    const item = { id, role };
    dispatch(deleteAccount(item))
      .unwrap()
      .then((data) => {
        alert(data.user.message);
        navigate(-1);
      })
      .catch(({ message }) => {});
  };

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;

  const CancelandRefund = (elem) => {
    confirmAlert({
      title: "Cancel Plan",
      message: "Do you want to cancel this plan? ",
      buttons: [
        {
          label: "Yes",
          onClick: () => CancelandRefund1(elem),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const CancelandRefund1 = (elem) => {
    const item = {
      userId: elem.employerId,
      status: "refundrequested",
      pointsId: elem?.planTransactionId?.employerPointsId,
      userType: "employer",
    };

    dispatch(CancelorRefund(item))
      .unwrap()
      .then((data) => {
        alert(data?.users.message);
        //navigate(-1);
        getDetails();
      })
      .catch(({ message }) => {});
  };

  const UpgradePlan = (elem) => {
    navigate(`/upgrade-employer-plan`, {
      state: { employerdata: elem },
    });
  };

  const AllowMacAdress = (data) => {
    allowMac(data.loginMAC, data.registerMAC);
  };

  const allowMac = (value, value2) => {
    const item = {
      macAddress: value,
    };
    dispatch(AllowMacs(item))
      .unwrap()
      .then((data) => {
        allowMac1(value2);
      })
      .catch(({ message }) => {});
  };

  const allowMac1 = (value) => {
    const item = {
      macAddress: value,
    };
    dispatch(AllowMacs(item))
      .unwrap()
      .then((data) => {
        alert(data?.allusers?.message);
      })
      .catch(({ message }) => {});
  };

  return (
    <>
      <div>
        <Navbartop />
        <ReviewModal
          showModal={reviewModal}
          data={data}
          setShowModal={setShowReviewModal}
          role="employer"
        />
        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-2 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;{" "}
                      <Link to="/employer">Employer</Link> &#8811; Employer
                      Details
                    </div>
                    {isLoading ? (
                      <Row>{ShowprofileSkeleton()}</Row>
                    ) : (
                      <div className="card">
                        <div className="card-header">
                          <Row>
                            <Col xs={4} md={2} lg={2} xl={2}>
                              {data.dp ? (
                                <img
                                  src={data.dp}
                                  alt=""
                                  style={{
                                    height: "90px",
                                    width: "90px",
                                    borderRadius: "20%",
                                  }}
                                  className="mt-2"
                                />
                              ) : (
                                <img
                                  src={empimg}
                                  alt=""
                                  style={{
                                    height: "90px",
                                    width: "90px",
                                    borderRadius: "20%",
                                  }}
                                  className="mt-2"
                                />
                              )}
                            </Col>
                            <Col
                              xs={6}
                              md={4}
                              lg={4}
                              xl={4}
                              align="left"
                              className="ps-2"
                            >
                              <h5 style={{ fontWeight: "bold" }}>
                                {data.fullName}
                              </h5>
                              <p>
                                <HiOutlineDevicePhoneMobile />
                                &nbsp;{data.mobile}
                              </p>
                              {data?.whatsappNumber && (
                                <p>
                                  <FaWhatsapp />
                                  &nbsp;{data?.whatsappNumber}
                                </p>
                              )}
                              <p>
                                <MdOutlineEmail />
                                &nbsp;{data.email}
                              </p>
                            </Col>
                            <Col
                              className="d-none d-md-block d-lg-block"
                              md={4}
                              lg={4}
                              xl={4}
                              align="left"
                            >
                              <h5 style={{ fontWeight: "bold" }}>
                                {data?.employeeMemberId}
                              </h5>
                              {data?.status === 1 && (
                                <p>
                                  Profile Status:{" "}
                                  <span style={{ color: "#42f554" }}>
                                    Active
                                  </span>
                                </p>
                              )}
                              {data?.status === 2 && (
                                <p>
                                  Profile:{" "}
                                  <span style={{ color: "#f59942" }}>
                                    Disable
                                  </span>
                                </p>
                              )}
                              {data?.status === 3 && (
                                <p>
                                  Profile:{" "}
                                  <span style={{ color: "#d42708" }}>
                                    Suspended
                                  </span>
                                </p>
                              )}
                              {data?.status === 4 && (
                                <p>
                                  Profile:{" "}
                                  <span style={{ color: "#080fd4" }}>
                                    Delete Requested
                                  </span>
                                </p>
                              )}
                              <p>
                                Regd. Date:&nbsp;
                                {Moment.utc(data?.createdAt).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                              <p>
                                Last Login:&nbsp;
                                {Moment.utc(data?.lastLoginDateTime).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                            </Col>
                            <Col xs={1} md={1} lg={2} align="right">
                              {role !== "Associate" && (
                                <Dropdown
                                  style={{
                                    backgroundColor: "none",
                                  }}
                                >
                                  <Dropdown.Toggle
                                    // id="dropdown-basic"
                                    style={{
                                      fontSize: "20px",
                                      backgroundColor: "none",
                                      border: "none",
                                      marginTop: "-10px",
                                    }}
                                  >
                                    <BiDotsVerticalRounded
                                      style={{
                                        marginLeft: "-5px",
                                        marginTop: "-2px",
                                        color: "#000",
                                      }}
                                    />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {role === "admin" && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          deleteAcc1(id, "employer")
                                        }
                                      >
                                        Delete account
                                      </Dropdown.Item>
                                    )}
                                    {role === "Manager" && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          deleteAcc1(id, "employer")
                                        }
                                      >
                                        Delete account
                                      </Dropdown.Item>
                                    )}
                                    {data?.status === 3 && (
                                      <div>
                                        {role === "admin" && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              activateAcc1(id, "employer")
                                            }
                                          >
                                            Activate account
                                          </Dropdown.Item>
                                        )}
                                      </div>
                                    )}

                                    {data?.status !== 3 && (
                                      <div>
                                        {role === "admin" && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              suspendAcc1(id, "employer")
                                            }
                                          >
                                            Deactivate account
                                          </Dropdown.Item>
                                        )}
                                      </div>
                                    )}
                                    <Dropdown.Item
                                      onClick={() =>
                                        resetPassword1(id, "employer")
                                      }
                                    >
                                      Reset password
                                    </Dropdown.Item>
                                    {role === "admin" && (
                                      <Dropdown.Item>
                                        <Link
                                          to={`/assign-employer-plan/${id}`}
                                          style={{ color: "#000" }}
                                        >
                                          Add Plan
                                        </Link>
                                      </Dropdown.Item>
                                    )}

                                    {(role === "admin" ||
                                      role === "Manager") && (
                                      <Dropdown.Item>
                                        <Link
                                          to={`/post-job/${id}`}
                                          style={{ color: "#000" }}
                                        >
                                          Post Job
                                        </Link>
                                      </Dropdown.Item>
                                    )}

                                    {role === "admin" && (
                                      <Dropdown.Item>
                                        <div
                                          style={{ color: "#000" }}
                                          onClick={() => AllowMacAdress(data)}
                                        >
                                          Allow MAC Address
                                        </div>
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </Col>
                            <Col
                              className="d-sm-block d-md-none d-lg-none"
                              sm={8}
                              align="left"
                            >
                              <h5 style={{ fontWeight: "bold" }}>
                                {data?.employeeMemberId}
                              </h5>
                              {data?.status === 1 && (
                                <p>
                                  Profile Status:{" "}
                                  <span style={{ color: "#42f554" }}>
                                    Active
                                  </span>
                                </p>
                              )}
                              {data?.status === 2 && (
                                <p>
                                  Profile:{" "}
                                  <span style={{ color: "#f59942" }}>
                                    Disable
                                  </span>
                                </p>
                              )}
                              {data?.status === 3 && (
                                <p>
                                  Profile:{" "}
                                  <span style={{ color: "#d42708" }}>
                                    Suspended
                                  </span>
                                </p>
                              )}
                              {data?.status === 4 && (
                                <p>
                                  Profile:{" "}
                                  <span style={{ color: "#080fd4" }}>
                                    Delete Requested
                                  </span>
                                </p>
                              )}
                              <p>
                                Regd. Date:&nbsp;
                                {Moment.utc(data?.createdAt).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                              <p>
                                Last Login:&nbsp;
                                {Moment.utc(data?.lastLoginDateTime).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                    {isLoading ? (
                      <Row>{DataSkeleton()}</Row>
                    ) : (
                      <Tabs
                        defaultActiveKey="profile"
                        id="justify-tab-example"
                        justify
                      >
                        <Tab eventKey="profile" title="Profile">
                          <Row className="card pb-3">
                            <Row>
                              <Col xs={12} md={6} lg={6} className="ps-4 mt-2">
                                <div align="right">
                                  {role !== "Associate" && (
                                    <Link
                                      to={`/editemployer-details/${id}`}
                                      style={{
                                        color: "#ff5c09",
                                        fontWeight: "600",
                                        borderBottom: "1px solid #ff5c09",
                                      }}
                                    >
                                      Edit
                                    </Link>
                                  )}
                                </div>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Email</p>
                                  <p className="text-details">{data.email}</p>
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">
                                    Whatsapp Number
                                  </p>
                                  {data.whatsappNumber && (
                                    <p className="text-details">
                                      {data.whatsappNumber}
                                    </p>
                                  )}
                                </Col>{" "}
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Website</p>
                                  {data.website && (
                                    <p className="text-details">
                                      {data.website}
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Property Type</p>
                                  {data.propertyType === 1 && (
                                    <p className="text-details">Hotel</p>
                                  )}
                                  {data.propertyType === 2 && (
                                    <p className="text-details">Restaurant</p>
                                  )}
                                  {data.propertyType === 3 && (
                                    <p className="text-details">Cafe</p>
                                  )}
                                  {data.propertyType === 4 && (
                                    <p className="text-details">
                                      Cloud Kitchen
                                    </p>
                                  )}
                                  {data.propertyType === 5 && (
                                    <p className="text-details">Other</p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">
                                    No.of total employee
                                  </p>
                                  {data.employeesCount && (
                                    <p className="text-details">
                                      {data.employeesCount}
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">
                                    Year of establishment
                                  </p>
                                  {data.establishmentYear && (
                                    <p className="text-details">
                                      {data.establishmentYear}
                                    </p>
                                  )}
                                </Col>
                              </Col>
                              <Col xs={12} md={6} lg={6} className="ps-4 mt-2">
                                <div align="right">
                                  {role !== "Associate" && (
                                    <Link
                                      to={`/editemployer-contactdetails/${id}`}
                                      style={{
                                        color: "#ff5c09",
                                        fontWeight: "600",
                                        borderBottom: "1px solid #ff5c09",
                                      }}
                                    >
                                      Edit
                                    </Link>
                                  )}
                                </div>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Address</p>
                                  {data.addressLine1 && (
                                    <p className="text-details">
                                      {data.addressLine1}
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">
                                    Contact Person Name
                                  </p>
                                  {data.contactPerson && (
                                    <p className="text-details">
                                      {data.contactPerson}
                                    </p>
                                  )}
                                </Col>

                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">
                                    Contact Person Number
                                  </p>
                                  {data?.contactPersonMobile && (
                                    <p className="text-details">
                                      {data?.contactPersonMobile}
                                    </p>
                                  )}
                                </Col>

                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">City</p>
                                  {data?.cityName && (
                                    <p className="text-details">
                                      {data?.cityName}
                                    </p>
                                  )}
                                </Col>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col
                                lg={12}
                                style={{
                                  borderTop: "solid 1px silver",
                                }}
                                className="p-4 ms-3"
                              >
                                <h5 style={{ color: "#ff5c09" }}>Rating</h5>
                                {data?.ratingsData?.behaviour?.ratingAvg ===
                                  0 &&
                                data?.ratingsData?.facilities?.ratingAvg ===
                                  0 &&
                                data?.ratingsData?.salary?.ratingAvg === 0 &&
                                data?.ratingsData?.workculture?.ratingAvg ===
                                  0 ? (
                                  <div>
                                    <div className="text-left">
                                      Not rated yet!
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div className="d-flex">
                                      <div>
                                        <div style={{ marginTop: "10px" }}>
                                          Behaviour :
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                          Facilities :
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                          Salary & increment :
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                          Work Culture :
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            marginLeft: "30px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <StarRating1
                                            value={Math.ceil(
                                              data?.ratingsData?.behaviour
                                                ?.ratingAvg
                                            )}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: "30px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <StarRating1
                                            value={Math.ceil(
                                              data?.ratingsData?.facilities
                                                ?.ratingAvg
                                            )}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: "30px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <StarRating1
                                            value={Math.ceil(
                                              data?.ratingsData?.salary
                                                ?.ratingAvg
                                            )}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: "30px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <StarRating1
                                            value={Math.ceil(
                                              data?.ratingsData?.workculture
                                                ?.ratingAvg
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <br />

                                    {reviews && (
                                      <Link
                                        className="text-start mt-5"
                                        style={{
                                          color: "#ff5c09",
                                          textDecoration: "underline",
                                          fontWeight: "600",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setShowReviewModal(true)}
                                      >
                                        <u>View Reviews</u>
                                      </Link>
                                    )}
                                  </>
                                )}
                              </Col>
                            </Row>
                          </Row>
                        </Tab>

                        <Tab eventKey="plan" title="Plan">
                          <Row className="card p-3 mb-5 table-responsive">
                            <Table responsive hover className="mb-5">
                              <thead>
                                <tr>
                                  <th className="text-left">
                                    Date of Purchase
                                  </th>
                                  <th className="text-center">Plan Name</th>
                                  <th className="text-center">Plan Price</th>

                                  <th className="text-center">Expiry</th>
                                  <th className="text-center">Validity</th>
                                  <th className="text-center">Status</th>
                                  <th className="text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {transactionsData.map(
                                  (transactionVal, index) => (
                                    <>
                                      <tr>
                                        <td className="text-left">
                                          {Moment.utc(
                                            transactionVal.planTransactionId
                                              .transactionStartDateTime
                                          ).format("DD-MM-YYYY")}
                                        </td>
                                        <td className="text-center">
                                          <Link
                                            onClick={(event) =>
                                              handleEpandRow(
                                                event,
                                                transactionVal.id
                                              )
                                            }
                                          >
                                            {
                                              transactionVal.planTransactionId
                                                .planName
                                            }
                                            <br />
                                            {transactionVal?.planTransactionId
                                              ?.isSelfPayment === false && (
                                              <span style={{ color: "#000" }}>
                                                (Added by Admin)
                                              </span>
                                            )}
                                          </Link>
                                        </td>

                                        <td className="text-center">
                                          {
                                            transactionVal.planTransactionId
                                              .price
                                          }
                                        </td>

                                        <td className="text-center">
                                          {Moment.utc(
                                            transactionVal.planTransactionId
                                              .transactionEndDateTime
                                          ).format("DD-MM-YYYY")}
                                        </td>

                                        <td className="text-center">
                                          {" "}
                                          {calculateDateDifference(
                                            transactionVal.planTransactionId
                                              .transactionStartDateTime,
                                            transactionVal.planTransactionId
                                              .transactionEndDateTime
                                          )}
                                          {calculateDateDifference(
                                            transactionVal.planTransactionId
                                              .transactionStartDateTime,
                                            transactionVal.planTransactionId
                                              .transactionEndDateTime
                                          ) === 1
                                            ? " Day"
                                            : " Days"}
                                        </td>
                                        <td className="text-center">
                                          {transactionVal?.status === 1 && (
                                            <p
                                              style={{
                                                backgroundColor:
                                                  "rgb(201, 240, 175)",
                                                borderRadius: "5px",
                                                padding: "4px 12px",
                                              }}
                                            >
                                              Active
                                            </p>
                                          )}
                                          {transactionVal?.status === 0 &&
                                            transactionVal?.planType ===
                                              "paid" && (
                                              <p
                                                style={{
                                                  backgroundColor: "#e9ebf0",
                                                  borderRadius: "5px",
                                                  padding: "4px 10px",
                                                }}
                                              >
                                                Expired
                                              </p>
                                            )}
                                          {transactionVal?.status === 0 &&
                                            transactionVal?.planType !==
                                              "paid" && (
                                              <p
                                                style={{
                                                  backgroundColor: "#e9ebf0",
                                                  borderRadius: "5px",
                                                  padding: "4px 10px",
                                                }}
                                              >
                                                Cancelled
                                              </p>
                                            )}
                                        </td>
                                        <td className="text-center">
                                          {transactionVal.status === 1 &&
                                            role === "admin" && (
                                              <Dropdown
                                                style={{
                                                  backgroundColor: "none",
                                                }}
                                              >
                                                <Dropdown.Toggle
                                                  // id="dropdown-basic"
                                                  style={{
                                                    fontSize: "20px",
                                                    backgroundColor: "none",
                                                    border: "none",
                                                  }}
                                                >
                                                  <BiDotsVerticalRounded
                                                    style={{
                                                      marginLeft: "-5px",
                                                      marginTop: "-2px",
                                                      color: "#000",
                                                    }}
                                                  />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      CancelandRefund(
                                                        transactionVal
                                                      )
                                                    }
                                                  >
                                                    Cancel/ Refund
                                                  </Dropdown.Item>

                                                  <Dropdown.Item
                                                    onClick={() =>
                                                      UpgradePlan(
                                                        transactionVal
                                                      )
                                                    }
                                                  >
                                                    Upgrade
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            )}
                                        </td>
                                      </tr>
                                      <>
                                        {expandedRows.includes(
                                          transactionVal?.id
                                        ) ? (
                                          <tr
                                            style={{
                                              backgroundColor: "#fff",
                                              padding: "10px",
                                              borderTop: "none",
                                            }}
                                          >
                                            <td
                                              className="text-left"
                                              // colspan="2"
                                            >
                                              No.of Job Post
                                              <br />
                                              {transactionVal?.currentJobPoints}
                                              &nbsp; /{" "}
                                              {transactionVal?.totalJobPoints}
                                            </td>
                                            <td
                                              className="text-center"
                                              // colspan="2"
                                            >
                                              No.of Response
                                              <br />
                                              {
                                                transactionVal?.totalResponsePoints
                                              }
                                            </td>
                                            <td
                                              className="text-center"
                                              // colspan="2"
                                            >
                                              No.of Profile View
                                              <br />
                                              {
                                                transactionVal?.currentProfileViewPoints
                                              }
                                              &nbsp; /{" "}
                                              {
                                                transactionVal?.totalProfileViewPoints
                                              }
                                            </td>
                                            <td
                                              className="text-center"
                                              // colspan="2"
                                            >
                                              Support Assistance
                                              <br />
                                              {transactionVal?.supportAssistance ===
                                                1 && <p>Yes</p>}
                                              {transactionVal?.supportAssistance ===
                                                0 && <p>No</p>}
                                            </td>
                                            <td
                                              className="text-center"
                                              // colspan="2"
                                            >
                                              Refund Policy
                                              <br />
                                              {transactionVal?.planTransactionId
                                                ?.paymentDetails
                                                ?.refundPolicy === 1 && (
                                                <p>Yes</p>
                                              )}
                                              {transactionVal?.planTransactionId
                                                ?.paymentDetails
                                                ?.refundPolicy === 0 && (
                                                <p>No</p>
                                              )}
                                            </td>
                                            <td
                                              className="text-center"
                                              // colspan="2"
                                            >
                                              Discount
                                              <br />
                                              {
                                                transactionVal
                                                  ?.planTransactionId
                                                  ?.paymentDetails?.discount
                                              }
                                            </td>
                                            <td></td>
                                          </tr>
                                        ) : null}
                                      </>
                                    </>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Row>
                        </Tab>
                        <Tab eventKey="job" title="Jobs">
                          <Row className="card p-3 mb-5 table-responsive">
                            <Table responsive hover className="mb-5">
                              <thead>
                                <tr>
                                  <th className="text-left">Designation</th>
                                  <th className="text-center">Salary</th>
                                  <th className="text-center">Location</th>
                                  <th className="text-center">Expiry</th>

                                  <th className="text-center">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {jobsData.map((jobsVal, index) => (
                                  <tr key={jobsVal?.id}>
                                    <td className="text-left">
                                      <Link
                                        to={`/JobsDetails/${jobsVal?._id}`}
                                        style={{
                                          color: "#a020f0",
                                        }}
                                      >
                                        {jobsVal?.designation?.substring(0, 20)}
                                      </Link>
                                    </td>

                                    <td className="text-center">
                                      Rs. {jobsVal?.salary}
                                    </td>
                                    <td className="text-center">
                                      {jobsVal?.location?.substring(0, 15)}
                                    </td>
                                    <td className="text-center">
                                      {Moment.utc(jobsVal?.expiryDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {jobsVal?.status === 1 && (
                                        <p
                                          style={{
                                            background: "#d8f2bd",
                                            borderRadius: "5px",
                                            padding: "1px 5px",
                                          }}
                                        >
                                          Active
                                        </p>
                                      )}
                                      {jobsVal?.status === 0 && (
                                        <p
                                          style={{
                                            background: "#d4d3cf",
                                            borderRadius: "5px",
                                            padding: "1px 5px",
                                          }}
                                        >
                                          Expired
                                        </p>
                                      )}
                                      {jobsVal?.status === 2 && (
                                        <p
                                          style={{
                                            background: "#f2eebd",
                                            borderRadius: "5px",
                                            padding: "1px 5px",
                                          }}
                                        >
                                          Disabled
                                        </p>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Row>
                        </Tab>
                      </Tabs>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EmployerDetails;
