import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class EventService {
  static getEvents(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    api = API_PATHS.getEvents + "?";
    if (item?.limit) {
      api += `limit=${item.limit}&`;
    }

    if (item?.page) {
      api += `page=${item.page}&`;
    }
    if (item?.nameEmailOrMobile) {
      api += `nameEmailOrMobile=${item.nameEmailOrMobile}&`;
    }

    if (item?.active === 0) {
      api += `expired=1&`;
    }
    if (item?.active === 1) {
      api += `active=1&`;
    }

    if (item?.active === 2) {
      api += `disabled=1&`;
    }

    if (item?.cuisines && item?.cuisines?.length > 0) {
      // Encode each cuisine and append to the API URL
      item?.cuisines?.forEach((cuisine) => {
        api += `cuisines[]=${encodeURIComponent(cuisine)}&`;
      });
    }

    if (item?.longitude) {
      api += `longitude=${item.longitude}&`;
    }
    if (item?.latitude) {
      api += `latitude=${item.latitude}&`;
    }
    // Remove the trailing "&" if there are parameters
    if (api.endsWith("&")) {
      api = api.slice(0, -1);
    }

    return axios.get(api, config).then((response) => {
      return response.data;
    });
  }

  static getEventDetails(id) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    api = API_PATHS.getEventDetails + "?id=" + id;
    return axios.get(api, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static sendEventNotifications = (id) => {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    api = API_PATHS.senteventNotifications;
    let item = {
      eventId: id,
    };
    return axios.post(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  };

  static getSharedprofiles(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    api = `${API_PATHS.getSharedPrrofiels}?jobId=${item.id}&limit=${item?.limit}&page=${item?.page}`;
    return axios.get(api, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static getEventSharedprofiles(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    api = `${API_PATHS.getSharedPrrofiels}?eventId=${item.id}&limit=${item?.limit}&page=${item?.page}`;
    return axios.get(api, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static getRequirememtSharedprofiles(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    api = `${API_PATHS.getSharedPrrofiels}?requirementId=${item.id}&limit=${item?.limit}&page=${item?.page}`;
    return axios.get(api, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static deleteSharedprofiles(id) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    api = `${API_PATHS.deleteSharedProfiles}?id=${id}`;
    return axios.delete(api, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static editEventDetails(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    let id = localStorage.getItem("eventId");
    api = API_PATHS.editEvent;
    return axios.put(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }
}

export default EventService;
