import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class CseService {
  


  static getCseaccounts(item) {
    let api = "";
    api = `${API_PATHS.getcseaccounts}?limit=${item.limit}&page=${item.page}`;
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios.get(api,config).then((response) => {
      return response.data;
    });
  }


//get sing cse account
  static getCseaccoutone(id) {
    let api = "";
    api = API_PATHS.getsinglecseaccount+"?id="+id;
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios.get(api,config).then((response) => {
      return response.data;
    });
  }


  //add cse account
  static addcseAccount(item) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios
      .post(API_PATHS.addcseaccount, item, config)
      .then((response) => {
        return response.data;
      });
  }

  static deleteCse(id) {
    let api = '';
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    
      api = API_PATHS.deleteCse + '?id=' + id ;
    localStorage.removeItem("id");
    return axios
        .delete(api,config)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}


  static EditcseAccount(item) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios
      .put(API_PATHS.editcseaccounts, item, config)
      .then((response) => {
        return response.data;
      });
  }

}


export default CseService;
