import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import clientimg from "../../src/Assets/Images/client.png";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Moment from "moment";

import {
  editEmployerDetails,
  getEmployerDetails,
} from "../store/slices/employer";
import Navbartop from "./navbar";
import { getLanguages, getStates } from "../store/slices/config";

function EditemployerContactDetails() {
  const { id } = useParams();
  localStorage.setItem("employerId", id);
  const [contactPerson, setcontactPerson] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [state, setState] = useState([]);
  const [cityName, setCityName] = useState("");
  const [citycoord, setcitycoord] = useState([]);

  const [data, setData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getEmployerDetails(id))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
        UpdateData(data?.users?.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const UpdateData = (data) => {
    setData({
      ...data,
      cityCoordinates: {
        type: "Point",
        coordinates: data?.cityCoordinates?.coordinates || [],
      },
    });
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Edit Employer Contact Details";
  }, []);

  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?=.*[a-zA-Z])[a-zA-Z0-9'@& -_]{4,}$/.test(name);
  };

  const isValidAddressline2 = (addressline2) => {
    // validating the name with one cap letter and 6 char of length
    return /^[a-zA-Z0-9 .'#-,]{1,100}$/.test(addressline2);
  };
  const isValidcurrentCity = (currentCityName) => {
    return /^[a-zA-Z0-9\- ',&@]{3,100}$/.test(currentCityName);
  };

  const isValidmobile = (mobileNumber) => {
    return /^[0-9]{10}$/.test(mobileNumber);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // alert("working")
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};

    if (data.contactPerson && !isValidName(data.contactPerson)) {
      validationErrors.contactPerson = "Name should be atleast 3 characters";
    }
    if (data.cityName && !isValidcurrentCity(data.cityName)) {
      validationErrors.cityName = "Please enter valid city name";
    }

    if (data.contactPersonMobile && !isValidmobile(data.contactPersonMobile)) {
      validationErrors.contactPersonMobile =
        "Please enter valid  mobile number";
    }

    if (data.addressLine1 && !isValidAddressline2(data.addressLine1)) {
      validationErrors.addressLine1 = "Please enter a valid address";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

      return;
    } else {
      const item = {
        contactPerson: data.contactPerson,
        contactPersonMobile: data.contactPersonMobile ,
        cityName: data.cityName ,
        addressLine1: data.addressLine1 ,
        id: id,
        cityCoordinates: cityName
          ? {
              type: "Point",
              coordinates: data?.cityCoordinates?.coordinates,
            }
          : undefined,
      };

      dispatch(editEmployerDetails(item))
        .unwrap()
        .then((data) => {
          alert("Profile updated succesfully");
          navigate(`/employerdetails/${id}`);
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const cityinputRef = useRef(null);
  useEffect(() => {
    if (cityinputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        cityinputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setcitycoord([]);
          setErrors({
            ...errors,
            cityName: "Please select location from the suggestion drop-down",
          });
        } else {
          setErrors({
            ...errors,
            cityName: "",
          });
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];
          setCityName(place.name);
          setcitycoord(coordinates);
          setcity(coordinates, place.name);
        }
      });

      cityinputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = cityinputRef.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setcitycoord([]);
          setErrors({
            ...errors,
            cityName: "Please select location from the suggestion drop-down",
          });
        }
      });
    }
  }, []);

  const setcity = (coordinates, city) => {
    setData((prevData) => ({
      ...prevData,
      cityName: city,
      cityCoordinates: {
        type: "Point",
        coordinates: coordinates,
      },
    }));
  };

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value } = e.target;
    switch (name) {
      case "contactPerson":
        setcontactPerson(value);
        setData({
          ...data,
          contactPerson: value,
        });
        validationErrors[name] =
          value !== "" && !isValidName(value)
            ? "Please enter a valid name"
            : "";
        if (value === "" || isValidName(value)) {
          delete validationErrors[name];
        }
        break;

      case "contactPersonMobile":
        setData({
          ...data,
          contactPersonMobile: value,
        });
        validationErrors[name] =
          value !== "" && !isValidmobile(value)
            ? "Contact number must have 10 digits"
            : "";
        if (value === "" || isValidmobile(value)) {
          validationErrors[name] = "";
        }
        break;

      case "cityName":
        setData({
          ...data,
          cityName: value,
        });
        validationErrors[name] = value !== "" ? "Please enter valid city" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "addressLine1":
        setData({
          ...data,
          addressLine1: value,
        });
        validationErrors[name] =
          value !== "" && !isValidAddressline2(value)
            ? "Please enter valid address"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }

        break;
      default:
        setData({
          ...data,
          [name]: value,
        });
        break;
    }
    setErrors(validationErrors);
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <div>
        <Navbartop />

        <div className="container-fluid pb-5 ms-2 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-2 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;{" "}
                      <Link to="/client">Employer</Link> &#8811; Edit Employer
                      Contact Details
                    </div>

                    <Form
                      autoComplete="off"
                      noValidate
                      onSubmit={submitHandler}
                    >
                      <Row className="d-flex justify-content-center mt-4 card p-3">
                        <Col lg={12} xs={12} md={12} className="mb-4">
                          <h5 className="text-left heading orange">
                            Contact Details
                          </h5>

                          <Row className="gx-2 d-flex justify-content-center p-2">
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">
                                  Contact Person
                                </label>
                                <Form.Control
                                  className="input1"
                                  name="contactPerson"
                                  value={data.contactPerson}
                                  onChange={handleChange}
                                  type="text"
                                  maxlength="100"
                                  isInvalid={!!errors.contactPerson}
                                  placeholder="Enter your name"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.contactPerson}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">
                                  Contact Person Mobile
                                </label>
                                <Form.Control
                                  type="text"
                                  name="contactPersonMobile"
                                  className="input1"
                                  value={data.contactPersonMobile}
                                  onChange={handleChange}
                                  isInvalid={!!errors.contactPersonMobile}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.contactPersonMobile}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">City</label>

                                <Form.Control
                                  name="cityName"
                                  value={data.cityName}
                                  onChange={handleChange}
                                  className="input1"
                                  isInvalid={!!errors.cityName}
                                  type="text"
                                  ref={cityinputRef}
                                  placeholder="Enter city"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cityName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">Address</label>
                                <Form.Control
                                  name="addressLine1"
                                  value={data?.addressLine1}
                                  onChange={handleChange}
                                  isInvalid={!!errors.addressLine1}
                                  className="input1 "
                                  type="text"
                                  placeholder="Enter adrress"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.addressLine1}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2"></Col>
                          </Row>
                          <Row className="d-flex justify-content-center mt-4">
                            <Col lg={4} xs={8}>
                              <div className="d-flex justify-content-around my-2 align-items-center">
                                <button
                                  onClick={cancelHandler}
                                  className="btn cancelBtn"
                                >
                                  Cancel
                                </button>
                                &nbsp;&nbsp;
                                <Button
                                  disabled={isdisabled}
                                  type="submit"
                                  className="btn saveBtn my-2"
                                >
                                  Save
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditemployerContactDetails;
