import React, { useEffect, useState } from "react";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import ShowSkeleton from "../utils/helpers/Skeleton";
import {
  GetBlockedMaclist,
  unBlockmacAddress,
} from "../store/slices/testimonial";
import ReactPaginate from "react-paginate";
import Moment from "moment";

function BlockedMacList() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [totaldata, setTotladata] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [item, setItem] = useState({
    limit: 25,
    page: 1,
  });
  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  const handlePageClick = async ({ selected }) => {
    const newPage = selected + 1;
    if (newPage === currentPage) {
      return;
    }
    const updatedItem = {
      ...item,
      limit: 25,
      page: newPage,
    };
    setItem(updatedItem);
    setCurrentPage(newPage);

    await getblockList(updatedItem);
  };

  React.useEffect(() => {
    getblockList(item);
  }, [dispatch]);

  const getblockList = (item) => {
    dispatch(GetBlockedMaclist(item))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
        setTotladata(data.users.totalDataCount);
        setTimeout(() => {
          setisLoading(false);
        }, [1000]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };
  useEffect(() => {
    document.title = "CookandChef Admin | Blocked Mac Address";
  }, []);

  const unblock = async function deletePlan(macAddress) {
    const item = {
      macAddress: macAddress,
    };
    dispatch(unBlockmacAddress(item))
      .unwrap()
      .then(() => {
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const Unblock = (macAddress) => {
    confirmAlert({
      title: "Want to Unblock?",
      message: "Are you sure you want to unblock this address?",
      buttons: [
        {
          label: "Yes",
          onClick: () => unblock(macAddress),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Blocked Mac Address
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Blocked Mac Address</h4>
                        &nbsp;&nbsp;
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : data?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No Data</b> found!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table
                            responsive
                            hover
                            style={{ overflowX: "scroll" }}
                          >
                            <thead>
                              <tr>
                                <th className="text-left">MAC Address</th>
                                <th className="text-left">Block Date</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((elem, index) => (
                                <tr key={index}>
                                  <td className="text-left">
                                    {elem.macAddress}
                                  </td>
                                  <td className="text-left">
                                    {Moment.utc(elem?.createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>

                                  {role === "admin" && (
                                    <td style={{ textAlign: "center" }}>
                                      <button
                                        onClick={() => Unblock(elem.macAddress)}
                                        className="btn btn-delete col-white"
                                        style={{ height: "25px" }}
                                      >
                                        Unblock
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                      <div className="justify-content-right">
                        {totaldata / itemsPerPage > 1 && (
                          <ReactPaginate
                            key={currentPage}
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            breakLinkClassName={"page-link"}
                            pageCount={totaldata / itemsPerPage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={pagesToShowInitially}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            initialPage={currentPage - 1}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlockedMacList;
