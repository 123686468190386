import React from "react";

import { Navigate, Outlet,useLocation } from "react-router-dom";

const useAuth = () => {
  const users = localStorage.getItem("users");
  if (users) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = (props) => {
  const auth = useAuth();
  const location = useLocation()
  const pathname = location.pathname
if(!auth && pathname ==="/admin-change-password"){
  return <Navigate to="/admin-change-password" />
}

  return auth ? <Outlet /> : <Navigate to="/cnc-members" />;
};

export default ProtectedRoutes;
