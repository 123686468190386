import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class faqService {





   //add testimonial account
   static addFaq(item) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios
      .post(API_PATHS.addfaq, item, config)
      .then((response) => {
        return response.data;
      });
  }


     //add testimonial account
     static editfaq(item) {
      let user = JSON.parse(localStorage.getItem("users"));
      const token = user.data.token;
  
      const config = {
        headers: {
          "x-access-token": token,
        },
      };
  
      return axios
        .put(API_PATHS.editfaq, item, config)
        .then((response) => {
          return response.data;
        });
    }
//getalll testimonial details
  static gellallFaq(item) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
      data:item
    };

    return axios
      .get(API_PATHS.getfaq,  config)
      .then((response) => {
        return response.data;
      });
  }


  //get single  testimonial details
  static getsinglefaq(id) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios
      .get(API_PATHS.getfaq +"?id="+ id ,  config)
      .then((response) => {
        return response.data;
      });
  }


  static deletefaq(id) {
    let api = '';
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

      api = API_PATHS.deletefaq + '?id=' + id ;
    localStorage.removeItem("id");
    return axios
        .delete(api,config)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}


}


export default faqService;
