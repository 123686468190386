import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import ActivityService from "../../services/activity.service";

export const getEmployerActivity = createAsyncThunk(
    "/activity/getEmployerActivity",

    async (item, thunkApi) => {
        try {
            const data = await ActivityService.getEmployerActivity(item)
            return { employeractivity: data }

        } catch (error) {
            const message = (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message || error.message.toString()
            return thunkApi.rejectWithValue({ message })
        }
    }
)


export const getAllUsersActivity = createAsyncThunk(
    "/activity/getAllUsersActivity",

    async (item, thunkApi) => {
        try {
            const data = await ActivityService.getAllusersActivity(item)
            return { allactivity: data }

        } catch (error) {
            const message = (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message || error.message.toString()
            return thunkApi.rejectWithValue({ message })
        }
    }
)

export const getReportsList = createAsyncThunk(
    "/activity/getReportsList",

    async (item, thunkApi) => {
        try {
            const data = await ActivityService.getReportList(item)
            return { activity: data }

        } catch (error) {
            const message = (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message || error.message.toString()
            return thunkApi.rejectWithValue({ message })
        }
    }
)

export const getCookActivity = createAsyncThunk(
    "/activity/getcookactivity",
    async (item,thunkAPI) => {
        try {
            const data = await ActivityService.getCookActivity(item);
            return { cookactivity: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getCookDeleteRequest = createAsyncThunk(
    "/activity/getcookDeleteactivity",
    async (item,thunkAPI) => {
        try {
            const data = await ActivityService.getCookDelete(item);
            return { cookdeleterequest: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getBlockeCooks = createAsyncThunk(
    "/activity/getblockeduser",
    async (item,thunkAPI) => {
        try {
            const data = await ActivityService.getBlockedCook(item);
            return { blockedcooks: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getBlockeEmployers = createAsyncThunk(
    "/activity/getblockeduser",
    async (item,thunkAPI) => {
        try {
            const data = await ActivityService.getBlockedEmployer(item);
            return { blockedemployers: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
export const getEmployerDeleteRequest = createAsyncThunk(
    "/activity/getemployeractivity",
    async (item,thunkAPI) => {
        try {
            const data = await ActivityService.getEmployerDelete(item);
            return { employerdeleterequest: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getUsersTrack = createAsyncThunk(
    "/activity/getUserstrack",
    async (item,thunkAPI) => {
        try {
            const data = await ActivityService.getUsersTrack(item);
            return { allusers: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getAllowedMacs = createAsyncThunk(
    "/activity/getallowedmacs",
    async (id,thunkAPI) => {
        try {
            const data = await ActivityService.getallowedMacaddress(id);
            return { allusers: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const AllowMacs = createAsyncThunk(
    "/activity/allowmacs",
    async (item,thunkAPI) => {
        try {
            const data = await ActivityService.allowMacaddress(item);
            return { allusers: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
const initialState = {
    loading: false,
    error: "",
    users: AuthService.getusersDetails() || null,
    isLoggedIn: false
}

const activitySlice = createSlice({
    name: "activity",
    initialState,
    extraReducers: {
        [getEmployerActivity.pending]: (state) => {
            state.loading = true
            state.error = ""
            state.users = null
            state.isLoggedIn = false

        },
        [getEmployerActivity.fulfilled]: (state, action) => {
            state.loading = true;
            state.error = "";
            state.users = action.payload.users
            state.isLoggedIn = true
        },
        [getEmployerActivity.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.users = null;
            state.isLoggedIn = false
        }
    }
})

const { reducer } = activitySlice
export default reducer