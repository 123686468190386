import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FaQuestion } from "react-icons/fa";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { MdOutlineDelete } from "react-icons/md";
import { BsActivity } from "react-icons/bs";
import { MdOutlineContactSupport } from "react-icons/md";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import home from "../Assets/Images/home.png";
import client from "../Assets/Images/client (1).png";
import housecook from "../Assets/Images/house.png";
import partycook from "../Assets/Images/bbq.png";
import catering from "../Assets/Images/Caering icon.png";
import chef from "../Assets/Images/chef (2).png";
import employer from "../Assets/Images/restaurant (2).png";
import plan from "../Assets/Images/price-tag.png";
import jobs from "../Assets/Images/jobs.png";
import events from "../Assets/Images/events.png";
import dropdown from "../Assets/Images/dropdown-close.png";
import dropRight from "../Assets/Images/drop-right.png";
import logout from "../Assets/Images/logout.png";
import { getSingleCse } from "../store/slices/cse";
import cse from "../Assets/Images/profile.png";
import sales from "../Assets/Images/plan.png";
import { useEffect } from "react";

const CSEMenu = () => {
  let data = JSON.parse(localStorage.getItem("users"));
  let role = data.data.roleId.roleName;
  const [submenu, setSubmenu] = useState(false);
  const open = () => {
    document.getElementById("side").classList.toggle("show");
  };
  const toggleSubMenu = () => {
    setSubmenu(!submenu);
  };
  const [expand, setExpand] = useState({
    activity: false,
    plans: false,
  });
  const toggleCollapse = (section) => {
    setExpand((prevExpand) => ({
      ...Object.fromEntries(Object.keys(prevExpand).map((key) => [key, false])),
      [section]: !prevExpand[section],
    }));
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function signOut(e) {
    localStorage.clear();
    navigate("/cnc-members");
  }
  const isActive = (path) => window.location.pathname === path;

  const [datas, setDatas] = useState();

  useEffect(() => {
    dispatch(getSingleCse(data.data.id))
      .unwrap()
      .then((data) => {
        setDatas(data.users.data);
      })
      .catch(({ message }) => {});
  }, []);

  return (
    <>
      <ul className="nav-lists pt-1">
        <li className="nav_items top_item">
          <NavLink to="/home" className="menu-title" activeClassName="active">
            <img
              src={home}
              alt="home"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Dashboard
          </NavLink>
        </li>
        {datas?.clientAccess === 1 && (
          <li className="nav_items">
            <NavLink to="/client" className="menu-title">
              <img
                src={client}
                alt="client"
                className="me-3"
                style={{ height: "18px", width: "18px" }}
              />
              Client
            </NavLink>
          </li>
        )}

        {datas?.houseCookAccess === 1 && (
          <li className="nav_items">
            <NavLink to="/house-cook" className="menu-title">
              <img
                src={housecook}
                alt="housecook"
                className="me-3"
                style={{ height: "18px", width: "18px" }}
              />
              Household Cook
            </NavLink>
          </li>
        )}

        {datas?.partyCookAccess === 1 && (
          <li className="nav_items">
            <NavLink to="/party-cook" className="menu-title">
              <img
                src={partycook}
                alt="partycook"
                className="me-3"
                style={{ height: "18px", width: "18px" }}
              />
              Party Cook
            </NavLink>
          </li>
        )}

        {datas?.chefAccess === 1 && (
          <li className="nav_items">
            <NavLink to="/chef" className="menu-title">
              <img
                src={chef}
                alt="chef"
                className="me-3"
                style={{ height: "18px", width: "18px" }}
              />
              Chef
            </NavLink>
          </li>
        )}

        {datas?.cateringAccess === 1 && (
          <li className="nav_items">
            <NavLink to="/catering" className="menu-title">
              <img
                src={catering}
                alt="catering"
                className="me-3"
                style={{ height: "18px", width: "18px" }}
              />
              Catering
            </NavLink>
          </li>
        )}
        {datas?.employerAccess === 1 && (
          <li className="nav_items">
            <NavLink to="/employer" className="menu-title">
              <img
                src={employer}
                alt="employer"
                className="me-3"
                style={{ height: "18px", width: "18px" }}
              />
              Employer
            </NavLink>
          </li>
        )}
        {/* {role === "Manager" && (
          <li className="nav_items">
            <NavLink
              to="/payments"
              className={`${
                isActive("/payments") ? "active-link" : "menu-title"
              }`}
            >
              <img
                src={sales}
                alt="employer"
                className="me-3"
                style={{ height: "18px", width: "18px" }}
              />
              Sales
            </NavLink>
          </li>
        )} */}

        {datas?.employerAccess === 1 && (
          <li className="nav_items">
            <NavLink to="/jobs" className="menu-title">
              <img
                src={jobs}
                alt="jobs"
                className="me-3"
                style={{ height: "18px", width: "18px" }}
              />
              Jobs
            </NavLink>
          </li>
        )}
        {datas?.clientAccess === 1 && (
          <li className="nav_items">
            <NavLink to="/events" className="menu-title">
              <img
                src={events}
                alt="jobs"
                className="me-3"
                style={{ height: "18px", width: "18px" }}
              />
              Events
            </NavLink>
          </li>
        )}

        <li className="nav_items">
          <NavLink
            to=""
            className="menu-title"
            onClick={(e) => toggleCollapse("plans", e)}
          >
            <img
              src={plan}
              alt="plan"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Plan
            {expand.plans ? (
              <BiChevronUp style={{ marginLeft: "90px" }} />
            ) : (
              <BiChevronDown style={{ marginLeft: "90px" }} />
            )}
          </NavLink>
        </li>

        {expand.plans && (
          <div className="ml-5">
            <Link
              to="/employerplan"
              className={`${
                isActive("/employerplan")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Employer Plan
            </Link>
            <br />
            <Link
              to="/clientplan"
              className={`${
                isActive("/clientplan") ? "active-link-link" : "menu-title-link"
              }`}
            >
              Client Plan
            </Link>
            <br />
            <Link
              to="/cookplan"
              className={`${
                isActive("/cookplan") ? "active-link-link" : "menu-title-link"
              }`}
            >
              Cook Plan
            </Link>
          </div>
        )}

        {role !== "Associate" && (
          <li className="nav_items">
            <NavLink
              to=""
              className="menu-title"
              onClick={(e) => toggleCollapse("activity", e)}
            >
              <BsActivity
                className="me-3"
                style={{ height: "18px", width: "18px" }}
              />
              Activity
              {expand.activity ? (
                <BiChevronUp style={{ marginLeft: "70px" }} />
              ) : (
                <BiChevronDown style={{ marginLeft: "70px" }} />
              )}
            </NavLink>
          </li>
        )}

        {expand.activity && (
          <div className="ml-5">
            <Link
              to="/employer-activity"
              className={`${
                isActive("/employer-activity")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Employer Activity
            </Link>

            <br />
            <Link
              to="/cook-activity"
              className={`${
                isActive("/cook-activity")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Cook Activity
            </Link>
          </div>
        )}
        {/* {role === "Manager" && (
          <li className="nav_items">
            <NavLink
              to=""
              className="menu-title"
              onClick={(e) => toggleCollapse("request", e)}
            >
              <MdOutlineDelete
                className="me-3"
                style={{ height: "18px", width: "18px" }}
              />
              Delete Request
              {expand.request ? (
                <BiChevronUp style={{ marginLeft: "15px" }} />
              ) : (
                <BiChevronDown style={{ marginLeft: "15px" }} />
              )}
            </NavLink>
          </li>
        )}

        {expand.request && (
          <div className="ml-5">
            <Link
              to="/employer-delete-request"
              className={`${
                isActive("/employer-delete-request")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Employer Request
            </Link>

            <br />
            <Link
              to="/cook-delete-request"
              className={`${
                isActive("/cook-delete-request")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Cook Request
            </Link>
          </div>
        )} */}
        {role === "Manager" && (
          <li className="nav_items">
            <NavLink
              to="/testimonial"
              className={`${
                isActive("/testimonial") ? "active-link" : "menu-title"
              }`}
            >
              <img
                src={events}
                alt="jobs"
                className="me-3"
                style={{ height: "18px", width: "18px" }}
              />
              Testimonial
            </NavLink>
          </li>
        )}

        <li className="nav_items">
          <NavLink
            to="/web-enquiry"
            className={`${
              isActive("/web-enquiry") ? "active-link" : "menu-title"
            }`}
          >
            <MdOutlineContactSupport
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Website Enquiry
          </NavLink>
        </li>

        <li className="nav_items">
          <Link to="/cnc-members" onClick={signOut} className="menu-title ">
            <img
              src={logout}
              alt="jobs"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Logout
          </Link>
        </li>
      </ul>
      ;
    </>
  );
};

export default CSEMenu;
