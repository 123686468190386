import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Moment from "moment";

import { editRequirement, getEmployerDetails } from "../store/slices/employer";
import Navbartop from "./navbar";
import { getCuisines, getLanguages } from "../store/slices/config";
import { getClientDetails } from "../store/slices/client";

function EditClientRequirement() {
  const { id } = useParams();
  localStorage.setItem("employerId", id);

  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [vesselWash, setvesselWash] = useState();
  const jobTypeRef = useRef(null);
  const genderRef = useRef(null);
  const cuisineRef = useRef(null);
  const minPaymentRef = useRef(null);
  const urgecyRef = useRef(null);
  const mealTypeRef = useRef(null);
  const dispatch = useDispatch();
  const [cuisines, setCuisines] = useState([]);
  const [selectCuisines, setSelectCuisines] = useState([]);
  const [isdisabled, setisDisabled] = useState(false);
  const [requirementId, setRequirementId] = useState("");

  useEffect(() => {
    document.title = "CookandChef Admin | Edit Requirement";
  }, []);

  React.useEffect(() => {
    dispatch(getClientDetails(id))
      .unwrap()
      .then((data) => {
        console.log(data);
        setData(data.users.data.requirementsData[0]);
        setSelectCuisines(data.users.data.requirementsData[0].cuisines);
        setRequirementId(data.users.data.requirementsData[0].id);
        setvesselWash(data.users.data.requirementsData[0].vesselWash);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  const handleChangeMeal = (e) => {
    const { value, name, checked } = e.target;
    switch (name) {
      case "lunch":
        setErrors({
          ...errors,
          mealType: "",
        });
        setData({
          ...data,
          lunch: checked ? 1 : 0,
        });
        break;
      case "dinner":
        setErrors({
          ...errors,
          mealType: "",
        });
        setData({
          ...data,
          dinner: checked ? 1 : 0,
        });
        break;
      case "breakfast":
        setErrors({
          ...errors,
          mealType: "",
        });
        setData({
          ...data,
          breakfast: checked ? 1 : 0,
        });
        break; // Add break statement here
      default:
        break;
    }
  };
  const validateCuisines = (selectedOptions) => {
    return selectedOptions?.length > 0;
  };
  const submitHandler = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};
    if (!data?.jobType) {
      validationErrors.jobType = "Please select job type";
    }
    if (!data?.preferredGender) {
      validationErrors.preferredGender = "Please select preferred gender";
    }

    if (
      (data?.breakfast === 0 && data?.lunch === 0 && data?.dinner === 0) ||
      !data ||
      (data?.breakfast === 0 &&
        data?.lunch === undefined &&
        data?.dinner === undefined) ||
      (data?.breakfast === undefined &&
        data?.lunch === 0 &&
        data?.dinner === undefined) ||
      (data?.breakfast === undefined &&
        data?.lunch === undefined &&
        data?.dinner === 0) ||
      (data?.breakfast === 0 &&
        data?.lunch === undefined &&
        data?.dinner === 0) ||
      (data?.breakfast === undefined &&
        data?.lunch === 0 &&
        data?.dinner === 0) ||
      (data?.breakfast === 0 &&
        data?.lunch === 0 &&
        data?.dinner === undefined) ||
      (data?.breakfast === undefined &&
        data?.lunch === undefined &&
        data?.dinner === undefined)
    ) {
      validationErrors.mealType = "Please select atleast one meal";
    }

    if (!data?.minimumPayment) {
      validationErrors.minimumPayment = "Please select minimum payment";
    }
    if (!data?.urgency) {
      validationErrors.urgency = "Please select urgency";
    }

    const isValid = validateCuisines(selectCuisines);
    if (!isValid) {
      validationErrors.cuisines = "Please enter atleast one cuisine";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (validationErrors.jobType) {
        jobTypeRef.current.focus();
      } else if (validationErrors.preferredGender) {
        genderRef.current.focus();
      } else if (validationErrors.mealType) {
        mealTypeRef.current.focus();
      } else if (validationErrors.urgency) {
        urgecyRef.current.focus();
      } else if (validationErrors.cuisines) {
        cuisineRef.current.querySelector(".input1 input").focus();
      }
      return;
    } else {
      const item = {
        id: requirementId,
        cuisines: selectCuisines,
        jobType: parseInt(data.jobType),
        minimumPayment: data.minimumPayment,
        urgency: data.urgency || undefined,
        breakfast: parseInt(data.breakfast) || 0,
        lunch: parseInt(data.lunch) || 0,
        dinner: parseInt(data.dinner) || 0,
        vesselWash: parseInt(vesselWash),
        preferredGender: parseInt(data.preferredGender) || undefined,
        status: 1,
      };

      dispatch(editRequirement(item))
        .unwrap()
        .then((data) => {
          setTimeout(() => {
            alert("Requirement updated successfully");
            navigate(-1);
          }, 2000);
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {});
  }, [dispatch]);
  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value, checked, type } = e.target;

    switch (name) {
      case "jobType":
        setData({
          ...data,
          jobType: value,
        });
        validationErrors[name] = value === "" ? "please select job type" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "preferredGender":
        setData({
          ...data,
          preferredGender: value,
        });
        validationErrors[name] =
          value === "" ? "Please select preferred gender" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "minimumPayment":
        setData({
          ...data,
          minimumPayment: value,
        });
        validationErrors[name] =
          value === "" ? "Please select minimum payment" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "urgency":
        setData({
          ...data,
          urgency: value,
        });
        validationErrors[name] = value === "" ? "Please select urgency" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };
  const handleCuisineChange = (selected) => {
    const validationErrors = { ...errors };
    setSelectCuisines(selected);
    validationErrors.cuisines =
      selected.length === 0 ? "Please enter cuisines known" : "";
    if (!selected.length === 0) {
      delete validationErrors.cuisines;
    }
    setErrors(validationErrors);
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <div>
        <Navbartop />

        <div className="container-fluid pb-5 ms-2 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-2 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;
                      <Link to="/client">&nbsp;Client</Link> &#8811;
                      <Link onClick={cancelHandler}>
                        &nbsp;Client Details&nbsp;
                      </Link>
                      &#8811;&nbsp;Edit Requirement
                    </div>

                    <Form
                      autoComplete="off"
                      noValidate
                      onSubmit={submitHandler}
                    >
                      <Row className="d-flex justify-content-center mt-4 card p-3">
                        <Col lg={12} xs={12} md={12} className="mb-4">
                          <h5 className="text-left heading orange">
                            Requirement Details
                          </h5>

                          <Row className="gx-2 d-flex justify-content-center p-2">
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">JobType *</label>
                                <Form.Control
                                  className="input1"
                                  name="jobType"
                                  value={data.jobType}
                                  onChange={handleChange}
                                  as="select"
                                  isInvalid={!!errors.jobType}
                                  placeholder="Select job type"
                                >
                                  <option value="" selected disabled hidden>
                                    Select
                                  </option>
                                  <option value="1">Part Time</option>
                                  <option value="2">Full Time</option>
                                  <option value="3">Any</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.jobType}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">
                                  Prefferred Gender *
                                </label>
                                <Form.Control
                                  as="select"
                                  name="preferredGender"
                                  className="input1"
                                  value={data.preferredGender}
                                  onChange={handleChange}
                                  isInvalid={!!errors.preferredGender}
                                  isValid={errors.preferredGender}
                                >
                                  <option value="" selected disabled hidden>
                                    Select
                                  </option>
                                  <option value="1">Male</option>
                                  <option value="2">Female</option>
                                  <option value="3">Other</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.preferredGender}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Row className="gx-2 d-flex justify-content-center p-2">
                              <Col lg={11} className="p-1 m-2">
                                <Form.Group className="my-2">
                                  <label className="mb-1 label">
                                    Cuisines *
                                  </label>
                                  <div ref={cuisineRef}>
                                    <Typeahead
                                      id="basic-typeahead-multiple"
                                      labelKey="cuisineName"
                                      multiple
                                      className="input1"
                                      onChange={handleCuisineChange}
                                      options={options}
                                      placeholder="Select cuisines"
                                      selected={selectCuisines}
                                    />
                                  </div>
                                </Form.Group>
                                <p
                                  style={{ color: "#DC3545", fontSize: "14px" }}
                                >
                                  {errors.cuisines}
                                </p>
                              </Col>
                            </Row>
                            <Row className="gx-2 d-flex justify-content-center p-2">
                              <Col lg={5} className="p-1 m-2">
                                <Form.Group className="my-2">
                                  <label className="mb-2 label">
                                    Meal Type *
                                  </label>
                                  <Form.Check
                                    type="checkbox"
                                    id="breakfast"
                                    name="breakfast"
                                    label="Breakfast"
                                    ref={mealTypeRef}
                                    checked={data?.breakfast === 1}
                                    onChange={handleChangeMeal}
                                  />

                                  <Form.Check
                                    type="checkbox"
                                    id="lunch"
                                    name="lunch"
                                    label="Lunch"
                                    checked={data?.lunch === 1}
                                    onChange={handleChangeMeal}
                                  />
                                  <Form.Check
                                    type="checkbox"
                                    id="dinner"
                                    name="dinner"
                                    label="Dinner"
                                    checked={data?.dinner === 1}
                                    onChange={handleChangeMeal}
                                  />
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      color: "#dc3545",
                                    }}
                                  >
                                    {errors.mealType}
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg={5} className="p-1 m-2">
                                <Form.Group className="my-2">
                                  <label className="mb-2 label">
                                    Min. Payment *
                                  </label>
                                  <Form.Control
                                    as="select"
                                    name="minimumPayment"
                                    className="input1"
                                    value={data?.minimumPayment}
                                    onChange={handleChange}
                                    isInvalid={!!errors.minimumPayment}
                                    ref={minPaymentRef}
                                    isValid={errors.minimumPayment}
                                  >
                                    <option value="" selected disabled hidden>
                                      Select
                                    </option>
                                    <option value="1000 to 2000">
                                      1000 to 2000
                                    </option>
                                    <option value="2000 to 3000">
                                      2000 to 3000
                                    </option>
                                    <option value="3000 to 5000">
                                      3000 to 5000
                                    </option>
                                    <option value="5000 to 10000">
                                      5000 to 10000
                                    </option>
                                    <option value="10000 to 15000">
                                      10000 to 15000
                                    </option>
                                    <option value="15000 to 25000">
                                      15000 to 25000
                                    </option>
                                    <option value="Above 25000">
                                      Above 25000
                                    </option>
                                  </Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.minimumPayment}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="gx-2 d-flex justify-content-center p-2">
                              <Col lg={5} className="p-1 m-2">
                                <Form.Group className="my-1">
                                  <label className="mb-1 label">
                                    Urgency *
                                  </label>
                                  <Form.Control
                                    as="select"
                                    name="urgency"
                                    className="input1"
                                    value={data.urgency}
                                    onChange={handleChange}
                                    isInvalid={!!errors.urgency}
                                    isValid={errors.urgency}
                                  >
                                    <option value="" selected disabled hidden>
                                      Select
                                    </option>
                                    <option value="Immediately">
                                      Immediately
                                    </option>
                                    <option value="1 month">1 month</option>
                                    <option value="2 months">2 months</option>
                                    <option value="3 months">3 months</option>
                                  </Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    {errors.dob}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col lg={5} className="p-1 m-2 mx-3">
                                <Form.Group className=" justify-content-start align-items-center">
                                  <label className="mb-2 label">
                                    Vessel Wash *
                                  </label>
                                  <Row className="ps-3">
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="yes"
                                        label="Yes"
                                        name="vesselWash"
                                        value={data?.vesselWash}
                                        onChange={() => setvesselWash(1)}
                                        checked={vesselWash === 1}
                                        className="switchBtn"
                                      />
                                    </Col>
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="no"
                                        label="No"
                                        name="vesselWash"
                                        value={data?.vesselWash}
                                        onChange={() => setvesselWash(0)}
                                        checked={vesselWash === 0}
                                        className="switchBtn"
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Row>
                          <Row className="d-flex justify-content-center mt-4">
                            <Col lg={4} xs={8}>
                              <div className="d-flex justify-content-around my-2 align-items-center">
                                <button
                                  type="cancel"
                                  onClick={cancelHandler}
                                  className="btn cancelBtn"
                                >
                                  Cancel
                                </button>

                                <Button
                                  disabled={isdisabled}
                                  type="submit"
                                  className="saveBtn ms-2 my-2"
                                >
                                  Save
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditClientRequirement;
