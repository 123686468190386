import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store";
import { AxiosSetup } from "./utils/helpers/api.helpers";
import { BrowserRouter } from 'react-router-dom';
AxiosSetup();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <BrowserRouter>
     <Provider store={store}>
    <App />
    </Provider>
    </BrowserRouter>

);


reportWebVitals();
