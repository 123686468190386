import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class ConfigService {
  static getCuisines() {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.getCuisine;

    return axios.get(api,config).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static getQualification() {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.getQualification;

    return axios.get(api,config).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static getLanguages() {
    let api = "";
    api = API_PATHS.getLanguages;
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios.get(api,config).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static getStates() {
    let api = "";
    api = API_PATHS.getState;
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    return axios.get(api,config).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static resetPass(item) {
    let api = "";
    api = API_PATHS.resetpassword;

    var user = JSON.parse(localStorage.getItem("users"));
    const token = user?.data?.token;

    if (token) {
      return axios
        .post(api, item, { headers: { "x-access-token": token } })
        .then((response) => {
          if (response.data) {
            // localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
        });
    }
  }


  static suspendAccount(item) {
    let api = "";
    api = API_PATHS.suspendAccount;

    var user = JSON.parse(localStorage.getItem("users"));
    const token = user?.data?.token;

    if (token) {
      return axios
        .post(api, item, { headers: { "x-access-token": token } })
        .then((response) => {
          if (response.data) {
            // localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
        });
    }
  }

  static activateAccount(item) {
    let api = "";
    api = API_PATHS.activateAccount;

    var user = JSON.parse(localStorage.getItem("users"));
    const token = user?.data?.token;

    if (token) {
      return axios
        .post(api, item, { headers: { "x-access-token": token } })
        .then((response) => {
          if (response.data) {
            // localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
        });
    }
  }

  static DeleteAccount(item) {
    let api = "";
    api = API_PATHS.deleteAccount;

    var user = JSON.parse(localStorage.getItem("users"));
    const token = user?.data?.token;
    const config = {
      headers: {
        "x-access-token": token,
      },
      data:item
    };
    
      return axios
        .delete(api, config)
        .then((response) => {
          if (response.data) {
            // localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
        });
    
  }

  static CancelDeleteAccount(item) {
    let api = "";
    var user = JSON.parse(localStorage.getItem("users"));
    const token = user?.data?.token;
  
    api = API_PATHS.canceldeleteAccount;

      return axios
        .post(api, item,  { headers: { "x-access-token": token } })
        .then((response) => {
          if (response.data) {
          
          }
          return response.data;
        });
    
  }

  static assignPlan(item) {
    let api = "";
    var user = JSON.parse(localStorage.getItem("users"));
    const token = user?.data?.token;
  
    api = API_PATHS.addplan;

      return axios
        .post(api, item,  { headers: { "x-access-token": token } })
        .then((response) => {
          if (response.data) {
          
          }
          return response.data;
        });
    
  }

  static getSupport() {
    let api = "";
    api = API_PATHS.websitesupport;
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios.get(api,config).then((response) => {
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  static removeuserDetails() {
    localStorage.removeItem("user");
  }

  static getuserDetails() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default ConfigService;
