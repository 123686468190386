//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT START---------------------------------------
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Navbartop from "./navbar";

//------------------------------------------------------------------------------------------
//---------------------------------------IMPORT END---------------------------------------

function AdminCookChangeRole() {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [isdisabled, setisDisabled] = useState(false);
  const [chef, setCook] = useState(false);
  const [houseCook, setHouseCook] = useState(false);
  const [partyCook, setPartyCook] = useState(false);
  const [showModal1, setshowModal1] = useState(false);
  const data = useSelector((state) => state?.cookData?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const [errors, setErrors] = useState("");
  const [changeError, setChangeError] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [notoneSelect, setNotselect] = useState();
  const [passwordError, setPasswordError] = useState("");
  const [rolesFrom, setRolesFrom] = useState({
    chef: 0,
    houseCook: 0,
    partyCook: 0,
  });
  const [rolesTo, setRolesTo] = useState({
    chef: 0,
    houseCook: 0,
    partyCook: 0,
  });

  const rolecomeFrom = [];
  const rolegoneTo = [];

  useEffect(() => {
    if (data?.data?.cookType === 2) {
      setCook(true);
      setRolesFrom((prevRoles) => ({
        ...prevRoles,
        chef: 1,
      }));
      setRolesTo((prevRoles) => ({
        ...prevRoles,
        chef: 1,
      }));
    }
    if (data?.data?.partyCook === 1) {
      setPartyCook(true);
      setRolesFrom((prevRoles) => ({
        ...prevRoles,
        partyCook: 1,
      }));
      setRolesTo((prevRoles) => ({
        ...prevRoles,
        partyCook: 1,
      }));
    }

    if (data?.data?.cookType === 1) {
      setHouseCook(true);
      setRolesFrom((prevRoles) => ({
        ...prevRoles,
        houseCook: 1,
      }));
      setRolesTo((prevRoles) => ({
        ...prevRoles,
        houseCook: 1,
      }));
    }
  }, []);

  const handleToggleCook = () => {
    setNotselect("");
    setCook((prevCook) => {
      const newCook = !prevCook;
      setRolesTo({
        ...rolesTo,
        chef: newCook ? 1 : 0,
      });
      if (newCook && houseCook) {
        setErrors("Both House Cook and Chef can't be selected.");
      } else {
        setErrors("");
      }
      return newCook;
    });

    setChangeError("");
  };

  const handleToggleHouseCook = () => {
    setNotselect("");
    setHouseCook((prevHouseCook) => {
      const newHouseCook = !prevHouseCook;
      setRolesTo({
        ...rolesTo,
        houseCook: newHouseCook ? 1 : 0,
      });
      if (chef && newHouseCook) {
        setErrors("Both House Cook and Chef can't be selected.");
      } else {
        setErrors("");
      }
      return newHouseCook;
    });

    setChangeError("");
  };

  const handleTogglePartyCook = () => {
    setNotselect("");
    const newPartyCook = !partyCook;
    setPartyCook(!partyCook);
    setRolesTo({
      ...rolesTo,
      partyCook: newPartyCook ? 1 : 0,
    });
    setChangeError("");
  };

  useEffect(() => {
    document.title = "CookandChef | Change Role";
  }, []);

  useEffect(() => {
    // Check if current values are different from the initial values
    const isCookChanged = rolesFrom.chef !== rolesTo.chef;
    const isHouseCookChanged = rolesFrom.houseCook !== rolesTo.houseCook;
    const isPartyCookChanged = rolesFrom.partyCook !== rolesTo.partyCook;

    // Set isChange to true if any of the values is different
    setIsChange(isCookChanged || isHouseCookChanged || isPartyCookChanged);
  }, [rolesFrom, rolesTo]);

  const HandleSubmit = (e) => {
    e.preventDefault();
    if (!chef && !houseCook && !partyCook) {
      setNotselect("Please select atleast one role");
    }
    if (!errors && isChange && (chef || houseCook || partyCook)) {
      setshowModal1(true);
    } else if (!errors && !isChange && !notoneSelect) {
      setChangeError("Please change roles to submit");
    }
    Object.keys(rolesFrom).forEach((role) => {
      if (rolesFrom[role] === 1) {
        if (role === "chef") {
          rolecomeFrom.push("Chef");
        }
        if (role === "houseCook") {
          rolecomeFrom.push("House Cook");
        }
        if (role === "partyCook") {
          rolecomeFrom.push("Party Cook");
        }
      }
    });

    // Map rolesTo values and push those that are 1 into rolegoneTo
    Object.keys(rolesTo).forEach((role) => {
      if (rolesTo[role] === 1) {
        if (role === "chef") {
          rolegoneTo.push("Chef");
        }
        if (role === "houseCook") {
          rolegoneTo.push("House Cook");
        }
        if (role === "partyCook") {
          rolegoneTo.push("Party Cook");
        }
      }
    });
    setModalMessage(
      "Cook type will be changed from " +
        rolecomeFrom.join(" and ") +
        " to " +
        rolegoneTo.join(", ")
    );
  };

  const handleCloseModal = () => {
    setshowModal1(false);
    setPasswordOpen(false);
  };

  const openPassword = () => {
    setPasswordOpen(true);
  };

  const ChangeRolefunc = () => {
    handleCloseModal();
    const item = {
      memberType: 1,
      cookType: chef ? 2 : houseCook ? 1 : 0,
      partyCook: partyCook ? 1 : 0,
      password: password,
    };
    // dispatch(ChangeRoleCook(item))
    //   .unwrap()
    //   .then((data) => {

    //     alert(data?.user?.message);

    //   })
    //   .catch(({ message }) => {
    //     setPassword("");
    //     alert(message);

    //   });
  };

  return (
    <>
      <Modal
        show={showModal1}
        onHide={handleCloseModal}
        style={{ top: "20%", left: "5%" }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header align="center">
          <Modal.Title
            align="center"
            style={{ fontSize: "20px" }}
          ></Modal.Title>
        </Modal.Header>
        <Modal.Body align="center">
          <h6>{modalMessage}</h6>
          <button className="modalcancelBtn m-2" onClick={handleCloseModal}>
            Cancel
          </button>
          <Button className="modalsaveBtn m-2" onClick={openPassword}>
            Ok
          </Button>
        </Modal.Body>
      </Modal>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Change Role
                  </div>
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Change Role</h4>
                      </div>
                    </div>

                    <Row>
                      <Col
                        lg={6}
                        xl={8}
                        md={8}
                        xs={12}
                        sm={12}
                        className="card text-center p-3 ms-3"
                      >
                        <Form
                          noValidate
                          autoComplete="off"
                          className="text-center mt-3 p-2"
                          style={{
                            height: "100%",
                            width: "80%",
                            backgroundColor: "",
                            borderRadius: "0px 15px 15px 0px",
                          }}
                        >
                          <Col lg={10} xl={10}>
                            <div className="d-flex justify-content-between p-1">
                              <div className="text-start">
                                <p
                                  className="mb-1"
                                  style={{ textWrap: "wrap" }}
                                >
                                  {" "}
                                  Chef
                                </p>
                              </div>
                              <div className="mt-1">
                                <Form>
                                  <Form.Check
                                    type="switch"
                                    id="notification-status"
                                    style={{ height: "4rem", width: "4rem" }}
                                    checked={chef === true}
                                    onChange={() => handleToggleCook()}
                                  />
                                </Form>
                              </div>
                            </div>
                          </Col>
                          <Col lg={10} xl={10}>
                            <div className="d-flex justify-content-between p-1">
                              <div className="text-start">
                                <p
                                  className="mb-1"
                                  style={{ textWrap: "wrap" }}
                                >
                                  House Cook
                                </p>
                              </div>

                              <Form>
                                <Form.Check
                                  type="switch"
                                  id="notification-status"
                                  style={{ height: "4rem", width: "4rem" }}
                                  checked={houseCook === true}
                                  onChange={() => handleToggleHouseCook()}
                                />
                              </Form>
                            </div>
                          </Col>
                          <Col lg={10} xl={10}>
                            <div className="d-flex justify-content-between p-1">
                              <div className="text-start">
                                <p
                                  className="mb-1"
                                  style={{ textWrap: "wrap" }}
                                >
                                  Party Cook
                                </p>
                              </div>

                              <Form>
                                <Form.Check
                                  type="switch"
                                  id="notification-status"
                                  style={{ height: "4rem", width: "4rem" }}
                                  checked={partyCook === true}
                                  onChange={() => handleTogglePartyCook()}
                                />
                              </Form>
                            </div>
                          </Col>

                          <p
                            style={{ color: "#dc3545", marginBottom: "20px" }}
                            align="center"
                          >
                            {errors || changeError || notoneSelect}
                          </p>

                          <Col lg={10} xl={10}>
                            <Form.Group>
                              <Button
                                type="cancel"
                                className="cancelBtn"
                                onClick={() => navigate("/cook/dashboard")}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="submit"
                                className="saveBtn ms-2"
                                disabled={isdisabled}
                                onClick={HandleSubmit}
                              >
                                Submit
                              </Button>
                            </Form.Group>
                          </Col>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /> <br />
      <br /> <br />
    </>
  );
}

export default AdminCookChangeRole;
