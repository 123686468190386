import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { assignplan, getCuisines } from "../store/slices/config";
import { getAllEmployerPlan } from "../store/slices/plan";
import { Postevent } from "../store/slices/jobs";
import { getEmployerDetails } from "../store/slices/employer";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

function PostEvent() {
  const { id } = useParams();
  const [myData, setMyData] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  const [foodType, setFoodType] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [dishes, setDishes] = useState("");
  const [expectedGuest, setexpectedGuest] = useState("");
  const [pincode, setPincode] = useState("");
  const [errors, setErrors] = useState({});
  const [cityCoord, setCityCoord] = useState([]);
  const [locationCoord, setLocationCoord] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate("");

  const [isdisabled, setisDisabled] = useState(false);
  const eventTypeRef = useRef(null);
  const dateRef = useRef(null);
  const cuisinesRef = useRef(null);
  const dishesRef = useRef(null);
  const guestsRef = useRef(null);
  const pincodeRef = useRef(null);

  const dispatch = useDispatch();

  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^[a-zA-Z0-9@&'’ -]{3,}$/.test(name);
  };
  const isValidcurrentCity = (currentCityName) => {
    return /^[a-zA-Z0-9\-' ,&@]{3,100}$/.test(currentCityName);
  };

  const isValidPincode = (pincode) => {
    // validating the name with one cap letter and 6 char of length
    return /^[0-9]{6}$/.test(pincode);
  };

  const isValidGuests = (expectedGuest) => {
    return /^(1[0-9]{1,3}|[2-9][0-9]{1,3}|10000)$/.test(expectedGuest);
  };

  const isValidDate = (inputDate) => {
    const date = new Date(inputDate);
    const currentDate = new Date();
    return date < currentDate;
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!selectedCuisines.length) {
      validationErrors.cuisines = "Please enter at least one cuisine";
    }
    if (!city.trim()) {
      validationErrors.city = "Please enter city";
    } else if (!isValidcurrentCity(city) || !cityCoord?.length) {
      validationErrors.city = "Please enter valid city";
    }
    if (!location.trim()) {
      validationErrors.location = "Please enter your location";
    } else if (!isValidcurrentCity(location) || !locationCoord?.length) {
      validationErrors.location = "Please enter valid location";
    }
    if (!eventDate.trim()) {
      validationErrors.eventDate = "Please enter event date";
    } else if (eventDate && isValidDate(eventDate)) {
      validationErrors.eventDate =
        "The event date should not be older than the current date";
    }
    if (!eventType.trim()) {
      validationErrors.eventType = "Please enter event type";
    } else if (!isValidName(eventType)) {
      validationErrors.eventType = "Please enter a valid event type";
    }
    if (!dishes.trim()) {
      validationErrors.dishes = "Please enter atleast one dish";
    }
    if (!expectedGuest.trim()) {
      validationErrors.expectedGuest = "Please enter expected no. of guests";
    } else if (!isValidGuests(expectedGuest)) {
      validationErrors.expectedGuest =
        "Expected guest should be greater than 10 and less than 10000";
    }
    if (!pincode.trim()) {
      validationErrors.pincode = "Please enter pincode";
    } else if (!isValidPincode(pincode)) {
      validationErrors.pincode = "Pincode a valid pincode";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (validationErrors.eventType) {
        eventTypeRef.current.focus();
      } else if (validationErrors.eventDate) {
        dateRef.current.focus();
      } else if (validationErrors.cuisines) {
        cuisinesRef.current.querySelector(".input1 input").focus();
      } else if (validationErrors.dishes) {
        dishesRef.current.focus();
      } else if (validationErrors.expectedGuest) {
        guestsRef.current.focus();
      } else if (validationErrors.location) {
        locationinpuutref.current.focus();
      } else if (validationErrors.pincode) {
        pincodeRef.current.focus();
      } else if (validationErrors.cityName) {
        cityinputRef.current.focus();
      }

      return;
    } else {
      const pin = Number(pincode);
      const item = {
        employerId: id,
        eventType: eventType || undefined,
        eventDate: eventDate || undefined,
        cuisines: selectedCuisines || undefined,
        expectedGuest: expectedGuest || undefined,
        pincode: pin || undefined,
        city: city || undefined,
        location: location || undefined,
        locationCoordinates: locationCoord
          ? {
              type: "Point",
              coordinates: locationCoord,
            }
          : undefined,
        cityCoordinates: cityCoord
          ? {
              type: "Point",
              coordinates: cityCoord,
            }
          : undefined,
        dishes: dishes || undefined,
      };
      setisDisabled(true);
      dispatch(Postevent(item))
        .unwrap()
        .then((data) => {
          alert("Event posted succesfully");
          setisDisabled(false);
          setTimeout(() => {
            navigate("/employer");
          }, 2000);
        })
        .catch(({ message }) => {
          setisDisabled(false);
          alert(message);
        });
    }
  };

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  }, [dispatch]);

  useEffect(() => {
    document.title = "CookandChef | Post Event";
  }, []);

  var cuisinoptions = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const locationinpuutref = useRef(null);

  useEffect(() => {
    if (locationinpuutref.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        locationinpuutref.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();

        if (!place.geometry || !place.geometry.location) {
          setLocationCoord([]);
          setErrors({
            ...errors,
            location: "Please select location from the suggestion drop-down",
          });
        } else {
          setErrors({
            ...errors,
            location: "",
          });
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];

          setLocation(place.name);
          setLocationCoord(coordinates);
        }
      });
      locationinpuutref.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = locationinpuutref.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setLocationCoord([]);
          setErrors({
            ...errors,
            location: "Please select location from the suggestion drop-down",
          });
        }
      });
    }
  }, []);

  const cityinputRef = useRef(null);

  useEffect(() => {
    if (cityinputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        cityinputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();

        if (!place.geometry || !place.geometry.location) {
          setCityCoord([]);
          setErrors({
            ...errors,
            city: "Please select location from the suggestion drop-down",
          });
        } else {
          setErrors({
            ...errors,
            city: "",
          });
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];

          setCity(place.name);
          setCityCoord(coordinates);
        }
      });
      cityinputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = cityinputRef.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setCityCoord([]);
          setErrors({
            ...errors,
            city: "Please select location from the suggestion drop-down",
          });
        }
      });
    }
  }, []);

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;
    switch (name) {
      case "city":
        setCity(value);
        validationErrors[name] = value === "" ? "Please enter valid city" : "";
        if (value !== "" && isValidcurrentCity(city)) {
          validationErrors[name] = "";
        }
        break;
      case "eventType":
        setEventType(value);
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Please enter a valid event type"
            : "";
        if (value !== "" && isValidName(value)) {
          validationErrors[name] = "";
        }
        break;
      case "location":
        setLocation(value);
        validationErrors[name] =
          value === "" ? "Please enter valid location" : "";
        if (value !== "" && isValidcurrentCity(location)) {
          validationErrors[name] = "";
        }
        break;

      case "eventDate":
        setEventDate(value);
        validationErrors[name] =
          value === "" || !isValidDate(value)
            ? "The event date should not be older than the current date"
            : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "dishes":
        setDishes(value);
        validationErrors[name] =
          value === "" ? "Please enter event datdishes" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "pincode":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 6) {
          value = value.slice(0, 6);
        }
        setPincode(value);
        validationErrors[name] =
          value === "" || !isValidPincode(value) ? "Please enter pincode" : "";
        if (value !== "" && isValidPincode(value)) {
          validationErrors[name] = "";
        }
        break;

      case "expectedGuest":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 5) {
          value = value.slice(0, 5);
        }
        setexpectedGuest(value);
        validationErrors[name] =
          value === "" || isValidGuests(value)
            ? ""
            : "Expected guests should be more than 10 and 10000";
        if (!value === "" && isValidGuests(value)) {
          delete validationErrors[name];
        }
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  const handleCuisineChange = (selectedOptions) => {
    setSelectedCuisines(selectedOptions);
    const isValid = validateCuisines(selectedOptions);
    setErrors((prevErrors) => ({
      ...prevErrors,
      cuisines: isValid ? "" : "Please select at least one cuisine.",
    }));
  };

  const validateCuisines = (selectedOptions) => {
    return selectedOptions.length > 0;
  };

  const date = new Date();
  date.setDate(date.getDate() + 1);

  const tommorrow = date.toISOString().split("T")[0];

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  React.useEffect(() => {
    dispatch(getEmployerDetails(id))
      .unwrap()
      .then((data1) => {
        setMyData(data1.users.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp;
                    <Link onClick={cancelHandler}>Client Details </Link> &#8811;
                    Add Event
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Post Event</h4>
                      </div>
                    </div>
                    <section className="form-section">
                      <Form
                        autoComplete="off"
                        noValidate
                        onSubmit={submitHandler}
                      >
                        <Row className="mt-4 gx-3 d-flex justify-content-center ">
                          <Col lg={12} xs={11} className="mb-4">
                            <Row className="=d-flex justify-content-center align-items-center">
                              <div
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  backgroundColor: "white",
                                  borderRadius: "15px",
                                  padding: "20px 40px",
                                }}
                              >
                                <Row>
                                  <Col lg={6} sm={12}>
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Event Type *
                                      </label>
                                      <Form.Control
                                        className="input1 my-2"
                                        name="eventType"
                                        value={eventType}
                                        onChange={handleChange}
                                        type="text"
                                        isInvalid={!!errors.eventType}
                                        isValid={
                                          !errors.eventType &&
                                          eventType.length > 0
                                        }
                                        placeholder="e.g Marriage, Birthday"
                                        ref={eventTypeRef}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.eventType}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} sm={12}>
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Date of Event *
                                      </label>
                                      <Form.Control
                                        className="input1"
                                        name="eventDate"
                                        value={eventDate}
                                        onChange={handleChange}
                                        type="date"
                                        id="session-date"
                                        min={tommorrow}
                                        isInvalid={!!errors.eventDate}
                                        ref={dateRef}
                                        placeholder="enter eventDate"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.eventDate}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6} sm={12}>
                                    <Form.Group className="my-1">
                                      <label className="mb-2 label">
                                        Cuisines *
                                      </label>
                                      <div ref={cuisinesRef}>
                                        <Typeahead
                                          id="basic-typeahead-multiple"
                                          labelKey="cuisineName"
                                          multiple
                                          className="input1"
                                          onChange={handleCuisineChange}
                                          options={cuisinoptions}
                                          placeholder="North Indian, South Indian"
                                          selected={selectedCuisines}
                                        />
                                      </div>
                                      <p
                                        style={{
                                          color: "#DC3545",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {errors.cuisines}
                                      </p>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} sm={12}>
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Dishes *
                                      </label>
                                      <Form.Control
                                        className="input1"
                                        name="dishes"
                                        value={dishes}
                                        onChange={handleChange}
                                        type="text"
                                        isInvalid={!!errors.dishes}
                                        isValid={
                                          !errors.dishes && dishes.length > 0
                                        }
                                        placeholder="Nan, Paneer"
                                        ref={dishesRef}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.dishes}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={6} sm={12}>
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Expected Guests *
                                      </label>
                                      <Form.Control
                                        name="expectedGuest"
                                        value={expectedGuest}
                                        onChange={handleChange}
                                        isInvalid={!!errors.expectedGuest}
                                        isValid={
                                          !errors.expectedGuest &&
                                          expectedGuest.length > 0
                                        }
                                        className="input1"
                                        type="text"
                                        placeholder="1000"
                                        ref={guestsRef}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.expectedGuest}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} sm={12}>
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Location *
                                      </label>
                                      <Form.Control
                                        name="location"
                                        value={location}
                                        onChange={handleChange}
                                        isInvalid={!!errors.location}
                                        isValid={
                                          !errors.location &&
                                          location.length > 0
                                        }
                                        className="input1"
                                        type="text"
                                        ref={locationinpuutref}
                                        placeholder="Hebbal"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.location}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={6} sm={12}>
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Pincode *
                                      </label>
                                      <Form.Control
                                        name="pincode"
                                        value={pincode}
                                        onChange={handleChange}
                                        isInvalid={!!errors.pincode}
                                        isValid={
                                          !errors.pincode && pincode.length > 0
                                        }
                                        className="input1"
                                        type="text"
                                        placeholder="110011"
                                        ref={pincodeRef}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.pincode}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} sm={12}>
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        City *
                                      </label>
                                      <Form.Control
                                        name="city"
                                        value={city}
                                        onChange={handleChange}
                                        isInvalid={!!errors.city}
                                        isValid={
                                          !errors.city && city.length > 0
                                        }
                                        className="input1"
                                        type="text"
                                        ref={cityinputRef}
                                        placeholder="Bangalore, Mumbai"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.city}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row className="d-flex justify-content-center mt-4">
                                  <Col lg={5} xs={8}>
                                    <div className="d-flex justify-content-around my-2 align-items-center">
                                      <Button
                                        className="cancelBtn"
                                        onClick={() => navigate(-1)}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        type="submit"
                                        disabled={isdisabled}
                                        className="saveBtn ms-2"
                                      >
                                        Save
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostEvent;
