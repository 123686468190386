import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Navbartop from "./navbar";
import { getCuisines } from "../store/slices/config";
import { EditEventDetails, getEventDetails } from "../store/slices/events";

function EditEventsDetails() {
  const { id } = useParams();
  localStorage.setItem("employerId", id);
  const [cuisines, setCuisines] = useState([]);
  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [dishes, setDishes] = useState("");
  const [expectedGuest, setexpectedGuest] = useState("");
  const [pincode, setPincode] = useState("");
  const [errors, setErrors] = useState({});
  const [cityCoord, setCityCoord] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const [locationCoord, setLocationCoord] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const eventTypeRef = useRef(null);
  const dateRef = useRef(null);
  const cuisinesRef = useRef(null);
  const dishesRef = useRef(null);
  const guestsRef = useRef(null);
  const pincodeRef = useRef(null);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    document.title = "CookandChef Admin | Edit Event Details";
  }, []);

  const date = new Date();
  date.setDate(date.getDate() + 1);

  const tommorrow = date.toISOString().split("T")[0];

  useEffect(() => {
    dispatch(getEventDetails(id))
      .unwrap()
      .then((data) => {
        setCity(data?.events?.data[0]?.city);
        setSelectedCuisines(data?.events?.data[0].cuisines);
        setEventDate(data?.events?.data[0].eventDate);
        setEventType(data?.events?.data[0].eventType);
        setDishes(data?.events?.data[0].dishes);
        setexpectedGuest(data?.events?.data[0].expectedGuest);
        setPincode(data?.events?.data[0].pincode);
        setLocation(data?.events?.data[0].location);
        setLocationCoord(data?.events?.data[0].locationCoordinates.coordinates);
        setCityCoord(data?.events?.data[0].cityCoordinates.coordinates);
      })
      .catch((err) => {
        setModalMessage(err);
        setShowModal(true);
      });
  }, []);

  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?=.*[a-zA-Z])[a-zA-Z0-9'@&’ -_]{3,}$/.test(name);
  };
  const isValidcurrentCity = (currentCityName) => {
    return /^[a-zA-Z\s.'-]{1,180}$/.test(currentCityName);
  };

  const isValidPincode = (pincode) => {
    // validating the name with one cap letter and 6 char of length
    return /^[1-9][0-9]{5}$/.test(pincode);
  };

  const isValidGuests = (expectedGuest) => {
    return /^(1[0-9]{1,3}|[2-9][0-9]{1,3}|10000)$/.test(expectedGuest);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};
    if (!selectedCuisines?.length) {
      validationErrors.cuisines = "Please enter at least one cuisine";
    }
    if (!city) {
      validationErrors.city = "Please enter city";
    } else if (!isValidcurrentCity(city) || !cityCoord?.length) {
      validationErrors.city = "Please enter valid city";
    }
    if (!location) {
      validationErrors.location = "Please enter event location";
    } else if (!isValidcurrentCity(location) || !locationCoord?.length) {
      validationErrors.location = "Please enter valid location";
    }
    if (!eventDate) {
      validationErrors.eventDate = "Please enter event date";
    }
    if (!eventType) {
      validationErrors.eventType = "Please enter event type";
    } else if (!isValidName(eventType)) {
      validationErrors.eventType = "Event type must be atleat 3 characters";
    }
    if (!dishes) {
      validationErrors.dishes = "Please enter atleast one dish";
    }
    if (!expectedGuest) {
      validationErrors.expectedGuest = "Please enter expected no. of guests";
    } else if (!isValidGuests(expectedGuest)) {
      validationErrors.expectedGuest =
        "Guests count should be greater than 10 and less than 10,000";
    }
    if (!pincode) {
      validationErrors.pincode = "Please enter pincode";
    } else if (!isValidPincode(pincode)) {
      validationErrors.pincode = "Please enter a valid pincode";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (validationErrors.eventType) {
        eventTypeRef.current.focus();
      } else if (validationErrors.eventDate) {
        dateRef.current.focus();
      } else if (validationErrors.dishes) {
        dishesRef.current.focus();
      } else if (validationErrors.expectedGuest) {
        guestsRef.current.focus();
      } else if (validationErrors.cityName) {
        cityinputRef.current.focus();
      } else if (validationErrors.location) {
        locationinpuutref.current.focus();
      } else if (validationErrors.pincode) {
        pincodeRef.current.focus();
      } else if (validationErrors.cuisines) {
        cuisinesRef.current.querySelector(".input1 input").focus();
      }
      return;
    } else {
      const pin = Number(pincode);
      const item = {
        id: id,
        eventType: eventType || undefined,
        eventDate: eventDate.toString().split("T")[0] || undefined,
        cuisines: selectedCuisines || undefined,
        expectedGuest: expectedGuest || undefined,
        pincode: pin || undefined,
        city: city || undefined,
        location: location || undefined,
        locationCoordinates:
          {
            type: "Point",
            coordinates: locationCoord,
          } || undefined,
        cityCoordinates:
          {
            type: "Point",
            coordinates: cityCoord,
          } || undefined,
        dishes: dishes || undefined,
      };

      dispatch(EditEventDetails(item))
        .unwrap()
        .then((data) => {
          alert(data?.events?.message);
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  var cuisinoptions = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const locationinpuutref = useRef(null);

  useEffect(() => {
    if (locationinpuutref.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        locationinpuutref.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();

        if (!place.geometry || !place.geometry.location) {
          setErrors({
            ...errors,
            location: "Please select location from the suggestion drop-down",
          });
          setLocationCoord([]);
        } else {
          setErrors({
            ...errors,
            location: "",
          });
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];

          setLocation(place.name);
          setLocationCoord(coordinates);
        }
      });
      locationinpuutref.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = locationinpuutref.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setErrors({
            ...errors,
            location: "Please select location from the suggestion drop-down",
          });
          setLocationCoord([]);
        }
      });
    }
  }, []);

  const cityinputRef = useRef(null);

  useEffect(() => {
    if (cityinputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        cityinputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();

        if (!place.geometry || !place.geometry.location) {
          setErrors({
            ...errors,
            city: "Please select location from the suggestion drop-down",
          });
          setCityCoord([]);
        } else {
          setErrors({
            ...errors,
            city: "",
          });
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];

          setCity(place.name);
          setCityCoord(coordinates);
        }
      });
      cityinputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();

        const inputValue = cityinputRef.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setErrors({
            ...errors,
            city: "Please select location from the suggestion drop-down",
          });
          setCityCoord([]);
        }
      });
    }
  }, []);

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value } = e.target;
    switch (name) {
      case "city":
        setCity(value);
        validationErrors[name] = value === "" ? "Please enter valid city" : "";
        if (value !== "" && isValidcurrentCity(value)) {
          validationErrors[name] = "";
        }
        break;
      case "eventType":
        setEventType(value);
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Event type should be atleast  3 characters"
            : "";
        if (value !== "" && isValidName(value)) {
          validationErrors[name] = "";
        }
        break;
      case "location":
        setLocation(value);
        validationErrors[name] =
          value === "" ? "Please enter valid location" : "";
        if (value !== "" && isValidcurrentCity(value)) {
          validationErrors[name] = "";
        }
        break;

      case "eventDate":
        setEventDate(value);
        validationErrors[name] = value === "" ? "Please enter event date" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "dishes":
        setDishes(value);
        validationErrors[name] =
          value === "" ? "Please enter event datdishes" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "pincode":
        setPincode(value);
        validationErrors[name] =
          value === "" || isValidPincode(value)
            ? "pincode must be atleast 6 digits"
            : "";
        if (value !== "" && isValidPincode(value)) {
          validationErrors[name] = "";
        }
        break;

      case "expectedGuest":
        setexpectedGuest(value);
        validationErrors[name] =
          value === "" || isValidGuests(value)
            ? ""
            : "Expected guests should be more than 10 and 10000";
        if (!value === "" && isValidGuests(value)) {
          delete validationErrors[name];
        }
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  const handleCuisineChange = (selectedOptions) => {
    setSelectedCuisines(selectedOptions);
    const isValid = validateCuisines(selectedOptions);
    setErrors((prevErrors) => ({
      ...prevErrors,
      cuisines: isValid ? "" : "Please select at least one cuisine.",
    }));
  };

  const validateCuisines = (selectedOptions) => {
    return selectedOptions.length > 0;
  };
  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <>
      <div>
        <Navbartop />

        <div className="container-fluid pb-5 ms-2 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-2 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;
                      <Link onClick={() => navigate(-1)}>Event Details</Link>
                      &#8811; Edit Event Details
                    </div>

                    <Form
                      autoComplete="off"
                      noValidate
                      onSubmit={submitHandler}
                    >
                      <Row className="d-flex justify-content-center mt-4 card p-3">
                        <Col lg={12} xs={12} md={12} className="mb-4">
                          <h5 className="text-left heading orange">
                            Job Details
                          </h5>
                          <Row className="mt-4 gx-3 d-flex justify-content-center ">
                            <Col lg={12} xs={11} className="mb-4">
                              <Row className="=d-flex justify=-content-center align-items-center">
                                <div
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "white",
                                    borderRadius: "15px",
                                    padding: "20px 40px",
                                  }}
                                >
                                  <Row>
                                    <Col lg={6} sm={12}>
                                      <Form.Group className="my-2">
                                        <label className="mb-2 label">
                                          Event Type *
                                        </label>
                                        <Form.Control
                                          className="input1"
                                          name="eventType"
                                          value={eventType}
                                          onChange={handleChange}
                                          type="text"
                                          isInvalid={!!errors.eventType}
                                          isValid={
                                            !errors.eventType &&
                                            eventType?.length > 0
                                          }
                                          ref={eventTypeRef}
                                          placeholder="e.g Marriage, Birthday"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.eventType}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6} sm={12}>
                                      <Form.Group className="my-2">
                                        <label className="mb-2 label">
                                          Date of Event *
                                        </label>
                                        <Form.Control
                                          className="input1"
                                          name="eventDate"
                                          value={
                                            eventDate?.toString().split("T")[0]
                                          }
                                          onChange={handleChange}
                                          type="date"
                                          min={tommorrow}
                                          id="session-date"
                                          isInvalid={!!errors.eventDate}
                                          ref={dateRef}
                                          placeholder="Enter event date"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.eventDate}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                      <Form.Group className="my-2">
                                        <label className="mb-2 label">
                                          Cuisines *
                                        </label>
                                        <div ref={cuisinesRef}>
                                          <Typeahead
                                            id="basic-typeahead-multiple"
                                            labelKey="cuisineName"
                                            multiple
                                            className="input1"
                                            onChange={handleCuisineChange}
                                            options={cuisinoptions}
                                            placeholder="North Indian, South Indian"
                                            selected={selectedCuisines}
                                          />
                                        </div>
                                        <p
                                          style={{
                                            color: "#DC3545",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {errors.cuisines}
                                        </p>
                                      </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                      <Form.Group className="my-2">
                                        <label className="mb-2 label">
                                          Dishes *
                                        </label>
                                        <Form.Control
                                          className="input1"
                                          name="dishes"
                                          value={dishes}
                                          onChange={handleChange}
                                          type="text"
                                          isInvalid={!!errors.dishes}
                                          isValid={
                                            !errors.dishes && dishes?.length > 0
                                          }
                                          ref={dishesRef}
                                          placeholder="Nan, Paneer"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.dishes}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12} className="my-2">
                                      <Form.Group className="my-2">
                                        <label className="mb-2 label">
                                          Expected Guests *
                                        </label>
                                        <Form.Control
                                          name="expectedGuest"
                                          value={expectedGuest}
                                          onChange={handleChange}
                                          ref={guestsRef}
                                          isInvalid={!!errors.expectedGuest}
                                          isValid={
                                            !errors.expectedGuest &&
                                            expectedGuest?.length > 0
                                          }
                                          className="input1"
                                          type="text"
                                          placeholder="1000"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.expectedGuest}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                      <Form.Group className="my-2">
                                        <label className="mb-2 label">
                                          Location *
                                        </label>
                                        <Form.Control
                                          name="location"
                                          value={location}
                                          onChange={handleChange}
                                          isInvalid={!!errors.location}
                                          isValid={
                                            !errors.location &&
                                            location?.length > 0
                                          }
                                          className="input1"
                                          type="text"
                                          ref={locationinpuutref}
                                          placeholder="Hebbal"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.location}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12} className="my-2">
                                      <Form.Group className="my-2">
                                        <label className="mb-2 label">
                                          Pincode *
                                        </label>
                                        <Form.Control
                                          name="pincode"
                                          value={pincode}
                                          onChange={handleChange}
                                          isInvalid={!!errors.pincode}
                                          isValid={
                                            !errors.pincode &&
                                            pincode?.length > 0
                                          }
                                          className="input1"
                                          ref={pincodeRef}
                                          type="text"
                                          placeholder="110011"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.pincode}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                      <Form.Group className="my-2">
                                        <label className="mb-2 label">
                                          City *
                                        </label>
                                        <Form.Control
                                          name="city"
                                          value={city}
                                          onChange={handleChange}
                                          isInvalid={!!errors.city}
                                          isValid={
                                            !errors.city && city?.length > 0
                                          }
                                          className="input1"
                                          type="text"
                                          ref={cityinputRef}
                                          placeholder="Bangalore, Mumbai"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.city}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  <Row className="d-flex justify-content-center mt-4">
                                    <Col lg={5} xs={8}>
                                      <div className="d-flex justify-content-around my-2 align-items-center">
                                        <Button
                                          className="cancelBtn"
                                          onClick={() => navigate(-1)}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          type="submit"
                                          disabled={isdisabled}
                                          className="saveBtn ms-2"
                                        >
                                          Save
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditEventsDetails;
