import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DropDownService from "../../services/dropdown.service"

export const createCuisines = createAsyncThunk(
    "admin/createCuisines",
    async (item,thunkAPI) => {
        try {
            const data = await DropDownService.createCuisine(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const createLanguages = createAsyncThunk(
    "admin/createLanguages",
    async (item,thunkAPI) => {
        try {
            const data = await DropDownService.createLanguage(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const createProfinces = createAsyncThunk(
    "admin/createProfinces",
    async (item,thunkAPI) => {
        try {
            const data = await DropDownService.createProfince(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const createQualifications = createAsyncThunk(
    "admin/createQualification",
    async (item,thunkAPI) => {
        try {
            const data = await DropDownService.createQualification(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);