import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import clientimg from "../../src/Assets/Images/client.png";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Moment from "moment";

import {
  editEmployerDetails,
  getEmployerDetails,
} from "../store/slices/employer";
import Navbartop from "./navbar";
import { getLanguages } from "../store/slices/config";
import AvatarModel from "../utils/helpers/ImageCropper/AvatarModal";
import { API_PATHS } from "../utils/constants/api.constants";

function EditEmployerDetails() {
  const { id } = useParams();
  localStorage.setItem("employerId", id);
  const [fullName, setfullName] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [imageData, setImageData] = useState("");

  React.useEffect(() => {
    dispatch(getEmployerDetails(id))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  useEffect(() => {
    document.title = "CookandChef Admin | Edit Employer";
  }, []);

  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?=.*[a-zA-Z])[a-zA-Z0-9'@&’ -_]{3,}$/.test(name);
  };

  const isValidEmployeesCount = (value) => {
    const intValue = parseInt(value, 10);
    return intValue >= 1 && intValue <= 100000 && /^[1-9]\d*$/.test(value);
  };

  const isValidEstablishmentYear = (value) =>
    /^(19[0-9]\d|20[0-2]\d)$/.test(value);

  const submitHandler = (e) => {
    e.preventDefault();
    // alert("working")
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};
    if (!data.fullName) {
      validationErrors.fullName = "Please enter name";
    } else if (!isValidName(data.fullName)) {
      validationErrors.fullName = "Name must be atleast 3 charaacters";
    }
    if (!data.propertyType) {
      validationErrors.propertyType = "Please select propertyType";
    }

    if (
      data.establishmentYear &&
      !isValidEstablishmentYear(data.establishmentYear)
    ) {
      validationErrors.establishmentYear =
        "Please enter valid establishment year";
    }
    if (data.employeesCount && !isValidEmployeesCount(data.employeesCount)) {
      validationErrors.employeesCount =
        "Employes count should not be greater than 100000";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

      return;
    } else {
      const item = {
        ...data,
        id: id,
      };

      dispatch(editEmployerDetails(item))
        .unwrap()
        .then((data) => {
          alert("Profile updated succesfully");
          navigate(`/employerdetails/${id}`);
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value } = e.target;
    switch (name) {
      case "fullName":
        setfullName(value);
        setData({
          ...data,
          fullName: value,
        });
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Please enter a valid name"
            : "";
        if (!value == "" && isValidName(value)) {
          delete validationErrors[name];
        }
        break;

      case "propertyType":
        setData({
          ...data,
          propertyType: value,
        });
        validationErrors[name] =
          value === "" ? "Please enter property type" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;

      case "establishmentYear":
        setData({
          ...data,
          establishmentYear: value,
        });
        validationErrors[name] =
          value !== "" && !isValidEstablishmentYear(value)
            ? "Please enter valid establishment year"
            : "";
        if (value !== "" && isValidEstablishmentYear(value)) {
          validationErrors[name] = "";
        }
        break;
      case "employeesCount":
        setData({
          ...data,
          employeesCount: value,
        });
        validationErrors[name] =
          value !== "" && !isValidEmployeesCount(value)
            ? "Employes count should not be greater than 100000"
            : "";
        if (value !== "" && isValidEmployeesCount(value)) {
          validationErrors[name] = "";
        }
        break;

      default:
        setData({
          ...data,
          [name]: value,
        });
        break;
    }
    setErrors(validationErrors);
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleChangeImage = (e) => {
    setAvatarModalOpen(true);
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    setImageData(formdata);
  };

  async function handleSave(requestOptions) {
    let response = await fetch(API_PATHS.uploadFile, requestOptions);
    let data2 = await response.json();
    setData({
      ...data,
      dp: data2.data.location,
    });
  }
  return (
    <>
      <div>
        <Navbartop />
        <Navbartop />
        <AvatarModel
          showModal1={avatarModalOpen}
          image={imageData}
          setShowModal1={setAvatarModalOpen}
          handleSave={handleSave}
          setData={setData}
          data={data}
        />
        <div className="container-fluid pb-5 ms-2 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-2 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;{" "}
                      <Link to="/client">Employer</Link> &#8811; Edit Employer
                      General Details
                    </div>

                    <Form
                      autoComplete="off"
                      noValidate
                      onSubmit={submitHandler}
                    >
                      <Row className="d-flex justify-content-center mt-4 card p-3">
                        <Col lg={12} xs={12} md={12} className="mb-4">
                          <h5 className="text-left heading orange">
                            General Details
                          </h5>

                          <Row className="gx-2 d-flex justify-content-center p-2">
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">Name *</label>
                                <Form.Control
                                  className="input1"
                                  name="fullName"
                                  value={data?.fullName}
                                  onChange={handleChange}
                                  type="text"
                                  maxlength="100"
                                  isInvalid={!!errors.fullName}
                                  placeholder="Enter your name"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.fullName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">
                                  Property Type *
                                </label>
                                <Form.Control
                                  as="select"
                                  name="propertyType"
                                  className="input1"
                                  value={data?.propertyType}
                                  onChange={handleChange}
                                  isInvalid={!!errors.propertyType}
                                >
                                  <option value="">Select</option>
                                  <option value="2">Restaurant</option>
                                  <option value="1">Hotel</option>
                                  <option value="3">Cafe</option>
                                  <option value="4">Cloud Kitchen</option>
                                  <option value="5">Other</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.propertyType}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">
                                  Establishment year
                                </label>
                                <Form.Control
                                  name="establishmentYear"
                                  value={data?.establishmentYear}
                                  onChange={handleChange}
                                  isInvalid={!!errors.establishmentYear}
                                  className="input1 "
                                  type="text"
                                  placeholder="Enter establishmentYear"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.establishmentYear}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">
                                  Employees count
                                </label>
                                <Form.Control
                                  name="employeesCount"
                                  value={data?.employeesCount}
                                  onChange={handleChange}
                                  isInvalid={!!errors.employeesCount}
                                  className="input1 "
                                  type="text"
                                  placeholder="Enter employees count"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.employeesCount}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              <label for="fileInput" className="mt-1">
                                Change Image
                              </label>
                              <Form.Control
                                type="file"
                                name="profiledp"
                                id="fileInput"
                                accept="image/*"
                                onChange={(e) => handleChangeImage(e)}
                                className="input1 "
                                placeholder="Please choose profile photo"
                              />
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              {" "}
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-center mt-4">
                            <Col lg={4} xs={8}>
                              <div className="d-flex justify-content-around my-2 align-items-center">
                                <button
                                  onClick={cancelHandler}
                                  className="btn cancelBtn"
                                >
                                  Cancel
                                </button>
                                &nbsp;&nbsp;
                                <Button
                                  disabled={isdisabled}
                                  type="submit"
                                  className="btn saveBtn my-2"
                                >
                                  Save
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditEmployerDetails;
