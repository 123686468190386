import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Navbartop from "../navbar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_PATHS } from "../../utils/constants/api.constants";
import { Link } from "react-router-dom";
import { AddAdvertisements } from "../../store/slices/advertisements";

function AddAdvertisement() {
  const [usertype, setUsertype] = useState("");
  const [image, setImage] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validationErrors = { ...errors };
    switch (name) {
      case "usertype":
        setUsertype(value);
        validationErrors[name] = value === "" ? "Please select user type" : "";
        if (value !== "") {
          delete validationErrors[name];
        }
        break;

      default:
        break;
    }
    setErrors(validationErrors);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Add Advertisement";
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const submitHandler = (e) => {
    const validationErrors = {};
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    if (!usertype?.trim()) {
      validationErrors.usertype = "Please select user type";
    }

    if (!image) {
      validationErrors.image = "Please upload image";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      let status = 1;
      let item = {
        usertype: usertype || undefined,
        status,
        image: image || undefined,
      };

      dispatch(AddAdvertisements(item))
        .unwrap()
        .then((data) => {
          alert(data?.ads?.message);
          navigate("/advertisements");
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user?.data?.token;

  async function imageUpload(e) {
    e.preventDefault();
    setErrors({
      ...errors,
      image: "",
    });
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (file && allowedTypes.includes(file.type)) {
      var formdata = new FormData();
      formdata?.append("file", e.target.files[0]);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
        headers: { "x-access-token": token },
      };
      let response = await fetch(API_PATHS.uploadFile, requestOptions);
      let data = await response.json();
      setImage(data?.data?.location);
    } else {
      setErrors({
        ...errors,
        image:
          "Invalid file format. Please upload a valid image file (JPEG, PNG, GIF).",
      });
    }
  }

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp;
                    <Link to="/advertisements">Advertisements</Link> &#8811; Add
                    Advertisements
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Add Advertisements</h4>
                      </div>
                    </div>
                    <section className="form-section">
                      <Form className="mx-4" onSubmit={submitHandler}>
                        <Row className="my-4  justify-content-start">
                          <Row className="d-flex ">
                            <Col lg={5} className="p-1">
                              <Form.Group>
                                <Form.Label className="mb-2 label">
                                  User Type *
                                </Form.Label>

                                <Form.Control
                                  name="usertype"
                                  value={usertype}
                                  onChange={handleChange}
                                  isInvalid={!!errors?.usertype}
                                  isValid={!errors?.usertype}
                                  as="select"
                                  placeholder="Enter  usertype"
                                >
                                  <option value="">User Type</option>
                                  <option value="chef">Chef</option>
                                  <option value="partycook">Partycook</option>
                                  <option value="catering">Catering</option>
                                  <option value="housecook">Housecook</option>
                                  <option value="employer">Employer</option>
                                  <option value="client">Client</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors?.usertype}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={5} className="p-1">
                              <Form.Group>
                                <label className="mb-2 label">Image *</label>

                                <Form.Control
                                  type="file"
                                  name="image"
                                  onChange={(e) => imageUpload(e)}
                                  className="input1"
                                  isInvalid={!!errors?.image}
                                  accept="image/*"
                                  isValid={!errors?.image}
                                  placeholder="Please upload image"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors?.image}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <br />
                              (Please upload image of resolution 720X90)
                            </Col>
                          </Row>

                          <Row className="justify-content-center mt-5">
                            <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                              <button
                                className="btn cancelBtn"
                                onClick={cancelHandler}
                              >
                                Cancel
                              </button>
                            </Col>{" "}
                            <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                              <button
                                className="btn saveBtn mx-2"
                                disabled={isdisabled}
                                type="submit"
                              >
                                Save
                              </button>
                            </Col>
                          </Row>
                        </Row>
                      </Form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAdvertisement;
