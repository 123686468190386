import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ConfigService from "../../services/config.service";

export const getCuisines = createAsyncThunk(
    "config/getcuisines",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.getCuisines();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const resetPassword = createAsyncThunk(
    "config/reset",
    async (item, thunkAPI) => {
        
        try {
            const data = await ConfigService.resetPass(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const suspendAccount = createAsyncThunk(
    "config/reset",
    async (item, thunkAPI) => {
        
        try {
            const data = await ConfigService.suspendAccount(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const activateAccount = createAsyncThunk(
    "config/reset",
    async (item, thunkAPI) => {
        
        try {
            const data = await ConfigService.activateAccount(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
export const deleteAccount = createAsyncThunk(
    "config/reset",
    async (item, thunkAPI) => {
        
        try {
            const data = await ConfigService.DeleteAccount(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const canceldeleteAccount = createAsyncThunk(
    "config/reset",
    async (item, thunkAPI) => {
        
        try {
            const data = await ConfigService.CancelDeleteAccount(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const assignplan = createAsyncThunk(
    "config/assignplan",
    async (item, thunkAPI) => {
        
        try {
            const data = await ConfigService.assignPlan(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getQualification = createAsyncThunk(
    "config/getqualification",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.getQualification();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const getLanguages = createAsyncThunk(
    "config/getlanguages",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.getLanguages();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getStates = createAsyncThunk(
    "config/getstates",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.getStates();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getSupport= createAsyncThunk(
    "config/getsupport",
    async ( thunkAPI) => {
        try {
            const data = await ConfigService.getSupport();
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);





const initialState = {
    loading: false,
    error: "",
    user: null,
    isLoggedIn: false,
};

const configSlice = createSlice({
    name: "config",
    initialState,
    extraReducers: {
        [getCuisines.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getCuisines.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getCuisines.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },

        [getQualification.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.user = null;
        },
        [getQualification.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [getQualification.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.user = null;
        },
       

        // [logout.fulfilled]: (state, action) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

const { reducer } = configSlice;
export default reducer;