import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class jobsService {
  static getJobs(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    api = API_PATHS.getJobs + "?";

    if (item?.limit) {
      api += `limit=${item.limit}&`;
    }

    if (item?.page) {
      api += `page=${item.page}&`;
    }

    if (item?.active === 0) {
      api += `expired=1&`;
    }
    if (item?.active === 1) {
      api += `active=1&`;
    }

    if (item?.active === 2) {
      api += `disabled=1&`;
    }

    if (item?.minimumSalary) {
      api += `minimumSalary=${item.minimumSalary}&`;
    }

    if (item?.maximumSalary) {
      api += `maximumSalary=${item.maximumSalary}&`;
    }
    if (item?.cuisines && item?.cuisines?.length > 0) {
      // Encode each cuisine and append to the API URL
      item?.cuisines?.forEach((cuisine) => {
        api += `cuisines[]=${encodeURIComponent(cuisine)}&`;
      });
    }

    if (item?.longitude) {
      api += `longitude=${item.longitude}&`;
    }
    if (item?.latitude) {
      api += `latitude=${item.latitude}&`;
    }
    if (item?.nameEmailOrMobile) {
      api += `nameEmailOrMobile=${item.nameEmailOrMobile}&`;
    }
    // Remove the trailing "&" if there are parameters
    if (api.endsWith("&")) {
      api = api.slice(0, -1);
    }

    return axios.get(api, config).then((response) => {
      return response.data;
    });
  }

  static getJobsDetails(id) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    api = API_PATHS.getJobsDetails + "?id=" + id;
    return axios.get(api, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static getApplicationList(limit, page, id) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    api = `${API_PATHS.getApplicationslist}?jobId=${id}&limit=${limit}&page=${page}`;

    return axios.get(api, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static getEventApplicationList(limit, page, id) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    api = `${API_PATHS.getApplicationslist}?eventId=${id}&limit=${limit}&page=${page}`;

    return axios.get(api, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static sendNotifications = (id) => {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.sendnotifications;
    let item = {
      jobId: id,
    };
    return axios.post(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  };

  static sendJobStauts = (id) => {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.jobstatus;
    let item = {
      jobId: id,
    };

    return axios.put(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  };

  static editJobsDetails(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    let id = localStorage.getItem("jobId");
    api = API_PATHS.editJob;
    return axios.put(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static PostJob(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.postJob;
    return axios.post(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static PostEvent(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.postEvent;
    return axios.post(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }
}

export default jobsService;
