import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";

export const login = createAsyncThunk(
    "auth/login",
    async({ username, password }, thunkAPI) => {
        try {
            const data = await AuthService.login({ username, password });
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//admin forgot password 
export const   AdminFogotPassword = createAsyncThunk(
    "auth/adminforgotpassword",
    async( thunkAPI) => {
        try {
            const data = await AuthService.Adminforgotpassword();
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//admin change password
export const AdminChangePassword = createAsyncThunk(
    "auth/adminchangepassword",
    async({ otp, password }, thunkAPI) => {
        try {
            const data = await AuthService.Adminchangepassword({ otp, password });
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const cselogin = createAsyncThunk(
    "auth/login",
    async({ username, password }, thunkAPI) => {
        try {
            const data = await AuthService.supportlogin({ username, password });
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const GetDashboardData = createAsyncThunk(
    "auth/getdashboarddata",
    async(item, thunkAPI) => {
        try {
            const data = await AuthService.adminDashboardData(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const logout = createAsyncThunk("auth/logout", async() => {
    AuthService.logout();
});

const initialState = {
    loading: false,
    error: "",
    users: AuthService.getusersDetails() || null,
    isLoggedIn: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: {
        [login.pending]: (state) => {
            state.loading = true;
            state.error = "";
            state.isLoggedIn = false;
            state.users = null;
        },
        [login.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.users = action.payload.users;
        },
        [login.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.users = null;
        },
        
        [logout.fulfilled]: (state) => {
            state.isLoggedIn = false;
            state.users = null;
        },
    },
});

const { reducer } = authSlice;
export default reducer;