import React, { useState, useRef, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button } from "react-bootstrap";

const ImageCropper = ({
  imageSrc,
  handleSave,
  handleCloseModal,
  setData,
  data,
}) => {
  const [src, setSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const cropperRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const onCrop = () => {
    // Use the cropper instance to get the cropped image
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        const croppedImage = croppedCanvas.toDataURL();
        setCroppedImage(croppedImage);
      }
    }
  };

  useEffect(() => {
    if (imageSrc) {
      // Read the image data and set it as the source for the cropper
      const reader = new FileReader();
      reader.onload = (event) => {
        setSrc(event.target.result);
      };
      reader.readAsDataURL(imageSrc.get("file"));
    }
  }, [imageSrc]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (croppedImage) {
      let user = JSON.parse(localStorage.getItem("users"));
      const token = user?.data?.token;

      // Convert data URL to Blob
      const response = await fetch(croppedImage, { responseType: "blob" });
      // Read the response blob
      const blob = await response.blob();
    
      let resizedBlob;
      if (blob.size > 16 * 1024 * 1024) {
        setShowModal(true);
        setModalMessage("Please upload an image less than 16 MB");
      } else {
        if (blob.size >= 2 * 1024 * 1024 && blob.size <= 6 * 1024 * 1024) {
          resizedBlob = await resizeImage(blob, 2 * 1024 * 1024);
        } else if (
          blob.size >= 6 * 1024 * 1024 &&
          blob.size <= 16 * 1024 * 1024
        ) {
          resizedBlob = await resizeImage(blob, 3 * 1024 * 1024);
        } else {
          resizedBlob = await resizeImage(blob, 1 * 1024 * 1024);
        }
      }

        // Update the FormData with the resized Blob
        const formData = new FormData();
        formData.append("file", resizedBlob);

      // Make the API request
      const requestOptions = {
        method: "POST",
        body: formData,
        headers: { "x-access-token": token },
      };

      handleSave(requestOptions);
      handleCloseModal();
    }
  };

  const resizeImage = async (blob, maxSizeInBytes) => {
    return new Promise(async (resolve) => {
      if (!(blob instanceof Blob)) {
        // If it's not a Blob, handle accordingly (e.g., throw an error)
        console.error("Input is not a Blob");
        return;
      }
      const originalSize = blob.size;
      // Use FileReader to read the blob as data URL
      const dataUrl = await new Promise((resolveReader) => {
        const reader = new FileReader();
        reader.onloadend = () => resolveReader(reader.result);
        reader.readAsDataURL(blob);
      });
      // Resize the image by compressing it to maxSizeInBytes using canvas
      const resizedDataUrl = await resizeImageDataURL(dataUrl, maxSizeInBytes);
      // Convert the resized data URL back to a Blob
      const resizedBlob = await fetch(resizedDataUrl).then((res) => res.blob());
      // Check if the resizedBlob is smaller than the original size
      if (resizedBlob.size < originalSize) {
        resolve(resizedBlob);
      } else {
        // If the resizedBlob is not smaller, resolve with the original blob
        resolve(blob);
      }
    });
  };

  const resizeImageDataURL = async (dataUrl, maxSizeInBytes) => {
    return new Promise((resolve) => {
      // Create an image element
      const img = new Image();
      // Set the source of the image to the data URL
      img.src = dataUrl;
      // Wait for the image to load
      img.onload = () => {
        // Create a canvas element
        const canvas = document.createElement("canvas");
        // Get the 2D context of the canvas
        const ctx = canvas.getContext("2d");
        // Set the dimensions of the canvas to match the image
        canvas.width = img.width;
        canvas.height = img.height;
        // Draw the image on the canvas
        ctx.drawImage(img, 0, 0, img.width, img.height);
        // Convert the canvas content to a data URL with specified quality and type
        const resizedDataUrl = canvas.toDataURL("image/jpeg", 0.9);
        // Resolve with the resized data URL
        resolve(resizedDataUrl);
      };
    });
  };


  return (
    <div>
      <h5 className="text-center m-3">Crop the image</h5>

      {imageSrc && (
        <Cropper
          style={{ height: "100%", width: "100%" }}
          initialAspectRatio={1}
          preview=".img-preview"
          src={src}
          ref={cropperRef}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          crop={onCrop}
          checkOrientation={false}
        />
      )}

      <div className="d-flex justify-content-center mt-4">
        <button
          type="button"
          className="modalcancelBtn"
          onClick={handleCloseModal}
        >
          Cancel
        </button>
        <button type="button" className="modalsaveBtn ms-2" onClick={onSubmit}>
          Crop
        </button>
      </div>
    </div>
  );
};

export default ImageCropper;
