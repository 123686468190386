import axios from "axios"
import { API_PATHS } from "../utils/constants/api.constants"




class DropDownService {
    static createCuisine(item) {
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        let api = API_PATHS.createCuisine
        return axios.post(api,item,config)
            .then((response) => {
                return response.data
            })
    }

    static createLanguage(item) {
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        let api = API_PATHS.createLanguage
        return axios.post(api,item,config)
            .then((response) => {
                return response.data
            })
    }

    static createProvince(item) {
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        let api = API_PATHS.createProvince
        return axios.post(api,item,config)
            .then((response) => {
                return response.data
            })
    }
    static createQualification(item) {
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        let api = API_PATHS.createQualification
        return axios.post(api,item,config)
            .then((response) => {
                return response.data
            })
    }
}

export default DropDownService
