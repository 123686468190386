import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import EventService from "../../services/events.service";


export const getEvents = createAsyncThunk(
    "events/getevents",
    async (item, thunkApi) => {
        try {
            const data = await EventService.getEvents(item)
            return { events: data }

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.message.toString()
            return thunkApi.rejectWithValue({ message })
        }
    }
)


export const SendEventsNotifications = createAsyncThunk(
    "events/sendnotifications",
    async (id, thunkApi) => {
        try {
            const data = await EventService.sendEventNotifications(id)
            return { events: data }

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.message.toString()
            return thunkApi.rejectWithValue({ message })
        }
    }
)


export const getEventDetails = createAsyncThunk(
    "events/eventsdetails",
    async (id,thunkAPI) => {
        try {
            const data = await EventService.getEventDetails(id);
            return { events: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getSharedProfiles = createAsyncThunk(
    "events/getSharredProfiles",
    async (item,thunkAPI) => {
        try {
            const data = await EventService.getSharedprofiles(item);
            return { profiles: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getRequirememtSharedProfiles = createAsyncThunk(
    "events/getRequirememtSharedprofiles",
    async (item,thunkAPI) => {
        try {
            const data = await EventService.getRequirememtSharedprofiles(item);
            return { profiles: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getEventSharedProfiles = createAsyncThunk(
    "events/getSharredProfiles",
    async (item,thunkAPI) => {
        try {
            const data = await EventService.getEventSharedprofiles(item);
            return { profiles: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const deleteSharedProfiles = createAsyncThunk(
    "events/deleteSharredProfiles",
    async (id,thunkAPI) => {
        try {
            const data = await EventService.deleteSharedprofiles(id);
            return { profiles: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const EditEventDetails = createAsyncThunk(
    "events/editEventsdetails",
    async (item,thunkAPI) => {
        try {
            const data = await EventService.editEventDetails(item);
            return { events: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

const initialState = {
    loading: false,
    error: "",
    events: [],
    isLoggedIn: false
}

const clientSlice = createSlice({
    name: "events",
    initialState,
    extraReducers: {
        [getEvents.pending]: (state) => {
            state.loading = true
            state.error = ""
            state.events = null
            state.isLoggedIn = false

        },
        [getEvents.fulfilled]: (state, action) => {
            state.loading = true;
            state.error = "";
            state.events = action.payload.events
            state.isLoggedIn = true
        },
        [getEvents.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.events = null;
            state.isLoggedIn = false
        }
    }
})

const { reducer } = clientSlice
export default reducer