import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import PaymentService from "../../services/payment.service";
import AuthService from "../../services/auth.service";

export const getpayment = createAsyncThunk(
    "payment/getpayment",
    async (item,  thunkApi) => {
        try {
            const data = await PaymentService.getPaymentList(item)
            return { sales: data }

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) ||
             error.message || error.message.toString()
            return thunkApi.rejectWithValue({ message })
        }
    }
)


export const getpaymentDetails = createAsyncThunk(
    "payment/paymentdetails",
    async (thunkAPI) => {
        try {
            const data = await PaymentService.getpaymentDetails();
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



const initialState = {
    loading: false,
    error: "",
    users: AuthService.getusersDetails() || null,
    isLoggedIn: false
}

const clientSlice = createSlice({
    name: "client",
    initialState,
    extraReducers: {
        [getpayment.pending]: (state) => {
            state.loading = true
            state.error = ""
            state.users = null
            state.isLoggedIn = false

        },
        [getpayment.fulfilled]: (state, action) => {
            state.loading = true;
            state.error = "";
            state.users = action.payload.users
            state.isLoggedIn = true
        },
        [getpayment.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.users = null;
            state.isLoggedIn = false
        }
    }
})

const { reducer } = clientSlice
export default reducer