import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cookService from "../../services/cook.service";
import AuthService from "../../services/auth.service";
import employerService from "../../services/employer.service";
export const getHouseCook = createAsyncThunk(
    "cook/getHouseCook",
    async (item, thunkAPI) => {
        try {
            const data = await cookService.getHouseCook(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
export const getChef = createAsyncThunk(
    "cook/getChef",
    async ( item ,thunkAPI) => {
        try {
            const data = await cookService.getChef(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getAllCookList = createAsyncThunk(
    "cook/getallcooks",
    async ( item ,thunkAPI) => {
        try {
            const data = await cookService.getAllCooklist(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const GetRoles = createAsyncThunk(
    "cook/getRoles",
    async ( thunkAPI) => {
        try {
            const data = await cookService.getRoles();
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getPartyCook = createAsyncThunk(
    "cook/getPartyCook",
    async (item, thunkAPI) => {
        try {
            const data = await cookService.getPartyCook(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);
export const getCatering = createAsyncThunk(
    "cook/getCatering",
    async (item, thunkAPI) => {
        try {
            const data = await cookService.getCatering(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getCookDetails = createAsyncThunk(
    "cook/cookdetails",
    async (id,thunkAPI) => {
        try {
            const data = await cookService.getCookDetails(id);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const EditcookDetails = createAsyncThunk(
    "cook/cookdetails",
    async (item,thunkAPI) => {
        try {
            const data = await cookService.editCookDetails(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const EditHousecookDetails = createAsyncThunk(
    "cook/cookdetails",
    async (item,thunkAPI) => {
        try {
            const data = await cookService.editHouseProfile(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const EditPartycookDetails = createAsyncThunk(
    "cook/cookdetails",
    async (item,thunkAPI) => {
        try {
            const data = await cookService.editPartyProfile(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const EditChefDetails = createAsyncThunk(
    "cook/cookdetails",
    async (item,thunkAPI) => {
        try {
            const data = await cookService.editChefProfile(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const ChangeRoleCook = createAsyncThunk(
    "cook/ChangeRoleCook",
    async ( item,thunkAPI) => {
        try {
            const data = await cookService.ChangeRolecook(item);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const EditCateringDetails = createAsyncThunk(
    "cook/cookdetails",
    async (item,thunkAPI) => {
        try {
            const data = await cookService.editCateringProfile(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

const initialState = {
    loading: false,
    error: "",
    users:[],
    isLoggedIn: false,
};

const cookSlice = createSlice({
    name: "cook",
    initialState,
    extraReducers: {
        [getHouseCook.pending]: (state) => {
            state.loading = true
            state.error = ""
            state.isLoggedIn = false
            state.users = null

        },
        [getHouseCook.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.users = action.payload.users;
        },
        [getHouseCook.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.users = null;
        },
        [getChef.pending]: (state) => {
            state.loading = true
            state.error = ""
            state.isLoggedIn = false
            state.users = null

        },
        [getChef.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.users = action.payload.users;
        },
        [getChef.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.users = null;
        },
        [getPartyCook.pending]: (state) => {
            state.loading = true
            state.error = ""
            state.isLoggedIn = false
            state.users = null

        },
        [getPartyCook.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.users = action.payload.users;
        },
        [getPartyCook.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.users = null;
        },
        [getCatering.pending]: (state) => {
            state.loading = true
            state.error = ""
            state.isLoggedIn = false
            state.users = null

        },
        [getCatering.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.isLoggedIn = true;
            state.users = action.payload.users;
        },
        [getCatering.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.isLoggedIn = false;
            state.users = null;
        },
    }
})


const { reducer } = cookSlice
export default reducer