import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addClientPlan,
  editClientPlan,
  getSingleClientPlan,
} from "../store/slices/plan";

function EditClientPlan() {
  const { id } = useParams();
  localStorage.setItem("clientplanId", id);
  const [clientPlanName, setClientPlanName] = useState("");
  const [price, setPrice] = useState(0);
  const [validityInDays, setValidityInDays] = useState(0);
  const [eventPoints, setEventPoints] = useState(0);
  const [houseCookPoints, setHouseCookPoints] = useState(0);
  const [partyCateringPoints, setPartyCateringPoints] = useState(0);
  const [refundPolicy, setRefundPolicy] = useState("No");
  const [supportAssistance, setSupportAssistance] = useState("Yes");
  const [isdisabled, setisDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?=.*[a-zA-Z])[a-zA-Z0-9'@& -_]{4,}$/.test(name);
  };

  const isValidPlanPrice = (price) => {
    // validating plan price between 50 and 10000
    return /^(5[0-9]{1}|[1-9][0-9]{2,3}|10000)$/.test(price);
  };
  const isValidValidity = (validity) => {
    // validating validity between 1 and 100
    return /\b([1-9]|[1-9][0-9]|[1-4][0-9]{2}|500)\b/.test(validity);
  };
  const isValidEventPoint = (points) => {
    // validating event points between 1 and 50
    return /^(?:[1-9]|[1-4][0-9]|50)$/.test(points);
  };

  const isValidHouseCookPoints = (points) => {
    // validating house cook points between 1 and 500
    return /^(?:[1-9]|[1-9][0-9]{1,2}|500)$/.test(points);
  };
  const isValidPartyCateringPoints = (points) => {
    // validating party/catering points between 1 and 500
    return /^(?:[1-9]|[1-9][0-9]{1,2}|500)$/.test(points);
  };

  useEffect(() => {
    dispatch(getSingleClientPlan(id))
      .unwrap()
      .then((data) => {
        setClientPlanName(data.users.data[0].clientPlanName);
        setPrice(data.users.data[0].price);
        setValidityInDays(data.users.data[0].validityInDays);
        setEventPoints(data.users.data[0].eventPoints);
        setHouseCookPoints(data.users.data[0].houseCookPoints);
        setPartyCateringPoints(data.users.data[0].partyCateringPoints);
        setRefundPolicy(data.users.data[0].refundPolicy);
        setSupportAssistance(data.users.data[0].supportAssistance);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validationErrors = { ...errors };
    switch (name) {
      case "clientPlanName":
        setClientPlanName(value);
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Name should be atleast 4 characters"
            : "";
        if (!value === "" && isValidName(value)) {
          delete validationErrors[name];
        }
        break;
      case "price":
        setPrice(value);
        validationErrors[name] =
          value === "" || !isValidPlanPrice(value)
            ? "Plan price should be in between 50 and 10000"
            : "";
        if (!value === "" && isValidPlanPrice(value)) {
          delete validationErrors[name];
        }
        break;
      case "validityInDays":
        setValidityInDays(value);
        validationErrors[name] =
          value === "" || !isValidValidity(value)
            ? `Validity should be between 1 to 500 days`
            : "";
        if (!value === "" && isValidValidity(value)) {
          delete validationErrors[name];
        }
        break;
      case "eventPoints":
        setEventPoints(value);
        validationErrors[name] =
          value === "" || !isValidEventPoint(value)
            ? `No. of event points should be in between 1 and 50`
            : "";
        if (!value === "" && isValidEventPoint(value)) {
          delete validationErrors[name];
        }
        break;
      case "houseCookPoints":
        setHouseCookPoints(value);
        validationErrors[name] =
          value === "" || !isValidHouseCookPoints(value)
            ? `No. of house cook points should be in between 1 and 500`
            : "";
        if (!value === "" && isValidHouseCookPoints(value)) {
          delete validationErrors[name];
        }
        break;
      case "partyCateringPoints":
        setPartyCateringPoints(value);
        validationErrors[name] =
          value === "" || !isValidPartyCateringPoints(value)
            ? `No. of party catering points should be in between 1 and 500`
            : "";
        if (!value === "" && isValidPartyCateringPoints(value)) {
          delete validationErrors[name];
        }
        break;

      default:
        break;
    }
    setErrors(validationErrors);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const submitHandler = (e) => {
    const validationErrors = {};
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    if (!clientPlanName.trim()) {
      validationErrors.clientPlanName = "Please enter plan name";
    } else if (!isValidName(clientPlanName)) {
      validationErrors.clientPlanName = "Please enter valid plan name";
    }
    if (!validityInDays) {
      validationErrors.validityInDays = "Please enter validity";
    } else if (validityInDays && !isValidValidity(validityInDays)) {
      validationErrors.validityInDays =
        "Validity should be between 1 to 500 days";
    }

    if (!price) {
      validationErrors.price = "Please enter plan price";
    } else if (price && !isValidPlanPrice(price)) {
      validationErrors.price = "Plan price should be in between 50 and 10000";
    }

    if (!eventPoints) {
      validationErrors.eventPoints = "Please enter event points";
    } else if (eventPoints && !isValidEventPoint(eventPoints)) {
      validationErrors.eventPoints =
        "No. of event points should be in between 1 and 50";
    }
    if (!houseCookPoints) {
      validationErrors.houseCookPoints = "Please enter house cook points";
    } else if (houseCookPoints && !isValidHouseCookPoints(houseCookPoints)) {
      validationErrors.errors =
        "No. of house cook points should be in between 1 and 500";
    }
    if (!partyCateringPoints) {
      validationErrors.partyCateringPoints =
        "Please enter party/ catering points";
    } else if (partyCateringPoints && !isValidPartyCateringPoints) {
      validationErrors.partyCateringPoints =
        "No. of party catering points should be in between 1 and 500";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      let status = 1;
      let item = {
        id,
        clientPlanName,
        validityInDays,
        price,
        eventPoints,
        houseCookPoints,
        partyCateringPoints,
        refundPolicy,
        supportAssistance,
        status,
      };

      dispatch(editClientPlan(item))
        .unwrap()
        .then(() => {
          alert("Plan edited successfully");
          navigate("/clientplan");
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Edit Client Plan";
  }, []);

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp;
                    <Link to="/clientplan">Client Plan</Link> &#8811; Edit
                    Client Plan
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Edit Client Plan</h4>
                      </div>
                    </div>
                    <section className="form-section">
                      <Form className="mx-4" onSubmit={submitHandler}>
                        <Row className="my-4 justify-content-center">
                          <Row>
                            <Col lg={5} className="m-2">
                              <Form.Group>
                                <Form.Label>Plan Name *</Form.Label>

                                <Form.Control
                                  name="clientPlanName"
                                  value={clientPlanName}
                                  onChange={handleChange}
                                  isInvalid={!!errors.clientPlanName}
                                  isValid={
                                    !errors.clientPlanName &&
                                    clientPlanName.length > 0
                                  }
                                  type="text"
                                  placeholder="Enter plan name"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.clientPlanName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="m-2">
                              <Form.Group>
                                <Form.Label>Validity *</Form.Label>

                                <Form.Control
                                  name="validityInDays"
                                  value={validityInDays}
                                  onChange={handleChange}
                                  isInvalid={!!errors.validityInDays}
                                  isValid={
                                    !errors.validityInDays &&
                                    validityInDays.length > 0
                                  }
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="Enter plan validity"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.validityInDays}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="m-2">
                              <Form.Group>
                                <Form.Label>Plan Price *</Form.Label>

                                <Form.Control
                                  name="price"
                                  value={price}
                                  onChange={handleChange}
                                  isInvalid={!!errors.price}
                                  isValid={!errors.price && price.length > 0}
                                  type="text"
                                  placeholder="Enter plan price"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.price}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={5} className="m-2">
                              <Form.Group>
                                <Form.Label>Event Points *</Form.Label>

                                <Form.Control
                                  name="eventPoints"
                                  value={eventPoints}
                                  onChange={handleChange}
                                  isInvalid={!!errors.eventPoints}
                                  isValid={
                                    !errors.eventPoints &&
                                    eventPoints.length > 0
                                  }
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="Enter event points"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.eventPoints}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="m-2">
                              <Form.Group>
                                <Form.Label>House Cook Points *</Form.Label>
                                <Form.Control
                                  name="houseCookPoints"
                                  value={houseCookPoints}
                                  onChange={handleChange}
                                  isInvalid={!!errors.houseCookPoints}
                                  isValid={
                                    !errors.houseCookPoints &&
                                    houseCookPoints.length > 0
                                  }
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="Enter house Cook profile view"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.houseCookPoints}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="m-2">
                              <Form.Group>
                                <Form.Label>
                                  Party/ Catering Points *
                                </Form.Label>

                                <Form.Control
                                  name="partyCateringPoints"
                                  value={partyCateringPoints}
                                  onChange={handleChange}
                                  isInvalid={!!errors.partyCateringPoints}
                                  isValid={
                                    !errors.partyCateringPoints &&
                                    partyCateringPoints.length > 0
                                  }
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="Enter party cook points"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.partyCateringPoints}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="m-2">
                              <Form.Group>
                                <Form.Label>Refund Policy</Form.Label>
                                <Form.Check
                                  checked={refundPolicy === 1}
                                  type="radio"
                                  id="Yes1"
                                  onChange={(e) => setRefundPolicy(1)}
                                  label="Yes"
                                />
                                <Form.Check
                                  checked={refundPolicy === 0}
                                  type="radio"
                                  id="No1"
                                  onChange={(e) => setRefundPolicy(0)}
                                  label="No"
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="m-2">
                              <Form.Group>
                                <Form.Label>Support Assistance</Form.Label>
                                <Form.Check
                                  checked={supportAssistance === 1}
                                  type="radio"
                                  id="Yes"
                                  onChange={(e) => setSupportAssistance(1)}
                                  label="Yes"
                                />
                                <Form.Check
                                  checked={supportAssistance === 0}
                                  type="radio"
                                  id="No"
                                  onChange={(e) => setSupportAssistance(0)}
                                  label="No"
                                />
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row className="justify-content-center mt-5">
                            <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                              <button
                                className="btn cancelBtn"
                                onClick={cancelHandler}
                              >
                                Cancel
                              </button>
                            </Col>{" "}
                            <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                              <button
                                className="btn saveBtn ms-2"
                                disabled={isdisabled}
                                type="submit"
                              >
                                Save
                              </button>
                            </Col>
                          </Row>
                        </Row>
                      </Form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditClientPlan;
