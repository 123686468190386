import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import CseService from "../../services/cse.service";




//get single cse account
export const getSingleCse = createAsyncThunk(
    "employer/getsinglecse",
    async (id,thunkAPI) => {
        try {
            const data = await CseService.getCseaccoutone(id);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);




export const getCseAccounts = createAsyncThunk(
    "employer/getcseaccounts",
    async (item,thunkAPI) => {
        try {
            const data = await CseService.getCseaccounts(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const editCseAccounts = createAsyncThunk(
    "employer/editcseaccounts",
    async (item,thunkAPI) => {
        try {
            const data = await CseService.EditcseAccount(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const addCseAccount = createAsyncThunk(
    "employer/addcseaccount",
    async (item,thunkAPI) => {
        try {
            const data = await CseService.addcseAccount(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);



export const DeleteCse = createAsyncThunk(
    "plan/delete/clientPlan",
    async(id,thunkAPI) => {
        try {
            const data = await CseService.deleteCse(id);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );

const initialState = {
    loading: false,
    error: "",
    users: AuthService.getusersDetails() || null,
    isLoggedIn: false
}

const clientSlice = createSlice({
    name: "cse",
    initialState,
    extraReducers: {
        [getSingleCse.pending]: (state) => {
            state.loading = true
            state.error = ""
            state.users = null
            state.isLoggedIn = false

        },
        [getSingleCse.fulfilled]: (state, action) => {
            state.loading = true;
            state.error = "";
            state.users = action.payload.users
            state.isLoggedIn = true
        },
        [getSingleCse.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.users = null;
            state.isLoggedIn = false
        }
    }
})

const { reducer } = clientSlice
export default reducer