import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import clientReducer from "./slices/client"
import employerReducer from "./slices/employer"
import cookReducer from "./slices/cook"


const reducer = {
    auth: authReducer,
    client: clientReducer,
    employer:employerReducer,
    cook:cookReducer,
 
}




const store = configureStore({
    reducer: reducer,
    devTools: true,
})

export default store;