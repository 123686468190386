import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Navbartop from "./navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import Moment from "moment";
import { Link } from "react-router-dom";
import LineChart from "./LineChart";
import { getCookActivity } from "../store/slices/activity";

function CookActivity() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totaldata, settotalData] = useState();
  const [exportData, setExportData] = useState([]);
  const [hourlyActData, setHourlyActData] = useState();
  const dispatch = useDispatch();
  const [dateError, setDateError] = useState("");

  const now = new Date();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = now.toLocaleDateString(undefined, options);
  const [item, setItem] = useState({
    role: "cook",
    date: formattedDate,
    limit: 25,
    page: 1,
  });
  const [date, setDate] = useState(formattedDate);
  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  useEffect(() => {
    document.title = "CookandChef Admin | Cook Activity";
  }, []);

  const handleApplyFilter = () => {
    const item = {
      role: "cook",
      date: date,
      limit: 25,
      page: 1,
    };
    if (date === "") {
      setDateError("Please select date");
      return;
    }

    setItem(item);
    setCurrentPage(1);
    if (date) {
      getclient(item);
    }
  };

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setItem({
      ...item,
      limit: 25,
      page: newPage,
    });
    setCurrentPage(newPage);
  };

  const handlenameChange = (e) => {
    setDate(e.target.value);
    setDateError("");
  };

  React.useEffect(() => {
    if (date) {
      getclient(item);
    }
  }, [dispatch, currentPage]);

  const getclient = (item) => {
    dispatch(getCookActivity(item))
      .unwrap()
      .then((data) => {
        setData(data?.cookactivity?.data?.activitiesData);
        settotalData(data?.cookactivity?.totalDataCount);
        setHourlyActData(data?.cookactivity?.data?.hourlyWiseData[0]?.data);
      })
      .catch(({ message }) => {});
  };

  const handleResetFilter = () => {
    setDate("");
    setItem(item);
    setCurrentPage(1);
  };

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Cook Activity
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Cook Activity</h4>
                      </div>
                    </div>

                    <div className="cards ps-1 pe-1">
                      <Form>
                        <Row className="d-flex flex-row card ps-2 pe-2 justify-content-left ">
                          <Col
                            lg={4}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <Form.Group>
                              <label className="m-1 label">Date</label>
                              <Form.Control
                                name="nameEmailOrMobile"
                                placeholder="date"
                                type="date"
                                value={date}
                                isInvalid={!!dateError}
                                onChange={handlenameChange}
                              />
                              <Form.Control.Feedback type="invalid">
                                {dateError}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col
                            lg={4}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <div className="group d-flex justify-content-center mt-4 ">
                              <Button
                                className="cancel_btn"
                                onClick={handleResetFilter}
                              >
                                Reset
                              </Button>
                              <Button
                                className="apply_btn ms-2"
                                onClick={handleApplyFilter}
                              >
                                Search
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                    <div className="card-body collapse show">
                      <div style={{ height: "500px", width: "100%" }}>
                        <LineChart hourlyActData={hourlyActData} />
                      </div>
                      <div className="card-block card-dashboard table-responsive">
                        <Table responsive hover>
                          <thead>
                            <tr>
                              <th className="text-left">Cook Name</th>
                              <th className="text-left">Job/ Event/ Profile</th>
                              <th className="text-left">Activity</th>
                              <th className="text-left">Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.length > 0 &&
                              data?.map((client) => (
                                <tr key={client?._id}>
                                  <td className="text-left">
                                    <Link
                                      to={`/cookdetails/${client?.cookId?.id}`}
                                      target="_blank"
                                      style={{
                                        color: "#a020f0",
                                      }}
                                    >
                                      {client?.cookId?.fullName?.substring(
                                        0,
                                        25
                                      )}
                                    </Link>
                                  </td>

                                  <td className="text-left">
                                    {client?.jobId && (
                                      <p>
                                        <Link
                                          to={`/JobsDetails/${client?.jobId?._id}`}
                                          target="_blank"
                                          style={{
                                            color: "#ff5c09",
                                          }}
                                        >
                                          {client?.jobId?.designation?.substring(
                                            0,
                                            30
                                          )}
                                        </Link>
                                        <br />
                                        <Link
                                          to={`/employerdetails/${client?.jobId?.employerId?.id}`}
                                          target="_blank"
                                          style={{
                                            color: "#a020f0",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {client?.jobId?.employerId?.fullName?.substring(
                                            0,
                                            30
                                          )}
                                        </Link>
                                      </p>
                                    )}

                                    {client?.eventId && (
                                      <p>
                                        <Link
                                          to={`/eventDetails/${client?.eventId?._id}`}
                                          target="_blank"
                                          style={{
                                            color: "#ff5c09",
                                          }}
                                        >
                                          {client?.eventId?.eventType?.substring(
                                            0,
                                            30
                                          )}
                                        </Link>
                                      </p>
                                    )}
                                    {client?.requirementId && (
                                      <p>
                                        <Link
                                          to={`/clientdetails/${client?.requirementId?.clientId?._id}`}
                                          target="_blank"
                                          style={{
                                            color: "#ff5c09",
                                          }}
                                        >
                                          {client?.requirementId?.clientId?.fullName.substring(
                                            0,
                                            30
                                          )}
                                        </Link>
                                      </p>
                                    )}
                                    {client?.employerId && !client?.jobId && (
                                      <Link
                                        to={`/employerdetails/${client?.employerId?.id}`}
                                        target="_blank"
                                        style={{
                                          color: "#a020f0",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {client?.employerId?.fullName?.substring(
                                          0,
                                          30
                                        )}
                                      </Link>
                                    )}
                                  </td>

                                  <td className="text-left">
                                    {client?.activity ===
                                      "viewedmobilenumber" && (
                                      <p>Viewed Mobile Number</p>
                                    )}
                                    {client?.activity ===
                                      "mobileinteraction" && (
                                      <p>Mobile Contact</p>
                                    )}
                                    {client?.activity ===
                                      "whatsappinteraction" && (
                                      <p>WhatsApp Contact</p>
                                    )}
                                    {client?.activity === "chatinteraction" && (
                                      <p>Chat</p>
                                    )}
                                    {client?.activity === "viewed" &&
                                      client?.requirementId && (
                                        <p>Viewed Requirement</p>
                                      )}
                                    {client?.activity === "viewed" &&
                                      client?.jobId && <p>Viewed Job</p>}
                                    {client?.activity === "viewed" &&
                                      client?.eventId && <p>Viewed Event</p>}
                                    {client?.activity === "cancelled" && (
                                      <p>Cancel Request</p>
                                    )}
                                    {client?.activity === "applied" && (
                                      <p>Applied Job</p>
                                    )}
                                    {client?.activity === "shortlisted" && (
                                      <p>Shortlisted</p>
                                    )}{" "}
                                  </td>
                                  <td className="text-left">
                                    {Moment.utc(client?.createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                      <div className="justify-content-right align-right">
                        {totaldata / itemsPerPage > 1 && (
                          <ReactPaginate
                            key={currentPage}
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            breakLinkClassName={"page-link"}
                            pageCount={totaldata / itemsPerPage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={pagesToShowInitially}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            initialPage={currentPage - 1}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CookActivity;
