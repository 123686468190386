import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { getCookDetails } from "../store/slices/cook";
import { Link, useParams } from "react-router-dom";
import Moment from "moment";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import cookimg from "../../src/Assets/Images/cook.png";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import {
  resetPassword,
  suspendAccount,
  deleteAccount,
  activateAccount,
} from "../store/slices/config";
import { confirmAlert } from "react-confirm-alert";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { MdOutlineEmail } from "react-icons/md";
import ShowprofileSkeleton from "./ProfileSkeleton";
import DataSkeleton from "./dataskeleton";
import { AllowMacs } from "../store/slices/activity";
import { FaStar, FaRegStar } from "react-icons/fa";
import ReviewModal from "../utils/helpers/ReviewModal";
import ChangeRoleModal from "../utils/helpers/ChangeRoleModal";
export function StarRating1({ value }) {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <span key={index}>
      {index < value ? (
        <FaStar color="#ffc107" style={{ marginRight: "10px" }} />
      ) : (
        <FaRegStar color="grey" style={{ marginRight: "10px" }} />
      )}
    </span>
  ));

  return <div>{stars}</div>;
}

function ChefDetails() {
  const { id } = useParams();
  localStorage.setItem("cookId", id);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [chefCuisine, setchefCuisine] = useState([]);
  const [partyCuisine, setpartyCuisine] = useState([]);
  const [houseCuisine, sethouseCuisine] = useState([]);
  const [cateringCuisine, setcateringCuisine] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [reviews, setReviews] = useState(false);
  const [reviewModal, setShowReviewModal] = useState(false);
  const [changeRoleModal, setChangeRole] = useState(false);
  useEffect(() => {
    if (data && data?.ratingsList) {
      // Check if any rating has a comment
      const hasComment = data?.ratingsList.some((rating) => rating.comment);
      setReviews(hasComment);
    }
  }, [data]);

  React.useEffect(() => {
    dispatch(getCookDetails(id))
      .unwrap()
      .then((data) => {
        setData(data?.users.data);
        setchefCuisine(data?.users.data?.chefCuisines);
        setpartyCuisine(data?.users.data?.partyCuisines);
        setcateringCuisine(data?.users.data?.cateringCuisines);
        setLanguages(data?.users.data?.languages);
        sethouseCuisine(data?.users.data?.householdCuisines);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  let navigate = useNavigate();
  const goToPreviousPath = () => {
    navigate(-1);
  };

  const ChangeRole = (id) => {
    setChangeRole(true);
  };
  useEffect(() => {
    document.title = "CookandChef Admin | Cook Details";
  }, []);

  const [message, setmessage] = useState("Do you want to reset password?");
  const [smessage, setsmessage] = useState(
    "Do you want to suspend this account?"
  );
  const [dmessage, setdmessage] = useState(
    "Do you want to delete this account?"
  );

  const [amessage, setamessage] = useState(
    "Do you want to activate this account?"
  );
  const resetPassword1 = (id, type) => {
    confirmAlert({
      title: "Reset Password",
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: () => resetpassword2(id, type),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const resetpassword2 = (id, type) => {
    const item = { id, type };
    dispatch(resetPassword(item))
      .unwrap()
      .then((data) => {
        alert(data?.user.message);
      })
      .catch(({ message }) => {});
  };

  const suspendAcc1 = (id, role) => {
    confirmAlert({
      title: "Suspend account",
      message: smessage,
      buttons: [
        {
          label: "Yes",
          onClick: () => suspendAcc2(id, role),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const suspendAcc2 = (id, role) => {
    const item = { id, role };
    dispatch(suspendAccount(item))
      .unwrap()
      .then((data) => {
        alert(data?.user.message);
        window.location.reload(true);
      })
      .catch(({ message }) => {});
  };

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  }, []);

  const activateAcc1 = (id, role) => {
    confirmAlert({
      title: "Activate account",
      message: amessage,
      buttons: [
        {
          label: "Yes",
          onClick: () => activateAcc2(id, role),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const activateAcc2 = (id, role) => {
    const item = { id, role };
    dispatch(activateAccount(item))
      .unwrap()
      .then((data) => {
        alert(data?.user.message);
        window.location.reload(true);
      })
      .catch(({ message }) => {});
  };

  const deleteAcc1 = (id, role) => {
    confirmAlert({
      title: "Delete account",
      message: dmessage,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteAcc2(id, role),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteAcc2 = (id, role) => {
    const item = { id, role };
    dispatch(deleteAccount(item))
      .unwrap()
      .then((data) => {
        alert(data?.user.message);

        navigate(-1);
      })
      .catch(({ message }) => {});
  };

  const AllowMacAdress = (data) => {
    allowMac(data.loginMAC, data.registerMAC);
  };

  const allowMac = (value, value2) => {
    const item = {
      macAddress: value,
    };
    dispatch(AllowMacs(item))
      .unwrap()
      .then((data) => {
        allowMac1(value2);
      })
      .catch(({ message }) => {});
  };

  const allowMac1 = (value) => {
    const item = {
      macAddress: value,
    };
    dispatch(AllowMacs(item))
      .unwrap()
      .then((data) => {
        alert(data?.allusers?.message);
      })
      .catch(({ message }) => {});
  };

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;
  return (
    <>
      <div>
        <ChangeRoleModal
          showModal={changeRoleModal}
          data={data}
          setShowModal={setChangeRole}
          id={id}
        />
        <Navbartop />
        <ReviewModal
          showModal={reviewModal}
          data={data}
          setShowModal={setShowReviewModal}
          role="client"
        />
        <div className="container-fluid pb-5  response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12 ">
                    <div
                      className="text-start my-2 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;&nbsp;
                      <Link to="/chef">Chef</Link> &#8811; Cook Details
                    </div>
                    {isLoading ? (
                      <Row>{ShowprofileSkeleton()}</Row>
                    ) : (
                      <div className="card p-2">
                        <Row>
                          <Col xs={3} md={2} lg={2} xl={2}>
                            {data?.dp ? (
                              <img
                                src={data?.dp}
                                alt=""
                                style={{
                                  height: "90px",
                                  width: "90px",
                                  borderRadius: "20%",
                                }}
                                className="mt-2"
                              />
                            ) : (
                              <img
                                src={cookimg}
                                alt=""
                                style={{
                                  height: "90px",
                                  width: "90px",
                                  borderRadius: "20%",
                                }}
                                className="mt-2"
                              />
                            )}
                          </Col>
                          <Col
                            xs={6}
                            md={4}
                            lg={4}
                            xl={4}
                            align="left"
                            className="ps-2"
                          >
                            <h5 style={{ fontWeight: "bold" }}>
                              {data?.fullName}
                            </h5>
                            <p>
                              <HiOutlineDevicePhoneMobile />
                              &nbsp; {data?.mobile}
                            </p>
                            {data?.whatsappNumber && (
                              <p>
                                <FaWhatsapp />
                                &nbsp;{data?.whatsappNumber}
                              </p>
                            )}
                            <p>
                              <MdOutlineEmail />
                              &nbsp;{data?.email}
                            </p>
                          </Col>
                          <Col
                            className="d-none d-md-block d-lg-block"
                            md={4}
                            lg={4}
                            xl={4}
                            align="left"
                          >
                            <h5 style={{ fontWeight: "bold" }}>
                              {data?.cookMemberId}
                            </h5>
                            {data?.status === 1 && (
                              <p>
                                Profile Status:{" "}
                                <span style={{ color: "#42f554" }}>Active</span>
                              </p>
                            )}
                            {data?.status === 2 && (
                              <p>
                                Profile:{" "}
                                <span style={{ color: "#f59942" }}>
                                  Disable
                                </span>
                              </p>
                            )}
                            {data?.status === 3 && (
                              <p>
                                Profile:{" "}
                                <span style={{ color: "#d42708" }}>
                                  Suspended
                                </span>
                              </p>
                            )}
                            {data?.status === 4 && (
                              <p>
                                Profile:{" "}
                                <span style={{ color: "#080fd4" }}>
                                  Delete Requested
                                </span>
                              </p>
                            )}
                            <p>
                              Regd. Date:&nbsp;
                              {Moment.utc(data?.createdAt).format("DD-MM-YYYY")}
                            </p>
                            <p>
                              Last Login:&nbsp;
                              {Moment.utc(data?.lastLoginDateTime).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </Col>
                          <Col xs={1} md={1} lg={2} align="right">
                            {role !== "Associate" && (
                              <Dropdown
                                style={{
                                  backgroundColor: "none",
                                }}
                              >
                                <Dropdown.Toggle
                                  // id="dropdown-basic"
                                  style={{
                                    fontSize: "20px",
                                    backgroundColor: "none",
                                    border: "none",
                                    marginTop: "-10px",
                                  }}
                                >
                                  <BiDotsVerticalRounded
                                    style={{
                                      marginLeft: "-5px",
                                      marginTop: "-2px",
                                      color: "#000",
                                    }}
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {role === "admin" && (
                                    <Dropdown.Item
                                      onClick={() => deleteAcc1(id, "cook")}
                                    >
                                      Delete account
                                    </Dropdown.Item>
                                  )}
                                  {role === "Manager" && (
                                    <Dropdown.Item
                                      onClick={() => deleteAcc1(id, "cook")}
                                    >
                                      Delete account
                                    </Dropdown.Item>
                                  )}

                                  {data?.status === 3 && (
                                    <div>
                                      {role === "admin" && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            activateAcc1(id, "cook")
                                          }
                                        >
                                          Activate account
                                        </Dropdown.Item>
                                      )}
                                    </div>
                                  )}

                                  {data?.status !== 3 && (
                                    <div>
                                      {role === "admin" && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            suspendAcc1(id, "cook")
                                          }
                                        >
                                          Deactivate account
                                        </Dropdown.Item>
                                      )}
                                    </div>
                                  )}
                                  {role !== "Associate" && (
                                    <Dropdown.Item
                                      onClick={() => resetPassword1(id, "cook")}
                                    >
                                      Reset password
                                    </Dropdown.Item>
                                  )}
                                  {role === "admin" && data?.cookType === 2 && (
                                    <Dropdown.Item>
                                      <Link
                                        to={`/assign-cook-plan/${id}`}
                                        style={{ color: "#000" }}
                                      >
                                        Add Plan
                                      </Link>
                                    </Dropdown.Item>
                                  )}
                                  {role === "admin" && (
                                    <Dropdown.Item
                                      onClick={() => ChangeRole(id)}
                                    >
                                      Change Role
                                    </Dropdown.Item>
                                  )}

                                  {role === "admin" && (
                                    <Dropdown.Item>
                                      <div
                                        style={{ color: "#000" }}
                                        onClick={() => AllowMacAdress(data)}
                                      >
                                        Allow MAC Address
                                      </div>
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </Col>
                          <Col
                            className="d-sm-block d-md-none d-lg-none"
                            sm={8}
                            align="left"
                          >
                            <h5 style={{ fontWeight: "bold" }}>
                              {data?.employeeMemberId}
                            </h5>
                            {data?.status === 1 && <p>Profile: Active</p>}
                            <p>
                              Regd. Date:
                              {Moment.utc(data?.createdAt).format("DD-MM-YYYY")}
                              <br />
                              Last Login:
                              {Moment.utc(data?.lastLoginDateTime).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    )}
                    {isLoading ? (
                      <Row>{DataSkeleton()}</Row>
                    ) : (
                      <Tabs
                        defaultActiveKey="profile"
                        id="justify-tab-example"
                        justify
                      >
                        <Tab eventKey="profile" title="Profile">
                          <Row className="card pb-3">
                            <Row>
                              <Col xs={12} md={12} lg={6} className="ps-4 mt-2">
                                {role !== "Associate" && (
                                  <div align="right">
                                    {data?.memberType === 1 && (
                                      <Link
                                        to={`/editcookdetails/${id}`}
                                        style={{
                                          color: "#ff5c09",
                                          fontWeight: "600",
                                          borderBottom: "1px solid #ff5c09",
                                        }}
                                      >
                                        Edit
                                      </Link>
                                    )}
                                    {data?.memberType === 2 && (
                                      <Link
                                        to={`/editcateringdetails/${id}`}
                                        style={{
                                          color: "#ff5c09",
                                          fontWeight: "600",
                                          borderBottom: "1px solid #ff5c09",
                                        }}
                                      >
                                        Edit
                                      </Link>
                                    )}
                                  </div>
                                )}
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">About</p>
                                  {data?.about && (
                                    <p className="text-details">
                                      {data?.about}
                                    </p>
                                  )}
                                </Col>
                                {data?.memberType === 1 && (
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">
                                      Date of Birth
                                    </p>
                                    {data?.dob && (
                                      <p className="text-details">
                                        {Moment.utc(data?.dob).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </p>
                                    )}
                                  </Col>
                                )}
                                {data?.memberType === 1 && (
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Gender</p>
                                    {data?.gender === 1 && (
                                      <p className="text-details">Male</p>
                                    )}
                                    {data?.gender === 2 && (
                                      <p className="text-details">Female</p>
                                    )}
                                    {data?.gender === 3 && (
                                      <p className="text-details">Other</p>
                                    )}
                                  </Col>
                                )}
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Email</p>
                                  <p className="text-details">{data?.email}</p>
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">
                                    WhatsApp Number
                                  </p>
                                  {data?.whatsappNumber && (
                                    <p className="text-details">
                                      {data?.whatsappNumber}
                                    </p>
                                  )}
                                </Col>
                                {data?.memberType === 1 && (
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">
                                      Qualification
                                    </p>
                                    {data?.qualification && (
                                      <p className="text-details">
                                        {data?.qualification}
                                      </p>
                                    )}
                                  </Col>
                                )}
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">
                                    Languages Known
                                  </p>
                                  <Row
                                    style={{
                                      marginTop: "-15px",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    {languages &&
                                      languages?.map((languageVal, index) => (
                                        <Col
                                          xs={5}
                                          sm={5}
                                          md={4}
                                          lg={3}
                                          xl={3}
                                          className="me-2"
                                          style={{
                                            backgroundColor: "#ffffff",
                                            borderRadius: "10px",
                                            color: "black",
                                            padding: "4px 8px",
                                            margin: "8px 0px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                            fontSize: "16px",
                                            marginRight: "4px",
                                            border: "solid 1px #ff5c09",
                                            width: "auto",
                                          }}
                                        >
                                          {languageVal}
                                        </Col>
                                      ))}
                                  </Row>
                                </Col>
                              </Col>
                              <Col xs={12} md={12} lg={6} className="ps-4 mt-2">
                                <div align="right">
                                  {role !== "Associate" && (
                                    <Link
                                      to={`/editcookcontactdetails/${id}`}
                                      style={{
                                        color: "#ff5c09",
                                        fontWeight: "600",
                                        borderBottom: "1px solid #ff5c09",
                                      }}
                                    >
                                      Edit
                                    </Link>
                                  )}
                                </div>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={6}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Address Line 1</p>
                                  {data?.addressLine1 && (
                                    <p className="text-details">
                                      {data?.addressLine1}
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={6}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Address Line 2</p>
                                  {data?.addressLine2 && (
                                    <p className="text-details">
                                      {data?.addressLine2}
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={6}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Area</p>
                                  {data?.area && (
                                    <p className="text-details">{data?.area}</p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={6}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">City</p>
                                  {data?.cityName && (
                                    <p className="text-details">
                                      {data?.cityName}
                                    </p>
                                  )}
                                </Col>

                                <Col
                                  xs={12}
                                  md={12}
                                  lg={6}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">State</p>
                                  {data?.provinceName && (
                                    <p className="text-details">
                                      {data?.provinceName}
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={6}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Pincode</p>
                                  {data?.pincode && (
                                    <p className="text-details">
                                      {data?.pincode}
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={6}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Landmark</p>
                                  {data?.landmark && (
                                    <p className="text-details">
                                      {data?.landmark}
                                    </p>
                                  )}
                                </Col>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col
                                lg={12}
                                style={{
                                  borderTop: "solid 1px silver",
                                }}
                                className="p-4 ms-3"
                              >
                                <h5 style={{ color: "#ff5c09" }}>Rating</h5>
                                {data?.ratingsData?.behaviour?.ratingAvg ===
                                  0 &&
                                data?.ratingsData?.hygiene?.ratingAvg === 0 &&
                                data?.ratingsData?.punctuality?.ratingAvg ===
                                  0 &&
                                data?.ratingsData?.taste?.ratingAvg === 0 ? (
                                  <div>
                                    <h6 className="text-left">
                                      Not rated yet!
                                    </h6>
                                  </div>
                                ) : (
                                  <>
                                    <div className="d-flex">
                                      <div>
                                        <div style={{ marginTop: "10px" }}>
                                          Behaviour :
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                          Hygiene :
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                          Punctuality :
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                          Cooking & Taste :
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            marginLeft: "30px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <StarRating1
                                            value={Math.ceil(
                                              data?.ratingsData?.behaviour
                                                ?.ratingAvg
                                            )}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: "30px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <StarRating1
                                            value={Math.ceil(
                                              data?.ratingsData?.hygiene
                                                ?.ratingAvg
                                            )}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: "30px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <StarRating1
                                            value={Math.ceil(
                                              data?.ratingsData?.punctuality
                                                ?.ratingAvg
                                            )}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: "30px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <StarRating1
                                            value={Math.ceil(
                                              data?.ratingsData?.taste
                                                ?.ratingAvg
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <br />

                                    {reviews && (
                                      <Link
                                        className="text-start mt-5"
                                        style={{
                                          color: "#ff5c09",
                                          textDecoration: "underline",
                                          fontWeight: "600",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setShowReviewModal(true)}
                                      >
                                        <u>View Reviews</u>
                                      </Link>
                                    )}
                                  </>
                                )}
                              </Col>
                            </Row>
                          </Row>
                        </Tab>

                        {data?.cookType === 1 && (
                          <Tab eventKey="house-profile" title="House Profile">
                            <Row className="card pb-3">
                              <Row>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <div align="right" className="mt-2">
                                    {role !== "Associate" && (
                                      <Link
                                        to={`/edithouseprofile/${id}`}
                                        style={{
                                          color: "#ff5c09",
                                          fontWeight: "600",
                                          borderBottom: "1px solid #ff5c09",
                                        }}
                                      >
                                        Edit
                                      </Link>
                                    )}
                                  </div>
                                </Col>

                                <Col xs={12} md={12} lg={6}>
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">
                                      Cuisines Known
                                    </p>
                                    <Row
                                      style={{
                                        marginTop: "-15px",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      {houseCuisine?.map(
                                        (houseplanVal, index) => (
                                          <Col
                                            xs={5}
                                            sm={5}
                                            md={4}
                                            lg={3}
                                            xl={3}
                                            className="me-2"
                                            style={{
                                              backgroundColor: "#ffffff",
                                              borderRadius: "10px",
                                              color: "black",
                                              padding: "4px 8px",
                                              margin: "8px 0px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              textAlign: "center",
                                              fontSize: "16px",
                                              marginRight: "4px",
                                              border: "solid 1px #ff5c09",
                                              width: "auto",
                                            }}
                                          >
                                            {houseplanVal}
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </Col>

                                  <Col
                                    xs={12}
                                    md={5}
                                    lg={5}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Job Type</p>
                                    {data?.jobType === 1 && (
                                      <p className="text-details">Part Time</p>
                                    )}
                                    {data?.jobType === 2 && (
                                      <p className="text-details">Full Time</p>
                                    )}
                                    {data?.jobType === 3 && (
                                      <p className="text-details">Any</p>
                                    )}
                                  </Col>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                  <Col
                                    xs={12}
                                    md={6}
                                    lg={5}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Min. Payment</p>
                                    {data?.payment && (
                                      <p className="text-details">
                                        {data?.payment}{" "}
                                      </p>
                                    )}
                                  </Col>

                                  <Col
                                    xs={12}
                                    md={6}
                                    lg={5}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Vessel Wash</p>
                                    {data?.householdVesselWash === 0 && (
                                      <p className="text-details">No</p>
                                    )}
                                    {data?.householdVesselWash === 1 && (
                                      <p className="text-details">Yes</p>
                                    )}
                                  </Col>
                                </Col>
                              </Row>
                            </Row>
                          </Tab>
                        )}
                        {data?.cookType === 2 && (
                          <Tab eventKey="chef-profile" title="Chef Profile">
                            <Row className="card pb-3">
                              <Row>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <div align="right" className="mt-2">
                                    <Link
                                      to={`/editchefprofile/${id}`}
                                      style={{
                                        color: "#ff5c09",
                                        fontWeight: "600",
                                        borderBottom: "1px solid #ff5c09",
                                      }}
                                    >
                                      Edit
                                    </Link>
                                  </div>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">
                                      Cuisines Known
                                    </p>
                                    <Row
                                      style={{
                                        marginTop: "-15px",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      {data?.chefProfileStatus !== 0 &&
                                        chefCuisine?.map(
                                          (chefCusineVal, index) => (
                                            <Col
                                              xs={5}
                                              sm={5}
                                              md={4}
                                              lg={4}
                                              xl={4}
                                              className="me-2"
                                              style={{
                                                backgroundColor: "#ffffff",
                                                borderRadius: "10px",
                                                color: "black",
                                                padding: "4px 8px",
                                                margin: "8px 0px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                                fontSize: "16px",
                                                marginRight: "4px",
                                                border: "solid 1px #ff5c09",
                                                width: "auto",
                                              }}
                                            >
                                              {chefCusineVal}
                                            </Col>
                                          )
                                        )}
                                    </Row>
                                  </Col>

                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Experience</p>
                                    {data?.chefExperience && (
                                      <p className="text-details">
                                        {data?.chefExperience} Years
                                      </p>
                                    )}
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">
                                      Current Company
                                    </p>
                                    {data?.currentCompany && (
                                      <p className="text-details">
                                        {data?.currentCompany}
                                      </p>
                                    )}
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">
                                      Current Salary
                                    </p>
                                    {data?.currentSalary && (
                                      <p className="text-details">
                                        ₹{data?.currentSalary}
                                      </p>
                                    )}
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">
                                      Expected Salary
                                    </p>
                                    {data?.expectedSalary && (
                                      <p className="text-details">
                                        ₹{data?.expectedSalary}
                                      </p>
                                    )}
                                  </Col>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Skills</p>
                                    <div className="d-flex">
                                      {data?.skills?.map((el, ind) => (
                                        <p className="text-details">
                                          {el}
                                          {ind === data?.skills?.length - 1
                                            ? ""
                                            : ","}
                                          &nbsp;
                                        </p>
                                      ))}
                                    </div>
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Current City</p>
                                    <p className="text-details">
                                      {data?.currentCityName}
                                    </p>
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">
                                      Willing to relocate
                                    </p>
                                    {data?.relocate === 0 && (
                                      <p className="text-details">No</p>
                                    )}
                                    {data?.relocate === 1 && (
                                      <p className="text-details">Yes</p>
                                    )}
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">
                                      Looking for job
                                    </p>
                                    {data?.jobSeeking === 0 && (
                                      <p className="text-details">No</p>
                                    )}
                                    {data?.jobSeeking === 1 && (
                                      <p className="text-details">Yes</p>
                                    )}
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Resume</p>
                                    {data?.resume && (
                                      <Link to={data?.resume}>Download</Link>
                                    )}
                                  </Col>
                                </Col>
                              </Row>
                            </Row>
                          </Tab>
                        )}

                        {data?.partyCook === 1 && (
                          <Tab eventKey="party-profile" title="Party Profile">
                            <Row className="justify-content-center card pb-3">
                              <div align="right" className="mt-2 pe-5">
                                <Link
                                  to={`/editpartyprofile/${id}`}
                                  style={{
                                    color: "#ff5c09",
                                    fontWeight: "600",
                                    borderBottom: "1px solid #ff5c09",
                                  }}
                                >
                                  Edit
                                </Link>
                              </div>
                              <Row>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={6}
                                  className="ps-4 pt-2"
                                >
                                  <p className="text-heading">Cuisines Known</p>
                                  <Row
                                    style={{
                                      marginTop: "-15px",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    {data?.partyCookProfileStatus !== 0 &&
                                      partyCuisine?.map(
                                        (partyplanVal, index) => (
                                          <Col
                                            xs={5}
                                            sm={5}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            className="me-2"
                                            style={{
                                              backgroundColor: "#ffffff",
                                              borderRadius: "10px",
                                              color: "black",
                                              padding: "4px 8px",
                                              margin: "8px 0px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              textAlign: "center",
                                              fontSize: "16px",
                                              marginRight: "4px",
                                              border: "solid 1px #ff5c09",
                                              width: "auto",
                                            }}
                                          >
                                            {partyplanVal}
                                          </Col>
                                        )
                                      )}
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Experience</p>
                                  {data?.partyExperience && (
                                    <p className="text-details">
                                      {data?.partyExperience} Years
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Specialty</p>
                                  <p className="text-details">
                                    {data?.speciality}
                                  </p>
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Max. Plates</p>
                                  <p className="text-details">
                                    {data?.partyMaxPlates}
                                  </p>
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Food Type</p>

                                  {data?.partyCookFoodType === 1 && (
                                    <p className="text-details">Pure Veg</p>
                                  )}
                                  {data?.partyCookFoodType === 2 && (
                                    <p className="text-details">Veg/ Non-Veg</p>
                                  )}
                                  {data?.partyCookFoodType === 3 && (
                                    <p className="text-details">Jain Food</p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Vessel Wash</p>
                                  {data?.partyCookVesselWash === 0 && (
                                    <p className="text-details">No</p>
                                  )}
                                  {data?.partyCookVesselWash === 1 && (
                                    <p className="text-details">Yes</p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Availability</p>

                                  {data?.partyCookAvailability === 1 && (
                                    <p className="text-details">All Days</p>
                                  )}
                                  {data?.partyCookAvailability === 2 && (
                                    <p className="text-details">Mon-Fri Only</p>
                                  )}
                                  {data?.partyCookAvailability === 3 && (
                                    <p className="text-details">Sat-Sun Only</p>
                                  )}
                                </Col>
                              </Row>
                            </Row>
                          </Tab>
                        )}
                        {data?.cookType === 2 && (
                          <Tab eventKey="chef-plan" title="Plan">
                            {" "}
                            <Row className="card p-3 table-responsive">
                              <Table responsive hover>
                                <thead>
                                  <tr>
                                    <th className="text-left">
                                      Date of Purchase
                                    </th>
                                    <th className="text-center">Plan Name</th>
                                    <th className="text-center">Plan Price</th>

                                    <th className="text-center">Expiry</th>
                                    <th className="text-center">Validity</th>
                                    <th className="text-center">Status</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {data?.transactionsData?.map(
                                    (transactionVal, index) => (
                                      <>
                                        <tr>
                                          <td className="text-left">
                                            {Moment.utc(
                                              transactionVal?.cookPointsId
                                                ?.chefPlanStartDate
                                            ).format("DD-MM-YYYY")}
                                          </td>
                                          <td className="text-center">
                                            {
                                              transactionVal?.cookPlanId
                                                ?.cookPlanName
                                            }
                                            <br />
                                            {transactionVal?.isSelfPayment ===
                                              false && (
                                              <span style={{ color: "#000" }}>
                                                (Added by Admin)
                                              </span>
                                            )}
                                          </td>
                                          <td className="text-center">
                                            {transactionVal?.cookPlanId?.price}
                                          </td>

                                          <td className="text-center">
                                            {Moment.utc(
                                              transactionVal?.transactionEndDateTime
                                            ).format("DD-MM-YYYY")}
                                          </td>
                                          <td className="text-center">
                                            {
                                              transactionVal?.cookPlanId
                                                ?.validityInDays
                                            }
                                          </td>
                                          <td className="text-center">
                                            {transactionVal?.status === 1 && (
                                              <p
                                                style={{
                                                  background: "#b9ebae",
                                                  borderRadius: "5px",
                                                }}
                                              >
                                                Active
                                              </p>
                                            )}
                                            {transactionVal?.status === 0 && (
                                              <p
                                                style={{
                                                  backgroundColor: "#e9ebf0",
                                                  borderRadius: "5px",
                                                  padding: "4px 10px",
                                                }}
                                              >
                                                Expired
                                              </p>
                                            )}
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </Row>
                          </Tab>
                        )}

                        {data?.memberType === 2 && (
                          <Tab
                            eventKey="catering-profile"
                            title="Catering Profile"
                          >
                            <Row className="ustify-content-center card pb-3">
                              <div align="right" className="mt-2 pe-5">
                                <Link
                                  to={`/editcatering/${id}`}
                                  style={{
                                    color: "#ff5c09",
                                    fontWeight: "600",
                                    borderBottom: "1px solid #ff5c09",
                                  }}
                                >
                                  Edit
                                </Link>
                              </div>
                              <Row>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={6}
                                  className="ps-4 pt-2"
                                >
                                  <p className="text-heading">Cuisines Known</p>
                                  <Row
                                    style={{
                                      marginTop: "-15px",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    {data?.cateringProfileStatus !== 0 &&
                                      cateringCuisine?.map(
                                        (cateringplanVal, index) => (
                                          <Col
                                            xs={5}
                                            sm={5}
                                            md={4}
                                            lg={3}
                                            xl={3}
                                            className="me-2"
                                            style={{
                                              backgroundColor: "#ffffff",
                                              borderRadius: "10px",
                                              color: "black",
                                              padding: "4px 8px",
                                              margin: "8px 0px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              textAlign: "center",
                                              fontSize: "16px",
                                              marginRight: "4px",
                                              border: "solid 1px #ff5c09",
                                              width: "auto",
                                            }}
                                          >
                                            {cateringplanVal}
                                          </Col>
                                        )
                                      )}
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Min. Plates</p>
                                  <p className="text-details">
                                    {data?.cateringMinPlates}
                                  </p>
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Food Type</p>

                                  {data?.cateringFoodType === 1 && (
                                    <p className="text-details">Pure Veg</p>
                                  )}
                                  {data?.cateringFoodType === 2 && (
                                    <p className="text-details">Veg/ Non-Veg</p>
                                  )}
                                  {data?.cateringFoodType === 3 && (
                                    <p className="text-details">Jain Food</p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Team Size</p>
                                  <p className="text-details">
                                    {data?.teamSize}
                                  </p>
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">FSSAI</p>

                                  <p className="text-details">{data?.fssai}</p>
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Website</p>

                                  <p className="text-details">
                                    {data?.website}
                                  </p>
                                </Col>
                              </Row>
                            </Row>
                          </Tab>
                        )}
                      </Tabs>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChefDetails;
