import React, { useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { editCseAccounts, getSingleCse } from "../store/slices/cse";
import { Link } from "react-router-dom";

function ChangeCsePassword() {
  const { id } = useParams();
  const [errors, setErrors] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isValidPassword = (password) => {
    return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?])?[\w!@#$%^&*()_+[\]{};':"\\|,.<>/?]{8,24}$/.test(
      password
    );
  };
  const [data1, setData1] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const [data, setData] = useState({
    name: "",
    username: "",
    roleId: "",
    status: 1,
    houseCookAccess: 0,
    partyCookAccess: 0,
    chefAccess: 0,
    cateringAccess: 0,
    employerAccess: 0,
    clientAccess: 0,
  });

  useEffect(() => {
    dispatch(getSingleCse(id))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
      })
      .catch(({ message }) => {});
  }, []);

  useEffect(() => {
    document.title = "CookandChef Admin | Change CSE Password";
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validationErrors = { ...errors };
    switch (name) {
      case "newPassword":
        validationErrors[name] =
          value === "" || !isValidPassword(value)
            ? "Password must be alphanumeric and min 8 characters"
            : "";
        break;
      case "confirmPassword":
        validationErrors[name] =
          value === "" || value === data1.newPassword
            ? ""
            : "New password and confirm password should be same";
        break;
      default:
        break;
    }
    setData1({
      ...data1,
      [name]: value,
    });

    setErrors(validationErrors);
  };

  console.log(data);

  const submitHandler = (e) => {
    e.preventDefault();
    const validationErrors = {};
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    if (!data1.newPassword) {
      validationErrors.newPassword = "Please enter new password";
    } else if (!isValidPassword(data1.newPassword)) {
      validationErrors.newPassword =
        "Password must be alphanumeric and min 8 characters";
    }
    if (!data1.confirmPassword) {
      validationErrors.confirmPassword = "Please enter confirm password";
    } else if (data1.newPassword !== data1.confirmPassword) {
      validationErrors.confirmPassword =
        "New password and confirm password should be same";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      const item = {
        ...data,
        password: data1.newPassword,
      };
      dispatch(editCseAccounts(item))
        .unwrap()
        .then((data) => {
          alert(data?.users?.message);
          navigate("/cse");
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const cancelHandler = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbartop />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp;
                    <Link to="/cse">CSE</Link> &#8811; Change CSE Password
                  </div>

                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Change CSE Password</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <Form className="mx-4">
                        <Row className="my-4 justify-content-center">
                          <Row>
                            <Col lg={6} className="my-2 mt-2">
                              <Form.Group>
                                <Form.Label>New Password *</Form.Label>

                                <Form.Control
                                  name="newPassword"
                                  onChange={handleChange}
                                  isInvalid={!!errors.newPassword}
                                  isValid={!errors.newPassword}
                                  type="text"
                                  placeholder="Enter New Password"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.newPassword}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="my-2">
                              <Form.Group>
                                <Form.Label>Confirm Password *</Form.Label>

                                <Form.Control
                                  name="confirmPassword"
                                  onChange={handleChange}
                                  isInvalid={!!errors.confirmPassword}
                                  isValid={!errors.confirmPassword}
                                  type="text"
                                  placeholder="Confirm Password"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.confirmPassword}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Row className="justify-content-center mt-5">
                              <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                                <button
                                  onClick={cancelHandler}
                                  className="btn cancelBtn"
                                >
                                  Cancel
                                </button>
                              </Col>
                              <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                                <Button
                                  disabled={isdisabled}
                                  className="btn saveBtn mx-2"
                                  type="submit"
                                  onClick={submitHandler}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Row>
                        </Row>
                      </Form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeCsePassword;
