import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../Assets/Images/nav_logo.png";
import { AdminChangePassword } from "../store/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { VscEye } from "react-icons/vsc";

const ChangeForgotPassword = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState();
  const [passwordError, setPassworrdError] = useState();
  const [password, setPassword] = useState("");
  const navigate = useNavigate("");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const isValidPassword = (password) => {
    return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?])?[\w!@#$%^&*()_+[\]{};':"\\|,.<>/?]{8,24}$/.test(
      password
    );
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | ChangeForgotpassword";
  }, []);

  const adminChangePassword = (e) => {
    e.preventDefault();
    if (!otp) {
      setOtpError("Please enter otp");
    } else if (otp && otp.length < 6) {
      setOtpError("OTP should be 6 digits");
    }
    if (!password) {
      setPassworrdError("Please enter password");
    } else if (!isValidPassword(password)) {
      setPassworrdError("Password must be alphanumeric and min 8 characters");
    } else {
      dispatch(AdminChangePassword({ otp, password }))
        .unwrap()
        .then((data) => {
          alert(data?.users?.message);
          if (data?.users?.message === "Password changed successfully") {
            navigate("/web-admin");
          }
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const hangeChangeOtp = (e) => {
    setOtp(e.target.value);
    if (!e.target.value) {
      setOtpError("Please enter otp");
    } else if (e.target.value.length < 6) {
      setOtpError("OTP should be 6 digits");
    } else {
      setOtpError("");
    }
  };

  const hangeChangePassword = (e) => {
    setPassword(e.target.value);
    if (!e.target.value) {
      setPassworrdError("Please enter otp");
    } else if (!isValidPassword(e.target.value)) {
      setPassworrdError("Password must be alphanumeric and min 8 characters");
    } else {
      setPassworrdError("");
    }
  };
  return (
    <div>
      <section className="login-bg-image">
        <div className="bg-overlay-orange" />

        <div className="card-section">
          <div className="d-flex justify-content-around align-items-center">
            <img src={logo} alt="travel-logo" className="div-logo" />

            <div className="h-logn-line" />

            <div className="login-form-2" align="center">
              {/*error && <Alert variant="danger">{error}</Alert>*/}
              <div className="d-flex justify-content-center">
                <img src={logo} alt="logo" className="logo-form-2" />
              </div>
              <h2
                className="text-center mb-4"
                style={{ color: "black", fontWeight: "500" }}
              >
                Change Password
              </h2>

              <form>
                <input
                  type="number"
                  value={otp}
                  onChange={hangeChangeOtp}
                  className="form-control text-black-ph"
                  placeholder="Enter OTP"
                  style={{ height: "50px" }}
                />
                <p className="alert-message">{otpError}</p>

                <div className="position-r mt-3">
                  <input
                    type={passwordShown ? "text" : "password"}
                    value={password}
                    onChange={hangeChangePassword}
                    className="form-control text-black-ph"
                    placeholder="Password"
                    style={{ height: "50px" }}
                  />
                  <p className="alert-message">{passwordError}</p>
                  <VscEye className="eye_icon_login" onClick={togglePassword} />
                </div>
                <div className="col-md-12 d-flex justify-content-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-warning login-btn col-white"
                    onClick={adminChangePassword}
                  >
                    Change Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ChangeForgotPassword;
