import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Navbartop from "./navbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getChef } from "../store/slices/cook";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { BsGlobe2 } from "react-icons/bs";
import { Link } from "react-router-dom";
import Moment from "moment";
import { API_PATHS } from "../utils/constants/api.constants";
import { Typeahead } from "react-bootstrap-typeahead";
import { getCuisines } from "../store/slices/config";
import "react-bootstrap-typeahead/css/Typeahead.css";
import MultiRangeSlider from "../utils/helpers/MultirangeSlider";
import { MdBlock } from "react-icons/md";
import { useSelector } from "react-redux";
import ExportCSVButton from "../utils/helpers/ExportButtons";
import ShowSkeleton from "../utils/helpers/Skeleton";

function Chef() {
  const [cuisines, setCuisines] = useState([]);
  const [chefCuisines, setchefCuisines] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [cityCoordinates, setcityCoordinates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [location, setLocation] = useState("");
  const [city, setCity] = useState("");
  const [skills, setSkills] = useState("");
  const [gender, setGender] = useState("");
  const [nameEmailOrMobile, setnameEmailOrMobile] = useState("");
  const [maximumExperience, setmaximumExperience] = useState("");
  const [minimumExperience, setminimumExperience] = useState("");
  const [minimumSalary, setminimumSalary] = useState(0);
  const [maximumSalary, setmaximumSalary] = useState(100000);
  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(100000);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100000);
  const [item, setItem] = useState({
    limit: 25,
    page: 1,
  });
  const [exp, setExp] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [exportData, setExportData] = useState([]);
  const [totaldata, settotalData] = useState();
  const [limit, setLimit] = useState();
  const [cuisineInputval, setCuisienInputVal] = useState("");
  const [cuisineError, setCuisineError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [cityError, setCityError] = useState("");
  // console.log(data);
  const [isLoading, setisLoading] = useState(true);
  const myData = useSelector((state) => state.cook.users);
  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  const handleApplyFilter = () => {
    search.set("minimumSalary", minimumSalary);
    search.set("maximumSalary", maximumSalary);
    search.set("page", 1);
    setSearch(search, {
      replace: true,
    });
    const item = {
      cuisines:
        chefCuisines[0] !== "" && chefCuisines.length
          ? chefCuisines
          : undefined,
      latitude: coordinates[0] || undefined,
      longitude: coordinates[1] || undefined,
      cityLatitude: cityCoordinates[0] || undefined,
      cityLongitude: cityCoordinates[1] || undefined,
      minimumExperience:
        parseInt(minimumExperience) >= 0
          ? parseInt(minimumExperience)
          : undefined,
      maximumExperience: parseInt(maximumExperience) || undefined,
      minimumSalary: parseInt(minimumSalary),
      maximumSalary: parseInt(maximumSalary) || undefined,
      skills: skills || undefined,
      nameEmailOrMobile: nameEmailOrMobile || undefined,
      gender: parseInt(gender) || undefined,
      limit: 25,
      page: 1,
    };
    if (cuisineInputval && !chefCuisines.length) {
      setCuisineError("Please Select valid cuisine");
    } else if (locationError) {
    } else if (cityError) {
    } else {
      setItem(item);
      setCurrentPage(1);
      getchef(item);
    }
  };

  const handleResetFilter = (e) => {
    e.preventDefault();
    setchefCuisines([]);
    setExp("");
    setmaximumExperience("");
    setminimumExperience("");
    setMinVal(0);
    setMaxVal(100000);
    setminimumSalary(0);
    setmaximumSalary(100000);
    setLocation("");
    setCity("");
    setCoordinates([]);
    setcityCoordinates([]);
    setSkills("");
    setGender("");
    setnameEmailOrMobile("");
    setSearch([]);
    localStorage.removeItem("coordinates");
    const item = {
      limit: 25,
      page: 1,
    };
    setSearch([]);
    setCurrentPage(1);
    setItem(item);
    getchef(item);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Chef";
  }, []);
  useEffect(() => {
    if (location === "") {
      setCoordinates([]);
    }
  }, [location]);

  const handlePageClick = async ({ selected }) => {
    const newPage = selected + 1;

    if (newPage === currentPage) {
      return;
    }
    const updatedItem = {
      ...item,
      limit: 25,
      page: newPage,
    };
    setItem(updatedItem);
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
    await getchef(updatedItem);
  };

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        setModalMessage(message);
        setShowModal(true);
      });
  }, [dispatch]);

  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const handleskillsChange = (e) => {
    setSkills(e.target.value);
    search.set("skills", e.target.value);

    setSearch(search, {
      replace: true,
    });
  };

  const handlegenderChange = (e) => {
    setGender(e.target.value);
    search.set("gender", e.target.value);

    setSearch(search, {
      replace: true,
    });
  };

  const handlenameChange = (e) => {
    setnameEmailOrMobile(e.target.value);
    search.set("nameEmailOrMobile", e.target.value);

    setSearch(search, {
      replace: true,
    });
  };

  const inputChangeCuisine = (e) => {
    setCuisienInputVal(e);
    setCuisineError("");
  };

  const currentDate = Moment().format("YYYY-MM-DD");
  const isTransactionPast = (chef) => {
    return Moment(chef?.transactions?.transactionEndDateTime).isBefore(
      currentDate
    );
  };

  const handleChefCuisineChange = (selected) => {
    setCuisineError("");
    setCuisienInputVal("");

    const validationErrors = { ...errors };
    search.set("cuisines", selected.join(","));
    setSearch(search, {
      replace: true,
    });
    setchefCuisines(selected);
    validationErrors.chefCuisines =
      selected.length === 0 ? "Please enter cuisine" : "";
    if (!selected.length === 0) {
      delete validationErrors.chefCuisines;
    }

    setErrors(validationErrors);
  };

  const inputRef = useRef(null);
  //useeffect
  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const googleMapsScript = document.createElement("script");
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${API_PATHS.googlemapsapikey}&libraries=places`;
      googleMapsScript.onload = initializeAutocomplete;
      googleMapsScript.onerror = () => {
        console.error("Failed to load Google Maps API script");
      };
      document.body.appendChild(googleMapsScript);
    };

    const initializeAutocomplete = () => {
      if (inputRef.current) {
        const autoCompleteCity = new window.google.maps.places.Autocomplete(
          inputRef.current,
          {
            componentRestrictions: { country: "in" },
          }
        );

        autoCompleteCity.addListener("place_changed", () => {
          const place = autoCompleteCity.getPlace();
          if (!place.geometry || !place.geometry.location) {
            setLocationError(
              "Please select location from the suggestion drop-down"
            );
          } else {
            setLocationError("");
            setLocation(place.name);
            const coordinates = [
              place.geometry.location.lat(),
              place.geometry.location.lng(),
            ];
            setCoordinates(coordinates);
            localStorage.setItem("coordinates", coordinates);
          }
        });
        inputRef.current.addEventListener("blur", () => {
          const place = autoCompleteCity.getPlace();
          const inputValue = inputRef.current.value.trim();
          if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
            setLocationError(
              "Please select location from the suggestion drop-down"
            );
          }
        });
      }
    };
    loadGoogleMapsScript();

    return () => {
      // Clean up code here, if needed
    };
  }, []);

  const cityinputRef = useRef(null);
  useEffect(() => {
    if (cityinputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        cityinputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setCityError("Please select city from the suggestion drop-down");
        } else {
          setCityError("");
          setCity(place.name);
          const coordinates = [
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ];
          setcityCoordinates(coordinates);
          localStorage.setItem("citycoordinates", coordinates);
        }
      });
      cityinputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = cityinputRef.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setCityError("Please select city from the suggestion drop-down");
        }
      });
    }
  }, []);

  const handlelocationChange = (e) => {
    setLocation(e.target.value);
    setLocationError("");
  };

  useEffect(() => {
    if (location !== "") {
      search.set("location", location);
      setSearch(search, {
        replace: true,
      });
    }
  }, [location]);

  useEffect(() => {
    if (city !== "") {
      search.set("city", city);
      setSearch(search, {
        replace: true,
      });
    }
  }, [city]);

  const handlecityChange = (e) => {
    setCity(e.target.value);
    setCityError("");
  };

  const handleExpChange = (e) => {
    const [minExp, maxExp] = e.target.value?.split("-");
    setExp(e.target.value);
    // Set the minimum and maximum experience in the state
    setminimumExperience(minExp);
    setmaximumExperience(maxExp);
    search.set("minimumexp", minExp);
    search.set("maximumexp", maxExp);

    setSearch(search, {
      replace: true,
    });
  };
  const HandleChange = ({ max, min }) => {
    setminimumSalary(min);
    setmaximumSalary(max);
  };

  useEffect(() => {
    const cuisineval = search.get("cuisines")?.split(",") ?? [];
    const minexpval = parseInt(search.get("minimumexp"));
    const maxexpval = parseInt(search.get("maximumexp"));
    const minsal = search.get("minimumSalary");
    const skillsval = search.get("skills");
    const genderval = search.get("gender");
    const keywordval = search.get("nameEmailOrMobile");
    const maxsal = search.get("maximumSalary");
    const locationq = search.get("location");
    const cityq = search.get("city");
    const pageq = search.get("page");
    if (cuisineval !== null && cuisineval[0] !== "") {
      setchefCuisines(cuisineval);
    }

    if (minexpval === 0) {
      setminimumExperience(0);
      setmaximumExperience(2);
      setExp("0-2");
    } else if (minexpval === 2) {
      setminimumExperience(2);
      setmaximumExperience(5);
      setExp("2-5");
    } else if (minexpval === 5) {
      setminimumExperience(5);
      setmaximumExperience(8);
      setExp("5-8");
    } else if (minexpval === 8) {
      setminimumExperience(8);
      setmaximumExperience(10);
      setExp("8-10");
    } else if (minexpval === 10) {
      setminimumExperience(10);
      setmaximumExperience(15);
      setExp("10-15");
    } else if (minexpval === 15) {
      setminimumExperience(15);
      setmaximumExperience(25);
      setExp("15-25");
    } else if (minexpval === 25) {
      setminimumExperience(25);
      setmaximumExperience(50);
      setExp("25-50");
    }
    if (minsal !== null) {
      setMinVal(parseInt(minsal));
      setminimumSalary(parseInt(minsal));
    }
    if (maxsal !== null) {
      setMaxVal(parseInt(maxsal));
      setmaximumSalary(parseInt(maxsal));
    }
    if (keywordval) {
      setnameEmailOrMobile(keywordval);
    }
    if (genderval) {
      setGender(genderval);
    }
    if (skillsval) {
      setSkills(skillsval);
    }
    let coordString;
    let coordArray;
    let citycoordString;
    let citycoordArray;
    if (locationq !== null) {
      setLocation(locationq);
      coordString = localStorage.getItem("coordinates");
      coordArray = coordString ? coordString.split(",") : [];
      setCoordinates(coordArray);
    }
    if (cityq !== null) {
      setCity(cityq);
      citycoordString = localStorage.getItem("citycoordinates");
      citycoordArray = citycoordString ? citycoordString.split(",") : [];
      setcityCoordinates(citycoordArray);
    }
    if (
      cuisineval ||
      minexpval ||
      minsal ||
      locationq ||
      cityq ||
      pageq ||
      keywordval ||
      genderval ||
      skillsval
    ) {
      const item = {
        cuisines:
          cuisineval[0] !== "" && cuisineval.length ? cuisineval : undefined,
        latitude: coordArray ? coordArray[0] : undefined,
        longitude: coordArray ? coordArray[1] : undefined,
        cityLatitude: citycoordArray ? citycoordArray[0] : undefined,
        cityLongitude: citycoordArray ? citycoordArray[1] : undefined,
        gender: parseInt(genderval) >= 0 ? parseInt(genderval) : undefined,
        skills: skillsval || undefined,
        nameEmailOrMobile: keywordval || undefined,
        minimumExperience:
          parseInt(minexpval) >= 0 ? parseInt(minexpval) : undefined,
        maximumExperience: parseInt(maxexpval) || undefined,
        minimumSalary: parseInt(minsal) || 0,
        maximumSalary: parseInt(maxsal) || undefined,
        limit: 25,
        page: parseInt(pageq) || 1,
      };

      setItem(item);
      getchef(item);
    } else {
      getchef(item);
    }
  }, []);

  const getchef = (item) => {
    dispatch(getChef(item))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
        settotalData(data.users.totalDataCount);

        setLimit(data.users.totalDataCount);
        setisLoading(true);
        setTimeout(() => {
          setisLoading(false);
        }, [500]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;
  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Chef
                  </div>
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Chef</h4>
                      </div>

                      {role === "admin" && (
                        <div align="right">
                          <div>
                            <ExportCSVButton
                              limit={limit}
                              filename="chef_data.xlsx"
                              getData={getChef}
                              item={item}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="cards ps-1 pe-1">
                      <Form>
                        <Row className="d-flex flex-row card ps-2 pe-2">
                          <Col
                            lg={3}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <Form.Group>
                              <Typeahead
                                clearButton
                                id="basic-typeahead-multiple"
                                labelKey="name"
                                multiple
                                className="input1"
                                onChange={handleChefCuisineChange}
                                options={options}
                                placeholder="Cuisines"
                                selected={chefCuisines}
                                isInvalid={!!cuisineError}
                                onInputChange={inputChangeCuisine}
                              />
                              <Form.Control.Feedback type="invalid">
                                {cuisineError}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col
                            lg={3}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <Form.Group>
                              <Form.Control
                                name="location"
                                placeholder="Current City"
                                value={location}
                                ref={inputRef}
                                onChange={handlelocationChange}
                                isInvalid={!!locationError}
                              />
                              <Form.Control.Feedback type="invalid">
                                {locationError}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col
                            lg={3}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <Form.Group>
                              <Form.Control
                                name="city"
                                placeholder="City"
                                value={city}
                                ref={cityinputRef}
                                onChange={handlecityChange}
                                isInvalid={!!cityError}
                              />
                              <Form.Control.Feedback type="invalid">
                                {cityError}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col
                            lg={3}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <Form.Group>
                              <Form.Control
                                as="select"
                                name="experience"
                                value={exp}
                                onChange={handleExpChange}
                              >
                                <option value="">Experience</option>
                                <option value="0-2">0-2 Years</option>
                                <option value="2-5">2-5 Years</option>
                                <option value="5-8">5-8 Years</option>
                                <option value="8-10">8-10 Years</option>
                                <option value="10-15">10-15 Years</option>
                                <option value="15-25">15-25 Years</option>
                                <option value="26-35">
                                  More than 25 Years
                                </option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col
                            lg={3}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-4 mt-3"
                          >
                            <Form.Group>
                              <Form.Control
                                name="skills"
                                placeholder="Skills"
                                value={skills}
                                onChange={handleskillsChange}
                              />
                            </Form.Group>
                          </Col>

                          <Col
                            lg={3}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-4 mt-3"
                          >
                            <Form.Group>
                              <Form.Control
                                name="nameEmailOrMobile"
                                placeholder="Name/ Email/ Mobile"
                                value={nameEmailOrMobile}
                                onChange={handlenameChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col
                            lg={3}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-4 mt-3"
                          >
                            <Form.Group>
                              <Form.Control
                                as="select"
                                name="gender"
                                value={gender}
                                onChange={handlegenderChange}
                              >
                                <option value="">Gender</option>
                                <option value="1">Male</option>
                                <option value="2">Female</option>
                                <option value="3">Other</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col
                            lg={3}
                            xs={12}
                            md={6}
                            className="d-flex flex-column mb-4"
                          >
                            <Form.Group align="center">
                              <Form.Label>Salary</Form.Label>
                              <MultiRangeSlider
                                min={min}
                                max={max}
                                minVal={minVal}
                                maxVal={maxVal}
                                setMinVal={setMinVal}
                                setMaxVal={setMaxVal}
                                onChange={({ min, max }) =>
                                  HandleChange({ min, max })
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="group d-flex justify-content-center mt-1 ">
                          <Button
                            className="cancel_btn"
                            onClick={handleResetFilter}
                          >
                            Reset
                          </Button>
                          <Button
                            className="apply_btn ms-2"
                            onClick={handleApplyFilter}
                          >
                            Search
                          </Button>
                        </div>
                      </Form>
                    </div>

                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : data?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No results</b> found for your search!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table responsive hover>
                            <thead>
                              <tr>
                                <th className="text-left">Name</th>
                                <th className="text-left">Mobile</th>
                                <th className="text-left">Email</th>
                                <th className="text-center">Date of Regd</th>
                                <th className="text-center">Last Login</th>
                                <th className="text-center">
                                  My Profile/
                                  <br /> Chef Profile
                                </th>
                                <th className="text-center">Plan</th>
                                <th className="text-left">Web/ App</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((cook) => (
                                <tr key={cook?._id}>
                                  <td className="text-left">
                                    <Link
                                      to={`/chefdetails/${cook?._id}`}
                                      style={{
                                        color: "#a020f0",
                                      }}
                                    >
                                      {cook?.status === 3 && (
                                        <MdBlock
                                          className="me-2"
                                          style={{ color: "red" }}
                                        />
                                      )}
                                      {cook?.fullName.substring(0, 25)}
                                    </Link>
                                  </td>
                                  <td className="text-left">{cook?.mobile}</td>
                                  <td className="text-left">
                                    {" "}
                                    {cook?.email?.substring(0, 25)}
                                  </td>
                                  <td className="text-center">
                                    {Moment.utc(cook?.createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {Moment.utc(cook?.lastLoginDateTime).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {cook?.basicProfileStatus === 0 && (
                                      <span
                                        style={{
                                          color: "#f06548",

                                          fontWeight: "500",
                                        }}
                                      >
                                        No
                                      </span>
                                    )}
                                    {cook?.basicProfileStatus === 1 && (
                                      <span
                                        style={{
                                          color: "#099885",

                                          fontWeight: "500",
                                        }}
                                      >
                                        Yes
                                      </span>
                                    )}{" "}
                                    /&nbsp;
                                    {cook?.chefProfileStatus === 0 && (
                                      <span
                                        style={{
                                          color: "#f06548",

                                          fontWeight: "500",
                                        }}
                                      >
                                        No
                                      </span>
                                    )}
                                    {cook?.chefProfileStatus === 1 && (
                                      <span
                                        style={{
                                          color: "#099885",

                                          fontWeight: "500",
                                        }}
                                      >
                                        Yes
                                      </span>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {cook?.transactions
                                      ?.transactionStartDateTime ? (
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          color: "black",
                                          background: isTransactionPast(cook)
                                            ? "#e9ebf0"
                                            : "#c9f0af",
                                          padding: "5px",
                                          borderRadius: "10px",
                                        }}
                                      >
                                        {Moment.utc(
                                          cook?.transactions
                                            ?.transactionEndDateTime
                                        ).format("DD-MM-YYYY")}
                                      </p>
                                    ) : (
                                      <p></p>
                                    )}
                                  </td>
                                  <td className="text-left">
                                    {cook?.webAccess === 0 && (
                                      <BsGlobe2
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {!(
                                      cook?.webAccess === 0 ||
                                      cook?.webAccess === 1
                                    ) && (
                                      <BsGlobe2
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {cook?.webAccess === 1 && (
                                      <BsGlobe2
                                        style={{
                                          color: "orange",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {cook?.appAccess === 0 && (
                                      <HiOutlineDevicePhoneMobile
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {!(
                                      cook?.appAccess === 0 ||
                                      cook?.appAccess === 1
                                    ) && (
                                      <HiOutlineDevicePhoneMobile
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {cook?.appAccess === 1 && (
                                      <HiOutlineDevicePhoneMobile
                                        style={{
                                          color: "blue",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                      <div className="justify-content-right">
                        {totaldata / itemsPerPage > 1 && (
                          <ReactPaginate
                            key={currentPage}
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            breakLinkClassName={"page-link"}
                            pageCount={Math.ceil(totaldata / itemsPerPage)}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={pagesToShowInitially}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            initialPage={currentPage - 1}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chef;
