import React, { useEffect, useState } from "react";
import Navbartop from "../component/navbar";
import { useDispatch } from "react-redux";
import { GetDashboardData } from "../store/slices/auth";
import { Link } from "react-router-dom";
import AllusersLineChart from "./AllusersLineChart";
import { Col, Row, Form, Button } from "react-bootstrap";
import { getAllUsersActivity } from "../store/slices/activity";

function Home() {
  let data = JSON.parse(localStorage.getItem("users"));
  let role = data?.data?.roleId?.roleName;
  const [dashboardData, setDashBoardData] = useState();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [totaldata, settotalData] = useState();
  const [data1, setData1] = useState();
  const now = new Date();
  const day = now.getDate().toString().padStart(2, "0");
  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Note: Month is zero-based
  const year = now.getFullYear();

  const formattedDate = `${year}-${month}-${day}`;
  const [startDate, setStartDate] = useState(formattedDate);
  const [endDate, setEndDate] = useState(formattedDate);
  const [dateError, setDateError] = useState("");
  const [totalCateringsCount, settotalCateringsCount] = useState(0);
  const [totalClientsCount, settotalClientsCount] = useState(0);
  const [totalCooksCount, settotalCooksCount] = useState(0);
  const [totalEmployersCount, settotalEmployersCount] = useState(0);
  const [item, setItem] = useState({
    startDate: startDate,
    endDate: endDate,
    limit: 25,
    page: 1,
  });
  const handleApplyFilter = () => {
    const item = {
      role: "employer",
      startDate: startDate,
      endDate: endDate,
      limit: 25,
      page: 1,
    };

    if (startDate && endDate === "") {
      setDateError("Please select date");
      return;
    }
    setItem(item);
    setCurrentPage(1);
    if (startDate && endDate) {
      getclient(item);
    }
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Employer Activity";
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    setDateError("");
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
    setDateError("");
  };

  React.useEffect(() => {
    if (startDate && endDate) {
      getclient(item);
    }
  }, [dispatch, currentPage]);

  const getclient = (item) => {
    dispatch(getAllUsersActivity(item))
      .unwrap()
      .then((data) => {
        setData1(data?.allactivity?.data?.dailywiseData);
        settotalData(data?.allactivity?.data?.totalUsersCount);
        settotalCateringsCount(data?.allactivity?.data?.totalCateringsCount);
        settotalClientsCount(data?.allactivity?.data?.totalClientsCount);
        settotalCooksCount(data?.allactivity?.data?.totalCooksCount);
        settotalEmployersCount(data?.allactivity?.data?.totalEmployersCount);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  useEffect(() => {
    getdashBoardData(item);
  }, []);

  const getdashBoardData = (item) => {
    dispatch(GetDashboardData(item))
      .unwrap()
      .then((data) => {
        setDashBoardData(data.users.data);
      })
      .catch(({ message }) => {});
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Home";
  }, []);

  return (
    <div>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-6 col-12">
                  <div className="card bg-white">
                    <div className="card-body">
                      <div className="card-block pt-2 pb-0">
                        <div className="media pb-2">
                          <div className="media-body white text-left">
                            {!data?.data?.name && (
                              <h4 className="font-medium-5 card-title mb-0">
                                Welcome Admin!
                              </h4>
                            )}
                            {data?.data?.name && (
                              <h4 className="font-medium-5 card-title mb-0">
                                Welcome {data?.data?.name}!
                              </h4>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                {role === "admin" && (
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card " style={{ background: "#93ccf7" }}>
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dashboardData?.totalSalesCount}
                              </h4>
                              <span className="black darken-1">
                                Today's Sales
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-poll fa-edit-icon warning"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart1"
                          className="height-70 lineChartWidget WidgetlineChart1 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                )}

                {(role === "admin" || role === "Manager") && (
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card" style={{ background: "#ffe198" }}>
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dashboardData?.totalUsersCount}
                              </h4>
                              <span className="black darken-1">
                                Today's Registration
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-wallet fa-edit-icon info"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
                {(role === "admin" ||
                  role === "Manager" ||
                  role === "Executive") && (
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <Link to="/joblist">
                      <div className="card" style={{ background: "#D6BFFF" }}>
                        <div className="card-body">
                          <div className="card-block pt-2 pb-0">
                            <div className="media">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  {dashboardData?.totalJobsCount}
                                </h4>
                                <span className="black darken-1">
                                  Today's Job Post
                                </span>
                              </div>
                              <div className="media-right text-right">
                                <i className="fas fa-donate fa-edit-icon success"></i>
                              </div>
                            </div>
                          </div>
                          <div
                            id="Widget-line-chart2"
                            className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                          ></div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {(role === "admin" ||
                  role === "Manager" ||
                  role === "Executive") && (
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                    <div className="card" style={{ background: "#D7FFBF" }}>
                      <div className="card-body">
                        <div className="card-block pt-2 pb-0">
                          <div className="media">
                            <div className="media-body white text-left">
                              <h4 className="font-medium-5 card-title mb-0">
                                {dashboardData?.totalJobApplicationsCount}
                              </h4>
                              <span className="black darken-1">
                                Today's Applications
                              </span>
                            </div>
                            <div className="media-right text-right">
                              <i className="fas fa-donate fa-edit-icon success"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          id="Widget-line-chart2"
                          className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
                {(role === "admin" || role === "Manager") && (
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <Link to="/all-cook-list">
                      <div className="card" style={{ background: "#BFFDFF" }}>
                        <div className="card-body">
                          <div className="card-block pt-2 pb-0">
                            <div className="media">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  {dashboardData?.totalCooksCount}
                                </h4>
                                <span className="black darken-1">
                                  Total Cooks
                                </span>
                              </div>
                              <div className="media-right text-right">
                                <i className="fas fa-donate fa-edit-icon success"></i>
                              </div>
                            </div>
                          </div>
                          <div
                            id="Widget-line-chart2"
                            className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                          ></div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {(role === "admin" || role === "Manager") && (
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <Link to="/client">
                      <div className="card" style={{ background: "#fc94c5" }}>
                        <div className="card-body">
                          <div className="card-block pt-2 pb-0">
                            <div className="media">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  {dashboardData?.totalClientsCount}
                                </h4>
                                <span className="black darken-1">
                                  Total Clients
                                </span>
                              </div>
                              <div className="media-right text-right">
                                <i className="fas fa-donate fa-edit-icon success"></i>
                              </div>
                            </div>
                          </div>
                          <div
                            id="Widget-line-chart2"
                            className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                          ></div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {(role === "admin" || role === "Manager") && (
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <Link to="/employer">
                      <div className="card" style={{ background: "#f1caa9" }}>
                        <div className="card-body">
                          <div className="card-block pt-2 pb-0">
                            <div className="media">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  {dashboardData?.totalEmployersCount}
                                </h4>
                                <span className="black darken-1">
                                  Total Employers
                                </span>
                              </div>
                              <div className="media-right text-right">
                                <i className="fas fa-donate fa-edit-icon success"></i>
                              </div>
                            </div>
                          </div>
                          <div
                            id="Widget-line-chart2"
                            className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                          ></div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>

            {(role === "admin" || role === "Manager") && (
              <div className="container-fluid">
                <div className="card col-xl-12 col-lg-12 col-md-12 col-12">
                  <div className=" ps-1 pe-1">
                    <Form>
                      <Row className="d-flex flex-row card ps-2 pe-2 justify-content-left ">
                        <Col
                          lg={4}
                          md={6}
                          xs={12}
                          className="d-flex flex-column mb-2"
                        >
                          <Form.Group>
                            <label className="m-1 label">Start Date</label>
                            <Form.Control
                              name="nameEmailOrMobile"
                              placeholder="date"
                              type="date"
                              value={startDate}
                              isInvalid={!!dateError}
                              onChange={handleStartDate}
                            />
                            <Form.Control.Feedback type="invalid">
                              {dateError}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          xs={12}
                          className="d-flex flex-column mb-2"
                        >
                          <Form.Group>
                            <label className="m-1 label">End Date</label>
                            <Form.Control
                              name="nameEmailOrMobile"
                              placeholder="date"
                              type="date"
                              value={endDate}
                              isInvalid={!!dateError}
                              onChange={handleEndDate}
                            />
                            <Form.Control.Feedback type="invalid">
                              {dateError}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          xs={12}
                          className="d-flex flex-column mb-2"
                        >
                          <div className="group d-flex justify-content-left mt-4 ">
                            {/* <Button
                      className="cancel_btn"
                      onClick={handleResetFilter}
                    >
                      Reset
                    </Button> */}
                            <Button
                              className="apply_btn ms-2"
                              onClick={handleApplyFilter}
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                      <div className="card" style={{ background: "#e7fffa" }}>
                        <div className="card-body">
                          <div className="card-block pt-2 pb-0">
                            <div className="media">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  {totalEmployersCount}
                                </h4>
                                <span className="black darken-1">
                                  Employers Registered
                                </span>
                              </div>
                              <div className="media-right text-right">
                                <i className="fas fa-wallet fa-edit-icon info"></i>
                              </div>
                            </div>
                          </div>
                          <div
                            id="Widget-line-chart2"
                            className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                      <div className="card" style={{ background: "#ecf2ff" }}>
                        <div className="card-body">
                          <div className="card-block pt-2 pb-0">
                            <div className="media">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  {totalCooksCount + totalCateringsCount}
                                </h4>
                                <span className="black darken-1">
                                  Cooks Registered
                                </span>
                              </div>
                              <div className="media-right text-right">
                                <i className="fas fa-donate fa-edit-icon success"></i>
                              </div>
                            </div>
                          </div>
                          <div
                            id="Widget-line-chart2"
                            className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                      <div className="card " style={{ background: "#feeee8" }}>
                        <div className="card-body">
                          <div className="card-block pt-2 pb-0">
                            <div className="media">
                              <div className="media-body white text-left">
                                <h4 className="font-medium-5 card-title mb-0">
                                  {totalClientsCount}
                                </h4>
                                <span className="black darken-1">
                                  Clients Registered
                                </span>
                              </div>
                              <div className="media-right text-right">
                                <i className="fas fa-donate fa-edit-icon success"></i>
                              </div>
                            </div>
                          </div>
                          <div
                            id="Widget-line-chart2"
                            className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body collapse show mb-5">
                    <div style={{ height: "500px", width: "100%" }}>
                      <AllusersLineChart
                        hourlyActData={data1}
                        activity={"users"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default Home;
