import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class AuthService {
    static login({ username, password }) {
       
        return axios
            .post(API_PATHS.login, {
                username,
                password,
            })
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static supportlogin({ username, password }) {
       
        return axios
            .post(API_PATHS.cselogin, {
                username,
                password,
            })
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    
    static supportlogin({ username, password }) {
       
        return axios
            .post(API_PATHS.cselogin, {
                username,
                password,
            })
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    //admin change password 
    static Adminforgotpassword() {
        return axios
              .post(API_PATHS.adminforgotpassword, {
                
              })
              .then((response) => {
                  if (response.data) {
                     
                  }
                  return response.data;
              });
      }

    static Adminchangepassword({ otp, password }) {
      return axios
            .post(API_PATHS.adminchangepassword, {
              otp,
              password
            })
            .then((response) => {
                if (response.data) {
                    
                }
                return response.data;
            });
    }

  //changed
  static adminDashboardData(item ) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    let api = ""
    api = `${API_PATHS.getdashboardData}?startDate=${item.startDate}&endDate=${item.endDate}`;


    return axios.get(api,config)
        .then((response) => {
            if (response.data) {
                
            }
            return response.data;
        });
}



    static removeusersDetails() {
        localStorage.removeItem("users");
    }

    static getusersDetails() {
        return JSON.parse(localStorage.getItem("users"));
    }
}

export default AuthService;