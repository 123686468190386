import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdsService from "../../services/ads.service"


export const AddAdvertisements = createAsyncThunk(
  "ads/post",
  async (item, thunkAPI) => {
    try {
      const data = await AdsService.addAdvertisements(item);
      return { ads: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const GetAdvertisements = createAsyncThunk(
  "ads/get",
  async (item, thunkAPI) => {
    try {
      const data = await AdsService.getAdvertisements(item);
      return { ads: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const GetSingleAdvertisements = createAsyncThunk(
    "singlead/get",
    async (item, thunkAPI) => {
      try {
        const data = await AdsService.getSingleAdd(item);
        return { ads: data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

export const EditAdvertisements = createAsyncThunk(
    "ads/edit",
    async (item, thunkAPI) => {
      try {
        const data = await AdsService.editAdvertisements(item);
        return { ads: data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );
  
  export const DeleteAdvertisements = createAsyncThunk(
    "ads/delete",
    async (item, thunkAPI) => {
      try {
        const data = await AdsService.deleteAdvertisements(item);
        return { ads: data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );