import React, { useState } from "react";
import { FaQuestion } from "react-icons/fa6";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { MdOutlineContactSupport } from "react-icons/md";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { BsActivity } from "react-icons/bs";
import { MdReportGmailerrorred } from "react-icons/md";
import { MdOutlineDelete } from "react-icons/md";
import home from "../Assets/Images/home.png";
import client from "../Assets/Images/client (1).png";
import housecook from "../Assets/Images/house.png";
import partycook from "../Assets/Images/bbq.png";
import catering from "../Assets/Images/Caering icon.png";
import chef from "../Assets/Images/chef (2).png";
import employer from "../Assets/Images/restaurant (2).png";
import plan from "../Assets/Images/price-tag.png";
import jobs from "../Assets/Images/jobs.png";
import events from "../Assets/Images/events.png";
import logout from "../Assets/Images/logout.png";
import cse from "../Assets/Images/profile.png";
import sales from "../Assets/Images/plan.png";
import campaign from "../Assets/Images/campaign.png";
import { MdBlock } from "react-icons/md";
import { RiDropdownList } from "react-icons/ri";

const AdminMenu = () => {
  const [expand, setExpand] = useState({
    activity: false,
    plans: false,
  });
  const toggleCollapse = (section) => {
    setExpand((prevExpand) => ({
      ...Object.fromEntries(Object.keys(prevExpand).map((key) => [key, false])),
      [section]: !prevExpand[section],
    }));
  };

  const navigate = useNavigate();
  function signOut(e) {
    localStorage.clear();
    navigate("/web-admin");
  }

  const isActive = (path) => window.location.pathname === path;

  return (
    <>
      <ul className="nav-lists pt-1">
        <li className="nav_items top_item">
          <NavLink
            to="/home"
            className={`${isActive("/home") ? "active-link" : "menu-title"}`}
            activeClassName="active"
          >
            <img
              src={home}
              alt="home"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Dashboard
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to="/client"
            className={`${isActive("/client") ? "active-link" : "menu-title"}`}
          >
            <img
              src={client}
              alt="client"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Client
          </NavLink>
        </li>
        <li className="nav_items">
          <NavLink
            to="/all-cook-list"
            className={`${
              isActive("/all-cook-list") ? "active-link" : "menu-title"
            }`}
          >
            <img
              src={chef}
              alt="chef"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Cooks
          </NavLink>
        </li>
        <li className="nav_items">
          <NavLink
            to="/house-cook"
            className={`${
              isActive("/house-cook") ? "active-link" : "menu-title"
            }`}
          >
            <img
              src={housecook}
              alt="housecook"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Household Cook
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to="/party-cook"
            className={`${
              isActive("/party-cook") ? "active-link" : "menu-title"
            }`}
          >
            <img
              src={partycook}
              alt="partycook"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Party Cook
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to="/chef"
            className={`${isActive("/chef") ? "active-link" : "menu-title"}`}
          >
            <img
              src={chef}
              alt="chef"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Chef
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to="/catering"
            className={`${
              isActive("/catering") ? "active-link" : "menu-title"
            }`}
          >
            <img
              src={catering}
              alt="catering"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Catering
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to="/employer"
            className={`${
              isActive("/employer") ? "active-link" : "menu-title"
            }`}
          >
            <img
              src={employer}
              alt="employer"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Employer
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to="/payments"
            className={`${
              isActive("/payments") ? "active-link" : "menu-title"
            }`}
          >
            <img
              src={sales}
              alt="employer"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Sales
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to="/jobs"
            className={`${isActive("/jobs") ? "active-link" : "menu-title"}`}
          >
            <img
              src={jobs}
              alt="jobs"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Jobs
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to="/events"
            className={`${isActive("/events") ? "active-link" : "menu-title"}`}
          >
            <img
              src={events}
              alt="jobs"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Events
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to=""
            className="menu-title"
            onClick={(e) => toggleCollapse("plans", e)}
          >
            <img
              src={plan}
              alt="plan"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Plan
            {expand.plans ? (
              <BiChevronUp style={{ marginLeft: "90px" }} />
            ) : (
              <BiChevronDown style={{ marginLeft: "90px" }} />
            )}
          </NavLink>
        </li>

        {expand.plans && (
          <div className="ml-5">
            <Link
              to="/employerplan"
              className={`${
                isActive("/employerplan")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Employer Plan
            </Link>
            <br />
            <Link
              to="/clientplan"
              className={`${
                isActive("/clientplan") ? "active-link-link" : "menu-title-link"
              }`}
            >
              Client Plan
            </Link>
            <br />
            <Link
              to="/cookplan"
              className={`${
                isActive("/cookplan") ? "active-link-link" : "menu-title-link"
              }`}
            >
              Cook Plan
            </Link>
          </div>
        )}

        <li className="nav_items">
          <NavLink
            to="/cse"
            className={`${isActive("/cse") ? "active-link" : "menu-title"}`}
          >
            <img
              src={cse}
              alt="jobs"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            CSE
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to=""
            className="menu-title"
            onClick={(e) => toggleCollapse("activity", e)}
          >
            <BsActivity
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Activity
            {expand.activity ? (
              <BiChevronUp style={{ marginLeft: "70px" }} />
            ) : (
              <BiChevronDown style={{ marginLeft: "70px" }} />
            )}
          </NavLink>
        </li>

        {expand.activity && (
          <div className="ml-5">
            <Link
              to="/all-user-activity"
              className={`${
                isActive("/all-user-activity")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              User Signup
            </Link>

            <br />
            <Link
              to="/employer-activity"
              className={`${
                isActive("/employer-activity")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Employer Activity
            </Link>

            <br />
            <Link
              to="/cook-activity"
              className={`${
                isActive("/cook-activity")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Cook Activity
            </Link>
          </div>
        )}

        <li className="nav_items">
          <NavLink
            to=""
            className="menu-title"
            onClick={(e) => toggleCollapse("request", e)}
          >
            <MdOutlineDelete
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Delete Request
            {expand.request ? (
              <BiChevronUp style={{ marginLeft: "15px" }} />
            ) : (
              <BiChevronDown style={{ marginLeft: "15px" }} />
            )}
          </NavLink>
        </li>

        {expand.request && (
          <div className="ml-5">
            <Link
              to="/employer-delete-request"
              className={`${
                isActive("/employer-delete-request")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Employer Request
            </Link>

            <br />
            <Link
              to="/cook-delete-request"
              className={`${
                isActive("/cook-delete-request")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Cook Request
            </Link>
          </div>
        )}

        <li className="nav_items">
          <NavLink
            to=""
            className="menu-title"
            onClick={(e) => toggleCollapse("report", e)}
          >
            <MdReportGmailerrorred
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            User Flag List
            {expand.report ? (
              <BiChevronUp style={{ marginLeft: "25px" }} />
            ) : (
              <BiChevronDown style={{ marginLeft: "25px" }} />
            )}
          </NavLink>
        </li>

        {expand.report && (
          <div className="ml-5">
            <Link
              to="/employer-report-list"
              className={`${
                isActive("/employer-report-list")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Employer Flag
            </Link>

            <br />
            <Link
              to="/cook-report-list"
              className={`${
                isActive("/cook-report-list")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Cook Flag
            </Link>
          </div>
        )}

        <li className="nav_items">
          <NavLink
            to=""
            className="menu-title"
            onClick={(e) => toggleCollapse("blocked", e)}
          >
            <MdBlock
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Blocked User
            {expand.blocked ? (
              <BiChevronUp style={{ marginLeft: "30px" }} />
            ) : (
              <BiChevronDown style={{ marginLeft: "30px" }} />
            )}
          </NavLink>
        </li>

        {expand.blocked && (
          <div className="ml-5">
            <Link
              to="/blocked-employer"
              className={`${
                isActive("/blocked-employer")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Employer
            </Link>

            <br />
            <Link
              to="/blocked-cooks"
              className={`${
                isActive("/blocked-cooks")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Cook
            </Link>
          </div>
        )}

        <li className="nav_items">
          <NavLink
            to="/testimonial"
            className={`${
              isActive("/testimonial") ? "active-link" : "menu-title"
            }`}
          >
            <img
              src={events}
              alt="jobs"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Testimonial
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to="/advertisements"
            className={`${
              isActive("/advertisements") ? "active-link" : "menu-title"
            }`}
          >
            <img
              src={campaign}
              alt="jobs"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Advertisements
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to=""
            className="menu-title"
            onClick={(e) => toggleCollapse("dropdownlist", e)}
          >
            <RiDropdownList
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Lists
            {expand.dropdownlist ? (
              <BiChevronUp style={{ marginLeft: "90px" }} />
            ) : (
              <BiChevronDown style={{ marginLeft: "90px" }} />
            )}
          </NavLink>
        </li>

        {expand.dropdownlist && (
          <div className="ml-5">
            <Link
              to="/add-cuisine"
              className={`${
                isActive("/add-cuisine")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Cuisine
            </Link>

            <br />
            <Link
              to="/add-language"
              className={`${
                isActive("/add-language")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Language
            </Link>
            <br />
            <Link
              to="/add-qualification"
              className={`${
                isActive("/add-qualification")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              Qualification
            </Link>

            <br />
            <Link
              to="/add-province"
              className={`${
                isActive("/add-province")
                  ? "active-link-link"
                  : "menu-title-link"
              }`}
            >
              State
            </Link>

            <br />
          </div>
        )}

        <li className="nav_items">
          <NavLink
            to="/blocked-mac-list"
            className={`${
              isActive("/blocked-mac-list") ? "active-link" : "menu-title"
            }`}
          >
            <MdBlock
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Blocked Mac Address
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to="/faq"
            className={`${isActive("/faq") ? "active-link" : "menu-title"}`}
          >
            <FaQuestion
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            FAQ
          </NavLink>
        </li>

        <li className="nav_items">
          <NavLink
            to="/web-enquiry"
            className={`${
              isActive("/web-enquiry") ? "active-link" : "menu-title"
            }`}
          >
            <MdOutlineContactSupport
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Website Enquiry
          </NavLink>
        </li>

        <li className="nav_items">
          <Link
            to="/web-admin"
            onClick={signOut}
            className={`${
              isActive("/web-admin") ? "active-link" : "menu-title"
            }`}
          >
            <img
              src={logout}
              alt="jobs"
              className="me-3"
              style={{ height: "18px", width: "18px" }}
            />
            Logout
          </Link>
        </li>
      </ul>
      ;
    </>
  );
};

export default AdminMenu;
