import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Navbartop from "./navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import Moment from "moment";
import { Link } from "react-router-dom";
import { getApplications } from "../store/slices/jobs";
import ShowSkeleton from "../utils/helpers/Skeleton";
import dog from "../Assets/Images/corgi.png";

function ApplicatiionsList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, settotalData] = useState();
  const [isLoading, setisLoading] = useState(true);
  //   console.log(data);

  // react paginate
  const totalPages = 10;
  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Job List";
  }, []);

  React.useEffect(() => {
    const items = {
      limit: itemsPerPage,
      page: currentPage,
      id: id,
    };
    dispatch(getApplications(items))
      .unwrap()
      .then((data) => {
        setData(data.jobs.data);
        settotalData(data.jobs.totalDataCount);
        setTimeout(() => {
          setisLoading(false);
        }, [1000]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch, currentPage]);

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Applications
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Applications</h4>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : data?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No Data</b> found!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table responsive hover>
                            <thead>
                              <tr>
                                <th className="text-left">Designation</th>
                                <th className="text-left">Cook Name</th>
                                <th className="text-left">Date Applied</th>
                                <th className="text-left">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((job) => (
                                <tr key={job?.id}>
                                  <td className="text-left">
                                    <Link
                                      to={`/JobsDetails/${job?.jobId?.id}`}
                                      style={{
                                        color: "#a020f0",
                                      }}
                                    >
                                      {job?.jobId?.designation?.substring(
                                        0,
                                        20
                                      )}
                                    </Link>
                                  </td>
                                  <td className="text-left">
                                    <Link
                                      to={`/cookdetails/${job?.cookId?.id}`}
                                      style={{
                                        color: "#a020f0",
                                      }}
                                    >
                                      {job?.cookId?.fullName?.substring(0, 25)}
                                    </Link>
                                  </td>

                                  <td className="text-left">
                                    {Moment.utc(job?.createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>

                                  <td className="text-left">
                                    {job?.applicationStatus === "applied" && (
                                      <p
                                        style={{
                                          color: "#929402",

                                          borderRadius: "5px",
                                          width: "70px",
                                          fontSize: "12px",
                                          height: "25px",

                                          backgroundColor: "#FEFFD3",
                                        }}
                                        align="center"
                                      >
                                        Applied
                                      </p>
                                    )}
                                    {job?.applicationStatus === "on Hold" && (
                                      <p
                                        style={{
                                          color: "#000000",

                                          borderRadius: "5px",
                                          width: "70px",
                                          fontSize: "12px",
                                          height: "25px",
                                          backgroundColor: "#FFAF03",
                                        }}
                                        align="center"
                                      >
                                        On Hold
                                      </p>
                                    )}
                                    {job?.applicationStatus ===
                                      "shortlisted" && (
                                      <p
                                        style={{
                                          color: "#0700F5",

                                          borderRadius: "5px",
                                          width: "70px",
                                          fontSize: "12px",
                                          height: "25px",
                                          backgroundColor: "rgb(209 235 250)",
                                        }}
                                        align="center"
                                      >
                                        Shortlisted
                                      </p>
                                    )}
                                    {job?.applicationStatus === "rejected" && (
                                      <p
                                        style={{
                                          color: "#FF0606",

                                          borderRadius: "5px",
                                          width: "70px",
                                          fontSize: "12px",
                                          height: "25px",
                                          backgroundColor: "#FFDDD5",
                                        }}
                                        align="center"
                                      >
                                        Rejected
                                      </p>
                                    )}
                                    {job?.applicationStatus === "hired" && (
                                      <p
                                        style={{
                                          color: "#028D36",

                                          borderRadius: "5px",
                                          width: "70px",
                                          fontSize: "12px",
                                          height: "25px",
                                          backgroundColor: "#e7f8c6",
                                        }}
                                        align="center"
                                      >
                                        Hired
                                      </p>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                      <div className="justify-content-center">
                        <ReactPaginate
                          previousLabel="<"
                          nextLabel=">"
                          breakLabel="..."
                          breakLinkClassName={"page-link"}
                          pageCount={totalData / itemsPerPage}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={pagesToShowInitially}
                          onPageChange={handlePageClick}
                          containerClassName="pagination"
                          activeClassName="active"
                          pageLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          disabledClassName="disabled"
                          initialPage={currentPage - 1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplicatiionsList;
