import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import logo from "../Assets/Images/nav_logo.png";
import { AdminFogotPassword, login } from "../store/slices/auth";
import { useDispatch, useSelector } from "react-redux";

import { VscEye } from "react-icons/vsc";

const Login = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Login";
  }, []);

  const adminForgotPassword = () => {
    dispatch(AdminFogotPassword())
      .unwrap()
      .then((data) => {
        if (data?.users?.message === "OTP sent successfully to email!") {
          alert(data?.users?.message);
          navigate("/admin-change-password");
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  async function Login(e) {
    e.preventDefault();
    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        navigate("/home");
      })
      .catch(({ message }) => {
        alert(message);
      });
  }
  return (
    <div>
      <section className="login-bg-image">
        <div className="bg-overlay-orange" />

        <div className="card-section">
          <div className="d-flex justify-content-around align-items-center">
            <img src={logo} alt="travel-logo" className="div-logo" />

            <div className="h-logn-line" />

            <div className="login-form-2" align="center">
              {/*error && <Alert variant="danger">{error}</Alert>*/}
              <div className="d-flex justify-content-center">
                <img src={logo} alt="logo" className="logo-form-2" />
              </div>
              <h2
                className="text-center mb-4"
                style={{ color: "black", fontWeight: "500" }}
              >
                Login
              </h2>

              <form onSubmit={Login}>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setusername(e.target.value)}
                  className="form-control text-black-ph"
                  placeholder="Username"
                  style={{ height: "50px" }}
                  required
                />
                {/* <p className="alert-message">{alertmobileNo}</p> */}

                <div className="position-r mt-3">
                  <input
                    type={passwordShown ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control text-black-ph"
                    placeholder="Password"
                    style={{ height: "50px" }}
                    required
                  />
                  <VscEye className="eye_icon_login" onClick={togglePassword} />
                </div>
                {/* <p className="alert-message">{alertpass}</p> */}

                <p
                  className="text-end mt-3"
                  onClick={adminForgotPassword}
                  style={{ fontSize: "14px", color: "red", cursor: "pointer" }}
                >
                  Forgot password?
                </p>

                <div className="col-md-12 d-flex justify-content-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-warning login-btn col-white"
                    style={{ cursor: "pointer" }}
                    // onClick={save}
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
