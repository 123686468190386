import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class testimonialService {





   //add testimonial account
   static addtestimonialAccount(item) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios
      .post(API_PATHS.addtestimonial, item, config)
      .then((response) => {
        return response.data;
      });
  }


     //add testimonial account
     static edittestimonialAccount(item) {
      let user = JSON.parse(localStorage.getItem("users"));
      const token = user.data.token;
  
      const config = {
        headers: {
          "x-access-token": token,
        },
      };
  
      return axios
        .put(API_PATHS.edittestimonial, item, config)
        .then((response) => {
          return response.data;
        });
    }
//getalll testimonial details
  static Getalltestimonial(item) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
      data:item
    };

    return axios
      .get(API_PATHS.alltestimonials,  config)
      .then((response) => {
        return response.data;
      });
  }


  //get single  testimonial details
  static GetSingletestimonial(id) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios
      .get(API_PATHS.alltestimonials +"?id="+ id ,  config)
      .then((response) => {
        return response.data;
      });
  }

  
//get blocked  mac addresses
static GetBlockedMacList(item) {
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data.token;
let api =""
api = `${API_PATHS.blockedmaclist}?limit=${item.limit}&page=${item.page}`
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  return axios
    .get(api,  config)
    .then((response) => {
      return response.data;
    });
}

//unblock mac address
static unblockmacAdress(item) {
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data.token;
let api =""
api = `${API_PATHS.unblockmacaddress}`
  const config = {
    headers: {
      "x-access-token": token,
    },
  };

  return axios
    .put(api, item, config)
    .then((response) => {
      return response.data;
    });
}

  static deleteTestimonial(id) {
    let api = '';
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
  
      api = API_PATHS.deletetestimonial + '?id=' + id ;
    localStorage.removeItem("id");
    return axios
        .delete(api,config)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}


}


export default testimonialService;
