import React, { useEffect, useState } from "react";
import Navbartop from "../component/navbar";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Col, Row, Form, Button } from "react-bootstrap";
import { getUsersTrack } from "../store/slices/activity";
import ShowSkeleton from "../utils/helpers/Skeleton";

function Whatsaptrack() {
  useEffect(() => {
    document.title = "CookandChef Admin | Home";
  }, []);
  const [isLoading, setLoading] = useState(false);
  const [allUsers, setallUsers] = useState(false);
  let data = JSON.parse(localStorage.getItem("users"));
  const [error, setError] = useState("");
  const [dateError, setDateError] = useState("");
  const now = new Date();
  const day = now.getDate().toString().padStart(2, "0");
  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Note: Month is zero-based
  const year = now.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;
  const [startDate, setStartDate] = useState(formattedDate);
  const [endDate, setEndDate] = useState(formattedDate);
  const dispatch = useDispatch();
  const [item, setItem] = useState({
    offset: 0,
    limit: 100,
    startDate: startDate,
    endDate: endDate,
  });

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    setItem({
      ...item,
      startDate: e.target.value,
    });
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
    setItem({
      ...item,
      endDate: e.target.value,
    });
  };

  useEffect(() => {
    getusersTrack();
  }, []);

  const getusersTrack = () => {
    dispatch(getUsersTrack(item))
      .unwrap()
      .then((data1) => {
        setallUsers(data1.allusers.data.customers);
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  };
  return (
    <div>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp; Track Users
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Track Users</h4>
                      </div>
                      <div>
                        <Form>
                          <Row className="d-flex flex-row card ps-2 pe-2 justify-content-left ">
                            <Col
                              lg={4}
                              md={6}
                              xs={12}
                              className="d-flex flex-column mb-2"
                            >
                              <Form.Group>
                                <label className="m-1 label">Start Date</label>
                                <Form.Control
                                  name="nameEmailOrMobile"
                                  placeholder="date"
                                  type="date"
                                  value={startDate}
                                  isInvalid={!!dateError}
                                  onChange={handleStartDate}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {dateError}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col
                              lg={4}
                              md={6}
                              xs={12}
                              className="d-flex flex-column mb-2"
                            >
                              <Form.Group>
                                <label className="m-1 label">End Date</label>
                                <Form.Control
                                  name="nameEmailOrMobile"
                                  placeholder="date"
                                  type="date"
                                  value={endDate}
                                  isInvalid={!!dateError}
                                  onChange={handleEndDate}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {dateError}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col
                              lg={4}
                              md={6}
                              xs={12}
                              className="d-flex flex-column mb-2"
                            >
                              <div className="group d-flex justify-content-center mt-4 ">
                                <Button
                                  className="apply_btn ms-2"
                                  onClick={getusersTrack}
                                >
                                  Search
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>

                      {isLoading ? (
                        <Row>{ShowSkeleton()}</Row>
                      ) : !allUsers || allUsers?.length === 0 ? (
                        <Col
                          lg={9}
                          xs={12}
                          md={12}
                          className="card p-3"
                          align="center"
                        >
                          <h5> Data not found</h5>
                          <h6>
                            Change the start date and end date to get data for a
                            different period.{" "}
                          </h6>
                        </Col>
                      ) : (
                        <Row
                          lg={10}
                          style={{
                            // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                            height: "100%",
                          }}
                        >
                          <Col
                            xs={12}
                            lg={4}
                            className="align-items-center justify-content-center m-2"
                            style={{
                              height: "550px",
                              overflowY: "auto",
                              padding: "20px",
                              borderRadius: "10px",
                              backgroundColor: "white",
                              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                            }}
                          >
                            {allUsers?.map((elem) => (
                              <div
                                key={elem.id}
                                className="d-flex align-items-center  mb-2 p-2"
                                style={{
                                  borderRadius: "10px",
                                  width: "100%",
                                  height: "auto",
                                  backgroundColor: "#f2f2f2",
                                  display: "flex",
                                  justifyContent: "center",
                                  // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                  whiteSpace: "nowrap",
                                  padding: "10px",
                                }}
                              >
                                <p
                                  className="text-center mt-2 mx-3"
                                  style={{
                                    cursor: "pointer",
                                    maxWidth: "80%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {elem.phone_number}
                                </p>
                              </div>
                            ))}
                          </Col>
                        </Row>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Whatsaptrack;
