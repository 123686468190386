import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class cookService {
  static getHouseCook(item) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    let api = "";
    api = `${API_PATHS.getHouseCook}`;
    return axios.post(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }
  static getChef(item) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    let api = "";
    api = `${API_PATHS.getChef}`;
    return axios.post(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  //getshortlistedEvents
  static ChangeRolecook(item) {
    let api = "";
    api = API_PATHS.changeRole;
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;
    return axios
      .put(api, item, { headers: { "x-access-token": token } })
      .then((response) => {
        if (response.data) {
          //localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  static getAllCooklist(item) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    let api = "";
    api = API_PATHS.getAllCookList + "?";
    if (item?.limit) {
      api += `limit=${item.limit}&`;
    }

    if (item?.page) {
      api += `page=${item.page}&`;
    }
    if (item?.nameEmailOrMobile) {
      api += `nameEmailOrMobile=${item.nameEmailOrMobile}&`;
    }
    if (item?.longitude) {
      api += `longitude=${item.longitude}&`;
    }
    if (item?.latitude) {
      api += `latitude=${item.latitude}&`;
    }
    return axios.get(api, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static getEmployerDetails(id) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    let api = "";
    api = API_PATHS.getEmployerProfile + "?id=" + id;
    return axios.get(api, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  //admin get roles
  static getRoles() {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    let api = "";
    api = API_PATHS.getroles;
    return axios.get(api, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static editEmployerDetails(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    let id = localStorage.getItem("employerId");
    api = API_PATHS.editemployer;
    return axios.put(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static getPartyCook(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = `${API_PATHS.getPartyCook}`;
    return axios.post(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static getCookDetails(id) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.getcookProfile + "?id=" + id;
    return axios.get(api, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static editCookDetails(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.editcook;
    return axios.put(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static editHouseProfile(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    api = API_PATHS.edithousecook;
    return axios.put(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }
  static editPartyProfile(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.editparty;
    return axios.put(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static editChefProfile(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.editchef;
    return axios.put(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static editCateringProfile(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.editcatering;
    return axios.put(api, item, config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static getCatering(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = `${API_PATHS.getCatering}`;
    return axios.post(api, item, config).then((response) => {
      if (response.data) {
      }

      return response.data;
    });
  }
}

export default cookService;
