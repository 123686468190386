import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FaQuestion } from "react-icons/fa";
import { Link, useNavigate, NavLink } from "react-router-dom";
import logo from "../Assets/Images/nav_logo.png";
import { FcSettings } from "react-icons/fc";
import { IoPersonCircle } from "react-icons/io5";
import logout from "../Assets/Images/logout.png";
import cse from "../Assets/Images/profile.png";
import sales from "../Assets/Images/plan.png";
import AdminMenu from "./adminmenu";
import CSEMenu from "./csemenu";
import { useEffect } from "react";

const Navbartop = () => {
  const dispatch = useDispatch();
  let data = JSON.parse(localStorage.getItem("users"));
  let role = data.data.roleId.roleName;

  const [submenu, setSubmenu] = useState(false);
  const open = () => {
    document.getElementById("side").classList.toggle("show");
  };

  const drop = () => {
    document.getElementById("usermenu").classList.toggle("showuser");
  };

  const navigate = useNavigate();
  function signOut(e) {
    localStorage.setItem("logout", Date.now().toString());

    navigate("/login");
    localStorage.clear();
  }

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "logout") {
        // Perform logout operations in this tab
        // For example, redirect to the login page
        window.location.href = "/cnc-members";
      }
    };

    // Attach the event listener
    window.addEventListener("storage", handleStorageChange);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const toggleSubMenu = () => {
    setSubmenu(!submenu);
  };

  return (
    <div>
      <nav className="top_nav">
        <FcSettings className="setting animate-rotate " onClick={open} />
        <IoPersonCircle className="user-icon" onClick={drop} />
      </nav>

      <nav className="sidebar" id="side">
        <NavLink to="/home">
          <div className="d-flex justify-content-center logo-section">
            <img src={logo} alt="cookandchef" />
          </div>
        </NavLink>
        {role === "admin" && <AdminMenu />}
        {role === "Executive" && <CSEMenu />}
        {role === "Manager" && <CSEMenu />}
        {role === "Associate" && <CSEMenu />}
      </nav>
      <div className="drop-user" id="usermenu">
        <ul className="user-p">
          {/* <li className="user-text">
            <NavLink to="/profile">
              <FaBuromobelexperte className="user-icon-style-mod" />
              My Profile
            </NavLink>
          </li> */}

          <li className="user-text">
            <Link to="/cnc-members" onClick={signOut}>
              <img
                src={logout}
                alt="jobs"
                className="me-3"
                style={{ height: "18px", width: "18px" }}
              />
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbartop;
