import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { getClientDetails } from "../store/slices/client";
import { Link, useParams, useNavigate } from "react-router-dom";
import Moment from "moment";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import clientimg from "../../src/Assets/Images/client.png";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { MdOutlineEmail } from "react-icons/md";
import { FaStar, FaRegStar } from "react-icons/fa";
import {
  resetPassword,
  suspendAccount,
  deleteAccount,
  activateAccount,
} from "../store/slices/config";
import { confirmAlert } from "react-confirm-alert";
import ShowprofileSkeleton from "./ProfileSkeleton";
import DataSkeleton from "./dataskeleton";
import { AllowMacs, getAllowedMacs } from "../store/slices/activity";
import ReviewModal from "../utils/helpers/ReviewModal";

export function StarRating1({ value }) {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <span key={index}>
      {index < value ? (
        <FaStar color="#ffc107" style={{ marginRight: "10px" }} />
      ) : (
        <FaRegStar color="grey" style={{ marginRight: "10px" }} />
      )}
    </span>
  ));

  return <div>{stars}</div>;
}

function ClientDetails() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);
    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  };

  useEffect(() => {
    document.title = "CookandChef | Admin - Client Details";
  }, []);

  const { id } = useParams();
  localStorage.setItem("clientId", id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  const [transactionsData, settransactionsData] = useState([]);
  const [requirementsData, setrequirementsData] = useState([]);
  const [jobType, setjobType] = useState();
  const [isLoading, setIsloading] = useState(true);
  const [reviews, setReviews] = useState(false);
  const [reviewModal, setShowReviewModal] = useState(false);

  useEffect(() => {
    if (data && data?.ratingsList) {
      // Check if any rating has a comment
      const hasComment = data?.ratingsList.some((rating) => rating.comment);
      setReviews(hasComment);
    }
  }, [data]);

  React.useEffect(() => {
    dispatch(getClientDetails(id))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
        setLanguages(data.users.data.languages);
        setCuisines(data.users.data.requirementsData[0].cuisines);
        setjobType(data.users.data.requirementsData[0].jobType);
        settransactionsData(data.users.data.transactionsData);
        setrequirementsData(data.users.data.requirementsData);
      })
      .catch(({ message }) => {
        //  alert(message);
      });
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  }, []);

  const [message, setmessage] = useState("Do you want to reset password?");
  const [smessage, setsmessage] = useState(
    "Do you want to suspend this account?"
  );
  const [dmessage, setdmessage] = useState(
    "Do you want to delete this account?"
  );
  const [amessage, setamessage] = useState(
    "Do you want to activate this account?"
  );
  const resetPassword1 = (id, type) => {
    confirmAlert({
      title: "Reset Password",
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: () => resetpassword2(id, type),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const resetpassword2 = (id, type) => {
    const item = { id, type };
    dispatch(resetPassword(item))
      .unwrap()
      .then((data) => {
        alert(data.user.message);
        window.location.reload(true);
      })
      .catch(({ message }) => {});
  };

  const suspendAcc1 = (id, role) => {
    confirmAlert({
      title: "Suspend account",
      message: smessage,
      buttons: [
        {
          label: "Yes",
          onClick: () => suspendAcc2(id, role),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const suspendAcc2 = (id, role) => {
    const item = { id, role };
    dispatch(suspendAccount(item))
      .unwrap()
      .then((data) => {
        alert(data.user.message);
        window.location.reload(true);
      })
      .catch(({ message }) => {});
  };

  const deleteAcc1 = (id, role) => {
    confirmAlert({
      title: "Delete account",
      message: dmessage,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteAcc2(id, role),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteAcc2 = (id, role) => {
    const item = { id, role };
    dispatch(deleteAccount(item))
      .unwrap()
      .then((data) => {
        alert(data.user.message);
        navigate(-1);
      })
      .catch(({ message }) => {});
  };

  const activateAcc1 = (id, role) => {
    confirmAlert({
      title: "Activate account",
      message: amessage,
      buttons: [
        {
          label: "Yes",
          onClick: () => activateAcc2(id, role),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const activateAcc2 = (id, role) => {
    const item = { id, role };
    dispatch(activateAccount(item))
      .unwrap()
      .then((data) => {
        alert(data?.user.message);
        window.location.reload(true);
      })
      .catch(({ message }) => {});
  };

  const AllowMacAdress = (data) => {
    allowMac(data.loginMAC, data.registerMAC);
  };

  const allowMac = (value, value2) => {
    const item = {
      macAddress: value,
    };
    dispatch(AllowMacs(item))
      .unwrap()
      .then((data) => {
        allowMac1(value2);
      })
      .catch(({ message }) => {});
  };

  const allowMac1 = (value) => {
    const item = {
      macAddress: value,
    };
    dispatch(AllowMacs(item))
      .unwrap()
      .then((data) => {
        alert(data?.allusers?.message);
      })
      .catch(({ message }) => {});
  };

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;

  const ShareRequirement = (id1) => {
    navigate(`/share-client-requirement/${id1}`);
    localStorage.setItem("clientId", id);
  };

  return (
    <>
      <div>
        <Navbartop />
        <ReviewModal
          showModal={reviewModal}
          data={data}
          setShowModal={setShowReviewModal}
          role="client"
        />

        <div className="container-fluid pb-5 ms-2 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-2 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;{" "}
                      <Link to="/client">Client</Link> &#8811; Client Details
                    </div>
                    {isLoading ? (
                      <Row>{ShowprofileSkeleton()}</Row>
                    ) : (
                      <div className="card p-2">
                        <Row>
                          <Col xs={4} md={2} lg={2} xl={2}>
                            {data.dp ? (
                              <img
                                src={data.dp}
                                alt=""
                                style={{
                                  height: "90px",
                                  width: "90px",
                                  borderRadius: "20%",
                                }}
                                className="mt-2"
                              />
                            ) : (
                              <img
                                src={clientimg}
                                alt=""
                                style={{
                                  height: "90px",
                                  width: "90px",
                                  borderRadius: "20%",
                                }}
                                className="mt-2"
                              />
                            )}
                          </Col>
                          <Col
                            xs={6}
                            md={4}
                            lg={4}
                            xl={4}
                            align="left"
                            className="ps-2"
                          >
                            <h5 style={{ fontWeight: "bold" }}>
                              {data.fullName}
                            </h5>
                            <p>
                              <HiOutlineDevicePhoneMobile />
                              &nbsp;{data.mobile}
                            </p>
                            {data?.whatsappNumber && (
                              <p>
                                <FaWhatsapp />
                                &nbsp;{data?.whatsappNumber}
                              </p>
                            )}
                            <p>
                              <MdOutlineEmail />
                              &nbsp;{data.email}
                            </p>
                          </Col>
                          <Col
                            className="d-none d-md-block d-lg-block"
                            md={4}
                            lg={4}
                            xl={4}
                            align="left"
                          >
                            <h5 style={{ fontWeight: "bold" }}>
                              {data?.employeeMemberId}
                            </h5>
                            {data?.status === 1 && (
                              <p>
                                Profile Status:{" "}
                                <span style={{ color: "#42f554" }}>Active</span>
                              </p>
                            )}
                            {data?.status === 2 && (
                              <p>
                                Profile:{" "}
                                <span style={{ color: "#f59942" }}>
                                  Disable
                                </span>
                              </p>
                            )}
                            {data?.status === 3 && (
                              <p>
                                Profile:{" "}
                                <span style={{ color: "#d42708" }}>
                                  Suspended
                                </span>
                              </p>
                            )}
                            {data?.status === 4 && (
                              <p>
                                Profile:{" "}
                                <span style={{ color: "#080fd4" }}>
                                  Delete Requested
                                </span>
                              </p>
                            )}
                            <p>
                              Regd. Date:&nbsp;
                              {Moment.utc(data?.createdAt).format("DD-MM-YYYY")}
                            </p>
                            <p>
                              Last Login:&nbsp;
                              {Moment.utc(data?.lastLoginDateTime).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </Col>
                          {role !== "Associate" && (
                            <Col xs={1} md={1} lg={2} align="right">
                              <Dropdown
                                style={{
                                  backgroundColor: "none",
                                }}
                              >
                                <Dropdown.Toggle
                                  // id="dropdown-basic"
                                  style={{
                                    fontSize: "20px",
                                    backgroundColor: "none",
                                    border: "none",
                                    marginTop: "-10px",
                                  }}
                                >
                                  <BiDotsVerticalRounded
                                    style={{
                                      marginLeft: "-5px",
                                      marginTop: "-2px",
                                      color: "#000",
                                    }}
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {role === "admin" && (
                                    <Dropdown.Item
                                      onClick={() => deleteAcc1(id, "employer")}
                                    >
                                      Delete account
                                    </Dropdown.Item>
                                  )}
                                  {role === "Manager" && (
                                    <Dropdown.Item
                                      onClick={() => deleteAcc1(id, "employer")}
                                    >
                                      Delete account
                                    </Dropdown.Item>
                                  )}
                                  {data?.status === 3 && (
                                    <div>
                                      {role === "admin" && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            activateAcc1(id, "employer")
                                          }
                                        >
                                          Activate account
                                        </Dropdown.Item>
                                      )}
                                    </div>
                                  )}

                                  {data?.status !== 3 && (
                                    <div>
                                      {role === "admin" && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            suspendAcc1(id, "employer")
                                          }
                                        >
                                          Deactivate account
                                        </Dropdown.Item>
                                      )}
                                    </div>
                                  )}

                                  <Dropdown.Item
                                    onClick={() =>
                                      resetPassword1(id, "employer")
                                    }
                                  >
                                    Reset password
                                  </Dropdown.Item>
                                  {role === "admin" && (
                                    <Dropdown.Item>
                                      <Link
                                        to={`/assign-client-plan/${id}`}
                                        style={{ color: "#000" }}
                                      >
                                        Add Plan
                                      </Link>
                                    </Dropdown.Item>
                                  )}

                                  {(role === "admin" || role === "Manager") && (
                                    <Dropdown.Item>
                                      <Link
                                        to={`/post-event/${id}`}
                                        style={{ color: "#000" }}
                                      >
                                        Post Event
                                      </Link>
                                    </Dropdown.Item>
                                  )}

                                  {role === "admin" && (
                                    <Dropdown.Item>
                                      <div
                                        onClick={() => AllowMacAdress(data)}
                                        style={{ color: "#000" }}
                                      >
                                        Allow MAC Address
                                      </div>
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          )}
                          <Col
                            className="d-sm-block d-md-none d-lg-none"
                            sm={8}
                            align="left"
                          >
                            <h5 style={{ fontWeight: "bold" }}>
                              {data?.employeeMemberId}
                            </h5>
                            {data?.status === 1 && <p>Profile: Active</p>}
                            <p>
                              Regd. Date:
                              {Moment.utc(data?.createdAt).format("DD-MM-YYYY")}
                              <br />
                              Last Login:
                              {Moment.utc(data?.lastLoginDateTime).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    )}
                    {isLoading ? (
                      <Row>{DataSkeleton()}</Row>
                    ) : (
                      <Tabs
                        defaultActiveKey="profile"
                        id="justify-tab-example"
                        justify
                      >
                        <Tab eventKey="profile" title="Profile">
                          <Row className="card pb-3">
                            <Row>
                              <Col xs={12} md={12} lg={6} className="ps-4 mt-2">
                                <div align="right">
                                  {role !== "Associate" && (
                                    <Link
                                      to={`/editclient/${id}`}
                                      style={{
                                        color: "#ff5c09",
                                        fontWeight: "600",
                                        borderBottom: "1px solid #ff5c09",
                                      }}
                                    >
                                      Edit
                                    </Link>
                                  )}
                                </div>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">DOB</p>
                                  {data.dob && (
                                    <p className="text-details">
                                      {Moment.utc(data.dob).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Gender</p>
                                  {data.gender === 1 && (
                                    <p className="text-details">Male</p>
                                  )}
                                  {data.gender === 2 && (
                                    <p className="text-details">Female</p>
                                  )}
                                  {data.gender === 3 && (
                                    <p className="text-details">Other</p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Email</p>
                                  <p className="text-details">{data.email}</p>
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">
                                    Whatsapp Number
                                  </p>
                                  {data.whatsappNumber && (
                                    <p className="text-details">
                                      {data.whatsappNumber}
                                    </p>
                                  )}
                                </Col>

                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Occupation</p>
                                  {data.occupation && (
                                    <p className="text-details">
                                      {data.occupation}
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">
                                    Languages Known
                                  </p>
                                  <Row
                                    style={{
                                      marginTop: "-15px",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    {languages &&
                                      languages.map((languageVal, index) => (
                                        <Col
                                          xs={5}
                                          sm={5}
                                          md={4}
                                          lg={3}
                                          xl={3}
                                          className="me-2"
                                          style={{
                                            backgroundColor: "#ffffff",
                                            borderRadius: "10px",
                                            color: "black",
                                            padding: "4px 8px",
                                            margin: "8px 0px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                            fontSize: "16px",
                                            marginRight: "4px",
                                            border: "solid 1px #ff5c09",
                                            width: "auto",
                                          }}
                                        >
                                          {languageVal}
                                        </Col>
                                      ))}
                                  </Row>
                                </Col>
                              </Col>
                              <Col xs={12} md={12} lg={6} className="ps-4 mt-2">
                                <div align="right">
                                  {role !== "Associate" && (
                                    <Link
                                      to={`/editclientcontactdetails/${id}`}
                                      style={{
                                        color: "#ff5c09",
                                        fontWeight: "600",
                                        borderBottom: "1px solid #ff5c09",
                                      }}
                                    >
                                      Edit
                                    </Link>
                                  )}
                                </div>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Address Line 1</p>
                                  {data.addressLine1 && (
                                    <p className="text-details">
                                      {data.addressLine1}
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Address Line 2</p>
                                  {data.addressLine2 && (
                                    <p className="text-details">
                                      {data.addressLine2}
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Area</p>
                                  {data.area && (
                                    <p className="text-details">{data.area}</p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">City</p>
                                  {data.cityName && (
                                    <p className="text-details">
                                      {data.cityName}
                                    </p>
                                  )}
                                </Col>

                                <Col
                                  xs={12}
                                  md={12}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">State</p>
                                  {data.provinceName && (
                                    <p className="text-details">
                                      {data.provinceName}
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Pincode</p>
                                  {data.pincode && (
                                    <p className="text-details">
                                      {data.pincode}
                                    </p>
                                  )}
                                </Col>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={5}
                                  className="ps-4 mt-2"
                                >
                                  <p className="text-heading">Landmark</p>
                                  {data.landmark && (
                                    <p className="text-details">
                                      {data.landmark}
                                    </p>
                                  )}
                                </Col>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col
                                lg={12}
                                style={{
                                  borderTop: "solid 1px silver",
                                }}
                                className="p-4 ms-3"
                              >
                                <h5 style={{ color: "#ff5c09" }}>Rating</h5>
                                {data?.ratingsData?.behaviour?.ratingAvg ===
                                  0 &&
                                data?.ratingsData?.facilities?.ratingAvg ===
                                  0 &&
                                data?.ratingsData?.salary?.ratingAvg === 0 &&
                                data?.ratingsData?.workculture?.ratingAvg ===
                                  0 ? (
                                  <div>
                                    <div className="text-left">
                                      Not rated yet!
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div className="d-flex">
                                      <div>
                                        <div style={{ marginTop: "10px" }}>
                                          Behaviour :
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                          Facilities :
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                          Salary & increment :
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                          Work culture :
                                        </div>
                                      </div>
                                      <div>
                                        <div
                                          style={{
                                            marginLeft: "30px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <StarRating1
                                            value={Math.ceil(
                                              data?.ratingsData?.behaviour
                                                ?.ratingAvg
                                            )}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: "30px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <StarRating1
                                            value={Math.ceil(
                                              data?.ratingsData?.facilities
                                                ?.ratingAvg
                                            )}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: "30px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <StarRating1
                                            value={Math.ceil(
                                              data?.ratingsData?.salary
                                                ?.ratingAvg
                                            )}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: "30px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <StarRating1
                                            value={Math.ceil(
                                              data?.ratingsData?.workculture
                                                ?.ratingAvg
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <br />

                                    {reviews && (
                                      <Link
                                        className="text-start mt-5"
                                        style={{
                                          color: "#ff5c09",
                                          textDecoration: "underline",
                                          fontWeight: "600",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setShowReviewModal(true)}
                                      >
                                        <u>View Reviews</u>
                                      </Link>
                                    )}
                                  </>
                                )}
                              </Col>
                            </Row>
                          </Row>
                        </Tab>

                        <Tab eventKey="requirement" title="Requirement">
                          <Row className="card pb-3">
                            {requirementsData.length !== 0 ? (
                              <Row>
                                <Col
                                  lg={11}
                                  xs={10}
                                  md={11}
                                  align="right"
                                  className="mt-3"
                                >
                                  {requirementsData[0]?.status === 1 && (
                                    <span
                                      style={{
                                        color: "#07A64D",
                                        fontSize: "14px",
                                        padding: "5px 12px",
                                        borderRadius: "5px",
                                        width: "80px",
                                        background: "#E3FCEE",
                                        marginRight: "-20px",
                                      }}
                                    >
                                      Active
                                    </span>
                                  )}
                                  {(requirementsData[0]?.status === 0 ||
                                    requirementsData[0]?.status === 2) && (
                                    <span
                                      style={{
                                        color: "#B0A111",
                                        marginRight: "-20px",
                                        fontSize: "14px",
                                        padding: "5px 7px",
                                        borderRadius: "5px",
                                        width: "80px",
                                        background: "#F9EE8A",
                                      }}
                                    >
                                      Paused
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  lg={1}
                                  xs={1}
                                  md={1}
                                  align="right"
                                  className="mt-3"
                                >
                                  {role !== "Associate" && (
                                    <Dropdown
                                      style={{
                                        backgroundColor: "none",
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        // id="dropdown-basic"
                                        style={{
                                          fontSize: "20px",
                                          backgroundColor: "none",
                                          border: "none",
                                          marginTop: "-5px",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        <BiDotsVerticalRounded
                                          style={{
                                            marginLeft: "-5px",
                                            marginTop: "-2px",
                                            color: "#000",
                                          }}
                                        />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <Link
                                            to={`/editrequirement/${id}`}
                                            style={{ color: "#000" }}
                                          >
                                            Edit
                                          </Link>
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          onClick={() =>
                                            ShareRequirement(
                                              data.requirementsData[0].id
                                            )
                                          }
                                        >
                                          Share cook profiles
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  )}
                                </Col>

                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="ps-4 mt-2"
                                >
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Job Type</p>
                                    <p className="text-details">
                                      {requirementsData[0].jobType === 1 && (
                                        <p className="text-details">
                                          Part Time
                                        </p>
                                      )}
                                      {requirementsData[0].jobType === 2 && (
                                        <p className="text-details">
                                          Full Time
                                        </p>
                                      )}
                                      {requirementsData[0].jobType === 3 && (
                                        <p className="text-details">Any</p>
                                      )}
                                    </p>
                                  </Col>

                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Cuisines</p>
                                    <Row
                                      style={{
                                        marginTop: "-15px",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      {cuisines &&
                                        cuisines.map((cuisineVal, index) => (
                                          <Col
                                            xs={5}
                                            sm={5}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            className="me-2"
                                            style={{
                                              backgroundColor: "#ffffff",
                                              borderRadius: "10px",
                                              color: "black",
                                              padding: "4px 8px",
                                              margin: "8px 0px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              textAlign: "center",
                                              fontSize: "16px",
                                              marginRight: "4px",
                                              border: "solid 1px #ff5c09",
                                              width: "auto",
                                            }}
                                          >
                                            {cuisineVal}
                                          </Col>
                                        ))}
                                    </Row>
                                  </Col>

                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Urgency</p>
                                    <p className="text-details">
                                      {data.requirementsData[0].urgency}
                                    </p>
                                  </Col>
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Vessel Wash</p>
                                    <p className="text-details">
                                      {data.requirementsData[0].vesselWash ===
                                        1 && (
                                        <p className="text-details">Yes</p>
                                      )}
                                      {data.requirementsData[0].vesselWash ===
                                        0 && <p className="text-details">No</p>}
                                    </p>
                                  </Col>
                                </Col>
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="ps-4 mt-2"
                                >
                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">
                                      Preferred Gender
                                    </p>
                                    <p className="text-details">
                                      {data.requirementsData[0]
                                        .preferredGender === 1 && (
                                        <p className="text-details">Male</p>
                                      )}
                                      {data.requirementsData[0]
                                        .preferredGender === 2 && (
                                        <p className="text-details">Female</p>
                                      )}
                                      {data.requirementsData[0]
                                        .preferredGender === 3 && (
                                        <p className="text-details">Any</p>
                                      )}
                                    </p>
                                  </Col>

                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Meal Type</p>
                                    <Row
                                      style={{
                                        marginTop: "-15px",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      {data.requirementsData[0].breakfast ===
                                        1 && (
                                        <Col
                                          xs={4}
                                          sm={4}
                                          md={4}
                                          lg={3}
                                          xl={3}
                                          className="me-2"
                                          style={{
                                            backgroundColor: "#ffffff",
                                            borderRadius: "10px",
                                            color: "black",
                                            padding: "4px 8px",
                                            margin: "8px 0px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                            fontSize: "16px",
                                            marginRight: "4px",
                                            border: "solid 1px #ff5c09",
                                            width: "auto",
                                          }}
                                        >
                                          Breakfast
                                        </Col>
                                      )}
                                      {data.requirementsData[0].lunch === 1 && (
                                        <Col
                                          xs={4}
                                          sm={4}
                                          md={4}
                                          lg={3}
                                          xl={3}
                                          className="me-2"
                                          style={{
                                            backgroundColor: "#ffffff",
                                            borderRadius: "10px",
                                            color: "black",
                                            padding: "4px 8px",
                                            margin: "8px 0px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                            fontSize: "16px",
                                            marginRight: "4px",
                                            border: "solid 1px #ff5c09",
                                            width: "auto",
                                          }}
                                        >
                                          Lunch
                                        </Col>
                                      )}
                                      {data.requirementsData[0].dinner ===
                                        1 && (
                                        <Col
                                          xs={4}
                                          sm={4}
                                          md={4}
                                          lg={3}
                                          xl={3}
                                          className="me-2"
                                          style={{
                                            backgroundColor: "#ffffff",
                                            borderRadius: "10px",
                                            color: "black",
                                            padding: "4px 8px",
                                            margin: "8px 0px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                            fontSize: "16px",
                                            marginRight: "4px",
                                            border: "solid 1px #ff5c09",
                                            width: "auto",
                                          }}
                                        >
                                          Dinner
                                        </Col>
                                      )}
                                    </Row>
                                  </Col>

                                  <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="ps-4 mt-2"
                                  >
                                    <p className="text-heading">Min. Payment</p>
                                    <p className="text-details">
                                      {data.requirementsData[0].minimumPayment}
                                    </p>
                                  </Col>
                                </Col>
                              </Row>
                            ) : (
                              <Row>
                                <Col md={12} align="center" className="p-5">
                                  <h5>Requirement has not updated yet!</h5>
                                </Col>
                              </Row>
                            )}
                          </Row>
                        </Tab>
                        <Tab eventKey="plan" title="Plan">
                          <Row className="card p-3 table-responsive">
                            <Table responsive hover>
                              <thead>
                                <tr>
                                  <th className="text-left">
                                    Date of Purchase
                                  </th>
                                  <th className="text-center">Plan Name</th>
                                  <th className="text-center">Plan Price</th>

                                  <th className="text-center">Expiry</th>
                                  <th className="text-center">Validity</th>
                                  <th className="text-center">Status</th>
                                </tr>
                              </thead>

                              <tbody>
                                {data.freePointsData?.map(
                                  (freePointsDataVal, index) => (
                                    <>
                                      <tr>
                                        <td className="text-left">
                                          {Moment.utc(
                                            freePointsDataVal.planStartsAt
                                          ).format("DD-MM-YYYY")}
                                        </td>
                                        <td className="text-center">
                                          <Link
                                            onClick={(event) =>
                                              handleEpandRow(
                                                event,
                                                freePointsDataVal.id
                                              )
                                            }
                                          >
                                            Welcome Bonus
                                          </Link>
                                        </td>
                                        <td className="text-center">0</td>

                                        <td className="text-center">
                                          {Moment.utc(
                                            freePointsDataVal.planExpiresAt
                                          ).format("DD-MM-YYYY")}
                                        </td>
                                        <td className="text-center">180</td>
                                        <td className="text-center">
                                          <p
                                            style={{
                                              background: "#b9ebae",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            Active
                                          </p>
                                        </td>
                                      </tr>

                                      <>
                                        {expandedRows.includes(
                                          freePointsDataVal.id
                                        ) ? (
                                          <tr
                                            style={{
                                              backgroundColor: "#fff",
                                              padding: "10px",
                                              borderTop: "none",
                                            }}
                                          >
                                            <td
                                              className="text-center"
                                              // colspan="2"
                                            >
                                              House Cook Points
                                              <br />
                                              {
                                                freePointsDataVal?.currentHouseCookPoints
                                              }
                                              &nbsp; /{" "}
                                              {
                                                freePointsDataVal?.totalHouseCookPoints
                                              }
                                            </td>
                                            <td
                                              className="text-center"
                                              // colspan="2"
                                            >
                                              Party Cook/ Catering Points
                                              <br />
                                              {
                                                freePointsDataVal.currentPartyCateringPoints
                                              }
                                              &nbsp; /{" "}
                                              {
                                                freePointsDataVal.totalPartyCateringPoints
                                              }
                                            </td>
                                            <td className="text-center">
                                              Event Points
                                              <br />
                                              {
                                                freePointsDataVal.currentEventPoints
                                              }
                                              &nbsp; /{" "}
                                              {
                                                freePointsDataVal.totalEventPoints
                                              }
                                            </td>
                                            <td className="text-center">
                                              Support Assistance
                                              <br />
                                              No
                                            </td>
                                            <td className="text-center">
                                              Refund Policy
                                              <br /> NA
                                            </td>
                                            <td className="text-center">
                                              Discount
                                              <br /> 0
                                            </td>
                                          </tr>
                                        ) : null}
                                      </>
                                    </>
                                  )
                                )}
                              </tbody>

                              <tbody>
                                {data.transactionsData?.map(
                                  (transactionVal, index) => (
                                    <>
                                      <tr>
                                        <td className="text-left">
                                          {Moment.utc(
                                            transactionVal.clientPointsId
                                              .planStartsAt
                                          ).format("DD-MM-YYYY")}
                                        </td>
                                        <td className="text-center">
                                          <Link
                                            onClick={(event) =>
                                              handleEpandRow(
                                                event,
                                                transactionVal.id
                                              )
                                            }
                                          >
                                            {
                                              transactionVal.clientPlanId
                                                .clientPlanName
                                            }
                                            <br />
                                            {transactionVal?.isSelfPayment ===
                                              false && (
                                              <span style={{ color: "#000" }}>
                                                (Added by Admin)
                                              </span>
                                            )}
                                          </Link>
                                        </td>
                                        <td className="text-center">
                                          {transactionVal.clientPlanId.price}
                                        </td>

                                        <td className="text-center">
                                          {Moment.utc(
                                            transactionVal.clientPointsId
                                              .planExpiresAt
                                          ).format("DD-MM-YYYY")}
                                        </td>
                                        <td className="text-center">
                                          {
                                            transactionVal.clientPlanId
                                              .validityInDays
                                          }
                                        </td>
                                        <td className="text-center">
                                          <p
                                            style={{
                                              background: "#b9ebae",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            Active
                                          </p>
                                          {/* <Dropdown
                                          style={{
                                            backgroundColor: "none",
                                          }}
                                        >
                                          <Dropdown.Toggle
                                            // id="dropdown-basic"
                                            style={{
                                              fontSize: "20px",
                                              backgroundColor: "none",
                                              border: "none",
                                              marginTop: "-10px",
                                            }}
                                          >
                                            <BiDotsVerticalRounded
                                              style={{
                                                marginLeft: "-5px",
                                                marginTop: "-2px",
                                                color: "#000",
                                              }}
                                            />
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            {role === "admin" && (
                                              <Dropdown.Item
                                                onClick={() =>
                                                  deleteAcc1(id, "employer")
                                                }
                                              >
                                                Cancel plan
                                              </Dropdown.Item>
                                            )}
                                            {role === "Manager" && (
                                              <Dropdown.Item
                                                onClick={() =>
                                                  deleteAcc1(id, "employer")
                                                }
                                              >
                                                Cancel plan
                                              </Dropdown.Item>
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown> */}
                                        </td>
                                      </tr>
                                      <>
                                        {expandedRows.includes(
                                          transactionVal.id
                                        ) ? (
                                          <tr
                                            style={{
                                              backgroundColor: "#fff",
                                              padding: "10px",
                                            }}
                                          >
                                            <td
                                              className="text-left"
                                              // colspan="2"
                                            >
                                              House Cook Points
                                              <br />
                                              {
                                                transactionVal.clientPointsId
                                                  .currentHouseCookPoints
                                              }
                                              &nbsp; /{" "}
                                              {
                                                transactionVal.clientPlanId
                                                  .houseCookPoints
                                              }
                                            </td>
                                            <td
                                              className="text-center"
                                              // colspan="2"
                                            >
                                              Party Cook/ Catering Points
                                              <br />
                                              {
                                                transactionVal.clientPointsId
                                                  .currentPartyCateringPoints
                                              }
                                              &nbsp; /{" "}
                                              {
                                                transactionVal.clientPlanId
                                                  .partyCateringPoints
                                              }
                                            </td>
                                            <td className="text-center">
                                              Event Points
                                              <br />
                                              {
                                                transactionVal.clientPointsId
                                                  .currentEventPoints
                                              }
                                              &nbsp; /{" "}
                                              {
                                                transactionVal.clientPlanId
                                                  .eventPoints
                                              }
                                            </td>
                                            <td className="text-center">
                                              Support Assistance
                                              <br />
                                              {transactionVal.clientPlanId
                                                .supportAssistance === 1 && (
                                                <p>Yes</p>
                                              )}
                                              {transactionVal.clientPlanId
                                                .supportAssistance === 0 && (
                                                <p>No</p>
                                              )}
                                            </td>
                                            <td className="text-center">
                                              Refund Policy
                                              <br />
                                              {transactionVal.clientPlanId
                                                .refundPolicy === 1 && (
                                                <p>Yes</p>
                                              )}
                                              {transactionVal.clientPlanId
                                                .refundPolicy === 0 && (
                                                <p>No</p>
                                              )}
                                            </td>
                                            <td className="text-center">
                                              Discount
                                              <br />
                                              {transactionVal.discount}
                                            </td>
                                          </tr>
                                        ) : null}
                                      </>
                                    </>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Row>
                        </Tab>
                      </Tabs>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientDetails;
