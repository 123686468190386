import React, { useEffect, useState } from "react";
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { useDispatch } from "react-redux";

const ExportCSVButton = ({ limit, filename, getData,item }) => {
const [data,setData] = useState()
const dispatch = useDispatch()

const getAllData = async () => {
  const { page, ...rest } = item;

  const item1 = {
    ...rest,
    limit: limit
  };

  try {
   
    // Use async/await to wait for the promise to resolve
    const response = await dispatch(getData(item1)).unwrap();
    return response.users.data;
    
  } catch (error) {
    // Handle the error, show a message, etc.
    console.error("Error getting data:", error);
    throw error; // Rethrow the error to propagate it further if needed
  }
};


 const  headers = [
    { label: "Name", key: "fullName" },
    { label: "Mobile", key: "mobile" },
    { label: "Email", key: "email" },
    { label: "Area", key: "area" },
    { label: "City", key: "cityName" },
    { label: "State", key: "provinceName" },
    { label: "Pin", key: "pincode" },
    { label: "Date of Regd", key: "createdAt" },
    { label: "Last Login", key: "updatedAt" },
    // Add more headers as needed
  ];

  const buttonStyle = {
    // backgroundColor: "#ff5c09",
    color: "#ff5c09",
    borderBottom: "1px solid #ff5c09",

    width: "100px",
    textAlign: "center",
    height: "33px",
    fontSize: "14px",
    paddingTop: "5px",
    cursor: "pointer", // Add cursor style for better user experience
  };
  const generateExcelAndDownload = async () => {
    try {
      // Wait for getAllData to complete and get the data
      const data = await getAllData();
  
      const workbook = await XlsxPopulate.fromBlankAsync();
      const sheet = workbook.sheet(0);
  
      // Add headers to the first row
      headers.forEach((header, columnIndex) => {
        sheet.cell(1, columnIndex + 1).value(header.label);
      });
  
      // Add data to the sheet
      data.forEach((item, rowIndex) => {
        headers.forEach((header, columnIndex) => {
          sheet.cell(rowIndex + 2, columnIndex + 1).value(item[header.key]);
        });
      });
  
      // Save the workbook to a blob
      const blob = await workbook.outputAsync({ type: "blob" });
  
      // Create a download link
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
  
      // Trigger a click to initiate the download
      a.click();
    } catch (error) {
      console.error("Error generating Excel file:", error);
      // Handle the error, show a message, etc.
    }
  };
  
  return (
    <div onClick={generateExcelAndDownload}>
      <u style={{ color: "#ff5c09", cursor: "pointer" }}>Download</u>
    </div>
  );
};

export default ExportCSVButton;
