import React, { useEffect, useState } from "react";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { getClientPlan, DeleteClientPlan } from "../store/slices/plan";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import ShowSkeleton from "../utils/helpers/Skeleton";
import dog from "../Assets/Images/corgi.png";

function ClientPlan() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  React.useEffect(() => {
    dispatch(getClientPlan())
      .unwrap()
      .then((data) => {
        setData(data.users.data);
        setTimeout(() => {
          setisLoading(false);
        }, [1000]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  useEffect(() => {
    document.title = "CookandChef Admin | Client Plan";
  }, []);

  const Delete = async function deletePlan(id) {
    localStorage.setItem("clientplanId", id);
    dispatch(DeleteClientPlan(id))
      .unwrap()
      .then(() => {
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });

    console.log(id);
  };

  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete this plan?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Client Plan
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Client Plan</h4>
                        &nbsp;&nbsp;
                        {role === "admin" && (
                          <Button
                            onClick={() => navigate("/add-client-plan")}
                            style={{ height: "25px" }}
                            className="btn-delete mt-2"
                          >
                            Add plan
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : data?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No Data</b> found!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table
                            responsive
                            hover
                            style={{ overflowX: "scroll" }}
                          >
                            <thead>
                              <tr>
                                <th className="text-left">Plan Name</th>
                                <th className="text-left">Price</th>
                                <th className="text-left">Validity</th>
                                <th className="text-left">Event Post</th>
                                <th className="text-left">House Cook View</th>
                                <th className="text-left">
                                  Party Cook/ <br />
                                  Catering view
                                </th>
                                {role === "admin" && (
                                  <th className="text-center">Action</th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((clientplanVal, index) => (
                                <tr key={index}>
                                  <td className="text-left">
                                    {clientplanVal.clientPlanName}
                                  </td>
                                  <td
                                    className="text-left"
                                    style={{ width: "200px" }}
                                  >
                                    Rs. {clientplanVal.price}
                                  </td>

                                  <td className="text-left">
                                    {clientplanVal.validityInDays} Days
                                  </td>
                                  <td className="text-left">
                                    {clientplanVal.eventPoints}
                                  </td>
                                  <td className="text-left">
                                    {clientplanVal.houseCookPoints}
                                  </td>
                                  <td className="text-left">
                                    {clientplanVal.partyCateringPoints}
                                  </td>

                                  {role === "admin" && (
                                    <td style={{ textAlign: "center" }}>
                                      <Link
                                        to={`/editclientplan/${
                                          clientplanVal._id
                                            ? clientplanVal._id
                                            : null
                                        }`}
                                        className="btn bg-purple col-white"
                                        style={{ height: "25px" }}
                                      >
                                        Edit
                                      </Link>
                                      &nbsp;&nbsp;
                                      <button
                                        onClick={() =>
                                          deleteData(clientplanVal._id)
                                        }
                                        className="btn btn-delete col-white"
                                        style={{ height: "25px" }}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientPlan;
