import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import faqService from "../../services/faq.service";
import AuthService from "../../services/auth.service";



//get single faqaccount
export const getsinglefaq= createAsyncThunk(
    "faq/getsingleTestimonial",
    async (id,thunkAPI) => {
        try {
            const data = await faqService.getsinglefaq(id);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const getAllfaq= createAsyncThunk(
    "faq/getAlltestimonial",
    async (item,thunkAPI) => {
        try {
            const data = await faqService.gellallFaq(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const addfaq= createAsyncThunk(
    "faq/addTestimonial",
    async (item,thunkAPI) => {
        try {
            const data = await faqService.addFaq(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//edit faqaccount
export const editfaq= createAsyncThunk(
    "faq/editTestimonial",
    async (item,thunkAPI) => {
        try {
            const data = await faqService.editfaq(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const Deletefaq= createAsyncThunk(
    "testimonial/delete",
    async(id,thunkAPI) => {
        try {
            const data = await faqService.deletefaq(id);
            return { user: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
  );
  


const initialState = {
    loading: false,
    error: "",
    users: AuthService.getusersDetails() || null,
    isLoggedIn: false
}

const testimonialSlice = createSlice({
    name: "testimonial",
    initialState,
    extraReducers: {
        [getsinglefaq.pending]: (state) => {
            state.loading = true
            state.error = ""
            state.users = null
            state.isLoggedIn = false

        },
        [getsinglefaq.fulfilled]: (state, action) => {
            state.loading = true;
            state.error = "";
            state.users = action.payload.users
            state.isLoggedIn = true
        },
        [getsinglefaq.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.users = null;
            state.isLoggedIn = false
        }
    }
})

const { reducer } = testimonialSlice
export default reducer