import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "User Activity",
    },
  },
};

function LineChart({ hourlyActData }) {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "No.of Active Users",
        data: [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });

  useEffect(() => {
    if (hourlyActData) {
      // Extracting keys (time intervals) and values (view counts) from hourlyActData
      const labels = Object.keys(hourlyActData);
      const data = Object.values(hourlyActData);

      // Update chartData state
      setChartData((prevData) => ({
        ...prevData,
        labels: labels,
        datasets: [
          {
            ...prevData.datasets[0],
            data: data,
          },
        ],
      }));
    }else{
      setChartData({labels: [],
      datasets: [
        {
          label: "No.of Active Users",
          data: [],
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],})
    }
  }, [hourlyActData]);

  return <Line options={options} data={chartData} />;
}

export default LineChart;
