import axios from "axios"
import { API_PATHS } from "../utils/constants/api.constants"




class ActivityService {

    static getEmployerActivity(item) {
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
        const config = {
          headers: {
            "x-access-token": token,
          },
       
        };
 
        let api = `${API_PATHS.getUserActivity}?role=${item?.role}&date=${item?.date}&limit=${item.limit}&page=${item.page}`;
      

        return axios.get(api,config)
            .then((response) => {
                return response.data
            })
    }

//getuseractivity for all users
    static getAllusersActivity(item) {
      let user = JSON.parse(localStorage.getItem("users"));
      const token = user.data.token;
      const config = {
        headers: {
          "x-access-token": token,
        },
     
      };

      let api = `${API_PATHS.getAllActivity}?startDate=${item?.startDate}&endDate=${item?.endDate}`;
    

      return axios.get(api,config)
          .then((response) => {
              return response.data
          })
  }


    static getCookActivity(item) {
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
        const config = {
          headers: {
            "x-access-token": token,
          },
      
        };
      
        let api = `${API_PATHS.getUserActivity}?role=${item?.role}&date=${item?.date}&limit=${item.limit}&page=${item.page}`;
        return axios.get(api, config)
            .then((response) => {
                if (response.data) {

                }
                return response.data
            })
    }



static getCookDelete(item) {
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data.token;
  const config = {
    headers: {
      "x-access-token": token,
    },

  };
  let api = `${API_PATHS.getDeleteRequests}?role=cook&status=4&limit=${item.limit}&page=${item.page}`;
  return axios.get(api, config)
      .then((response) => {
          if (response.data) {

          }
          return response.data
      })
}

static getBlockedCook(item) {
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data.token;
  const config = {
    headers: {
      "x-access-token": token,
    },

  };
  let api = `${API_PATHS.blockedProfile}?role=cook&status=3&limit=${item.limit}&page=${item.page}`;
  return axios.get(api, config)
      .then((response) => {
          if (response.data) {

          }
          return response.data
      })
}


static getUsersTrack(item) {
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data.token;
  const config = {
    headers:{
    "Authorization": `Basic ${API_PATHS.trackUsersAPIKEY}` 
    }
  };

  const filter ={
    "filters": [
      {
        "trait": "created_at_utc",
        "op": "gt",
        "val": item.startDate
    },
    {
        "trait": "created_at_utc",
        "op": "lt",
        "supr_op": "and",
        "val": item.endDate
    }
    ]
}
  let api = `${API_PATHS.trackUsers}?offset=${item.offset}&limit=${item.limit}`;
  return axios.post(api,filter, config)
      .then((response) => {
          if (response.data) {

          }
          return response.data
      })
}



static getBlockedEmployer(item) {
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data.token;
  const config = {
    headers: {
      "x-access-token": token,
    },

  };
  let api = `${API_PATHS.blockedProfile}?role=employer&status=3&limit=${item.limit}&page=${item.page}`;
  return axios.get(api, config)
      .then((response) => {
          if (response.data) {

          }
          return response.data
      })
}

//getting allow  mac address
static getallowedMacaddress(id) {
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data.token;
  const config = {
    headers: {
      "x-access-token": token,
    },

  };
  let api = `${API_PATHS.getallowedMacs}`;
  return axios.get(api, config)
      .then((response) => {
          if (response.data) {

          }
          return response.data
      })
}

//getting allow  mac address
static allowMacaddress(item) {
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data.token;
  const config = {
    headers: {
      "x-access-token": token,
    },

  };
  let api = `${API_PATHS.allowmacaddress}`;
  return axios.post(api,item, config)
      .then((response) => {
          if (response.data) {

          }
          return response.data
      })
}



//get reports list
static getReportList(item) {
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data.token;
  const config = {
    headers: {
      "x-access-token": token,
    },
  };
  let api = `${API_PATHS.getReportsList}?role=${item.role}&limit=${item.limit}&page=${item.page}`;
  return axios.get(api, config)
      .then((response) => {
          if (response.data) {

          }
          return response.data
      })
}


static getEmployerDelete(item) {
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data.token;
  const config = {
    headers: {
      "x-access-token": token,
    },

  };
  

  let api = `${API_PATHS.getDeleteRequests}?role=employer&status=4&limit=${item.limit}&page=${item.page}`;
  return axios.get(api, config)
      .then((response) => {
          if (response.data) {

          }
          return response.data
      })
}

}



export default ActivityService