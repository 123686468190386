import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class PlanService {

    static getSingleCookPlan(id) {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        api = API_PATHS.getCookPlan + "?id="+ id;
        return axios
            .get(api,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static editCookPlan(item) {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
        api = API_PATHS.editCookPlan 
        return axios
            .put(api,item,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getCookPlan() {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        api = API_PATHS.getCookPlan;
        return axios
            .get(api,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

        static deleteCookPlan(id) {
    let api = '';
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
  
      api = API_PATHS.deleteCookPlan + '?id=' + id ;
    localStorage.removeItem("id");
    return axios
        .delete(api,config)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

    //client plan service
    static getSingleClientPlan(id) {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
      
        api = API_PATHS.getClientPlan + "?id="+ id;
        return axios
            .get(api,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getClientPlan() {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        api = API_PATHS.getClientPlan;
        return axios
            .get(api,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static editClientPlan(item) {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        api = API_PATHS.editClientPlan;
        return axios
            .put(api,item,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static deleteClientPlan(id) {
    let api = '';
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
 
      api = API_PATHS.deleteClientPlan + '?id=' + id ;
    localStorage.removeItem("id");
    return axios
        .delete(api,config)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}



    static postClientPlan(item) {
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        return axios
            .post(API_PATHS.addClientPlan, item,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static postEmployerPlan(item) {
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        return axios

            .post(API_PATHS.addEmployerPlan, item,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static deleteEmployerPlan(id) {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
  
          api = API_PATHS.deleteEmployertPlan + '?id=' + id ;
        localStorage.removeItem("id");
        return axios
            .delete(api,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static postCookPlan(item) {
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        return axios
            .post(API_PATHS.addCookPlan, item,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static getEmployerPlan(id) {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
   
        api = API_PATHS.getEmployerPlan + "?id="+ id;
        return axios
            .get(api,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getAllEmployerPlan() {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
        api = API_PATHS.getEmployerPlan
        return axios
            .get(api,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static editEmployerPlan(item) {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        api = API_PATHS.editEmployerPlan;
        return axios
            .put(api,item,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }






    static removeusersDetails() {
        localStorage.removeItem("users");
    }

    static getusersDetails() {
        return JSON.parse(localStorage.getItem("users"));
    }
}

export default PlanService;