import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Navbartop from "./navbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { getpayment } from "../store/slices/payment";
import Table from "react-bootstrap/Table";
import Moment from "moment";
import { Link } from "react-router-dom";
import ShowSkeleton from "../utils/helpers/Skeleton";
import dog from "../Assets/Images/corgi.png";
import ExportCSVButtonSales from "../utils/helpers/ExportButtonSales";

function Payments() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [totalData, settotalData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [pagData, setPageData] = useState();
  const [sortVal, setSortVal] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [limit, setLimit] = useState();
  const [item, setItem] = useState({
    limit: 25,
    page: currentPage,
    status: 2,
  });

  // react paginate
  const totalPages = 10;
  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  const handlePageClick = async ({ selected }) => {
    const newPage = selected + 1;

    if (newPage === currentPage) {
      return;
    }

    setCurrentPage(newPage);
    setItem({
      ...item,
      page: newPage,
    });
    const item1 = {
      ...item,
      page: newPage,
    };
    getPayments(item1);
  };

  const handleApplyFilter = (value) => {
    if (value === "yes") {
      setCurrentPage(1);

      setItem({
        limit: 25,
        page: 1,
        status: 1,
      });
      const item1 = {
        limit: 25,
        page: 1,
        status: 1,
      };
      getPayments(item1);
    } else if (value === "no") {
      setCurrentPage(1);
      setItem({
        limit: 25,
        page: 1,
        status: 0,
      });
      const item1 = {
        limit: 25,
        page: 1,
        status: 0,
      };
      getPayments(item1);
    } else {
      setCurrentPage(1);

      setItem({
        limit: 25,
        page: 1,
        status: 2,
      });
      const item1 = {
        limit: 25,
        page: 1,
        status: 2,
      };
      getPayments(item1);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 500);
  }, [item]);

  useEffect(() => {
    document.title = "CookandChef Admin | Payment";
    getPayments(item);
  }, []);

  const handleChange = (e) => {
    let value = e.target.value;
    setSortVal(value);
    handleApplyFilter(value);
  };

  const getPayments = (item1) => {
    dispatch(getpayment(item1))
      .unwrap()
      .then((data) => {
        settotalData(data?.sales?.totalDataCount);
        setData(data?.sales?.data);
        setLimit(data?.sales?.totalDataCount);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Sales
                  </div>

                  <Form>
                    <Row>
                      <Col lg={12} sm={12} xs={12} md={12}>
                        {" "}
                        <Row className="justify-content-end">
                          <Col lg={2} sm={4} md={3} xs={3} align="right">
                            <label
                              style={{
                                height: "40px",
                                lineHeight: "40px",
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              Filter by:
                            </label>
                          </Col>
                          <Col lg={3} sm={7} md={4} xs={7}>
                            <Form.Group className="mb-1">
                              <Form.Control
                                as="select"
                                name="experience"
                                value={sortVal}
                                onChange={handleChange}
                              >
                                <option value="All">All</option>
                                <option value="yes">Successful</option>
                                <option value="no">Not Processed</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Sales</h4>
                      </div>

                      {/* <div align="right">
                      <div>
                        <ExportCSVButtonSales
                          limit={limit}
                          filename="sales_data.xlsx"
                          getData={getpayment}
                          item={item}
                        />
                      </div>
                    </div> */}
                    </div>

                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : pagData?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No Data</b> found!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table responsive hover>
                            <thead>
                              <tr>
                                <th className="text-left">Name</th>
                                <th className="text-left">User Type</th>
                                <th className="text-left">Mobile</th>
                                <th className="text-left">Date of Payment</th>
                                <th className="text-left">Amount</th>
                                <th className="text-left">Discount</th>
                                <th className="text-left">Plan Name</th>

                                <th className="text-left">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((salesVal) => (
                                <tr key={salesVal.id}>
                                  <td className="text-left">
                                    {salesVal?.employerId?.memberType === 2 && (
                                      <Link
                                        to={`/employerdetails/${salesVal?.employerId?.id}`}
                                        target="_blank"
                                      >
                                        {salesVal?.employerId?.fullName?.substring(
                                          0,
                                          25
                                        )}
                                      </Link>
                                    )}
                                    {salesVal?.employerId?.memberType === 1 && (
                                      <Link
                                        to={`/clientdetails/${salesVal?.employerId?.id}`}
                                        target="_blank"
                                      >
                                        {salesVal?.employerId?.fullName?.substring(
                                          0,
                                          25
                                        )}
                                      </Link>
                                    )}
                                    {salesVal?.cookId && (
                                      <Link
                                        to={`/cookdetails/${salesVal?.cookId?.id}`}
                                        target="_blank"
                                      >
                                        {salesVal?.cookId?.fullName?.substring(
                                          0,
                                          25
                                        )}
                                      </Link>
                                    )}
                                  </td>
                                  <td className="text-left">
                                    {salesVal?.employerId?.memberType === 2 && (
                                      <p>Employer</p>
                                    )}
                                    {salesVal?.employerId?.memberType === 1 && (
                                      <p>Client</p>
                                    )}
                                    {salesVal?.cookId && <p>Cook</p>}
                                  </td>
                                  <td className="text-left">
                                    {salesVal?.employerId?.mobile}
                                    {salesVal?.cookId?.mobile}
                                  </td>
                                  <td className="text-left">
                                    {Moment.utc(
                                      salesVal.transactionStartDateTime
                                    ).format("DD-MM-YYYY")}
                                  </td>
                                  <td className="text-left">
                                    {salesVal.amount}
                                  </td>
                                  <td className="text-left">
                                    {salesVal.discount}
                                  </td>

                                  <td className="text-left">
                                    {salesVal.planName}{" "}
                                    {salesVal?.categoryType ===
                                      "assistance" && <p>(Assistance)</p>}
                                    <br />
                                    {salesVal?.isSelfPayment === false && (
                                      <span style={{ color: "#000" }}>
                                        (Added by Admin)
                                      </span>
                                    )}
                                  </td>
                                  <td className="text-left">
                                    {salesVal.paymentStatus === 1 && (
                                      <p
                                        style={{
                                          color: "#00AC0E",
                                        }}
                                      >
                                        Successful
                                      </p>
                                    )}
                                    {salesVal.paymentStatus === 0 && (
                                      <p
                                        style={{
                                          borderRadius: "5px",
                                          color: "#e08790",
                                        }}
                                      >
                                        Not Processed
                                      </p>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                      <div className="justify-content-center">
                        {totalData / itemsPerPage > 1 && (
                          <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            breakLinkClassName={"page-link"}
                            pageCount={Math.ceil(totalData / itemsPerPage)}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={pagesToShowInitially}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            initialPage={currentPage - 1}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payments;
