import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class PaymentService {
  static getPaymentList(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = `${API_PATHS.getPayments}?limit=${item.limit}&page=${item.page}&status=${item.status}`;
    // api = `${API_PATHS.getHouseCook}?limit=${limit}&page=${page}`;

    return axios.get(api,config).then((response) => {
      return response.data;
    });
  }
}


export default PaymentService;
