import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Navbartop from "./navbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { getBlockeCooks } from "../store/slices/activity";
import Table from "react-bootstrap/Table";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { BsGlobe2 } from "react-icons/bs";
import Moment from "moment";
import { Link } from "react-router-dom";
import { MdBlock } from "react-icons/md";
import ExportCSVButton from "../utils/helpers/ExportButtons";
import ShowSkeleton from "../utils/helpers/Skeleton";

function BlockedCooks() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totaldata, settotalData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [location, setLocation] = useState("");
  const [errors, setErrors] = useState({});
  const [plans, setPlans] = useState("");
  const [exportData, setExportData] = useState([]);
  const [nameEmailOrMobile, setnameEmailOrMobile] = useState("");
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [isLoading, setisLoading] = useState(true);
  const [limit, setLimit] = useState();
  const [locationError, setLocationError] = useState("");
  const dispatch = useDispatch();

  const [item, setItem] = useState({
    limit: 25,
    page: 1,
  });

  // react paginate
  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  useEffect(() => {
    document.title = "CookandChef Admin | Blocked Cooks";
  }, []);

  const handlePageClick = async ({ selected }) => {
    const newPage = selected + 1;

    if (newPage === currentPage) {
      return;
    }
    const updatedItem = {
      ...item,
      limit: 25,
      page: newPage,
    };
    setItem(updatedItem);
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
    await getemployer(updatedItem);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    const planvalq = search.get("plan");
    const locationq = search.get("location");
    const keywordq = search.get("nameEmailOrMobile");
    const pageq = search.get("page");

    if (planvalq) {
      setPlans(planvalq);
    }
    if (keywordq) {
      setnameEmailOrMobile(keywordq);
    }
    let coordString;
    let coordArray;

    if (locationq || planvalq || keywordq || pageq) {
      const item = {
        plans: parseInt(planvalq) >= 0 ? parseInt(planvalq) : undefined,
        latitude: coordArray ? coordArray[0] : undefined,
        longitude: coordArray ? coordArray[1] : undefined,
        nameEmailOrMobile: keywordq || undefined,
        limit: 25,
        page: parseInt(pageq) || 1,
      };

      setItem(item);
      getemployer(item);
    } else {
      getemployer(item);
    }
  }, []);

  const getemployer = (item) => {
    dispatch(getBlockeCooks(item))
      .unwrap()
      .then((data) => {
        setData(data?.blockedcooks.data);
        settotalData(data?.blockedcooks.totalDataCount);

        setLimit(data?.blockedcooks.totalDataCount);
        setisLoading(true);
        setTimeout(() => {
          setisLoading(false);
        }, [1000]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Blocked Cook
                  </div>
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Blocked Cooks</h4>
                      </div>
                    </div>

                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : data?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No results</b> found for your search!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table responsive hover>
                            <thead>
                              <tr>
                                <th className="text-left">Name</th>
                                <th className="text-left">Mobile</th>
                                <th className="text-left">Email</th>
                                <th className="text-center">Date of Regd</th>
                                <th className="text-center"> Last Login</th>
                                <th className="text-left">Web/ App</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((employer) => (
                                <tr key={employer?._id}>
                                  <td className="text-left">
                                    <Link
                                      to={`/cookdetails/${employer?._id}`}
                                      style={{
                                        color: "#a020f0",
                                      }}
                                    >
                                      {employer?.fullName?.substring(0, 25)}
                                    </Link>
                                  </td>
                                  <td className="text-left">
                                    {employer?.mobile}
                                  </td>

                                  <td className="text-left">
                                    {employer?.email?.substring(0, 20)}
                                  </td>
                                  <td className="text-center">
                                    {Moment.utc(employer?.createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {Moment.utc(
                                      employer?.lastLoginDateTime
                                    ).format("DD-MM-YYYY")}
                                  </td>

                                  <td className="text-left">
                                    {employer?.webAccess === 0 && (
                                      <BsGlobe2
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {employer?.webAccess === 1 && (
                                      <BsGlobe2
                                        style={{
                                          color: "orange",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {!(
                                      employer?.webAccess === 0 ||
                                      employer?.webAccess === 1
                                    ) && (
                                      <BsGlobe2
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {employer?.appAccess === 0 && (
                                      <HiOutlineDevicePhoneMobile
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {!(
                                      employer?.appAccess === 0 ||
                                      employer?.appAccess === 1
                                    ) && (
                                      <HiOutlineDevicePhoneMobile
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {employer?.appAccess === 1 && (
                                      <HiOutlineDevicePhoneMobile
                                        style={{
                                          color: "blue",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                      <div className="justify-content-center">
                        {totaldata / itemsPerPage > 1 && (
                          <ReactPaginate
                            key={currentPage}
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            breakLinkClassName={"page-link"}
                            pageCount={totaldata / itemsPerPage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={pagesToShowInitially}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            initialPage={currentPage - 1}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlockedCooks;
