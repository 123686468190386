import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addfaq } from "../store/slices/faq";
import { Link } from "react-router-dom";
import { getCuisines } from "../store/slices/config";
import { createCuisines } from "../store/slices/dropdown";

function CreateCuisines() {
  const [cuisine, setCuisine] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const [cuisineError, setCuisineError] = useState();
  const [cuisines, setCuisines] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const isValidCuisine = (question) => {
    return /^(?=.*[a-zA-Z])[a-zA-Z0-9'@& -_]{3,}$/.test(question);
  };

  React.useEffect(() => {
    getcuisienes();
  }, [dispatch]);

  const getcuisienes = () => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Add Faq";
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "cuisine":
        setCuisine(value);
        const isCuisineNameExists = cuisines.some(
          (el) => el.cuisineName.toLowerCase() === value.toLowerCase()
        );

        if (isCuisineNameExists) {
          setCuisineError(
            "This cuisine name is already entered. Please enter a different name."
          );
        } else if (value === "" || !isValidCuisine(value)) {
          setCuisineError("Please enter a valid cuisine name");
        } else if (value !== "" && isValidCuisine(value)) {
          setCuisineError("");
        }
        break;
      default:
        break;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const isCuisineNameExists = cuisines.some(
      (el) => el.cuisineName.toLowerCase() === cuisine.toLowerCase()
    );

    if (isCuisineNameExists) {
      setCuisineError(
        "This cuisine name is already entered. Please enter a different name."
      );
    } else if (!cuisine.trim()) {
      setCuisineError("Please enter cuisine name");
    } else if (!isValidCuisine(cuisine)) {
      setCuisineError("Please enter a valid cuisine name");
    } else {
      let item = {
        cuisineName: cuisine,
      };

      dispatch(createCuisines(item))
        .unwrap()
        .then(() => {
          alert("Cuisine added successfully");
          setCuisine("");
          getcuisienes();
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp; Add Cuisine
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Add Cuisine</h4>
                      </div>
                    </div>

                    <div className="d-flex p-3">
                      <section className="form-section">
                        <Form className="mx-4" onSubmit={submitHandler}>
                          <Row className="my-4  justify-content-start">
                            <Row className="d-flex ">
                              <Col lg={12} className="p-1  mt-3">
                                <Form.Group>
                                  <Form.Label>Cuisine Name *</Form.Label>
                                  <Form.Control
                                    name="cuisine"
                                    value={cuisine}
                                    onChange={handleChange}
                                    isInvalid={!!cuisineError}
                                    isValid={!cuisineError}
                                    as="textarea"
                                    placeholder="Enter Cuisine Name"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {cuisineError}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row className="justify-content-center mt-5">
                              <Col xs={6} md={4} lg={4} xl={4} xxl={4}>
                                <button
                                  className="btn cancelBtn"
                                  onClick={cancelHandler}
                                >
                                  Cancel
                                </button>
                              </Col>
                              <Col xs={6} md={4} lg={4} xl={4} xxl={4}>
                                <button
                                  className="btn saveBtn ms-3"
                                  disabled={isdisabled}
                                  type="submit"
                                >
                                  Save
                                </button>
                              </Col>
                            </Row>
                          </Row>
                        </Form>
                      </section>

                      <div className="card align-items-center">
                        <h6> Cuisines List </h6>
                        <div
                          className="card align-items-center w-100"
                          style={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            padding: "20px",
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}
                        >
                          {cuisines?.map((el) => {
                            return <p>{el?.cuisineName}</p>;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateCuisines;
