import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Moment from "moment";
import Navbartop from "./navbar";
import { getLanguages, getQualification } from "../store/slices/config";
import { EditcookDetails, getCookDetails } from "../store/slices/cook";
import AvatarModel from "../utils/helpers/ImageCropper/AvatarModal";
import { API_PATHS } from "../utils/constants/api.constants";

function EditCookDetails() {
  const { id } = useParams();
  localStorage.setItem("employerId", id);
  const [fullName, setfullName] = useState("");
  const [languages, setLanguages] = useState([]);
  const [about, setabout] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [dp, setDp] = useState("");
  const [errors, setErrors] = useState({});
  const [education, setEducation] = useState([]);
  const [language, setLanguage] = useState([]);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [isdisabled, setisDisabled] = useState(false);
  const dispatch = useDispatch();
  const [avatarModalOpen, setAvatarModalOpen] = useState(false);
  const [imageData, setImageData] = useState("");

  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data?.token;
  const isValidName = (fullName) => {
    return /^(?!\d+$)[a-zA-Z][a-zA-Z0-9 ]{3,}$/.test(fullName);
  };
  const isValidAbout = (about) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?!\s)[\s\S]{3,}$/.test(about);
  };
  const isValidDOB = (dob) => {
    const today = moment();
    const dobDate = moment(dob);

    // Check if the user is between 18 and 80 years old
    return (
      dobDate.isValid() &&
      dobDate.isBefore(today.subtract(18, "years")) &&
      dobDate.isAfter(today.subtract(80, "years"))
    );
  };

  React.useEffect(() => {
    dispatch(getCookDetails(id))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
        setLanguages(data.users.data.languages);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  useEffect(() => {
    document.title = "CookandChef Admin | Edit Cook";
  }, []);

  React.useEffect(() => {
    dispatch(getQualification())
      .unwrap()
      .then((data) => {
        setEducation(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getLanguages())
      .unwrap()
      .then((data) => {
        setLanguage(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  var options = language.map(function (item) {
    return item["languageName"];
  });

  React.useEffect(() => {
    dispatch(getQualification())
      .unwrap()
      .then((data) => {
        setEducation(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    // alert("working")
    const validationErrors = {};
    if (!data.fullName) {
      validationErrors.fullName = "Please enter name";
    } else if (!isValidName(data.fullName)) {
      validationErrors.fullName = "Name must be at least 4 characters";
    }

    if (data?.about && !isValidAbout(data?.about)) {
      validationErrors.about = "Enter at least three characters";
    }

    if (!data.gender) {
      validationErrors.gender = "Please select gender";
    }
    if (data.dob && !isValidDOB(data.dob)) {
      validationErrors.dob = "Age must be between 18 and 80 years";
    }

    if (!languages?.length) {
      validationErrors.languages = "Please select at least one language";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

      return;
    } else {
      const item = {
        id: id,
        fullName: data.fullName || undefined,
        dp: data.dp || undefined,
        dob: data?.dob?.toString().split("T")[0] || undefined,
        gender: data.gender || undefined,
        qualification: data.qualification || undefined,
        languages: languages || undefined,
        about: about,
      };

      dispatch(EditcookDetails(item))
        .unwrap()
        .then((data) => {
          alert("Profile updated succesfully");
          navigate(-1);
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value } = e.target;
    switch (name) {
      case "fullName":
        setfullName(value);
        setData({
          ...data,
          fullName: value,
        });
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Please enter a valid name"
            : "";
        if (!value == "" && isValidName(value)) {
          delete validationErrors[name];
        }
        break;

      case "languages":
        setLanguages(value);
        setData({
          ...data,
          languages: value,
        });
        validationErrors[name] =
          value === "" ? "Please enter languages known" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;

      case "about":
        e.target.style.height = "auto";
        e.target.style.height = e.target.scrollHeight + "px";
        setabout(value);
        setData({
          ...data,
          about: value,
        });
        validationErrors[name] =
          value !== "" && !isValidAbout(value)
            ? "Enter at least three characters"
            : "";
        if (value !== "" && isValidAbout(value)) {
          validationErrors[name] = "";
        }
        break;

      case "gender":
        setGender(value);
        setData({
          ...data,
          gender: value,
        });
        validationErrors[name] =
          value === "" ? "Please select your gender" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "qualification":
        setData({
          ...data,
          qualification: value,
        });
        validationErrors[name] =
          value === "" ? "Please enter your qualification" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "dob":
        setDob(value);
        setData({
          ...data,
          dob: value,
        });
        validationErrors[name] =
          value !== "" && !isValidDOB(value)
            ? "Age must be between 18 and 80 years"
            : "";
        if (value !== "" && isValidDOB(value)) {
          validationErrors[name] = "";
        }
        break;

      default:
        setData({
          ...data,
          [name]: value,
        });
        break;
    }

    setErrors(validationErrors);
  };

  const handleLanguageChange = (selectedOptions) => {
    setLanguages(selectedOptions);
    const isValid = validateLanguages(selectedOptions);

    setErrors((prevErrors) => ({
      ...prevErrors,
      languages: isValid ? "" : "Please select at least one language.",
    }));
  };

  const validateLanguages = (selectedOptions) => {
    return selectedOptions.length > 0;
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  const handleChangeImage = (e) => {
    setAvatarModalOpen(true);
    var formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    setImageData(formdata);
  };

  async function handleSave(requestOptions) {
    let response = await fetch(API_PATHS.uploadFile, requestOptions);
    let data2 = await response.json();
    setData({
      ...data,
      dp: data2.data.location,
    });
  }

  return (
    <>
      <div>
        <Navbartop />
        <AvatarModel
          showModal1={avatarModalOpen}
          image={imageData}
          setShowModal1={setAvatarModalOpen}
          handleSave={handleSave}
          setData={setData}
          data={data}
        />
        <div className="container-fluid pb-5 ms-2 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-1 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;
                      <Link onClick={cancelHandler}> Cook Profile</Link> &#8811;
                      Edit Cook General Details
                    </div>

                    <Form
                      autoComplete="off"
                      noValidate
                      onSubmit={submitHandler}
                    >
                      <Row className="d-flex justify-content-center mt-4 card p-3">
                        <Col lg={12} xs={12} md={12} className="mb-4">
                          <h5 className="text-left heading orange">
                            General Details
                          </h5>

                          <Row className="gx-2 d-flex justify-content-center">
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">Name *</label>
                                <Form.Control
                                  className="input1"
                                  name="fullName"
                                  value={data?.fullName}
                                  onChange={handleChange}
                                  type="text"
                                  maxlength="100"
                                  isInvalid={!!errors.fullName}
                                  placeholder="Enter your name"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.fullName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">Gender *</label>
                                <Form.Control
                                  as="select"
                                  name="gender"
                                  className="input1"
                                  value={data?.gender}
                                  onChange={handleChange}
                                  isInvalid={!!errors.gender}
                                  isValid={errors.gender}
                                >
                                  <option value="">Select</option>
                                  <option value="1">Male</option>
                                  <option value="2">Female</option>
                                  <option value="3">Other</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.gender}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">
                                  Date of Birth
                                </label>
                                <Form.Control
                                  name="dob"
                                  value={data?.dob?.toString().split("T")[0]}
                                  onChange={handleChange}
                                  isInvalid={!!errors.dob}
                                  className="input1 pb-3"
                                  type="date"
                                  max={Moment().format("YYYY-MM-DD")}
                                  placeholder="Enter your date of birth"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.dob}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">
                                  Qualification
                                </label>
                                <Form.Control
                                  as="select"
                                  name="qualification"
                                  value={data?.qualification}
                                  onChange={handleChange}
                                  isInvalid={!!errors.qualification}
                                  className="input1 pb-3"
                                >
                                  <option value="">Select</option>
                                  {education.map((edudata, index) => (
                                    <option
                                      key={index}
                                      value={edudata.iqualificationNamed}
                                    >
                                      {edudata.qualificationName}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.qualification}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">
                                  Languages Known *
                                </label>

                                <Typeahead
                                  id="basic-typeahead-multiple"
                                  labelKey="name"
                                  multiple
                                  className="input1"
                                  onChange={handleLanguageChange}
                                  options={options}
                                  placeholder="Languages known"
                                  selected={languages}
                                />
                                <p style={{ color: "red", fontSize: "12px" }}>
                                  {errors.languages}
                                </p>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-2">
                                <label className="mb-2 label">About</label>
                                <Form.Control
                                  name="about"
                                  value={data.about}
                                  onChange={handleChange}
                                  isInvalid={!!errors.about}
                                  className="input1"
                                  as="textarea"
                                  maxLength={1000}
                                  rows={1}
                                  style={{ resize: "none" }}
                                  placeholder="Enter about"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.about}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              <label for="fileInput" className="mt-1">
                                Change Image
                              </label>

                              <Form.Control
                                type="file"
                                name="profiledp"
                                id="fileInput"
                                accept="image/*"
                                onChange={(e) => handleChangeImage(e)}
                                className="input1 "
                                placeholder="Please choose profile photo"
                              />
                            </Col>
                            <Col lg={5} className="p-1 m-2" />
                          </Row>
                        </Col>

                        <Row className="d-flex justify-content-center mt-4">
                          <Col lg={4} xs={8}>
                            <div className="d-flex justify-content-around my-1 align-items-center">
                              <button
                                onClick={cancelHandler}
                                className="btn cancelBtn"
                                type="cancel"
                              >
                                Cancel
                              </button>
                              &nbsp;&nbsp;
                              <Button
                                type="submit"
                                disabled={isdisabled}
                                className="btn saveBtn my-2"
                              >
                                Save
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCookDetails;
