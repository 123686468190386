import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Navbartop from "./navbar";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getPartyCook } from "../store/slices/cook";
import { ShareProfile } from "../store/slices/employer";
import ReactPaginate from "react-paginate";
import ShowSkeleton from "../utils/helpers/Skeleton";
import { getEventSharedProfiles } from "../store/slices/events";

function ShareCook() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [oldSelectedItems, setOldSelectedItems] = useState([]);
  const [data, setData] = useState();
  const [totaldata, settotalData] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setisLoading] = useState(true);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [data1, setData1] = useState();
  const [currentPage, setCurrentPage] = useState(querypage);
  const [item, setItem] = useState({
    limit: 25,
    page: 1,
  });
  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  useEffect(() => {
    getchef(item);
  }, []);

  const handlePageClick = async ({ selected }) => {
    const newPage = selected + 1;

    if (newPage === currentPage) {
      return;
    }
    const updatedItem = {
      ...item,
      limit: 25,
      page: newPage,
    };
    setItem(updatedItem);
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
    await getchef(updatedItem);
  };
  const getchef = (item) => {
    dispatch(getPartyCook(item))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
        settotalData(data.users.totalDataCount);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  React.useEffect(() => {
    const item = { id: id };
    getprofiles(item);
  }, [dispatch]);

  const getprofiles = (item) => {
    dispatch(getEventSharedProfiles(item))
      .unwrap()
      .then((data) => {
        setData1(data.profiles.data);
        const alreadySharedProfileIds = data.profiles.data.map(
          (profile) => profile.cookId.id
        );

        setSelectedItems(alreadySharedProfileIds);
        setOldSelectedItems(alreadySharedProfileIds);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const columns = [
    { dataField: "fullName", text: "Name", filter: textFilter() },
    { dataField: "mobile", text: "Mobile", filter: textFilter() },
    { dataField: "email", text: "Email", filter: textFilter() },
  ];

  const handleCheckboxChange = (row, isSelect, rowIndex, e) => {
    if (isSelect) {
      setSelectedItems([...selectedItems, row._id]);
    } else {
      setSelectedItems(selectedItems.filter((id) => id !== row._id));
    }
  };

  const ShareJob = () => {
    const newSelectedItems = selectedItems.filter(
      (elem) => !oldSelectedItems.includes(elem)
    );
    if (newSelectedItems.length) {
      const employerId = localStorage.getItem("EventId");
      const item = {
        eventId: id,
        employerId: employerId,
        cookIds: newSelectedItems,
      };

      dispatch(ShareProfile(item))
        .unwrap()
        .then((data) => {
          alert(data.users.message);
          navigate(-1);
        })
        .catch(({ message }) => {
          alert(message);
        });
    } else {
      alert("Please select atleast one cook");
    }
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Share Cook Profile";
  }, []);

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Share Cook Profile
                  </div>
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Share Cook Profile</h4>
                      </div>

                      <div className="d-flex align-items-right">
                        <Button className="apply_btn ms-2" onClick={ShareJob}>
                          Share
                        </Button>
                      </div>
                    </div>
                    {selectedItems?.length ? (
                      <h5 style={{ marginLeft: "30px" }}>
                        {" "}
                        You selected
                        {"  " + selectedItems?.length}{" "}
                        {selectedItems?.length > 1 ? "Profiles" : "Profile"}{" "}
                      </h5>
                    ) : (
                      ""
                    )}
                    <div className="p-4">
                      {data && data.length > 0 && (
                        <BootstrapTable
                          keyField="_id"
                          data={data}
                          columns={columns}
                          selectRow={{
                            mode: "checkbox",
                            clickToSelect: true,
                            onSelect: handleCheckboxChange,
                            selected: selectedItems,
                            onSelectAll: (isSelect, rows, e) => {
                              const selectedIds = isSelect
                                ? rows.map((row) => row.id)
                                : [];

                              // Check if the profiles in data1 are already selected
                              const alreadySelectedIds = data1
                                .filter((profile) =>
                                  selectedIds.includes(profile.id)
                                )
                                .map((profile) => profile.id);

                              // If there are already selected profiles in data1, update the selectedItems state
                              if (alreadySelectedIds.length > 0) {
                                setSelectedItems((prevSelectedItems) => [
                                  ...prevSelectedItems,
                                  ...alreadySelectedIds,
                                ]);
                              } else {
                                setSelectedItems(selectedIds);
                              }
                            },
                          }}
                          filter={filterFactory()}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-content-right">
                {totaldata / itemsPerPage > 1 && (
                  <ReactPaginate
                    key={currentPage}
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel="..."
                    breakLinkClassName={"page-link"}
                    pageCount={Math.ceil(totaldata / itemsPerPage)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={pagesToShowInitially}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    activeClassName="active"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    disabledClassName="disabled"
                    initialPage={currentPage - 1}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShareCook;
