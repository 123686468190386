import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import employerService from "../../services/employer.service";
import AuthService from "../../services/auth.service";

export const getEmployer = createAsyncThunk(
    "employer/getEmployer",
    async (item, thunkApi) => {
        try {
            const data = await employerService.getEmployer(item)
            return { users: data }

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.message.toString()
            return thunkApi.rejectWithValue({ message })
        }
    }
)


export const getEmployerDetails = createAsyncThunk(
    "employer/employerdetails",
    async (id,thunkAPI) => {
        try {
            const data = await employerService.getEmployerDetails(id);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//get single cse account
export const getSingleCse = createAsyncThunk(
    "employer/getsinglecse",
    async (id,thunkAPI) => {
        try {
            const data = await employerService.getCseaccoutone(id);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


//share profile 
export const ShareProfile = createAsyncThunk(
    "employer/shareprofile",
    async (item,thunkAPI) => {
        try {
            const data = await employerService.Shareprofile(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const editEmployerDetails = createAsyncThunk(
    "employer/employerdetails",
    async (item,thunkAPI) => {
        try {
            const data = await employerService.editEmployerDetails(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const editRequirement = createAsyncThunk(
    "employer/editrequirement",
    async (item,thunkAPI) => {
        try {
            const data = await employerService.editRequirement(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const editCateringDetails = createAsyncThunk(
    "employer/cateeringdetails",
    async (item,thunkAPI) => {
        try {
            const data = await employerService.editCateringdetails(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);






export const getCseAccounts = createAsyncThunk(
    "employer/getcseaccounts",
    async (item,thunkAPI) => {
        try {
            const data = await employerService.getCseaccounts(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const editCseAccounts = createAsyncThunk(
    "employer/editcseaccounts",
    async (item,thunkAPI) => {
        try {
            const data = await employerService.EditcseAccount(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

export const getAlltestimonial = createAsyncThunk(
    "employer/getAlltestimonial",
    async (item,thunkAPI) => {
        try {
            const data = await employerService.Getalltestimonial(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);




export const addCseAccount = createAsyncThunk(
    "employer/addcseaccount",
    async (item,thunkAPI) => {
        try {
            const data = await employerService.addcseAccount(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


//cancelorrefund plan
export const CancelorRefund = createAsyncThunk(
    "employer/cacelorrefund",
    async (item,thunkAPI) => {
        try {
            const data = await employerService.CancelorRefundPlan(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//upgadeplan
export const UpgradeEmployerPlan = createAsyncThunk(
    "employer/employerUpgradeplan",
    async (item,thunkAPI) => {
        try {
            const data = await employerService.UpgradeEmployerPlan(item);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

const initialState = {
    loading: false,
    error: "",
    users: AuthService.getusersDetails() || null,
    isLoggedIn: false
}

const clientSlice = createSlice({
    name: "client",
    initialState,
    extraReducers: {
        [getEmployer.pending]: (state) => {
            state.loading = true
            state.error = ""
            state.users = null
            state.isLoggedIn = false

        },
        [getEmployer.fulfilled]: (state, action) => {
            state.loading = true;
            state.error = "";
            state.users = action.payload.users
            state.isLoggedIn = true
        },
        [getEmployer.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.users = null;
            state.isLoggedIn = false
        }
    }
})

const { reducer } = clientSlice
export default reducer