import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navbartop, { WebSideBar } from "./navbar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addCookPlan } from "../store/slices/plan";

function AddCookPlan() {
  const [cookPlanName, setCookPlanName] = useState("");
  const [profileBoostRank, setProfileBoostRank] = useState("");
  const [actionPerDay, setActionPerDay] = useState(0);
  const [actionPerMonth, setActionPerMonth] = useState(0);
  const [price, setPrice] = useState(0);
  const [validityInDays, setValidityInDays] = useState(0);
  const [resumeBuilder, setResumeBuilder] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  const isValidActionsPerDay = (actions) => {
    // validating actions per day between 1 and 100
    return /^(?:[1-9]|[1-9]\d|100)$/.test(actions);
  };
  const isValidActionsPerMonth = (actions) => {
    // validating actions per month between 1 and 500
    return /^(?:[1-9]|[1-9][0-9]{1,2}|500)$/.test(actions);
  };

  const isValidPlanPrice = (price) => {
    // validating plan price between 10 and 10000
    return /^(10{1,4}|[1-9]\d{1,3}|10000)$/.test(price);
  };
  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?=.*[a-zA-Z])[a-zA-Z0-9'@& -_]{4,}$/.test(name);
  };

  const isValidValidity = (validity) => {
    // validating validity between 1 and 100
    return /\b([1-9]|[1-9][0-9]|[1-4][0-9]{2}|500)\b/.test(validity);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validationErrors = { ...errors };
    switch (name) {
      case "cookPlanName":
        setCookPlanName(value);
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Name should be atleast 4 characters"
            : "";
        if (!value === "" && isValidName(value)) {
          delete validationErrors[name];
        }
        break;

      case "profileBoostRank":
        setProfileBoostRank(value);
        validationErrors[name] =
          value === "" ? "Please enter profile boost rank" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      case "actionPerDay":
        setActionPerDay(value);
        validationErrors[name] =
          value === "" || !isValidActionsPerDay(value)
            ? "Actions per day should be between 1 and 100"
            : "";
        if (!value === "" && isValidActionsPerMonth(value)) {
          delete validationErrors[name];
        }
        break;
      case "actionPerMonth":
        setActionPerMonth(value);
        validationErrors[name] =
          value === "" || !isValidActionsPerMonth(value)
            ? "Actions per month should be between 1 and 500"
            : "";
        if (!value === "" && isValidActionsPerMonth(value)) {
          delete validationErrors[name];
        }
        break;
      case "price":
        setPrice(value);
        validationErrors[name] =
          value === "" || !isValidPlanPrice(value)
            ? "Plan price should be in between 10 and 10000"
            : "";
        if (!value === "" && isValidPlanPrice(value)) {
          delete validationErrors[name];
        }
        break;
      case "validityInDays":
        setValidityInDays(value);
        validationErrors[name] =
          value === "" || !isValidValidity(value)
            ? `Validity should be between 1 to 500 days`
            : "";
        if (!value === "" && isValidValidity(value)) {
          delete validationErrors[name];
        }
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const submitHandler = (e) => {
    const validationErrors = {};
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);

    if (!cookPlanName.trim()) {
      validationErrors.cookPlanName = "Please enter plan name";
    } else if (!isValidName(cookPlanName)) {
      validationErrors.cookPlanName = "Please enter valid plan name";
    }
    if (!validityInDays) {
      validationErrors.validityInDays = "Please enter validity";
    } else if (validityInDays && !isValidValidity(validityInDays)) {
      validationErrors.validityInDays =
        "Validity should be between 1 to 500 days";
    }

    if (!price) {
      validationErrors.price = "Please enter plan price";
    } else if (price && !isValidPlanPrice(price)) {
      validationErrors.price = "Plan price should be in between 10 and 10000";
    }

    if (!profileBoostRank && !resumeBuilder) {
      validationErrors.profileBoostRank = "Please select one";
    }
    if (!actionPerDay) {
      validationErrors.actionPerDay = "Please enter actions per day";
    } else if (actionPerDay && !isValidActionsPerDay(actionPerDay)) {
      validationErrors.actionPerDay =
        "Actions per day should be between 1 and 100";
    }
    if (!actionPerMonth) {
      validationErrors.actionPerMonth = "Please enter actions per month";
    } else if (actionPerMonth && !isValidActionsPerMonth(actionPerMonth)) {
      validationErrors.actionPerMonth =
        "Actions per month should be between 1 and 500";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      let status = 1;
      let item = {
        cookPlanName,
        validityInDays,
        price,
        profileBoostRank: !profileBoostRank ? 0 : 1,
        resumeBuilder: !resumeBuilder ? 0 : 1,
        actionPerDay,
        actionPerMonth,
        status,
      };

      dispatch(addCookPlan(item))
        .unwrap()
        .then(() => {
          navigate("/cookplan");
          alert("Plan added successfully");
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Add Cook Plan";
  }, []);

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp;
                    <Link to="/cookplan">Cook Plan</Link> &#8811; Add Cook Plan
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Add Cook Plan</h4>
                      </div>
                    </div>
                    <section className="form-section">
                      <Form className="mx-4" onSubmit={submitHandler}>
                        <Row className="my-4  justify-content-center">
                          <Row>
                            <Col lg={5} className="my-2 mt-2">
                              <Form.Group>
                                <Form.Label>Plan Name *</Form.Label>

                                <Form.Control
                                  name="cookPlanName"
                                  value={cookPlanName}
                                  onChange={handleChange}
                                  isInvalid={!!errors.cookPlanName}
                                  isValid={
                                    !errors.cookPlanName &&
                                    cookPlanName.length > 0
                                  }
                                  type="text"
                                  placeholder="Enter plan name"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cookPlanName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="my-2">
                              <Form.Group>
                                <Form.Label>Validity *</Form.Label>

                                <Form.Control
                                  name="validityInDays"
                                  value={validityInDays}
                                  onChange={handleChange}
                                  isInvalid={!!errors.validityInDays}
                                  isValid={
                                    !errors.validityInDays &&
                                    validityInDays.length > 0
                                  }
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="Enter plan validity"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.validityInDays}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="my-2">
                              <Form.Group>
                                <Form.Label>Price *</Form.Label>

                                <Form.Control
                                  name="price"
                                  value={price}
                                  onChange={handleChange}
                                  isInvalid={!!errors.price}
                                  isValid={!errors.price && price.length > 0}
                                  type="text"
                                  placeholder="Enter plan price"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.price}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="my-2">
                              <Form.Group>
                                <Form.Label>Actions per day *</Form.Label>
                                <Form.Control
                                  name="actionPerDay"
                                  value={actionPerDay}
                                  onChange={handleChange}
                                  isInvalid={!!errors.actionPerDay}
                                  isValid={
                                    !errors.actionPerDay &&
                                    actionPerDay.length > 0
                                  }
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="Enter actions per day"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.actionPerDay}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="my-2">
                              <Form.Group>
                                <Form.Label>Actions per month *</Form.Label>

                                <Form.Control
                                  name="actionPerMonth"
                                  value={actionPerMonth}
                                  onChange={handleChange}
                                  isInvalid={!!errors.actionPerMonth}
                                  isValid={
                                    !errors.actionPerMonth &&
                                    actionPerMonth.length > 0
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  type="number"
                                  placeholder="Enter actions per month"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.actionPerMonth}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="my-2">
                              <Form.Group>
                                <Form.Check
                                  className="mt-3"
                                  onChange={(e) =>
                                    setProfileBoostRank(!profileBoostRank)
                                  }
                                  label="Profile Boost Included"
                                />
                                <Form.Check
                                  onChange={(e) =>
                                    setResumeBuilder(!resumeBuilder)
                                  }
                                  label="Resume Builder Included"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.profileBoostRank}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="my-2">
                              <Form.Group></Form.Group>
                            </Col>
                          </Row>
                        </Row>
                        <Row className="justify-content-center mt-5">
                          <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                            <button
                              className="btn cancelBtn"
                              onClick={cancelHandler}
                            >
                              Cancel
                            </button>
                          </Col>
                          <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                            <button
                              className="btn saveBtn mx-2"
                              disabled={isdisabled}
                              type="submit"
                            >
                              Save
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCookPlan;
