import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function AllusersLineChart({ hourlyActData, activity }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: activity === "users" ? "User Sign Ups" : "User Activity",
      },
    },
  };
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label:
          activity === "users" ? "No.of User Sign Ups" : "No.of Active Users",
        data: [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });

  useEffect(() => {
    if (hourlyActData) {
      // Extracting keys (time intervals) and values (view counts) from hourlyActData
      const dates = hourlyActData.map((item) => item.date);
      const counts = hourlyActData.map((item) => item.count);

      // Update chartData state
      setChartData((prevData) => ({
        ...prevData,
        labels: dates,
        datasets: [
          {
            ...prevData.datasets[0],
            data: counts,
          },
        ],
      }));
    } else {
      setChartData({
        labels: [],
        datasets: [
          {
            label:
              activity === "users"
                ? "No.of Registered Users"
                : "No.of Active Users",
            data: [],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      });
    }
  }, [hourlyActData]);

  return <Line options={options} data={chartData} />;
}

export default AllusersLineChart;
