import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class employerService {
  static getEmployer(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = `${API_PATHS.getEmployer}`;
    // api = `${API_PATHS.getHouseCook}?limit=${limit}&page=${page}`;

    return axios.post(api,item,config).then((response) => {
      return response.data;
    });
  }


  static getCseaccounts(item) {
    let api = "";
    api = `${API_PATHS.getcseaccounts}?limit=${item.limit}&page=${item.page}`;
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios.get(api,config).then((response) => {
      return response.data;
    });
  }

//share profile
  static Shareprofile(item) {
    let api = "";
    api = `${API_PATHS.shareProfile}`;
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios.post(api,item,config).then((response) => {
      return response.data;
    });
  }

//get sing cse account
  static getCseaccoutone(id) {
    let api = "";
    api = API_PATHS.getsinglecseaccount+"?id="+id;
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios.get(api,config).then((response) => {
      return response.data;
    });
  }


  //add cse account
  static addcseAccount(item) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios
      .post(API_PATHS.addcseaccount, item, config)
      .then((response) => {
        return response.data;
      });
  }


  static EditcseAccount(item) {
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    return axios
      .put(API_PATHS.editcseaccounts, item, config)
      .then((response) => {
        return response.data;
      });
  }
  static getEmployerDetails(id) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };

    api = API_PATHS.getEmployerProfile + "?id=" + id;
    return axios.get(api,config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

  static editEmployerDetails(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.editemployer;
    return axios.put(api, item ,config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
    
  }

  static editRequirement(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.editRequirement;
    return axios.put(api, item ,config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }


  //cancelorrefundemployerplan
  static CancelorRefundPlan(item) {
    let api = "";
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
    api = API_PATHS.CancelorRefund;
    return axios.put(api, item ,config).then((response) => {
      if (response.data) {
      }
      return response.data;
    });
  }

//UpgradeEmployerplan 
static UpgradeEmployerPlan(item) {
  let api = "";
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data.token;

  const config = {
    headers: {
      "x-access-token": token,
    },
  };
  api = API_PATHS.UpgradeemployerPlan;
  return axios.put(api, item ,config).then((response) => {
    if (response.data) {
    }
    return response.data;
  });
}


static editCateringdetails(item) {
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data.token;

  const config = {
    headers: {
      "x-access-token": token,
    },
  };
  let api = "";
  let id = localStorage.getItem("employerId");
  api = API_PATHS.editcatering;
  return axios.put(api, item,config).then((response) => {
    if (response.data) {
    }
    return response.data;
  });
}
}


export default employerService;
