import axios from "axios"
import { API_PATHS } from "../utils/constants/api.constants"




class clientService {

    static getClient(item) {
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        let api = ""
        api = `${API_PATHS.getClient}`;

        return axios.post(api,item,config)
            .then((response) => {
                return response.data
            })
    }
    static getClientDetails(id) {
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        let api = ""
        api = API_PATHS.getEmployerProfile+ '?id=' + id ;
        return axios.get(api,config)
            .then((response) => {
                if (response.data) {

                }
                return response.data
            })
    }



}



export default clientService