import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Navbartop from "./navbar";
import { getCuisines } from "../store/slices/config";
import { EditHousecookDetails, getCookDetails } from "../store/slices/cook";
function EditHouseProfile() {
  const [cuisines, setCuisines] = useState([]);
  const [householdCuisines, sethouseholdCuisines] = useState([]);
  const [payment, setPayment] = useState("");
  const [jobTypeVal, setjobTypeVal] = useState(3);
  const [householdVesselWash, sethouseholdVesselWash] = useState();
  const [isdisabled, setisDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  React.useEffect(() => {
    dispatch(getCookDetails(id))
      .unwrap()
      .then((data) => {
        sethouseholdCuisines(data.users.data.householdCuisines);
        setjobTypeVal(data.users.data.jobType || 3);
        setPayment(data.users.data.payment);
        sethouseholdVesselWash(data.users.data.householdVesselWash || 0);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value } = e.target;
    switch (name) {
      case "payment":
        setPayment(value);
        validationErrors[name] = value === "" ? "Please select payment" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      default:
        break;
    }

    setErrors(validationErrors);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Edit House Profile";
  }, []);

  async function submitHandler(e) {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};

    if (!householdCuisines) {
      validationErrors.householdCuisines = "Please enter cuisines known";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      const jobType = parseInt(jobTypeVal);
      let item = {
        id,
        householdCuisines,
        payment,
        jobType,
        householdVesselWash,
      };
      dispatch(EditHousecookDetails(item))
        .unwrap()
        .then(() => {
          alert("Profile updated successfully");
          navigate(-1);
        })
        .catch(({ message }) => {
          alert(message);
        });
      //  }
    }
  }
  const handleCuisineChange = (selected) => {
    const validationErrors = { ...errors };
    sethouseholdCuisines(selected);
    validationErrors.householdCuisines =
      selected.length === 0 ? "Please enter cuisines known" : "";
    if (!selected.length === 0) {
      delete validationErrors.householdCuisines;
    }

    setErrors(validationErrors);
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      {" "}
      <div>
        <Navbartop />

        <div className="container-fluid pb-5 ms-2 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-1 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;
                      <Link onClick={cancelHandler}> Cook Profile</Link> &#8811;
                      Edit House Profile
                    </div>

                    <Form
                      autoComplete="off"
                      noValidate
                      onSubmit={submitHandler}
                    >
                      <Row className="d-flex justify-content-center mt-4 card p-3">
                        <div
                          style={{
                            minHeight: "40dvh",
                            width: "100%",
                            backgroundColor: "white",
                            borderRadius: "15px",
                            padding: "20px 40px",
                          }}
                        >
                          <Row>
                            <Col lg={6} className="p-3">
                              <Form.Group>
                                <label className="mb-2 label">
                                  Cuisines Known *
                                </label>

                                <Typeahead
                                  clearButton
                                  name="householdCuisines"
                                  id="basic-typeahead-multiple"
                                  labelKey="householdCuisines"
                                  multiple
                                  className="input1"
                                  onChange={handleCuisineChange}
                                  options={options}
                                  placeholder="Cuisines known"
                                  selected={householdCuisines}
                                  isInvalid={!!errors.householdCuisines}
                                />

                                <Form.Control.Feedback type="invalid">
                                  {errors.householdCuisines}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="p-3">
                              <Form.Group>
                                <label className="mb-2 label">
                                  Min. Payment
                                </label>
                                <Form.Control
                                  as="select"
                                  name="payment"
                                  className="input1"
                                  value={payment}
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  <option value="3000 to 5000">
                                    3000 to 5000
                                  </option>
                                  <option value="5000 to 10000">
                                    5000 to 10000
                                  </option>
                                  <option value="10000 to 15000">
                                    10000 to 15000
                                  </option>
                                  <option value="15000 to 25000">
                                    15000 to 25000
                                  </option>
                                  <option value="Above 25000">
                                    Above 25000
                                  </option>
                                </Form.Control>
                              </Form.Group>
                            </Col>

                            <Col lg={6} className="p-3">
                              <Form.Group>
                                <label className="mb-2 label">Job Type</label>
                                <Row className="ms-2">
                                  <Col lg={5} md={6} xs={6}>
                                    <Form.Check
                                      type="switch"
                                      id="no"
                                      label="Part Time"
                                      name="jobTypeVal"
                                      value={jobTypeVal}
                                      onChange={() => setjobTypeVal(1)}
                                      checked={jobTypeVal === 1}
                                      className="switchBtn"
                                    />
                                  </Col>
                                  <Col lg={5} md={6} xs={6}>
                                    <Form.Check
                                      type="switch"
                                      id="no"
                                      label="Full Time"
                                      name="jobTypeVal"
                                      value={jobTypeVal}
                                      onChange={() => setjobTypeVal(2)}
                                      checked={jobTypeVal === 2}
                                      className="switchBtn"
                                    />
                                  </Col>
                                  <Col lg={5} md={6} xs={6}>
                                    <Form.Check
                                      type="switch"
                                      id="yes"
                                      label="Any"
                                      name="jobTypeVal"
                                      value={jobTypeVal}
                                      onChange={() => setjobTypeVal(3)}
                                      checked={jobTypeVal === 3}
                                      className="switchBtn"
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="p-3">
                              <Form.Group className="justify-content-start align-items-center">
                                <label className="mb-2 label">
                                  Vessel Wash
                                </label>
                                <Row className="ms-2">
                                  <Col lg={3} md={3} xs={6}>
                                    <Form.Check
                                      type="switch"
                                      id="yes"
                                      label="Yes"
                                      name="householdVesselWash"
                                      value={householdVesselWash}
                                      onChange={() => sethouseholdVesselWash(1)}
                                      checked={householdVesselWash === 1}
                                      className="switchBtn"
                                    />
                                  </Col>
                                  <Col lg={3} md={3} xs={6}>
                                    <Form.Check
                                      type="switch"
                                      id="no"
                                      label="No"
                                      name="householdVesselWash"
                                      value={householdVesselWash}
                                      onChange={() => sethouseholdVesselWash(0)}
                                      checked={householdVesselWash === 0}
                                      className="switchBtn"
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="d-flex justify-content-center mt-4">
                            <Col lg={4} xs={8}>
                              <div className="d-flex justify-content-around my-2 align-items-center">
                                <button
                                  onClick={cancelHandler}
                                  className="btn cancelBtn"
                                >
                                  Cancel
                                </button>
                                &nbsp; &nbsp;
                                <Button
                                  type="submit"
                                  disabled={isdisabled}
                                  className="btn saveBtn my-2"
                                >
                                  Save
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditHouseProfile;
