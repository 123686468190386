import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import clientService from "../../services/client.service";
import AuthService from "../../services/auth.service";

export const getClient = createAsyncThunk(
    "/client/getClient",

    async (item, thunkApi) => {
        try {
            const data = await clientService.getClient(item)
            return { users: data }

        } catch (error) {
            const message = (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message || error.message.toString()
            return thunkApi.rejectWithValue({ message })
        }
    }
)

export const getClientDetails = createAsyncThunk(
    "client/clientdetails",
    async (id,thunkAPI) => {
        try {
            const data = await clientService.getClientDetails(id);
            return { users: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

const initialState = {
    loading: false,
    error: "",
    users: AuthService.getusersDetails() || null,
    isLoggedIn: false
}

const clientSlice = createSlice({
    name: "client",
    initialState,
    extraReducers: {
        [getClient.pending]: (state) => {
            state.loading = true
            state.error = ""
            state.users = null
            state.isLoggedIn = false

        },
        [getClient.fulfilled]: (state, action) => {
            state.loading = true;
            state.error = "";
            state.users = action.payload.users
            state.isLoggedIn = true
        },
        [getClient.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.users = null;
            state.isLoggedIn = false
        }
    }
})

const { reducer } = clientSlice
export default reducer