import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import jobsService from "../../services/jobs.service";


export const getJobs = createAsyncThunk(
    "jobs/getJobs",
    async (item, thunkApi) => {
        try {
            const data = await jobsService.getJobs(item)
            return { jobs: data }

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.message.toString()
            return thunkApi.rejectWithValue({ message })
        }
    }
)

export const getApplications = createAsyncThunk(
    "jobs/getapplications",
    async ({ limit, page ,id}, thunkApi) => {
        try {
            const data = await jobsService.getApplicationList(limit, page,id)
            return { jobs: data }

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.message.toString()
            return thunkApi.rejectWithValue({ message })
        }
    }
)

export const getEventApplications = createAsyncThunk(
    "jobs/getapplications",
    async ({ limit, page ,id}, thunkApi) => {
        try {
            const data = await jobsService.getEventApplicationList(limit, page,id)
            return { jobs: data }

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.message.toString()
            return thunkApi.rejectWithValue({ message })
        }
    }
)

export const SendNotifications = createAsyncThunk(
    "jobs/sendnotifications",
    async (id, thunkApi) => {
        try {
            const data = await jobsService.sendNotifications(id)
            return { jobs: data }

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.message.toString()
            return thunkApi.rejectWithValue({ message })
        }
    }
)


export const SendStatus = createAsyncThunk(
    "jobs/sendstatus",
    async (id, thunkApi) => {
        try {
            const data = await jobsService.sendJobStauts(id)
            return { jobs: data }

        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.message.toString()
            return thunkApi.rejectWithValue({ message })
        }
    }
)

export const getJobsDetails = createAsyncThunk(
    "jobs/jobsdetails",
    async (id,thunkAPI) => {
        try {
            const data = await jobsService.getJobsDetails(id);
            return { jobs: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const EditJobsDetails = createAsyncThunk(
    "jobs/editjobsdetails",
    async (item,thunkAPI) => {
        try {
            const data = await jobsService.editJobsDetails(item);
            return { jobs: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const Postjob = createAsyncThunk(
    "jobs/postjob",
    async (item,thunkAPI) => {
        try {
            const data = await jobsService.PostJob(item);
            return { jobs: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);


export const Postevent = createAsyncThunk(
    "jobs/postevent",
    async (item,thunkAPI) => {
        try {
            const data = await jobsService.PostEvent(item);
            return { jobs: data };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

const initialState = {
    loading: false,
    error: "",
    jobs: [],
    isLoggedIn: false
}

const clientSlice = createSlice({
    name: "jobs",
    initialState,
    extraReducers: {
        [getJobs.pending]: (state) => {
            state.loading = true
            state.error = ""
            state.jobs = null
            state.isLoggedIn = false

        },
        [getJobs.fulfilled]: (state, action) => {
            state.loading = true;
            state.error = "";
            state.jobs = action.payload.jobs
            state.isLoggedIn = true
        },
        [getJobs.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
            state.jobs = null;
            state.isLoggedIn = false
        }
    }
})

const { reducer } = clientSlice
export default reducer