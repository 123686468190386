import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Navbartop from "./navbar";
import { getCuisines } from "../store/slices/config";
import { EditPartycookDetails, getCookDetails } from "../store/slices/cook";

function EditPartyProfile() {
  const dispatch = useDispatch();
  const [cuisines, setCuisines] = useState([]);
  const [partyCuisines, setpartyCuisines] = useState([]);
  const [partyExperienceVal, setpartyExperienceVal] = useState();
  const [speciality, setSpeciality] = useState();
  const [partyMaxPlatesVal, setpartyMaxPlatesVal] = useState();
  const [partyCookFoodTypeVal, setpartyCookFoodTypeVal] = useState();
  const [partyCookAvailabilityVal, setpartyCookAvailabilityVal] = useState();
  const [partyCookVesselWash, setpartyCookVesselWash] = useState();
  const [errors, setErrors] = useState({});
  const [isdisabled, setisDisabled] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  React.useEffect(() => {
    dispatch(getCookDetails(id))
      .unwrap()
      .then((data) => {
        setpartyCuisines(data.users.data.partyCuisines);
        setpartyMaxPlatesVal(data.users.data.partyMaxPlates);
        setpartyExperienceVal(data.users.data.partyExperience);
        setpartyCookFoodTypeVal(data.users.data.partyCookFoodType);
        setpartyCookAvailabilityVal(data.users.data.partyCookAvailability);
        setpartyCookVesselWash(data.users.data.partyCookVesselWash || 0);
        setSpeciality(data.users.data.speciality);
      })
      .catch(({ message }) => {
        //  alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        //  alert(message);
      });
  }, [dispatch]);
  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;
    switch (name) {
      case "partyCuisines":
        setpartyCuisines(value);
        validationErrors[name] =
          value === "" ? "Please enter cuisines known" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "partyExperienceVal":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 2) {
          value = value.slice(0, 2);
        }
        setpartyExperienceVal(value);
        validationErrors[name] =
          value === "" || !isValidpartyExperienceVal(value)
            ? "Max. experience allowed is 35 years"
            : "";
        if (!value === "" && isValidpartyExperienceVal(value)) {
          delete validationErrors[name];
        }
        break;

      case "speciality":
        setSpeciality(value);
        validationErrors[name] =
          value !== "" && !isValidspeciality(value)
            ? "Please enter valid speciality"
            : "";
        if (!value === "" && isValidspeciality(value)) {
          delete validationErrors[name];
        }
        break;

      case "partyMaxPlatesVal":
        setpartyMaxPlatesVal(value);
        validationErrors[name] = value === "" ? "" : "";
        if (!value === "" && isValidpartyMaxPlatesVal(value)) {
          delete validationErrors[name];
        }
        break;

      case "partyCookFoodTypeVal":
        setpartyCookFoodTypeVal(value);
        validationErrors[name] = value === "" ? "" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "partyCookAvailabilityVal":
        setpartyCookAvailabilityVal(value);
        validationErrors[name] =
          value === "" ? "Please select availability" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      default:
        break;
    }
    setErrors(validationErrors);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Edit Party Profile";
  }, []);

  const isValidpartyExperienceVal = (partyExperienceVal) => {
    return /^(0[1-9]|[1-4][0-9]?|50)$/.test(partyExperienceVal);
  };

  const isValidpartyMaxPlatesVal = (partyMaxPlatesVal) => {
    return /^(0|[1-9]|[1-2][0-9]|[1-9][0-9][0-9])$/.test(partyMaxPlatesVal);
  };

  const isValidspeciality = (speciality) => {
    return /^[a-zA-Z0-9 ,]{2,100}$/.test(speciality);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const validationErrors = {};
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    if (!partyCuisines?.length) {
      validationErrors.partyCuisines = "Please enter cuisines known";
    }
    if (!partyExperienceVal) {
      validationErrors.partyExperienceVal = "Please enter experience";
    }

    if (!partyCookAvailabilityVal) {
      validationErrors.partyCookAvailabilityVal = "Please select availability ";
    }
    console.log(partyCuisines);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      const partyExperience = parseInt(partyExperienceVal);
      const partyMaxPlates = parseInt(partyMaxPlatesVal);
      const partyCookAvailability = parseInt(partyCookAvailabilityVal);
      const partyCookFoodType = parseInt(partyCookFoodTypeVal);

      if (partyCookFoodTypeVal === "") {
        let item = {
          id,
          partyCuisines: partyCuisines || undefined,
          partyExperience: partyExperience || undefined,
          speciality: speciality,
          partyMaxPlates: partyMaxPlates || undefined,
          partyCookAvailability: partyCookAvailability || undefined,
          partyCookVesselWash: partyCookVesselWash || undefined,
        };
        dispatch(EditPartycookDetails(item))
          .unwrap()
          .then(() => {
            alert("Profile updated successfully");
            navigate(-1);
          })
          .catch(({ message }) => {
            alert(message);
          });
      } else if (partyCookFoodTypeVal !== "") {
        let item = {
          id,
          partyCuisines: partyCuisines || undefined,
          partyExperience: partyExperience || undefined,
          speciality: speciality,
          partyMaxPlates: partyMaxPlates || undefined,
          partyCookAvailability: partyCookAvailability || undefined,
          partyCookFoodType: partyCookFoodTypeVal,
          partyCookVesselWash: partyCookVesselWash || undefined,
        };
        dispatch(EditPartycookDetails(item))
          .unwrap()
          .then(() => {
            alert("Profile updated successfully");
            navigate(-1);
          })
          .catch(({ message }) => {
            alert(message);
          });
      }
    }
  };

  const handleCuisineChange = (selected) => {
    const validationErrors = { ...errors };

    setpartyCuisines(selected);
    validationErrors.partyCuisines =
      selected.length === 0 ? "Please enter cuisines known" : "";
    if (!selected.length === 0) {
      delete validationErrors.partyCuisines;
    }

    setErrors(validationErrors);
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <div>
        <Navbartop />

        <div className="container-fluid pb-5 ms-2 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-1 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;
                      <Link onClick={cancelHandler}> Cook Profile</Link> &#8811;
                      Edit Party Profile
                    </div>
                    <Form
                      autoComplete="off"
                      noValidate
                      onSubmit={submitHandler}
                    >
                      <Row className="mt-4 gx-3 d-flex justify-content-center ">
                        <Col lg={12} xs={11} className="mb-4">
                          <Row className="=d-flex justify=-content-center align-items-center">
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "white",
                                borderRadius: "15px",
                                padding: "20px 40px",
                              }}
                            >
                              <Col lg={12}>
                                <Row>
                                  <Col lg={6} className="p-3">
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Cuisines Known *
                                      </label>

                                      <Typeahead
                                        clearButton
                                        id="basic-typeahead-multiple"
                                        name="cuisines"
                                        multiple
                                        className="input1"
                                        onChange={handleCuisineChange}
                                        options={options}
                                        placeholder="Cuisines"
                                        selected={partyCuisines}
                                        isInvalid={!!errors.partyCuisines}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.partyCuisines}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} className="p-3">
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Experience *
                                      </label>
                                      <Form.Control
                                        type="number"
                                        maxLength={2}
                                        name="partyExperienceVal"
                                        value={partyExperienceVal}
                                        isInvalid={!!errors.partyExperienceVal}
                                        isValid={!errors.partyExperienceVal}
                                        onChange={handleChange}
                                        className="input1"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder="Enter party experience"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.partyExperienceVal}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} className="p-3">
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Speciality
                                      </label>
                                      <Form.Control
                                        type="text"
                                        name="speciality"
                                        value={speciality}
                                        isInvalid={!!errors.speciality}
                                        isValid={!errors.speciality}
                                        onChange={handleChange}
                                        className="input1"
                                        placeholder="Enter speciality"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.speciality}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} className="p-3">
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Max. Plates
                                      </label>

                                      <Form.Control
                                        as="select"
                                        name="partyMaxPlatesVal"
                                        className="input1"
                                        value={partyMaxPlatesVal}
                                        onChange={handleChange}
                                        isInvalid={!!errors.partyMaxPlatesVal}
                                        isValid={errors.partyMaxPlatesVal}
                                      >
                                        <option value="">Select</option>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                      </Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.partyMaxPlatesVal}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} className="p-3">
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Food Type
                                      </label>
                                      <Form.Control
                                        as="select"
                                        name="partyCookFoodTypeVal"
                                        className="input1"
                                        value={partyCookFoodTypeVal}
                                        onChange={handleChange}
                                        isInvalid={
                                          !!errors.partyCookFoodTypeVal
                                        }
                                        isValid={errors.partyCookFoodTypeVal}
                                      >
                                        <option value="">Select</option>
                                        <option value="1">Pure Veg</option>
                                        <option value="2">Veg/ Non-Veg</option>
                                        <option value="3">Jain Food</option>
                                      </Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.partyCookFoodTypeVal}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>

                                  <Col lg={6} className="p-3">
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Availability *
                                      </label>
                                      <Form.Control
                                        as="select"
                                        name="partyCookAvailabilityVal"
                                        className="input1"
                                        value={partyCookAvailabilityVal}
                                        onChange={handleChange}
                                        isInvalid={
                                          !!errors.partyCookAvailabilityVal
                                        }
                                        isValid={
                                          errors.partyCookAvailabilityVal
                                        }
                                      >
                                        <option value="">Select</option>
                                        <option value="1">All Days</option>
                                        <option value="2">Mon-Fri Only</option>
                                        <option value="3">Sat-Sun Only</option>
                                      </Form.Control>
                                      <Form.Control.Feedback type="invalid">
                                        {errors.partyCookAvailabilityVal}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} className="p-3">
                                    <Form.Group className=" justify-content-start align-items-center">
                                      <label className="mb-2 label">
                                        Vessel Wash
                                      </label>
                                      <Row className="ms-2">
                                        <Col lg={3} md={3} xs={6}>
                                          <Form.Check
                                            type="switch"
                                            id="yes"
                                            label="Yes"
                                            name="partyCookVesselWash"
                                            value={partyCookVesselWash}
                                            onChange={() =>
                                              setpartyCookVesselWash(1)
                                            }
                                            checked={partyCookVesselWash === 1}
                                            className="switchBtn"
                                          />
                                        </Col>
                                        <Col lg={3} md={3} xs={6}>
                                          <Form.Check
                                            type="switch"
                                            id="no"
                                            label="No"
                                            name="partyCookVesselWash"
                                            value={partyCookVesselWash}
                                            onChange={() =>
                                              setpartyCookVesselWash(0)
                                            }
                                            checked={partyCookVesselWash === 0}
                                            className="switchBtn"
                                          />
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row className="d-flex justify-content-center mt-4">
                                  <Col lg={4} xs={8}>
                                    <div className="d-flex justify-content-around my-2 align-items-center">
                                      <button
                                        onClick={cancelHandler}
                                        className="btn cancelBtn"
                                      >
                                        Cancel
                                      </button>
                                      &nbsp; &nbsp;
                                      <Button
                                        disabled={isdisabled}
                                        type="submit"
                                        className="saveBtn my-2"
                                      >
                                        Save
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditPartyProfile;
