import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Moment from "moment";
import {
  editEmployerDetails,
  getEmployerDetails,
} from "../store/slices/employer";
import Navbartop from "./navbar";
import { getStates } from "../store/slices/config";

function EditClientContactDetails() {
  const { id } = useParams();
  localStorage.setItem("employerId", id);

  const [addressline1, setaddressLine1] = useState("");
  const [addressline2, setaddressLine2] = useState("");

  const [cityName, setcityName] = useState("");
  const [pincode, setPincode] = useState("");
  const [landmark, setLandmark] = useState("");
  const [area, setArea] = useState("");
  const [provinceName, setprovinceName] = useState("");

  const [errors, setErrors] = useState({});
  const [isdisabled, setisDisabled] = useState(false);
  const [state, setState] = useState([]);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [citycoord, setcitycoord] = useState([]);
  const [areacoord, setareacoord] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "CookandChef Admin | Edit Client Contact Details";
  }, []);

  React.useEffect(() => {
    dispatch(getEmployerDetails(id))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
        setcitycoord(data.users.data.cityCoordinates.coordinates);
        setareacoord(data.users.data.areaCoordinates.coordinates);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getStates())
      .unwrap()
      .then((data) => {
        setState(data.user.data);
      })
      .catch(({ message }) => {});
  }, [dispatch]);

  const isValidAddressline1 = (addressline1) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?! )[a-zA-Z0-9\s.',#-]{1,100}$/.test(addressline1);
  };

  const isValidAddressline2 = (addressline2) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?! )[a-zA-Z0-9\s.',#-]{1,50}$/.test(addressline2);
  };

  const isValidLandmark = (landmark) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?! )[a-zA-Z0-9\s.',#-]{1,50}$/.test(landmark);
  };

  const isValidPincode = (pincode) => {
    // validating the name with one cap letter and 6 char of length
    return /^[1-9][0-9]{5}$/.test(pincode);
  };

  const isValidCity = (CityName) => {
    return /^[a-zA-Z0-9\-',&@]{3,100}$/.test(CityName);
  };

  const cityinputRef = useRef(null);
  useEffect(() => {
    if (cityinputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        cityinputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();

        if (!place.geometry || !place.geometry.location) {
          setErrors({
            ...errors,
            cityName: "Please select location from the suggestion drop-down",
          });
          setcitycoord([]);
        } else {
          setErrors({
            ...errors,
            cityName: "",
          });
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];

          setcityName(place.name);
          setcity(place.name);
          setcitycoord(coordinates);
        }
      });

      cityinputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();

        const inputValue = cityinputRef.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setErrors({
            ...errors,
            cityName: "Please select location from the suggestion drop-down",
          });
          setcitycoord([]);
        }
      });
    }
  }, []);
  const setcity = (city) => {
    setData((prevData) => ({
      ...prevData,
      cityName: city,
    }));
  };

  const areainputRef = useRef(null);
  useEffect(() => {
    if (areainputRef.current) {
      const autoCompleteArea = new window.google.maps.places.Autocomplete(
        areainputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );
      autoCompleteArea.addListener("place_changed", () => {
        const place = autoCompleteArea.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setErrors({
            ...errors,
            area: "Please select location from the suggestion drop-down",
          });
          setareacoord([]);
        } else if (place.geometry.viewport || place.geometry.location) {
          setErrors({
            ...errors,
            area: "",
          });

          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];

          setArea(place.name);
          setarea(place.name, coordinates);
          setareacoord(coordinates);
        }
      });
      areainputRef.current.addEventListener("blur", () => {
        const place = autoCompleteArea.getPlace();

        if (!place?.geometry || !place?.geometry?.location) {
          setErrors({
            ...errors,
            area: "Please select location from the suggestion drop-down",
          });
          setareacoord([]);
        }
      });
    }
  }, []);

  const setarea = (area, coordinates) => {
    setData((prevData) => ({
      ...prevData,
      area: area,
    }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    // alert("working")
    const validationErrors = {};

    if (!data.addressLine1) {
      validationErrors.addressLine1 = "Please enter address line 1";
    }

    if (!isValidAddressline1(data.addressLine1)) {
      validationErrors.addressLine1 = "Please enter valid address";
    }

    if (!data.cityName) {
      validationErrors.cityName = "Please enter city name";
    } else if (!isValidCity(data.cityName) || !citycoord?.length) {
      validationErrors.cityName = "Please ennter valid city name";
    }
    if (!data.pincode) {
      validationErrors.pincode = "Please enter pincode";
    } else if (!isValidPincode(data.pincode)) {
      validationErrors.pincode = "Pincode must be 6 digits";
    }
    if (data.landmark && !isValidLandmark(data.landmark)) {
      validationErrors.landmark = "Please enter a valid landmark";
    }

    if (data.addressLine2 && !isValidAddressline2(data.addressLine2)) {
      validationErrors.addressLine2 = "Please enter valid address";
    }
    if (!data.area) {
      validationErrors.area = "Please enter area";
    } else if (!isValidCity(data.area) || !areacoord?.length) {
      validationErrors.area = "Please enter valid area name";
    }

    if (!data.provinceName) {
      validationErrors.state = "Please select state";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log(validationErrors);
      return;
    } else {
      const item = {
        id: id,

        addressLine1: data.addressLine1 || undefined,
        addressLine2: data.addressLine2,

        cityName: data.cityName || undefined,
        area: data.area || undefined,
        provinceName: data.provinceName || undefined,
        pincode: data.pincode || undefined,
        landmark: data.landmark ,
        cityCoordinates:
          {
            type: "Point",
            coordinates: citycoord,
          } || undefined,
        areaCoordinates:
          {
            type: "Point",
            coordinates: areacoord,
          } || undefined,
      };

      dispatch(editEmployerDetails(item))
        .unwrap()
        .then((data) => {
          alert("Profile updated succesfully");
          navigate(`/clientdetails/${id}`);
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, value } = e.target;
    switch (name) {
      case "cityName":
        setcityName(value);
        setData({
          ...data,
          cityName: value,
        });
        validationErrors[name] = value === "" ? "Please enter city" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;

      case "addressLine1":
        setaddressLine1(value);
        setData({
          ...data,
          addressLine1: value,
        });
        validationErrors[name] =
          value === ""
            ? "Please enter your address"
            : value === "" || !isValidAddressline1(value)
            ? "Please enter a valid address"
            : "";
        if (value !== "" && isValidAddressline1(value)) {
          delete validationErrors[name];
        }
        break;
      case "addressLine2":
        setaddressLine2(value);
        setData({
          ...data,
          addressLine2: value,
        });
        validationErrors[name] =
          value !== "" && !isValidAddressline2(value)
            ? "Please enter a valid address"
            : "";
        if (value !== "" && isValidAddressline2(value)) {
          delete validationErrors[name];
        }
        break;

      case "pincode":
        setPincode(value);
        setData({
          ...data,
          pincode: value,
        });
        validationErrors[name] =
          value === ""
            ? "Please enter pincode"
            : value === "" || !isValidPincode(value)
            ? "Please enter a valid pincode"
            : "";
        if (value !== "" && isValidPincode(value)) {
          delete validationErrors[name];
        }
        break;

      case "landmark":
        setLandmark(value);
        setData({
          ...data,
          landmark: value,
        });
        validationErrors[name] =
          value !== "" && !isValidLandmark(value)
            ? "Please enter a valid landmark"
            : "";
        if (value !== "" && isValidLandmark(value)) {
          delete validationErrors[name];
        }
        break;
      case "area":
        setArea(value);
        setData({
          ...data,
          area: value,
        });
        validationErrors[name] = value === "" ? "Please enter area" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "provinceName":
        setprovinceName(value);
        setData({
          ...data,
          provinceName: value,
        });
        validationErrors[name] = value === "" ? "Please select state" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      default:
        setData({
          ...data,
          [name]: value,
        });
        break;
    }

    setErrors(validationErrors);
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <div>
        <Navbartop />

        <div className="container-fluid pb-5 ms-2 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-2 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;
                      <Link to="/client">&nbsp;Client</Link> &#8811;
                      <Link onClick={cancelHandler}>
                        &nbsp;Client Details&nbsp;
                      </Link>
                      &#8811; Edit Client Contact Details
                    </div>

                    <Form
                      autoComplete="off"
                      noValidate
                      onSubmit={submitHandler}
                    >
                      <Row className="d-flex justify-content-center mt-4 card p-3">
                        <Col lg={12} xs={12}>
                          <h5 className="text-left heading orange">
                            Contact Details
                          </h5>

                          <Row className="gx-2 d-flex justify-content-center">
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">
                                  Address Line 1 *
                                </label>
                                <Form.Control
                                  name="addressLine1"
                                  value={data.addressLine1}
                                  onChange={handleChange}
                                  isInvalid={!!errors.addressLine1}
                                  className="input1"
                                  type="text"
                                  maxlength="100"
                                  placeholder="Address line 1"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.addressLine1}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">
                                  Address Line 2
                                </label>
                                <Form.Control
                                  name="addressLine2"
                                  value={data.addressLine2}
                                  onChange={handleChange}
                                  maxlength="50"
                                  isInvalid={!!errors.addressLine2}
                                  className="input1"
                                  type="text"
                                  placeholder="Address line 2"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.addressLine2}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">City *</label>
                                <Form.Control
                                  name="cityName"
                                  value={data.cityName}
                                  onChange={handleChange}
                                  isInvalid={!!errors.cityName}
                                  className="input1"
                                  type="text"
                                  maxlength="50"
                                  ref={cityinputRef}
                                  placeholder="Enter city name"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cityName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">Pincode *</label>
                                <Form.Control
                                  name="pincode"
                                  maxlength="6"
                                  value={data.pincode}
                                  onChange={handleChange}
                                  isInvalid={!!errors.pincode}
                                  className="input1"
                                  type="text"
                                  placeholder="Enter pincode"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.pincode}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">Landmark</label>
                                <Form.Control
                                  name="landmark"
                                  value={data.landmark}
                                  onChange={handleChange}
                                  isInvalid={!!errors.landmark}
                                  className="input1"
                                  maxlength="50"
                                  type="text"
                                  placeholder="Enter landmark"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.landmark}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">Area *</label>
                                <Form.Control
                                  name="area"
                                  value={data.area}
                                  onChange={handleChange}
                                  isInvalid={!!errors.area}
                                  className="input1"
                                  type="text"
                                  maxlength="50"
                                  ref={areainputRef}
                                  placeholder="Enter area"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.area}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2">
                              <Form.Group className="my-1">
                                <label className="mb-1 label">State *</label>

                                <Form.Control
                                  as="select"
                                  name="provinceName"
                                  className="input1"
                                  value={data.provinceName}
                                  onChange={handleChange}
                                  isInvalid={!!errors.provinceName}
                                >
                                  <option value="">Select</option>
                                  {state.map((edudata, index) => (
                                    <option
                                      key={index}
                                      value={edudata.provinceName}
                                    >
                                      {edudata.provinceName}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.state}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={5} className="p-1 m-2"></Col>
                          </Row>
                        </Col>
                        <Row className="d-flex justify-content-center mt-4">
                          <Col lg={4} xs={8}>
                            <div className="d-flex justify-content-around my-2 align-items-center">
                              <button
                                onClick={cancelHandler}
                                className="btn cancelBtn"
                              >
                                Cancel
                              </button>
                              &nbsp;&nbsp;
                              <Button
                                type="submit"
                                disabled={isdisabled}
                                className="btn saveBtn my-2"
                              >
                                Save
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditClientContactDetails;
