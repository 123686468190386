import React, { useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editEmployerPlan, getEmployerPlan } from "../store/slices/plan";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function EditEmployerPlan() {
  const { id } = useParams();
  localStorage.setItem("employerplanId", id);
  const [employerPlanName, setEmployerPlanName] = useState("");
  const [validityInDays, setValidityInDays] = useState();
  const [price, setPrice] = useState();
  const [jobPoints, setJobPoints] = useState();
  const [profileViewPoints, setProfileViewPoints] = useState();
  const [responsePoints, setResponsePoints] = useState();
  const [refundPolicyVal, setRefundPolicyVal] = useState();
  const [supportAssistance, setSupportAssistance] = useState();
  const [assistancePrice, setAssistancePrice] = useState();
  const [plantypeVal, setPlantypeVal] = useState();
  const [errors, setErrors] = useState({});
  const [isdisabled, setisDisabled] = useState(false);
  const isValidName = (fullName) => {
    return /^(?!\d+$)[a-zA-Z][a-zA-Z0-9 ]{3,}$/.test(fullName);
  };

  const isValidPlanPrice = (price) => {
    // validating plan price between 50 and 10000
    return /^(?:[5-9][0-9]{1}|[1-9][0-9]{2,3}|10000)$/.test(price);
  };

  const isValidValidity = (validity) => {
    // validating validity between 1 and 100
    return /\b([1-9]|[1-9][0-9]|[1-4][0-9]{2}|500)\b/.test(validity);
  };

  const isValidProfileViews = (views) => {
    // validating profile views between 1 and 1000
    return /^(?:[1-9]\d{0,2}|1000)$/.test(views);
  };

  const isValidJobPosts = (jobPosts) => {
    // validating job posts between 1 and 50
    return /^(?:[1-9]|[1-4]\d|50)$/.test(jobPosts);
  };

  const isValidResponses = (responses) => {
    // validating responses between 1 and 1000
    return /^(?:[1-9]\d{0,2}|1000)$/.test(responses);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Edit Employer Plan";
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validationErrors = { ...errors };
    switch (name) {
      case "employerPlanName":
        setEmployerPlanName(value);
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Please enter valid plan name"
            : "";
        if (value !== "" && isValidName(value)) {
          validationErrors[name] = "";
        }
        break;

      case "validityInDays":
        setValidityInDays(value);
        validationErrors[name] =
          value === "" || !isValidValidity(value)
            ? "Validity should be between 1 to 500 days"
            : "";
        if (value !== "" && isValidValidity(value)) {
          validationErrors[name] = "";
        }
        break;

      case "price":
        setPrice(value);
        validationErrors[name] =
          value === "" || !isValidPlanPrice(value)
            ? "Plan price should be in between 50 and 10000"
            : "";
        if (value !== "" && isValidPlanPrice(value)) {
          validationErrors[name] = "";
        }
        break;

      case "jobPoints":
        setJobPoints(value);
        validationErrors[name] =
          value === "" || !isValidJobPosts(value)
            ? "No. of job posts should be in between 1 and 50"
            : "";
        if (value !== "" && isValidJobPosts(value)) {
          validationErrors[name] = "";
        }
        break;

      case "profileViewPoints":
        setProfileViewPoints(value);
        validationErrors[name] =
          value === "" || !isValidProfileViews(value)
            ? "No. of profile views should be in between 1 and 1000"
            : "";
        if (value !== "" && isValidProfileViews(value)) {
          validationErrors[name] = "";
        }
        break;
      case "responsePoints":
        setResponsePoints(value);
        validationErrors[name] =
          value === "" || !isValidResponses(value)
            ? "No. of responses should be in between 1 and 1000"
            : "";
        if (value !== "" && isValidResponses(value)) {
          validationErrors[name] = "";
        }
        break;
      case "plantypeVal":
        setPlantypeVal(value);
        validationErrors[name] = value === "" ? "Please select plan type" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;

      case "refundPolicyVal":
        setRefundPolicyVal(value);
        validationErrors[name] =
          value === "" ? "Please select refund policy" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "assistancePrice":
        setAssistancePrice(value);
        if (!value) {
          validationErrors[name] = "Please enter assistance price";
        } else {
          if (value && parseInt(value) > parseInt(price)) {
            validationErrors[name] =
              "Assistance price should not be greater than plan price";
          } else {
            delete validationErrors[name];
          }
        }
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const submitHandler = (e) => {
    const validationErrors = {};
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);

    if (!employerPlanName.trim()) {
      validationErrors.employerPlanName = "Please enter plan name";
    } else if (employerPlanName && !isValidName(employerPlanName)) {
      validationErrors.employerPlanName = "Name should be atleast 4 characters";
    }
    if (!validityInDays) {
      validationErrors.validityInDays = "Please enter validity";
    } else if (validityInDays && !isValidValidity(validityInDays)) {
      validationErrors.validityInDays =
        "Validity should be between 1 to 500 days";
    }

    if (!price) {
      validationErrors.price = "Please enter plan price";
    } else if (price && !isValidPlanPrice(price)) {
      validationErrors.price = "Plan price should be in between 50 and 10000";
    }
    if (!jobPoints) {
      validationErrors.jobPoints = "Please enter No.of job post";
    } else if (jobPoints && !isValidJobPosts(jobPoints)) {
      validationErrors.jobPoints =
        "No. of Job posts should be in between 1 and 50";
    }
    if (!profileViewPoints) {
      validationErrors.profileViewPoints = "Please No.of profile view";
    } else if (profileViewPoints && !isValidProfileViews(profileViewPoints)) {
      validationErrors.profileViewPoints =
        "No. of profile views should be in between 1 and 1000";
    }
    if (!responsePoints) {
      validationErrors.responsePoints = "Please enter No.of response";
    } else if (responsePoints && !isValidResponses) {
      validationErrors.responsePoints =
        "No. of responses should be in between 1 and 1000";
    }
    if (!plantypeVal) {
      validationErrors.planTypeVal = "Please select plan type";
    }

    if (parseInt(assistancePrice) > parseInt(price)) {
      validationErrors.assistancePrice =
        "Assistance price should Not be more than plan price";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log(validationErrors);
      return;
    } else {
      const plantype = parseInt(plantypeVal);
      const refundPolicy = parseInt(refundPolicyVal);
      let status = 1;
      let assistancePr;
      if (supportAssistance == 0) {
        assistancePr = assistancePrice;
      }

      let item = {
        id: id,
        employerPlanName,
        validityInDays,
        price,
        jobPoints,
        profileViewPoints,
        responsePoints,
        refundPolicy,
        supportAssistance,
        status,
        assistancePrice: assistancePr || undefined,
        plantype,
      };

      dispatch(editEmployerPlan(item))
        .unwrap()
        .then(() => {
          alert("Plan edited successfully");
          navigate("/employerplan");
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  useEffect(() => {
    dispatch(getEmployerPlan(id))
      .unwrap()
      .then((data) => {
        setEmployerPlanName(data.users.data[0].employerPlanName);

        setJobPoints(data.users.data[0].jobPoints);
        setPrice(data.users.data[0].price);
        setProfileViewPoints(data.users.data[0].profileViewPoints);
        setPlantypeVal(data.users.data[0].planTypeVal);
        setRefundPolicyVal(data.users.data[0].refundPolicy);
        setValidityInDays(data.users.data[0].validityInDays);
        setResponsePoints(data.users.data[0].responsePoints);
        setPlantypeVal(data.users.data[0].plantype);
        setSupportAssistance(data.users.data[0].supportAssistance);
        if (data.users.data[0].supportAssistance == 0) {
          setAssistancePrice(data.users.data[0].assistancePrice);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <Navbartop />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp;
                    <Link to="/employerplan">Employer Plan</Link> &#8811; Edit
                    Employer Plan
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Edit Employer Plan</h4>
                      </div>
                    </div>

                    <section className="form-section">
                      <Form className="mx-4" onSubmit={submitHandler}>
                        <Row className="my-4 justify-content-center">
                          <Row>
                            <Col lg={6} className="my-2 mt-2">
                              <Form.Group>
                                <Form.Label>Plan Name *</Form.Label>

                                <Form.Control
                                  name="employerPlanName"
                                  value={employerPlanName}
                                  onChange={handleChange}
                                  isInvalid={!!errors.employerPlanName}
                                  isValid={!errors.employerPlanName}
                                  type="text"
                                  placeholder="Enter plan name"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.employerPlanName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="my-2">
                              <Form.Group>
                                <Form.Label>Plan Validity *</Form.Label>

                                <Form.Control
                                  name="validityInDays"
                                  value={validityInDays}
                                  onChange={handleChange}
                                  isInvalid={!!errors.validityInDays}
                                  isValid={!errors.validityInDays}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  min="1"
                                  placeholder="Enter plan validity"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.validityInDays}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="my-2">
                              <Form.Group>
                                <Form.Label>Plan Price *</Form.Label>

                                <Form.Control
                                  name="price"
                                  value={price}
                                  onChange={handleChange}
                                  isInvalid={!!errors.price}
                                  isValid={!errors.price}
                                  type="text"
                                  placeholder="Enter plan price"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.price}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="my-2">
                              <Form.Group>
                                <Form.Label>Job Post *</Form.Label>

                                <Form.Control
                                  name="jobPoints"
                                  value={jobPoints}
                                  onChange={handleChange}
                                  isInvalid={!!errors.jobPoints}
                                  isValid={!errors.jobPoints}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="Enter No.of job post"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.jobPoints}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="my-2">
                              <Form.Group>
                                <Form.Label>Profile View *</Form.Label>
                                <Form.Control
                                  name="profileViewPoints"
                                  value={profileViewPoints}
                                  onChange={handleChange}
                                  isInvalid={!!errors.profileViewPoints}
                                  isValid={!errors.profileViewPoints}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="Enter profile views"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.profileViewPoints}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="my-2">
                              <Form.Group>
                                <Form.Label>Response *</Form.Label>

                                <Form.Control
                                  name="responsePoints"
                                  value={responsePoints}
                                  onChange={handleChange}
                                  isInvalid={!!errors.responsePoints}
                                  isValid={!errors.responsePoints}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="Enter No.of response"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.responsePoints}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="my-2">
                              <Form.Group>
                                <Form.Label>Plan Type</Form.Label>
                                <Form.Select
                                  name="plantypeVal"
                                  onChange={handleChange}
                                  value={plantypeVal}
                                  isInvalid={!!errors.plantypeVal}
                                  isValid={
                                    !errors.plantypeVal && plantypeVal > 0
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value={1}>Short Term</option>
                                  <option value={2}>Long Term</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  {errors.plantypeVal}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="my-2">
                              <Form.Group>
                                <Form.Label>Refund Policy</Form.Label>
                                <Form.Select
                                  name="refundPolicyVal"
                                  onChange={handleChange}
                                  value={refundPolicyVal}
                                  isInvalid={!!errors.refundPolicyVal}
                                  isValid={
                                    !errors.refundPolicyVal &&
                                    refundPolicyVal > 0
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value={1}>Yes</option>
                                  <option value={0}>No</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="my-2">
                              <Form.Group>
                                <Form.Label>
                                  Is support assistance included?
                                </Form.Label>
                                <Form.Check
                                  checked={supportAssistance === 1}
                                  type="radio"
                                  id="Yes"
                                  onChange={(e) => setSupportAssistance(1)}
                                  label="Yes"
                                />
                                <Form.Check
                                  checked={supportAssistance === 0}
                                  type="radio"
                                  id="No"
                                  onChange={(e) => setSupportAssistance(0)}
                                  label="No"
                                />
                              </Form.Group>
                            </Col>
                            {supportAssistance === 0 ? (
                              <Col lg={6} className="my-2">
                                <Form.Group>
                                  <Form.Label>
                                    Support Assistance Price
                                  </Form.Label>
                                  <Form.Control
                                    name="assistancePrice"
                                    onChange={handleChange}
                                    value={assistancePrice}
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    min="1"
                                    placeholder="Enter support assistance price"
                                    isValid={!!errors.assistancePrice}
                                    isInvalid={!!errors.assistancePrice}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.assistancePrice}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            ) : null}
                          </Row>
                        </Row>
                        <Row className="justify-content-center mt-5">
                          <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                            <button
                              className="btn cancelBtn"
                              onClick={cancelHandler}
                            >
                              Cancel
                            </button>
                          </Col>
                          <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                            <Button
                              className="btn saveBtn mx-2"
                              disabled={isdisabled}
                              type="submit"
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditEmployerPlan;
