import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Navbartop from "./navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import Moment from "moment";
import { Link } from "react-router-dom";
import { getSupport } from "../store/slices/config";
import ShowSkeleton from "../utils/helpers/Skeleton";
import dog from "../Assets/Images/corgi.png";

function WebContactForm() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totaldata, settotalData] = useState();
  const [date, setDate] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "CookandChef Admin | Web Support";
  }, []);

  const [item, setItem] = useState({
    limit: 25,
    page: 1,
  });

  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setItem({
      ...item,
      limit: 25,
      page: newPage,
    });
    setCurrentPage(newPage);
  };

  React.useEffect(() => {
    getenquiryData(item);
  }, [dispatch, currentPage]);

  const getenquiryData = (item) => {
    dispatch(getSupport(item))
      .unwrap()
      .then((data) => {
        console.log(data);
        setData(data.user.data);
        settotalData(data.user.totalDataCount);
        setTimeout(() => {
          setisLoading(false);
        }, [1000]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;
  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Web Contact Form
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Web Contact Form</h4>
                      </div>
                    </div>

                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : data?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No data</b> found!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table responsive hover>
                            <thead>
                              <tr>
                                <th className="text-left">Date</th>
                                <th className="text-left">Name</th>
                                <th className="text-left">Email</th>
                                <th className="text-left">Mobile Number</th>
                                <th className="text-left">Subject</th>
                                <th
                                  className="text-left"
                                  style={{ width: "200px" }}
                                >
                                  Message
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.length > 0 &&
                                data?.map((enquiryData) => (
                                  <tr key={enquiryData?._id}>
                                    <td className="text-left">
                                      {" "}
                                      {Moment.utc(
                                        enquiryData?.createdAt
                                      ).format("DD-MM-YYYY")}
                                    </td>
                                    <td className="text-left">
                                      {enquiryData?.name}
                                    </td>
                                    <td className="text-left">
                                      {enquiryData?.email}
                                    </td>

                                    <td className="text-left">
                                      {enquiryData?.mobile}
                                    </td>

                                    <td className="text-left">
                                      {enquiryData?.subject}
                                    </td>
                                    <td className="text-left">
                                      <span style={{ textWrap: "wrap" }}>
                                        {enquiryData?.message}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                      <div className="justify-content-right align-right">
                        <ReactPaginate
                          previousLabel="<"
                          nextLabel=">"
                          breakLabel="..."
                          breakLinkClassName={"page-link"}
                          pageCount={totaldata / itemsPerPage}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={pagesToShowInitially}
                          onPageChange={handlePageClick}
                          containerClassName="pagination"
                          activeClassName="active"
                          pageLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          disabledClassName="disabled"
                          initialPage={currentPage - 1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WebContactForm;
