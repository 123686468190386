import React from "react";

import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const users = localStorage.getItem("users");
  if (users) {
    return true;
  } else {
    return false;
  }
};

const PublicRoutes = (props) => {
  const auth = useAuth();

  return auth ? <Navigate to="/cnc-members" /> : <Outlet />;
};

export default PublicRoutes;
