import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editfaq, getsinglefaq } from "../store/slices/faq";
import { Link } from "react-router-dom";
function EditFAQs() {
  const { id } = useParams();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  const isValidQuestion = (question) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?=.*[a-zA-Z])[a-zA-Z0-9'@& -_]{4,}$/.test(question);
  };

  const isValidAnswer = (answer) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?=.*[a-zA-Z])[a-zA-Z0-9'@& -_]{4,}$/.test(answer);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Edit FAQ";
  }, []);

  useEffect(() => {
    dispatch(getsinglefaq(id))
      .unwrap()
      .then((data) => {
        setQuestion(data.users.data[0].question);
        setAnswer(data.users.data[0].answer);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const validationErrors = { ...errors };
    switch (name) {
      case "question":
        setQuestion(value);
        validationErrors[name] =
          value === "" || !isValidQuestion(value)
            ? "Question should be atleast 4 characters"
            : "";
        if (value !== "" && isValidQuestion(value)) {
          delete validationErrors[name];
        }
        break;
      case "answer":
        e.target.style.height = "auto";
        e.target.style.height = e.target.scrollHeight + "px";
        setAnswer(value);
        validationErrors[name] =
          value === "" || !isValidAnswer(value)
            ? "Answer should be atleast 4 characters"
            : "";
        if (!value === "" && isValidAnswer(value)) {
          delete validationErrors[name];
        }
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const submitHandler = (e) => {
    const validationErrors = {};
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    if (!question.trim()) {
      validationErrors.name = "Please enter question";
    } else if (!isValidQuestion(question)) {
      validationErrors.name = "Please enter valid question";
    }
    if (!answer) {
      validationErrors.message = "Please enter answer";
    } else if (!isValidAnswer(answer)) {
      validationErrors.message = "Please enter valid answer";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      let item = {
        id: id,
        question,
        answer,
      };

      dispatch(editfaq(item))
        .unwrap()
        .then(() => {
          alert("Edited successfully");
          navigate("/faq");
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp;
                    <Link to="/faq">FAQ</Link> &#8811; Edit FAQ
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Edit FAQ</h4>
                      </div>
                    </div>
                    <section className="form-section">
                      <Form className="mx-4" onSubmit={submitHandler}>
                        <Row className="my-4  justify-content-start">
                          <Row className="d-flex ">
                            <Col lg={10} className="p-1">
                              <Form.Group>
                                <Form.Label>Question *</Form.Label>

                                <Form.Control
                                  name="question"
                                  value={question}
                                  onChange={handleChange}
                                  isInvalid={!!errors.name}
                                  isValid={!errors.name && question.length > 0}
                                  as="textarea"
                                  style={{ resize: "none" }}
                                  rows={1}
                                  placeholder="Enter question"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.name}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Col lg={10} className="p-1 mt-2">
                            <Form.Group>
                              <Form.Label>Message *</Form.Label>

                              <Form.Control
                                name="answer"
                                value={answer}
                                onChange={handleChange}
                                isInvalid={!!errors.message}
                                isValid={!errors.message && answer.length > 0}
                                as="textarea"
                                style={{ resize: "none" }}
                                rows={1}
                                placeholder="Enter answer"
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Row className="justify-content-center mt-5">
                            <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                              <button
                                className="btn cancelBtn"
                                onClick={cancelHandler}
                              >
                                Cancel
                              </button>
                            </Col>{" "}
                            <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                              <button
                                className="btn saveBtn ms-2"
                                disabled={isdisabled}
                                type="submit"
                              >
                                Save
                              </button>
                            </Col>
                          </Row>
                        </Row>
                      </Form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditFAQs;
