import React from 'react';
import './App.css';
import './style.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Home from './component/home';
import Login from './component/login';
import ProtectedRoutes from './component/ProtectedRoutes';
import PublicRoutes from './component/PublicRoutes';
import Navbartop from './component/navbar';
import CookPlan from './component/cookplan';
import ClientPlan from './component/clientplan';
import EmployerPlan from './component/employerplan';
import AddEmployerPlan from './component/AddEmployerPlan';
import AddCookPlan from './component/AddCookPlan';
import AddClientPlan from './component/AddClientPlan';
import HouseCook from './component/HouseCook';
import Chef from './component/Chef';
import PartyCook from './component/PartyCook';
import Catering from './component/Catering';
import Client from './component/Client';
import Employer from './component/Employer';
import CookDetails from './component/cookdetails';
import ClientDetails from './component/clientdetails';
import EmployerDetails from './component/employerdetails';
import EditEmployerPlan from './component/editemployerplan';
import EditClientPlan from './component/editclientplan';
import EditCookPlan from './component/editcookplan';
import Jobs from './component/JobsList';
import JobsDetails from './component/jobsDetails';
import Events from './component/events';
import EventDetails from './component/eventDetails';
import EditClientDetails from './component/editclient';
import EditCookDetails from './component/editcook';
import EditClientContactDetails from './component/editclientcontactdetails';
import EditCookContactDetails from './component/editcookcontactdetails';
import EditemployerContactDetails from './component/editemployercontactdetails';
import EditEmployerDetails from './component/editemployer';
import EditHouseProfile from './component/edithouseprofile';
import EditPartyProfile from './component/editpartyprofile';
import EditChefProfile from './component/editchefprofile';
import CSE from './component/cse';
import AddCsePlan from './component/AddCsePlan';
import EditCateringProfile from './component/editcatering';
import EditCsePlan from './component/editCse';
import Payments from './component/payments';
import SupportLogin from './component/Supportlogin';
import AddTestimonial from './component/AddTestimonils';
import Testimonial from './component/Testimonial';
import EditTestimonial from './component/EditTestimonial';
import FAQ from './component/Faq';
import AddFaqs from './component/AddFaq';
import EditFAQs from './component/editfaq';
import EmployerActivity from './component/employeractivity';
import CookActivity from './component/cookactivity';
import WebContactForm from './component/websupport';
import CookDeleteRequest from './component/cookdeleterequest';
import EmployerDeleteRequest from './component/employerdeleterequest';
import AssignClientPlan from './component/assignclientplan';
import AssignChefPlan from './component/assignchefplan';
import AssignEmployerPlan from './component/assignemployerplan.jsx';
import EditClientRequirement from './component/editrequirement.jsx';
import EditJobDetails from './component/EditJobDetails.jsx';
import EditEventsDetails from './component/EditEventDetails.jsx';
import UpgradeEmployerPlans from './component/UpgradeEmployerPlan.jsx';
import ReportsList from './component/EmployerReportList.jsx';
import CookReportsList from './component/CookReportList.jsx';
import ApplicatiionsList from './component/ApplicationList.jsx';
import EventApplicatiionsList from './component/EventApplicationList.jsx';
import PostJob from './component/PostJob.jsx';
import PostEvent from './component/PostEvent.jsx';
import BlockedCooks from './component/BlockedCooks.jsx';
import BlockedEmployers from './component/BlockedEmployers.jsx';
import ShareChef from './component/ShareChef.jsx';
import ShareCook from './component/ShareCook.jsx';
import SharedProfilesList from './component/SharedProfilesList.jsx';
import SharedEventsProfilesList from './component/SharedEventProfilesList.jsx';
import AllUsersActivity from './component/AllusersActivity.jsx';
import Whatsaptrack from './component/WhatsappTrack.jsx';
import AdminCookChangeRole from './component/AdminCookChangeRole.jsx';
import CreateCuisines from './component/CreateCuisines.jsx';
import CreateLanguage from './component/CreateLanguage.jsx';
import CreateProvince from './component/CreateProvince.jsx';
import CeateQualification from './component/CreateQualification.jsx';
import ShareClientRequirement from './component/ShareClientequirement.jsx';
import BlockedMacList from './component/BlockedMacLists.jsx';
import JobsList from './component/jobs.jsx';
import EditCateringDetails from './component/editcateringdetails.jsx';
import Advertisements from './component/Advertisements/Adevertisements.jsx';
import AddAdvertisement from './component/Advertisements/AddAverttisements.jsx';
import EditAdvertisement from './component/Advertisements/EditAdvertisements.jsx';
import AllCookList from './component/AllCooks.jsx';
import ChangeCsePassword from './component/ChangecsePassword.jsx';
import ChefDetails from './component/ChefDetails.jsx';
import PartyCookDetails from './component/PartyCookDetails.jsx';
import CateringDetails from './component/CateringDetails.jsx';
import HouseCookDetails from './component/HouseCookDetails.jsx';
import ChangeForgotPassword from './component/ChangeForgotPassword.jsx';


function App() {
  return (

      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route exact path='/Home' element={<Home />} />
          <Route path="/" element={<Navigate replace to="home" />} />
          <Route exact path="/Navbar" element={<Navbartop />} />
          <Route exact path="/cookplan" element={<CookPlan />} />
          <Route exact path="/clientplan" element={<ClientPlan />} />
          <Route exact path="/employerplan" element={<EmployerPlan />} />
          <Route exact path="/add-employer-plan" element={<AddEmployerPlan />} />
          <Route exact path="/add-cse" element={<AddCsePlan />} />
          <Route exact path="/shared-profiles-list/:id" element={<SharedProfilesList />} />
          <Route exact path="/shared-event-profiles-list/:id" element={<SharedEventsProfilesList />} />
          <Route exact path="/share-chef-profile/:id" element={<ShareChef />} />
          <Route exact path="/share-cook-profile/:id" element={<ShareCook />} />
          <Route exact path="/add-cook-plan" element={<AddCookPlan />} />
          <Route path="/add-client-plan" element={<AddClientPlan />} />
          <Route path='/house-cook' element={<HouseCook />} />
          <Route path='/change-cse-password/:id' element={<ChangeCsePassword />} />
          <Route path='/all-cook-list' element={<AllCookList />} />
          <Route path='/advertisements' element={<Advertisements />} />
          <Route path='/add-advertisements' element={<AddAdvertisement />} />
          <Route path='/edit-advertisements/:id' element={<EditAdvertisement />} />
          <Route path='/editclient/:id' element={<EditClientDetails/>} />
          <Route path='/editclientcontactdetails/:id' element={<EditClientContactDetails/>} />
          <Route path='/editcookdetails/:id' element={<EditCookDetails />} />
          <Route path='/editcateringdetails/:id' element={<EditCateringDetails />} />
          <Route path='/editrequirement/:id' element={<EditClientRequirement />} />
          <Route path='/editemployer-contactdetails/:id' element={<EditemployerContactDetails/>} />
          <Route path='/editemployer-details/:id' element={<EditEmployerDetails />} />
          <Route path='/editcookcontactdetails/:id' element={<EditCookContactDetails/>} />
          <Route path='/edithouseprofile/:id' element={<EditHouseProfile/>} />
          <Route path='/editcatering/:id' element={<EditCateringProfile/>} />
          <Route path='/editpartyprofile/:id' element={<EditPartyProfile/>} />
          <Route path='/editchefprofile/:id' element={<EditChefProfile/>} />
          <Route  path="/all-user-activity" element={<AllUsersActivity />} />
          <Route path="/employer-activity" element={<EmployerActivity />} />
          <Route path="/cook-activity" element={<CookActivity />} />
          <Route path="/cook-delete-request" element={<CookDeleteRequest />} />
          <Route path="/employer-delete-request" element={<EmployerDeleteRequest />} />
          <Route path='/chef' element={<Chef />} />
          <Route path='/editjobdetails/:id' element={<EditJobDetails />} />
          <Route path='/editevent-details/:id' element={<EditEventsDetails />} />
          <Route path='/post-job/:id' element={<PostJob />} />
          <Route path='/post-event/:id' element={<PostEvent />} />
          <Route path='/party-cook' element={<PartyCook />} />
          <Route path='/catering' element={<Catering />} />
          <Route path='/client' element={<Client />} />
          <Route path='/employer' element={<Employer />} />
          <Route path='/jobs' element={<Jobs/>} />
          <Route path='/joblist' element={<JobsList/>} />
          <Route path='/events' element={<Events/>} />
          <Route path='/jobsDetails/:id' element={<JobsDetails />} />
          <Route path='/eventDetails/:id' element={<EventDetails />} />
          <Route path='/chefdetails/:id' element={<ChefDetails />} />
          <Route path='/cateringdetails/:id' element={<CateringDetails />} />
          <Route path='/partycookdetails/:id' element={<PartyCookDetails />} />
          <Route path='/housecookdetails/:id' element={<HouseCookDetails />} />
          <Route path='/cookdetails/:id' element={<CookDetails />} />
          <Route path='/clientdetails/:id' element={<ClientDetails />} />
          <Route path='/employerdetails/:id' element={<EmployerDetails />} />
          <Route path='/editemployerplan/:id' element={<EditEmployerPlan />} />
          <Route path='/editcookplan/:id' element={<EditCookPlan />} />
          <Route path='/edit-cse-plan/:id' element={<EditCsePlan />} />
          <Route path='/editclientplan/:id' element={<EditClientPlan />} />
          <Route path='/cse' element={<CSE />} />
          <Route path ="/cook-change-role/:id" element ={<AdminCookChangeRole/>}/>
          <Route path ="/add-testimonial" element ={<AddTestimonial/>}/>
          <Route path ="/testimonial" element ={<Testimonial/>}/>
          <Route path ="/edit-testimonial/:id" element ={<EditTestimonial/>}/>
          <Route path ="/web-enquiry" element ={<WebContactForm/>}/>
          <Route path='/faq' element={<FAQ />} />
          <Route path ="/add-faq" element ={<AddFaqs/>}/>
          <Route path ="/blocked-mac-list" element={<BlockedMacList/>}/>
          <Route path ="/edit-faq/:id" element ={<EditFAQs/>}/>
          <Route path ="/assign-client-plan/:id" element ={<AssignClientPlan/>}/>
          <Route path ="/assign-cook-plan/:id" element ={<AssignChefPlan/>}/>
          <Route path ="/assign-employer-plan/:id" element ={<AssignEmployerPlan/>}/>
          <Route path ="/upgrade-employer-plan" element ={<UpgradeEmployerPlans/>}/>
          <Route path='/employer-report-list' element={<ReportsList />} />
          <Route path='/cook-report-list' element={<CookReportsList />} />
          <Route path ="/jobs/applications/:id"  element ={<ApplicatiionsList/>} />
          <Route path ="/events/applications/:id"  element ={<EventApplicatiionsList/>} />
          <Route path='/payments' element={<Payments />} />
          <Route path='/share-client-requirement/:id' element={<ShareClientRequirement />} />
          <Route path='/blocked-cooks' element={<BlockedCooks />} />
          <Route path='/blocked-employer' element={<BlockedEmployers />} />
          <Route path='/track-users' element={<Whatsaptrack/>} />
          <Route path ="/add-cuisine" element={<CreateCuisines/>} />
          <Route path ="/add-language" element={<CreateLanguage/>} />
          <Route path ="/add-province" element={<CreateProvince/>} />
          <Route path ="/add-qualification" element={<CeateQualification />} />

        </Route>
      
        <Route path="web-admin" element={<PublicRoutes />}>
          <Route exact path="/web-admin" element={<Login />} />
        
        </Route>
        <Route path='/admin-change-password' element={<ChangeForgotPassword/>} />
        <Route exact path="/cnc-members" element={<SupportLogin />} />

        
      </Routes>
  

  );
}

export default App;
