import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Navbartop from "./navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getAllUsersActivity } from "../store/slices/activity";
import AllusersLineChart from "./AllusersLineChart";

function AllUsersActivity() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totaldata, settotalData] = useState();
  const [exportData, setExportData] = useState([]);
  const [data1, setData1] = useState();
  const [dateError, setDateError] = useState("");
  const dispatch = useDispatch();
  const now = new Date();
  const day = now.getDate().toString().padStart(2, "0");
  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Note: Month is zero-based
  const year = now.getFullYear();

  const formattedDate = `${year}-${month}-${day}`;
  const [startDate, setStartDate] = useState(formattedDate);
  const [endDate, setEndDate] = useState(formattedDate);
  const [totalCateringsCount, settotalCateringsCount] = useState(0);
  const [totalClientsCount, settotalClientsCount] = useState(0);
  const [totalCooksCount, settotalCooksCount] = useState(0);
  const [totalEmployersCount, settotalEmployersCount] = useState(0);
  const [item, setItem] = useState({
    startDate: startDate,
    endDate: endDate,
    limit: 25,
    page: 1,
  });

  // console.log(data);

  // react paginate
  // const totalPages = 10;
  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  const handleApplyFilter = () => {
    const item = {
      role: "employer",
      startDate: startDate,
      endDate: endDate,
      limit: 25,
      page: 1,
    };

    if (startDate && endDate === "") {
      setDateError("Please select date");
      return;
    }
    setItem(item);
    setCurrentPage(1);
    if (startDate && endDate) {
      getclient(item);
    }
  };

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setItem({
      ...item,
      limit: 25,
      page: newPage,
    });
    setCurrentPage(newPage);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Employer Activity";
  }, []);

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    setDateError("");
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
    setDateError("");
  };

  React.useEffect(() => {
    if (startDate && endDate) {
      getclient(item);
    }
  }, [dispatch, currentPage]);

  const getclient = (item) => {
    dispatch(getAllUsersActivity(item))
      .unwrap()
      .then((data) => {
        setData1(data?.allactivity?.data?.dailywiseData);
        settotalData(data?.allactivity?.data?.totalUsersCount);
        settotalCateringsCount(data?.allactivity?.data?.totalCateringsCount);
        settotalClientsCount(data?.allactivity?.data?.totalClientsCount);
        settotalCooksCount(data?.allactivity?.data?.totalCooksCount);
        settotalEmployersCount(data?.allactivity?.data?.totalEmployersCount);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleResetFilter = () => {
    setStartDate("");
    setEndDate("");
    setItem(item);
    setCurrentPage(1);
  };

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;
  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; All Users Activity
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">All Users Activity</h4>
                      </div>
                    </div>

                    <div className="cards ps-1 pe-1">
                      <Form>
                        <Row className="d-flex flex-row card ps-2 pe-2 justify-content-left ">
                          <Col
                            lg={4}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <Form.Group>
                              <label className="m-1 label">Start Date</label>
                              <Form.Control
                                name="nameEmailOrMobile"
                                placeholder="date"
                                type="date"
                                value={startDate}
                                isInvalid={!!dateError}
                                onChange={handleStartDate}
                              />
                              <Form.Control.Feedback type="invalid">
                                {dateError}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <Form.Group>
                              <label className="m-1 label">End Date</label>
                              <Form.Control
                                name="nameEmailOrMobile"
                                placeholder="date"
                                type="date"
                                value={endDate}
                                isInvalid={!!dateError}
                                onChange={handleEndDate}
                              />
                              <Form.Control.Feedback type="invalid">
                                {dateError}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <div className="group d-flex justify-content-left mt-4 ">
                              {/* <Button
                                className="cancel_btn"
                                onClick={handleResetFilter}
                              >
                                Reset
                              </Button> */}
                              <Button
                                className="apply_btn ms-2"
                                onClick={handleApplyFilter}
                              >
                                Submit
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                        <div className="card" style={{ background: "#f2f2f2" }}>
                          <div className="card-body">
                            <div className="card-block pt-2 pb-0">
                              <div className="media">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    {totalEmployersCount}
                                  </h4>
                                  <span className="grey darken-1">
                                    Employers Registered
                                  </span>
                                </div>
                                <div className="media-right text-right">
                                  <i className="fas fa-wallet fa-edit-icon info"></i>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Widget-line-chart2"
                              className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                        <div className="card" style={{ background: "#f2f2f2" }}>
                          <div className="card-body">
                            <div className="card-block pt-2 pb-0">
                              <div className="media">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    {totalCooksCount + totalCateringsCount}
                                  </h4>
                                  <span className="grey darken-1">
                                    Cooks Registered
                                  </span>
                                </div>
                                <div className="media-right text-right">
                                  <i className="fas fa-donate fa-edit-icon success"></i>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Widget-line-chart2"
                              className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                        <div
                          className="card "
                          style={{ background: "#f2f2f2" }}
                        >
                          <div className="card-body">
                            <div className="card-block pt-2 pb-0">
                              <div className="media">
                                <div className="media-body white text-left">
                                  <h4 className="font-medium-5 card-title mb-0">
                                    {totalClientsCount}
                                  </h4>
                                  <span className="grey darken-1">
                                    New Clients
                                  </span>
                                </div>
                                <div className="media-right text-right">
                                  <i className="fas fa-donate fa-edit-icon success"></i>
                                </div>
                              </div>
                            </div>
                            <div
                              id="Widget-line-chart2"
                              className="height-70 lineChartWidget WidgetlineChart2 mb-2"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body collapse show mb-5">
                      <div style={{ height: "500px", width: "100%" }}>
                        <AllusersLineChart
                          hourlyActData={data1}
                          activity={"users"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllUsersActivity;
