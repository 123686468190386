import axios from "axios";
import { API_PATHS } from "../utils/constants/api.constants";

class AdsService {

    static getSingleAdd(id) {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        api = API_PATHS.getAdvertisements + "?id="+ id;
        return axios
            .get(api,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }


    static editAdvertisements(item) {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
        api = API_PATHS.editAdvertisements 
        return axios
            .put(api,item,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static addAdvertisements(item) {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
        api = API_PATHS.addAdvertisements
        return axios
            .post(api,item,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

    static getAdvertisements(item) {
        let api = '';
        let user = JSON.parse(localStorage.getItem("users"));
        const token = user.data.token;
    
        const config = {
          headers: {
            "x-access-token": token,
          },
        };
        api = `${API_PATHS.getAdvertisements}?limit=${item.limit}&page=${item.page}` 
        return axios
            .get(api,config)
            .then((response) => {
                if (response.data) {
                    // localStorage.setItem("users", JSON.stringify(response.data));
                }
                return response.data;
            });
    }

        static deleteAdvertisements(id) {
    let api = '';
    let user = JSON.parse(localStorage.getItem("users"));
    const token = user.data.token;

    const config = {
      headers: {
        "x-access-token": token,
      },
    };
  
      api = API_PATHS.deleteAdvertisemets + '?id=' + id ;
    localStorage.removeItem("id");
    return axios
        .delete(api,config)
        .then((response) => {
            if (response.data) {
                // localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

    static removeusersDetails() {
        localStorage.removeItem("users");
    }

    static getusersDetails() {
        return JSON.parse(localStorage.getItem("users"));
    }
}

export default AdsService;