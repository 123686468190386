import React, { useEffect, useState } from "react";
import Navbartop from "../navbar";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { confirmAlert } from "react-confirm-alert";
import ReactPaginate from "react-paginate";
import ShowSkeleton from "../../utils/helpers/Skeleton";
import {
  DeleteAdvertisements,
  GetAdvertisements,
} from "../../store/slices/advertisements";

function Advertisements() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totaldata, settotalData] = useState();
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    document.title = "CookandChef Admin | Advertisements";
  }, []);

  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
  };

  React.useEffect(() => {
    const items = {
      limit: itemsPerPage,
      page: currentPage,
    };
    getAds(items);
  }, [dispatch, currentPage]);

  const getAds = (items) => {
    dispatch(GetAdvertisements(items))
      .unwrap()
      .then((data) => {
        setData(data.ads.data);
        settotalData(data.ads.totalDataCount);
        setTimeout(() => {
          setisLoading(false);
        }, [1000]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const Delete = async function deleteads(id) {
    dispatch(DeleteAdvertisements(id))
      .unwrap()
      .then((data) => {
        const items = {
          limit: itemsPerPage,
          page: currentPage,
        };
        alert(data?.ads?.message)
        getAds(items);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete this advertisement?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Advertisement
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Advertisements </h4>
                        &nbsp;&nbsp;
                        <Button
                          onClick={() => navigate("/add-advertisements")}
                          style={{ height: "25px" }}
                          className="mt-2 btn-delete"
                        >
                          Add Advertisement
                        </Button>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : data?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No data</b> found!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table
                            responsive
                            hover
                            style={{ overflowX: "scroll" }}
                          >
                            <thead>
                              <tr>
                                <th className="text-left">User Type</th>

                                <th className="text-center">Image</th>

                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((val, index) => (
                                <tr key={index}>
                                  <td
                                    className="text-left"
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    {val.usertype}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{
                                      width: "200px",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <img
                                      src={val.image}
                                      alt=""
                                      style={{ height: "50px", width: "80px" }}
                                    />
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <Link
                                      to={`/edit-advertisements/${
                                        val._id ? val._id : null
                                      }`}
                                      className="btn bg-purple col-white"
                                      style={{ height: "25px" }}
                                    >
                                      Edit
                                    </Link>
                                    &nbsp;&nbsp;
                                    <button
                                      onClick={() => deleteData(val._id)}
                                      className="btn btn-delete col-white"
                                      style={{ height: "25px" }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                      <div className="justify-content-right">
                        {totaldata / itemsPerPage > 1 && (
                          <ReactPaginate
                            key={currentPage}
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            breakLinkClassName={"page-link"}
                            pageCount={Math.ceil(totaldata / itemsPerPage)}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={pagesToShowInitially}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            initialPage={currentPage - 1}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Advertisements;
